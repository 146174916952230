import React from "react";
import { useQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";

import Privileges from "../../../Constants/Privilages";
import { onFormResetAction } from "../../../Store/Actions";
import { conditionalFooterRowStyles } from "../../../Constants";
import HelperFns, { showToast } from "../../../Helpers/HelperFns";
import { GET_AGENT_ACTIVITIES_REPORT } from "../../../Graphql/query";

import { Tooltip } from "@mui/material";
import Loader from "../../../Components/Loader";
import { DateTimePickerForm } from "form-builder";
import DataTable from "react-data-table-component";
import { LeadAgentSelect } from "../../../Components/CRM";

const filtersReducerKey = "agentActivitiesReport";
const formatData = (data) => {
  if (!data?.length) return data || [];

  const sum = data?.reduce(
    (acc, curr) => ({
      total_activities: acc?.total_activities
        ? acc?.total_activities + +curr?.total_activities
        : +curr?.total_activities,
      call_count: acc?.call_count
        ? acc?.call_count + +curr?.call_count
        : +curr?.call_count,
      totalConnectedCall: acc?.totalConnectedCall
        ? acc?.totalConnectedCall + +curr?.totalConnectedCall
        : +curr?.totalConnectedCall,
      message_count: acc?.message_count
        ? acc?.message_count + +curr?.message_count
        : +curr?.message_count,
      meeting_count: acc?.meeting_count
        ? acc?.meeting_count + +curr?.meeting_count
        : +curr?.meeting_count,
      demo_count: acc?.demo_count
        ? acc?.demo_count + +curr?.demo_count
        : +curr?.demo_count,
      email_count: acc?.email_count
        ? acc?.email_count + +curr?.email_count
        : +curr?.email_count,
      proposal_count: acc?.proposal_count
        ? acc?.proposal_count + +curr?.proposal_count
        : +curr?.proposal_count,
    }),
    {}
  );

  return [...data, { name: "Total", ...sum }];
};

const AgentActivitiesReport = () => {
  const dispatch = useDispatch();

  // Local State
  const [data, setData] = React.useState([]);

  // Reducer State
  const filters = useSelector((state) => state.crm[filtersReducerKey]);

  // Server State
  const { loading } = useQuery(GET_AGENT_ACTIVITIES_REPORT, {
    variables: {
      to: filters?.to?.format("YYYY-MM-DD"),
      from: filters?.from?.format("YYYY-MM-DD"),
      agents: filters?.agents ? filters?.agents?.map((agent) => agent?.id) : [],
    },
    onCompleted: (res) => {
      setData(formatData(res?.agent_activities_report));
    },
    onError: (error) => {
      showToast(
        "error",
        error?.graphQLErrors?.[0]?.extensions?.reason ||
          error?.graphQLErrors?.[0]?.message ||
          error?.message
      );
    },
  });

  // Constants
  const canViewAll = HelperFns.checkPrivileges({
    privileges: [Privileges.VIEW_OTHER_CRM_REPORTS],
  });
  const columns = React.useMemo(
    () => [
      { name: "Agent name", selector: "name", grow: 2 },
      {
        name: "activities",
        cell: (row) => (
          <ViewCell
            count={row?.total_activities}
            per_day={row?.activities_per_day}
          />
        ),
      },
      {
        name: "Call",
        cell: (row) => (
          <ViewCell count={row?.call_count} per_day={row?.calls_per_day} />
        ),
      },
      {
        name: "Connected Call",
        cell: (row) => <ViewCell count={row?.totalConnectedCall} />,
      },
      {
        name: "message",
        cell: (row) => (
          <ViewCell
            count={row?.message_count}
            per_day={row?.messages_per_day}
          />
        ),
      },
      {
        name: "meeting",
        cell: (row) => (
          <ViewCell
            count={row?.meeting_count}
            per_day={row?.meetings_per_day}
          />
        ),
      },
      {
        name: "demo",
        cell: (row) => (
          <ViewCell count={row?.demo_count} per_day={row?.demos_per_day} />
        ),
      },
      {
        name: "email",
        cell: (row) => (
          <ViewCell count={row?.email_count} per_day={row?.emails_per_day} />
        ),
      },
      {
        name: "proposal",
        cell: (row) => (
          <ViewCell
            count={row?.proposal_count}
            per_day={row?.proposals_per_day}
          />
        ),
      },
    ],
    []
  );

  /* ↓ Helpers ↓ */

  const handleSelectFilters = (val, { name }) => {
    dispatch(onFormResetAction(filtersReducerKey, { ...filters, [name]: val }));
  };
  const handleDateFiltersChange = (name, val) => {
    dispatch(onFormResetAction(filtersReducerKey, { ...filters, [name]: val }));
  };

  return (
    <>
      {/* Filters */}
      <div className="d-flex gap-20 justify-content-between align-items-end mb-3 mt-2">
        {canViewAll ? (
          <LeadAgentSelect
            isMulti
            isClearable
            name="agents"
            label="agent"
            page="REPORTS"
            value={filters.agents}
            onChange={handleSelectFilters}
            inputContainerStyle="w-100"
            containerStyle=" "
            rootStyle="flex-1"
          />
        ) : null}
        <DateTimePickerForm
          hasIcon
          isClearable
          label="from"
          value={filters.from}
          rootStyle="flex-fill"
          containerStyle="py-0"
          labelStyle="w-100 mb-2"
          datePickerContainer="w-100"
          onChange={(val) => handleDateFiltersChange("from", val)}
        />
        <DateTimePickerForm
          hasIcon
          isClearable
          label="to"
          value={filters.to}
          rootStyle="flex-fill"
          containerStyle="py-0"
          labelStyle="w-100 mb-2"
          datePickerContainer="w-100"
          onChange={(val) => handleDateFiltersChange("to", val)}
        />
      </div>

      {/* Table */}
      <DataTable
        noHeader
        data={data}
        columns={columns}
        className="cards_table"
        progressPending={loading}
        progressComponent={<Loader />}
        conditionalRowStyles={conditionalFooterRowStyles}
      />
    </>
  );
};

export default AgentActivitiesReport;

const ViewCell = ({ count, per_day }) => {
  return (
    <div className="d-flex gap-10">
      <b style={{ width: 35, textAlign: "center" }}>{count}</b>
      {per_day ? (
        <Tooltip title="per day">
          <span style={{ minWidth: 40, textAlign: "center" }}>({per_day})</span>
        </Tooltip>
      ) : null}
    </div>
  );
};
