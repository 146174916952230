import React, { useState } from "react";
import ClockinsContainer from "../../../Containers/FlexibleLists/ClockinsContainer";
import moment from "moment";
import MyClockinsContainer from "./MyClockinsContainer";
import FlexEditAttendanceModal from "../../../Components/FlexEditAttendanceModal";
import { useTranslation } from "react-i18next";
import { getSumOfDurations } from "../../../Helpers/HelperFns";

const MyWorkDayTemplatesContainer = ({
  handleOpenClockinModal,
  day,
  refetchTimeSheet,
  isMyProfile = true,
  ...props
}) => {
  const { t } = useTranslation();

  const [editFlexAttendanceModal, setEditFlexAttendanceModal] = useState({
    isOpen: false,
    isView: false,
    clockin: {},
  });

  const [isWorkDayTemplatesOpen, setIsWorkDayTemplatesOpen] = useState(false);

  const handleOpenWorkdayTemplates = () => {
    setIsWorkDayTemplatesOpen((prev) => !prev);
  };

  const date = moment(day?.date).format("ddd YYYY-MM-DD");

  const containers = day?.details;

  const totalSignIns = containers
    ?.map(
      (container) => container?.calculatedExtraDataPerContainer?.totalClockIns
    )
    ?.flat(Infinity)
    ?.reduce((acc, curr) => +acc + +curr, 0);

  const totalHours = getSumOfDurations(
    containers
      ?.map(
        (container) => container?.calculatedExtraDataPerContainer?.totalHours
      )
      ?.flat(Infinity)
  );

  const actualTotalHours = getSumOfDurations(
    containers
      ?.map(
        (container) =>
          container?.calculatedExtraDataPerContainer?.actualTotalHours
      )
      ?.flat(Infinity)
  );

  const overTime = getSumOfDurations(
    containers
      ?.map(
        (container) => container?.calculatedExtraDataPerContainer?.totalOverTime
      )
      ?.flat(Infinity)
  );

  const actualOverTime = getSumOfDurations(
    containers
      ?.map(
        (container) =>
          container?.calculatedExtraDataPerContainer?.actualTotalOverTime
      )
      ?.flat(Infinity)
  );

  const Leave = getSumOfDurations(
    containers
      ?.map(
        (container) => container?.calculatedExtraDataPerContainer?.totalLeave
      )
      ?.flat(Infinity)
  );

  return (
    <>
      <div className="clockins-main-container">
        <div
          onClick={handleOpenWorkdayTemplates}
          className="clockins-container"
          style={{
            cursor: "pointer",
            paddingTop: 10,
          }}
        >
          <p className="clockins-container-date font-weight-bold">{date}</p>
          <div className="clockins-container-total-sign-ins d-flex align-items-center">
            <p className="mr-2">{t("total sign-ins")}</p>
            <p>{totalSignIns}</p>
          </div>
          <div className="clockins-container-total-hours d-flex align-items-center">
            <p className="mr-2">total hours</p>
            <p>
              {totalHours == "00:00:00" ? "" : totalHours}({actualTotalHours})
            </p>
          </div>

          <div className="clockins-container-overtime d-flex align-items-center">
            <p className="mr-2">{t("overtime")}</p>
            <p>
              {overTime == "00:00:00" ? "" : overTime}({actualOverTime})
            </p>
          </div>

          <div className="clockins-container-leave d-flex align-items-center">
            <p className="mr-2">leave</p>
            <p>{Leave}</p>
          </div>
        </div>

        {isWorkDayTemplatesOpen ? (
          <div>
            {containers.map((container, index) => (
              <>
                <MyClockinsContainer
                  handleOpenClockinModal={handleOpenClockinModal}
                  container={container}
                  refetchTimeSheet={refetchTimeSheet}
                  editFlexAttendanceModal={editFlexAttendanceModal}
                  setEditFlexAttendanceModal={setEditFlexAttendanceModal}
                  isMyProfile={isMyProfile}
                  {...props}
                />
                {index !== containers?.length - 1 && containers?.length > 1 ? (
                  <hr />
                ) : null}
              </>
            ))}
          </div>
        ) : null}
      </div>
      {editFlexAttendanceModal.isOpen ? (
        <FlexEditAttendanceModal
          dismiss={() => {
            setEditFlexAttendanceModal({
              isOpen: false,
              isView: false,
              clockin: null,
            });
          }}
          isModalVisible={editFlexAttendanceModal?.isOpen}
          isViewRequest={editFlexAttendanceModal?.isView}
          fetchData={refetchTimeSheet}
          isMyProfile={isMyProfile}
          {...props}
        />
      ) : null}
    </>
  );
};

export default MyWorkDayTemplatesContainer;
