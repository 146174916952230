import React, { useCallback, useMemo } from "react";
import {
  Button,
  Checkbox,
  Collapse,
  Drawer,
  FormControlLabel,
  styled,
  Tooltip,
  InputBase,
  LinearProgress,
} from "@mui/material";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import "./NewMultiSelectStyle.scss";
import { useDispatch } from "react-redux";
import { updateValueAction } from "../../../Store/Actions";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {
  CalendarToday,
  ExpandMore,
  Person2Outlined,
  Person3Outlined,
  PersonOutlined,
} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import ListIcon from "@mui/icons-material/List";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding } from "@fortawesome/free-regular-svg-icons";
import { faMailBulk } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import Select, { components } from "react-select";

const IconOption = React.memo(({ isSelected, children, ...props }) => {
  const { onMouseMove, onMouseOver, onMouseDown, ...rest } = props.innerProps;
  const newProps = Object.assign(props, { innerProps: rest });
  return (
    <components.Option {...newProps}>
      <input
        className="mr-2"
        style={{ accentColor: "#099bf0" }}
        type="checkbox"
        checked={isSelected}
      />
      {children}
    </components.Option>
  );
});

const IndicatorSeparator = ({ num, ...props }) => {
  if (!num) return null;
  return (
    <div
      className={`remaining-container ${
        props?.isFocused ? "remaining-container-active" : ""
      }`}
    >
      {num}
    </div>
  );
};

const customClear = ({ num, ...props }) => {
  return (
    <components.ClearIndicator {...props}>
      <CloseIcon
        sx={{ color: props?.isFocused ? "#009efb" : "#898a8d", fontSize: 16 }}
      />
    </components.ClearIndicator>
  );
};

const Menu = React.memo(({ children, ...props }) => {
  // console.log(props.getValue());
  return (
    <components.Menu {...props}>
      {children}
      <div className="d-flex justify-content-end other-text-styles">
        <span className="plus-jakarta-sans ">
          {`${props?.getValue()?.length ?? 0} of ${props?.options?.length}`}
        </span>
      </div>
    </components.Menu>
  );
});

const customStyles = {
  control: (provided, state) => {
    return {
      ...provided,
      display: "flex",
      flexWrap: "nowrap",
      border: "0 solid #D6D3D3",
      borderBottom: `2px solid ${state?.isFocused ? "#009EFB" : "#D6D3D3"}`,
      borderRadius: "0px",
      boxShadow: "none",
      "&:hover": {
        borderColor: state?.isFocused ? "#009EFB" : "#D6D3D3",
      },
    };
  },
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: state?.isFocused ? "#009EFB" : "#D6D3D3",
    "&:hover": {
      color: state?.isFocused ? "#009EFB" : "#D6D3D3",
    },
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    textOverflow: "ellipsis",
    maxWidth: "90%",
    maxHeight: "30px",
    overflow: "hidden",
  }),
  menu: (provided) => ({
    ...provided,
    padding: "10px",
  }),
  menuList: (provided) => ({
    ...provided,
    "::-webkit-scrollbar": {
      width: "7px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#B0B2B6",
      border: "#fff solid",
      borderWidth: "2px",
      borderRadius: "8px",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#009EFB",
      border: "transparent solid",
      borderWidth: "10px",
      borderRadius: "8px",
    },
  }),
  option: (provided) => ({
    ...provided,
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "#f2f6f8",
    },
    "&:focus": {
      backgroundColor: "#f2f6f8",
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#D6D3D3",
  }),
  multiValue: (provided, state) => {
    return {
      ...provided,
      backgroundColor: "#fff",
      border: `1px solid ${state.isFocused ? "#009EFB" : "#D6D3D3"}`,
      borderRadius: "7px",
    };
  },
  multiValueRemove: (provided) => ({
    ...provided,
    color: "#D6D3D3",
    "&:hover": {
      backgroundColor: "#fff",
      color: "#9ea3a2",
    },
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: "#898A8D",
    padding: "1px 2px",
  }),
};

const renderIcon = (open, icon) => {
  switch (icon) {
    case "people":
      return (
        <PeopleOutlineIcon
          fontSize="small"
          sx={{ color: open ? "#009EFB" : "#D6D3D3" }}
        />
      );
    case "person":
      return (
        <PersonOutlined
          fontSize="small"
          sx={{ color: open ? "#009EFB" : "#D6D3D3" }}
        />
      );
    case "list":
      return (
        <ListIcon
          fontSize="small"
          sx={{ color: open ? "#009EFB" : "#D6D3D3" }}
        />
      );
    case "calendar":
      return (
        <CalendarToday
          sx={{ color: open ? "#009EFB" : "#D6D3D3", fontSize: 15 }}
        />
      );
    case "building":
      return (
        <FontAwesomeIcon
          icon={faBuilding}
          color={open ? "#009EFB" : "#D6D3D3"}
        />
      );
    case "office":
      return (
        <FontAwesomeIcon
          icon={faMailBulk}
          color={open ? "#009EFB" : "#D6D3D3"}
        />
      );
    default:
      break;
  }
};

const NewMultiSelect = ({
  options,
  value,
  onChange,
  isLoading,
  dataTableRef,
  name,
  label,
  icon,
  placeHolder,
  optionLabel = "name",
  optionValue = "id",
  isSingle,
  disableClear,
  onIntercept = () => {},
  ...props
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  let allOptions = props?.allOptions ?? options;

  const handleSelect = useCallback(
    (opt) => {
      if (onIntercept(name, opt)) return;
      if (isSingle) {
        dispatch(updateValueAction(dataTableRef, name, opt?.[optionValue]));
      } else {
        dispatch(
          updateValueAction(
            dataTableRef,
            name,
            opt?.map((o) => o?.[optionValue])
          )
        );
      }
    },
    [dispatch, dataTableRef, isSingle, name, optionValue]
  );

  const memoizedControl = useCallback(
    (props) => (
      <components.Control {...props}>
        {icon ? (
          <div className={"bselect_icon"}>
            {renderIcon(props?.isFocused, icon)}
          </div>
        ) : null}
        {props?.children}
      </components.Control>
    ),
    [icon]
  );

  const memoizedIndicatorSeparator = useCallback(
    (props) =>
      IndicatorSeparator({
        num: isSingle ? 0 : (value?.length ?? 0),
        ...props,
      }),
    [isSingle, value]
  );

  const customComponents = useMemo(
    () => ({
      Option: IconOption,
      ClearIndicator: customClear,
      Control: memoizedControl,
      Menu,
      IndicatorSeparator: memoizedIndicatorSeparator,
    }),
    [IconOption, customClear, memoizedControl, Menu, memoizedIndicatorSeparator]
  );

  const selectedValue = useMemo(() => {
    return isSingle
      ? options?.find((opt) => opt?.[optionValue] === value)
      : allOptions?.filter((item) => value?.includes(item?.[optionValue]));
  }, [isSingle, options, allOptions, value, optionValue]);

  return (
    <div className="new-multi-select-root-container">
      <label className={`new-multi-select-label-style font-weight-400`}>
        {label}
      </label>
      <Select
        options={options}
        getOptionLabel={(option) => option?.[optionLabel]}
        getOptionValue={(option) => option?.[optionValue]}
        isMulti={!isSingle}
        hideSelectedOptions={false}
        value={selectedValue}
        onChange={handleSelect}
        isClearable={disableClear ? false : true}
        styles={customStyles}
        components={customComponents}
        closeMenuOnSelect={false}
        placeholder={<>{placeHolder ?? t("select...")}</>}
        isLoading={isLoading}
      />
    </div>
  );
};

export default React.memo(NewMultiSelect);
