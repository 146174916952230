import {
  TOGGLE_ASSET_TYPE_MODAL,
  TOGGLE_COMPANY_ASSET_MODAL,
  ADD_ASSET_ITEMS_INPUT,
  REMOVE_ASSET_ITEMS_INPUT,
  ON_ASSET_CHILD_INPUT_CHANGE,
  TOGGLE_TRANSFER_ASSETS_MODAL,
  TOGGLE_UNSCRAPP_ITEM_MODAL,
  TOGGLE_SCRAPP_ITEM_MODAL,
  TOGGLE_WAREHOUSE_DETAILS_MODAL,
  TOGGLE_WAREHOUSE_MODAL,
  TOGGLE_ASSET_UNITS_MODAL,
  TOGGLE_ADD_NEW_UNITS_MODAL,
  TOGGLE_ASSIGN_RETRIEVE_ASSET_MODAL,
  RESET_ASSIGN_RETRIEVE_ASSET_MODAL_VALUES,
  ADD_ASSET_INPUTS,
  TOGGLE_EMPLOYEE_ASSET_REQUEST_MODAL,
  TOGGLE_ACCEPT_ASSET_REQUEST_MODAL,
  TOGGLE_RETRIVE_ASSET_MODAL,
  TOGGLE_EXCHANGE_ITEM_MODAL,
  TOGGLE_SCRAP_NON_SERIALIZED_ASSETS,
  ADJUST_NUMBER_OF_UNITS,
} from "../Actions/Assets/types";
import Types from "../Actions/types";

export const initState = {
  // asset type start
  assetTypeModal: {
    isOpen: false,
  },
  upsertAssetTypeForm: {
    id: null,
    name: null,
  },
  upsertAssetTypeFormClientValidations: [],
  // asset type end

  // warehouse start
  warehouseModal: {
    isOpen: false,
  },
  upsertWarehouseForm: {
    id: null,
    name: null,
    address: null,
  },
  upsertWarehouseClientValidations: [],
  // warehouse end

  // start of company assets
  companyAssetModal: {
    isOpen: false,
  },
  upsertCompanyAssetForm: {
    id: null,
    name: "",
    brand: "",
    type_id: "",
    model: "",
    number_of_units: "",
    items: [],
    product_type: "serialized",
    description: "",
  },
  upsertCompanyAssetFormClientValidations: [],
  // end of company assets

  // start of asset units modal
  assetUnitsModal: {
    isOpen: false,
  },
  // end of asset units modal

  // start of add new units modal
  addNewUnitsModal: {
    isOpen: false,
  },
  addNewUnitsForm: {},
  addNewUnitsFormClientValidations: [],
  // end of add new units modal

  // start of assign / retrive assets modal
  assignRetriveAssetModal: {
    isOpen: false,
    preSelectedEmployee: null,
    preSelectedTabValue: null,
  },
  assignRetriveAssetForm: {
    employee_id: null,
    warehouseId: null,
    assetId: null,
    items: [
      {
        id: null,
        asset_id: null,
        product_type: "",
        number_of_items: "",
        serial_number: "",
        serialOptions: [],
        warehouseOptions: [],
        warehouse_id: null,
      },
    ],
  },
  assignRetriveAssetFormClientValidations: [],
  // end of assign / retrive assets modal

  // scrapp item start
  scrappItemModal: {
    isOpen: false,
    item: null,
  },
  scrappItemForm: {
    id: null,
    warehouseId: null,
  },
  scrappItemClientValidations: [],
  // scrapp item end

  // unscrapp start
  unscrappItemModal: {
    isOpen: false,
    item: null,
  },
  unscrappItemForm: {
    id: null,
    warehouseId: null,
  },
  unscrappItemClientValidations: [],
  // unscrapp end

  // warehouse details start
  warehouseDetailsModal: {
    isOpen: false,
    warehouseId: null,
  },
  // warehouse details end

  // transfer assets start
  transferAssetsModal: {
    isOpen: false,
    asset: null,
    warehouseId: null,
  },

  transferAssetForm: {
    warehouseId: null,
    numberOfUnuts: 0,
    itemIds: [],
  },

  transferAssetsFormClientValidation: [],
  // transfer assets end

  // employee assets requests start
  employeeAssetsRequestModal: {
    isOpen: false,
  },

  employeeAssetsRequestForm: {
    assetType: null,
    description: null,
  },

  employeeAssetRequestClientValidations: [],

  acceptAssetRequestModal: {
    isOpen: false,
    request: null,
  },

  acceptAssetRequestForm: {
    assetId: null,
    numberOfUnits: null,
    itemIds: [],
    warehouseId: null,
  },

  acceptAssetRequestClientValidations: [],
  // employee assets requests end

  // retrieve asset start
  retriveAssetModal: {
    isOpen: false,
    itemId: null,
  },

  retriveAssetForm: {
    warehouseId: null,
  },

  retriveAssetClientValidations: [],
  // retrieve asset end

  // exchange item start
  exchangeItemModal: {
    isOpen: false,
    itemId: null,
  },

  exchangeItemForm: {
    employeeId: null,
  },

  exchangeItemClientValidations: [],
  // exchange item end

  // scrapp non serialized assets modal start
  scrapNonSerializedAssetModal: {
    isOpen: false,
    items: [],
  },

  scrapNonSerializedForm: {
    number: null,
  },

  scrapNonSerializedFormClientValidations: [],
  // scrapp non serialized assets modal end
};

export const assetsReducer = (state = initState, action) => {
  switch (action.type) {
    case TOGGLE_ASSET_TYPE_MODAL:
      return {
        ...state,
        assetTypeModal: action?.payload,
      };

    case TOGGLE_WAREHOUSE_MODAL:
      return {
        ...state,
        warehouseModal: action?.payload,
      };

    case TOGGLE_COMPANY_ASSET_MODAL:
      return {
        ...state,
        companyAssetModal: action?.payload,
      };

    case ADD_ASSET_ITEMS_INPUT:
      return {
        ...state,
        [action.payload.formName]: {
          ...state?.[action.payload.formName],
          items: [
            ...state?.[action.payload.formName]?.items,
            {
              serial_number: "",
            },
          ],
        },
      };

    case ADD_ASSET_INPUTS:
      return {
        ...state,
        [action.payload.formName]: {
          ...state?.[action.payload.formName],
          items: [
            ...state?.[action.payload.formName]?.items,
            {
              id: null,
              asset_id: null,
              product_type: "",
              number_of_items: "",
              serial_number: "",
              serialOptions: [],
              warehouseOptions: [],
              warehouse_id: null,
            },
          ],
        },
      };

    case REMOVE_ASSET_ITEMS_INPUT:
      return {
        ...state,
        [action.payload.formName]: {
          ...state?.[action.payload.formName],
          items: state?.[action.payload.formName]?.items?.filter(
            (_, i) => i != action.payload?.index
          ),
        },
      };

    case ON_ASSET_CHILD_INPUT_CHANGE:
      let newValues = [
        ...state[action.payload.formName]?.[action.payload.childName],
      ];
      newValues[action.payload.index][action.payload.name] =
        action.payload.value;
      return {
        ...state,
        [action.payload.formName]: {
          ...state[action.payload.formName],
          [action.payload.childName]: newValues,
        },
      };

    case TOGGLE_ASSET_UNITS_MODAL:
      return {
        ...state,
        assetUnitsModal: action?.payload,
      };

    case TOGGLE_ADD_NEW_UNITS_MODAL:
      return {
        ...state,
        addNewUnitsModal: action?.payload,
      };

    case TOGGLE_ASSIGN_RETRIEVE_ASSET_MODAL:
      return {
        ...state,
        assignRetriveAssetModal: action?.payload,
      };

    case RESET_ASSIGN_RETRIEVE_ASSET_MODAL_VALUES:
      return {
        ...state,
        assignRetriveAssetForm: {
          employee_id: null,
          warehouseId: null,
          assetId: null,
          items: [
            {
              id: null,
              asset_id: null,
              product_type: "",
              number_of_items: "",
              serial_number: "",
              serialOptions: [],
              warehouseOptions: [],
              warehouse_id: null,
            },
          ],
        },
        assignRetriveAssetFormClientValidations: [],
      };

    case TOGGLE_SCRAPP_ITEM_MODAL:
      return {
        ...state,
        scrappItemModal: action?.payload,
      };

    case TOGGLE_UNSCRAPP_ITEM_MODAL:
      return {
        ...state,
        unscrappItemModal: action?.payload,
      };

    case TOGGLE_WAREHOUSE_DETAILS_MODAL:
      return {
        ...state,
        warehouseDetailsModal: action?.payload,
      };

    case TOGGLE_TRANSFER_ASSETS_MODAL:
      return {
        ...state,
        transferAssetsModal: action?.payload,
      };

    case TOGGLE_EMPLOYEE_ASSET_REQUEST_MODAL:
      return {
        ...state,
        employeeAssetsRequestModal: action?.payload,
      };

    case TOGGLE_ACCEPT_ASSET_REQUEST_MODAL:
      return {
        ...state,
        acceptAssetRequestModal: action?.payload,
      };

    case TOGGLE_RETRIVE_ASSET_MODAL:
      return {
        ...state,
        retriveAssetModal: action?.payload,
      };

    case TOGGLE_EXCHANGE_ITEM_MODAL:
      return {
        ...state,
        exchangeItemModal: action?.payload,
      };

    case TOGGLE_SCRAP_NON_SERIALIZED_ASSETS:
      return {
        ...state,
        scrapNonSerializedAssetModal: action?.payload,
      };

    case ADJUST_NUMBER_OF_UNITS:
      const numberOfUnits = action?.payload?.numberOfUnits;
      const currentNumberOfUnits = state?.upsertCompanyAssetForm?.items?.length;

      if (numberOfUnits > currentNumberOfUnits) {
        return {
          ...state,
          upsertCompanyAssetForm: {
            ...state?.upsertCompanyAssetForm,
            items: [
              ...state?.upsertCompanyAssetForm?.items,
              ...[
                ...new Array(numberOfUnits - currentNumberOfUnits)
                  .fill(null)
                  .map((_) => ({
                    serial_number: "",
                  })),
              ],
            ],
          },
        };
      } else {
        return {
          ...state,
          upsertCompanyAssetForm: {
            ...state?.upsertCompanyAssetForm,
            items: [
              ...state?.upsertCompanyAssetForm?.items?.slice(
                0,
                -(currentNumberOfUnits - numberOfUnits)
              ),
            ],
          },
        };
      }

    case Types.LOG_OUT:
    case "SWITCH_ACCOUNT":
      return { ...initState };

    default:
      return state;
  }
};
