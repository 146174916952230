import { DateTimePickerForm } from "form-builder";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { AddButton } from "./Buttons";
import { RemoveIconButton } from "./IconButtonWithTooltip";

const ClockinsInput = ({ clockins, setClockins }) => {
  const { t } = useTranslation();

  const handleChangeClockin = (name, value, index) => {
    setClockins((clockins) =>
      clockins?.map((clockIn, i) => {
        if (index == i) {
          return {
            ...clockIn,
            [name]: value,
          };
        } else {
          return clockIn;
        }
      })
    );
  };

  const handleAddClockin = () => {
    setClockins((clockins) => [
      ...clockins,
      { clockInTime: null, clockOutTime: null },
    ]);
  };

  const handleRemoveClockin = (index) => {
    setClockins((clockins) => clockins?.filter((_, i) => i != index));
  };

  return (
    <div>
      <div className="d-flex align-items-center gap-5">
        <p className="my-2 font-weight-bold">{t("clock-ins")}</p>
        <AddButton isIconOnly onClick={handleAddClockin} />
      </div>

      {clockins?.map((clockIn, index) => (
        <div className="row mb-1">
          <DateTimePickerForm
            label="clock in time"
            name="clockInTime"
            rootStyle="col-5"
            containerStyle="container-style-default pb-0"
            datePickerContainer="w-100"
            timeOnly
            onChange={(value) =>
              handleChangeClockin("clockInTime", value, index)
            }
            onSelect={(value) =>
              handleChangeClockin("clockInTime", value, index)
            }
            value={clockIn?.clockInTime}
          />
          <DateTimePickerForm
            label="clock out time"
            name="clockOutTime"
            rootStyle="col-5"
            containerStyle="container-style-default pb-0"
            datePickerContainer="w-100"
            timeOnly
            onChange={(value) =>
              handleChangeClockin("clockOutTime", value, index)
            }
            onSelect={(value) =>
              handleChangeClockin("clockOutTime", value, index)
            }
            value={clockIn?.clockOutTime}
          />
          <div className="col-1 align-self-end">
            {clockins.length > 1 && index !== 0 ? (
              <RemoveIconButton onClick={() => handleRemoveClockin(index)} />
            ) : null}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ClockinsInput;
