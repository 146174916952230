import React, { useState } from "react";
import { Button, Spinner } from "reactstrap";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useMutation } from "@apollo/client";
import { useSelector } from "react-redux";
import HelperFns, { getReqData, showToast } from "../../Helpers/HelperFns";
import MainModal from "../MainModal";
import Dropzone from "react-dropzone-uploader";
import {
  acceptFlexRequestMutation,
  cancelFlexRequestMutation,
  flexEditAttendanceRequestMutation,
  rejectFlexRequestMutation,
} from "../../Graphql/mutation";
import Picker from "rc-picker";
import {
  BSelect,
  CheckboxBooleanForm,
  DateTimePickerForm,
} from "../../Builder/Form";
import Privilages from "../../Constants/Privilages";

const FlexEditAttendanceModal = ({
  dismiss,
  isModalVisible,
  isViewRequest = false,
  fetchData = () => {},
  isMyProfile = true,
  ...props
}) => {
  const userId = useSelector((state) => state.auth.userProfile?.id);
  const editFlexAttendanceForm = useSelector(
    (state) => state.super?.editFlexAttendanceForm
  );
  const isCreatedByMe = userId === editFlexAttendanceForm?.createdBy?.id;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const formProps = {
    formName: "editFlexAttendanceForm",
    formNameValidation: "editFlexAttendanceFormValidation",
    formServerValidation: "editFlexAttendanceFormServerValidation",
    formSubmitting: isSubmitting,
  };

  const { t } = useTranslation();

  const [requestFiles, setRequestFiles] = useState([]);

  const [
    flexEditAttendanceRequest,
    { loading: flexEditAttendanceRequestLoading },
  ] = useMutation(flexEditAttendanceRequestMutation, {
    onCompleted: (res) => {
      if (
        res?.flexEditAttendanceRequest?.__typename === "GeneralException" ||
        res?.flexEditAttendanceRequest?.__typename === "PaidPayrollException"
      ) {
        showToast("error", "somthing went wrong");
        return;
      } else if (res?.flexEditAttendanceRequest?.flex_request?.id) {
        fetchData();
        dismiss();
      }
    },
    onError: (error) => {
      showToast(
        "error",
        error.graphQLErrors?.[0]?.extensions?.reason ?? error.message
      );
    },
  });

  const [acceptFlexRequestFunc, { loading: acceptFlexRequestLoading }] =
    useMutation(acceptFlexRequestMutation, {
      onCompleted: (response) => {
        if (
          response?.accept?.__typename === "GeneralException" ||
          response?.accept?.__typename === "PaidPayrollException"
        ) {
          showToast(
            "error",
            response.data?.accept?.message ?? t("Something went wrong")
          );
          return;
        }
        fetchData();
        dismiss();
      },
      onError: (error) => {
        showToast(
          "error",
          error.graphQLErrors?.[0]?.extensions?.reason ?? error.message
        );
      },
    });

  const [cancelFlexRequestFunc, { loading: cancelFlexRequestLoading }] =
    useMutation(cancelFlexRequestMutation, {
      onCompleted: (response) => {
        if (
          response?.cancel?.__typename === "GeneralException" ||
          response?.cancel?.__typename === "PaidPayrollException"
        ) {
          showToast(
            "error",
            response?.cancel?.message ?? t("Something went wrong")
          );
          return;
        }
        fetchData();
        dismiss();
      },
      onError: (err) => {
        showToast(
          "error",
          err?.graphQLErrors?.[0]?.extensions?.reason ||
            err?.graphQLErrors?.[0]?.message ||
            err?.message
        );
      },
    });

  const [rejectFlexRequestFunc, { loading: rejectFlexRequestLoading }] =
    useMutation(rejectFlexRequestMutation, {
      onCompleted: (response) => {
        if (
          response?.reject?.__typename === "GeneralException" ||
          response?.reject?.__typename === "PaidPayrollException"
        ) {
          showToast(
            t("Error", response?.reject?.message ?? t("Something went wrong"))
          );
          return;
        }
        fetchData();
        dismiss();
      },
      onError: (error) => {
        showToast(
          t(
            "something went wrong",
            error.graphQLErrors?.[0]?.extensions?.reason ?? error.message
          )
        );
      },
    });

  const addRequestFileObject = (file) => {
    setRequestFiles((requestFiles) => [...requestFiles, file]);
  };

  // function to remove an object from an requestFiles array
  const removeRequestFileObject = (id) => {
    setRequestFiles((requestFiles) =>
      requestFiles.filter((file) => file?.id !== id)
    );
  };

  const handleConfirm = () => {
    const {
      order,
      working_time,
      clockInOffice,
      clockOutOffice,
      leave_type,
      leave_hours,
      hasPendingEditAttendanceRequests,
      checkIns,
      ...filteredInput
    } = editFlexAttendanceForm;
    setIsSubmitting(true);
    flexEditAttendanceRequest({
      variables: {
        input: {
          ...filteredInput,
          clock_in_time: `${editFlexAttendanceForm.date} ${editFlexAttendanceForm.clock_in_time}`,
          clock_out_time: `${editFlexAttendanceForm.date} ${editFlexAttendanceForm.clock_out_time}`,
          files: requestFiles?.map(({ file, extension }) => ({
            file,
            extension,
          })),
          emp_id: isMyProfile ? userId : props.id,
        },
      },
    });
  };

  const handleAccept = () => {
    acceptFlexRequestFunc({ variables: { id: editFlexAttendanceForm?.id } });
  };

  const handleReject = () => {
    rejectFlexRequestFunc({ variables: { id: editFlexAttendanceForm?.id } });
  };

  const handleCancelRequest = () => {
    cancelFlexRequestFunc({
      variables: { input: { id: editFlexAttendanceForm?.id } },
    });
  };

  let Daysopts = [
    {
      value: moment
        .utc(editFlexAttendanceForm.date, "YYYY-MM-DD")
        .subtract(1, "days")
        .format("YYYY-MM-DD"),
      label: moment
        .utc(editFlexAttendanceForm.date)
        .subtract(1, "days")
        .format("dddd (DD/MM)"),
    },
    {
      value: moment
        .utc(editFlexAttendanceForm.date, "YYYY-MM-DD")
        .format("YYYY-MM-DD"),
      label: moment.utc(editFlexAttendanceForm.date).format("dddd (DD/MM)"),
    },
    {
      value: moment
        .utc(editFlexAttendanceForm.date, "YYYY-MM-DD")
        .add(1, "days")
        .format("YYYY-MM-DD"),
      label: moment
        .utc(editFlexAttendanceForm.date, "YYYY-MM-DD")
        .add(1, "days")
        .format("dddd (DD/MM)"),
    },
  ];

  const handleImageChange = ({ file, meta }, status) => {
    // to convert image to base64 string
    let reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      let base64 = reader.result?.split(",");
      let fileType = file?.type?.split("/");

      if (status == "done") {
        let target = {
          file: base64[1],
          extension: fileType[1],
          id: meta?.id,
        };
        addRequestFileObject(target);
      }
    };

    if (status == "removed") {
      removeRequestFileObject(meta?.id);
    }
    return;
  };

  const removeAllSelectedFiles = (_, allFiles) => {
    allFiles?.forEach((f) => f?.remove());
  };

  const renderConfirmBtnFunction = () => {
    return isViewRequest
      ? HelperFns.checkPrivileges({
          privileges: [
            Privilages.MANAGE_EMPLOYEE_REQUESTS,
            Privilages.MANAGE_EMPLOYEE_ATTENDANCE,
          ],
          allowBP: true,
        })
        ? handleAccept
        : undefined
      : HelperFns.checkPrivileges({
            privileges: [
              Privilages.APPLY_FOR_EDIT_ATTENDANCE_REQUESTS_ON_BEHALF,
              Privilages.APPLY_FOR_EDIT_ATTENDANCE_REQUESTS,
            ],
          })
        ? handleConfirm
        : undefined;
  };

  const renderCancelBtnFunction = () => {
    return isViewRequest
      ? HelperFns.checkPrivileges({
          privileges: [
            Privilages.MANAGE_EMPLOYEE_REQUESTS,
            Privilages.MANAGE_EMPLOYEE_ATTENDANCE,
          ],
          allowBP: true,
        })
        ? handleReject
        : HelperFns.checkPrivileges({
              privileges: [
                Privilages.APPLY_FOR_EDIT_ATTENDANCE_REQUESTS_ON_BEHALF,
                Privilages.APPLY_FOR_EDIT_ATTENDANCE_REQUESTS,
              ],
            }) && isCreatedByMe
          ? handleCancelRequest
          : undefined
      : undefined;
  };

  return (
    <MainModal
      isOpen={isModalVisible}
      toggle={dismiss}
      modalTitle={t("edit attendance")}
      hasModalFooter={!(isCreatedByMe && isViewRequest)}
      btnLabel={isViewRequest ? t("accept") : t("confirm")}
      btnOnClick={renderConfirmBtnFunction()}
      btnSubmitLoading={
        flexEditAttendanceRequestLoading || acceptFlexRequestLoading
      }
      btnLabelCancel={
        isViewRequest
          ? isCreatedByMe
            ? t("cancel")
            : t("reject")
          : t("cancel")
      }
      btnOnCancelClick={renderCancelBtnFunction()}
      btnCancelLoading={rejectFlexRequestLoading || cancelFlexRequestLoading}
    >
      <div className="">
        <BSelect
          {...formProps}
          optionLabel="label"
          optionValue="value"
          options={Daysopts}
          isClearable
          containerStyle="month-picker mr-2"
          placeholder={t("select day")}
          icon="calendar"
          name="date"
          isDisabled={isViewRequest}
        />
        <DateTimePickerForm
          {...formProps}
          datePickerContainer="w-100"
          label="clock in time"
          containerStyle="containerStyle flex-column align-items-start"
          labelStyle="custom-label-style mb-2"
          name="clock_in_time"
          timeOnly
          hasIcon
          isClearable
          disabled={isViewRequest}
        />

        <DateTimePickerForm
          {...formProps}
          datePickerContainer="w-100"
          label="clock out time"
          containerStyle="containerStyle flex-column align-items-start"
          labelStyle="custom-label-style mb-2"
          name="clock_out_time"
          timeOnly
          hasIcon
          isClearable
          disabled={isViewRequest}
        />

        <CheckboxBooleanForm
          {...formProps}
          options={["work remotely"]}
          name="home"
          type="checkbox"
          disabled={isViewRequest}
        />

        {isViewRequest ? null : (
          <div className="documents_dropzone_style">
            <Dropzone
              onChangeStatus={handleImageChange}
              canRemove
              accept="image/*"
              name="attachments"
              multiple={true}
              inputContent={t("face_placeholder")}
              onSubmit={removeAllSelectedFiles}
              disabled={isViewRequest}
            />
          </div>
        )}
      </div>
    </MainModal>
  );
};

export default FlexEditAttendanceModal;
