import React, { useEffect, useState } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useCustomMutation from "../../Helpers/Hooks/useCustomMutation";

import {
  updateValueAction,
  onFormResetAction,
  fetchChangeAssignments,
  fetchAssignmentsAttempt,
  setFormServerValidationAction,
  showToast,
  onInputResetAction,
} from "../../Store/Actions";
import {
  serializeFetchChangeAssignment,
  serializeUpsertChangeAssignment,
  serializeUpsertingFlexAssignment,
  handleFilterOptionByEmpCodeOrName,
} from "../../Helpers/HelperFns";
import moment from "moment";
import Privilages from "../../Constants/Privilages";
import {
  GET_ASSIGNMENT_USERS_ACCORDING_TO_DATE,
  GET_FLEX_WORK_DAY_TEMPLATES_MENU,
  GET_WORKING_TIMMING_OPTIONS_QUERY,
  changeAssignmentFormQuery,
} from "../../Graphql/query";
import {
  upsertAssignmentMutation,
  upsertFlexAssignmentMutation,
} from "../../Graphql/mutation";

import Loader from "../../Components/Loader";
import MainModal from "../../Components/MainModal";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import {
  BSelect,
  DateTimePickerForm,
  CheckboxBooleanForm,
  RadioboxForm,
} from "form-builder";
import { WorkPlaces } from "../../Components/SharedFormSections/DayOffException";
import _ from "lodash";
import useAllowCostCenter from "../../Helpers/Hooks/useAllowCostCenter";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import useCompanyAttType from "../../Helpers/Hooks/useCompanyAttType";

const ChangeAssignmentModal = ({
  refetch = () => {},
  onClose,
  data,
  date,
  ...props
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { allowCostCenter } = useAllowCostCenter();

  // Local State
  const [modalMessage, setModalMessage] = React.useState("");
  const [formSubmitting, setFormSubmitting] = React.useState(false);

  // Constants
  const formProps = {
    formSubmitting,
    reducer: "assignments",
    formName: "changeAssignment",
    formNameValidation: "changeAssignmentFormValidation",
    formServerValidation: "changeAssignmentFormServerValidation",
  };

  // Reducer State
  const formData = useSelector(
    (state) => state?.[formProps.reducer]?.[formProps.formName]
  );
  const isChangeShift = data?.isEdit
    ? !formData?.retain_default_work_timing
    : data?.isChangeShift;

  const formClientValidation = useSelector(
    (state) => state?.[formProps.reducer]?.[formProps.formNameValidation]
  );

  const { from, employees, locations, work_timings } = useSelector(
    (state) => state.assignments.assignmentFilters
  );

  // Server State
  const [upsertShifts, { loading: upsertLoading }] = useCustomMutation(
    upsertAssignmentMutation
  );

  // Server State
  const [upsertFlex, { loading: upserFlextLoading }] = useCustomMutation(
    upsertFlexAssignmentMutation
  );

  const [normalWorkTimingList, setNormalWorkTimingList] = useState([]);
  const [halfWorkTimingList, setHalfWorkTimingList] = useState([]);

  const { loading: workTimmingLoading } = useQuery(
    GET_WORKING_TIMMING_OPTIONS_QUERY,
    {
      variables: {
        work_timming_date: moment(formData?.date).format("YYYY-MM-DD"),
      },
      onCompleted: (res) => {
        setNormalWorkTimingList(res?.work_timings_menu);
        setHalfWorkTimingList(res?.half_work_timings_menu);
        if (
          !res?.work_timings_menu?.find(
            (wt) => wt.id == formData?.normal_work_timing_id
          )
        ) {
          dispatch(
            updateValueAction("changeAssignment", "normal_work_timing_id", null)
          );
        }
        if (
          !res?.half_work_timings_menu?.find(
            (wt) => wt.id == formData?.first_half_work_timing_id
          )
        ) {
          dispatch(
            updateValueAction(
              "changeAssignment",
              "first_half_work_timing_id",
              null
            )
          );
        }
        if (
          !res?.half_work_timings_menu?.find(
            (wt) => wt.id == formData?.second_half_work_timing_id
          )
        ) {
          dispatch(
            updateValueAction(
              "changeAssignment",
              "second_half_work_timing_id",
              null
            )
          );
        }
      },
      skip: !formData?.date || formData?.isFlex,
      onError: (err) => {
        showToast(
          "error",
          err?.graphQLErrors[0]?.extensions?.reason ||
            err?.graphQLErrors[0]?.message ||
            err?.message
        );
      },
    }
  );

  const { data: workDayTemplateData, loading: workDayTemplateLoading } =
    useQuery(GET_FLEX_WORK_DAY_TEMPLATES_MENU, {
      skip: !formData?.date || !formData?.isFlex,
      onError: (err) => {
        showToast(
          "error",
          err?.graphQLErrors[0]?.extensions?.reason ||
            err?.graphQLErrors[0]?.message ||
            err?.message
        );
      },
    });

  const getWorkTimings = (workTimingList, actualWorkTiming = false) => {
    let returnOptions = [...workTimingList];
    if (actualWorkTiming) {
      returnOptions = [...workTimingList, actualWorkTiming];
    }
    return _.uniqBy(returnOptions, "id");
  };

  const { loading, data: changeAssignmentQueryData } = useQuery(
    changeAssignmentFormQuery,
    {
      variables: {
        isIncludeAssignment: data?.isEdit && !data?.isFlex,
        isIncludeFlexAssignment: data?.isEdit && data?.isFlex,
        assignmentId: data?.id,
        workTimingsMenuDate: date,
      },
      onCompleted: ({
        assignment = {},
        flex_assignment = {},
        offices,
        work_timings_menu,
      }) => {
        dispatch(
          fetchChangeAssignments({
            ...(data?.isEdit && {
              ...serializeFetchChangeAssignment(
                data?.isFlex ? flex_assignment : assignment,
                data?.isFlex
              ),
            }),

            // Options
            offices,
            work_timings: work_timings_menu || [],
          })
        );
      },
    }
  );

  const [
    getUsersRelevantToDate,
    { loading: usersRelevantToDateLoading, data: usersRelevantToDateQueryData },
  ] = useLazyQuery(GET_ASSIGNMENT_USERS_ACCORDING_TO_DATE, {
    onCompleted: () => {
      !data?.isEdit &&
        !data?.isEmpProfile &&
        dispatch(
          onInputResetAction(formProps.formName, "employee_applicable_on_ids")
        );
    },
  });

  useEffect(() => {
    getUsersRelevantToDate({
      variables: {
        date: !!formData?.date
          ? moment(formData?.date).format("YYYY-MM-DD")
          : undefined,
      },
    });
  }, [formData?.date]);

  const handleInterceptFromDate = () => {
    !data?.isEdit &&
      !data?.isEmpProfile &&
      dispatch(
        onInputResetAction(formProps.formName, "employee_applicable_on_ids")
      );
  };

  /* State Effects */

  React.useEffect(() => {
    if (data.isEmpProfile) {
      dispatch(
        updateValueAction(formProps.formName, "employee_applicable_on_ids", [
          data.userId,
        ])
      );
    }
  }, [data.isEmpProfile, loading]);

  useEffect(() => {
    dispatch(updateValueAction(formProps?.formName, "isFlex", !!data?.isFlex));
    dispatch(onFormResetAction(formProps.formNameValidation));
  }, [data?.isFlex]);

  /* Helpers */

  const handleUpdateWeight = (_, val) => {
    dispatch(updateValueAction(formProps.formName, "weight", val?.weight));
  };

  const handleSubmit = () => {
    setFormSubmitting(true);
    if (!formClientValidation?.length) {
      if (!formData?.isFlex) {
        upsertShifts({
          isShowSuccessModal: false,
          variables: {
            input: serializeUpsertChangeAssignment({
              isChangeShift,
              isFlex: formData?.isFlex,
              ...formData,
            }),
          },
          onCompleted: ({ updateOrCreateAssignment }) => {
            if (updateOrCreateAssignment?.message)
              return setModalMessage(updateOrCreateAssignment?.message);

            data?.isEmpProfile
              ? props?.actions?.refetch()
              : dispatch(
                  fetchAssignmentsAttempt({
                    from: moment(from, "DD-MM-YYYY").format("YYYY-MM-DD"),
                    to: moment(from, "DD-MM-YYYY")
                      .add(6, "days")
                      .format("YYYY-MM-DD"),
                    employeeIds: employees.map((employee) => +employee),
                    locationIds: locations.map((location) => +location),
                    workTimingsIds: work_timings.map(
                      (workTiming) => +workTiming
                    ),
                  })
                );
            handleCloseModal();
          },
          onError: (err) => {
            if (err?.[0]?.extensions?.validation)
              dispatch(
                setFormServerValidationAction({
                  serverValidationName: formProps.formServerValidation,
                  validations: err?.[0]?.extensions?.validation,
                })
              );
          },
        });
      } else {
        handelFlexMutation();
      }
    }
  };

  const handelFlexMutation = () => {
    upsertFlex({
      // isShowSuccessModal: false,
      variables: {
        input: {
          id: formData?.id,
          change_shift: true,
          name: isChangeShift ? "change shift" : "additional shift",
          from: formData?.date?.replaceAll("/", "-"),
          to: formData?.date?.replaceAll("/", "-"),
          weight: 0,
          treat_as_normal: true,
          apply_on: "employee",
          flex_work_day_template_id: parseInt(
            formData?.flex_work_day_template_id
          ),
          retain_work_day_template: isChangeShift ? 0 : 1,
          applicable_on_ids: formData?.employee_applicable_on_ids?.map(
            (applicable_id) => +applicable_id
          ),
          workplace_setting_identical:
            formData?.apply_work_timing_location ||
            formData?.workplace_setting_identical,
          workplace_setting: formData?.apply_work_timing_location
            ? "work_timing_location"
            : formData?.workplace_setting,
          additional_work_places: formData?.additional_work_places,
          workplace_setting_sign_out: formData?.apply_work_timing_location
            ? "work_timing_location"
            : formData?.workplace_setting_identical
              ? formData?.workplace_setting
              : formData?.workplace_setting_signout,
          additional_work_places_sign_out:
            !formData?.workplace_setting_identical
              ? formData?.additional_work_places_signout
              : formData?.additional_work_places,
        },
      },
      onCompleted: ({ updateOrCreateFlexAssignment }) => {
        if (updateOrCreateFlexAssignment?.message) {
          setModalMessage(updateOrCreateFlexAssignment?.message);
        } else {
          refetch();
          handleCloseModal();
        }
      },
      onError: (err) => {
        if (err?.[0]?.extensions?.validation)
          dispatch(
            setFormServerValidationAction({
              serverValidationName: formProps.formServerValidation,
              validations: err?.[0]?.extensions?.validation,
            })
          );
      },
    });
  };

  const handleCloseModal = () => {
    dispatch(onFormResetAction(formProps.formName));
    dispatch(
      setFormServerValidationAction({
        serverValidationName: formProps.formServerValidation,
        validations: {},
      })
    );
    onClose();
  };

  const resetEmployee = () => {
    !data?.isEdit &&
      !data?.isEmpProfile &&
      dispatch(
        updateValueAction(formProps?.formName, "employee_applicable_on_ids", [])
      );
  };

  const filteredOptions =
    usersRelevantToDateQueryData?.assignmentUsersList?.filter((user) =>
      formData?.isFlex
        ? user?.work_day_type?.toLowerCase() == "flex"
        : user?.work_day_type?.toLowerCase() == "fixed"
    );

  const { hasFlex, hasFixed } = useCompanyAttType();

  return (
    <MainModal
      isOpen
      toggle={handleCloseModal}
      modalTitle={isChangeShift ? "Change Shifts" : "additional Shifts"}
      btnOnClick={handleSubmit}
      btnSubmitLoading={upsertLoading || upserFlextLoading}
    >
      {(data?.isEdit || data?.isEmpProfile) && loading ? (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      ) : null}

      <RadioboxForm
        {...formProps}
        name="isFlex"
        label="apply to employees working according to"
        options={[
          ...(hasFixed
            ? [
                {
                  label: t("fixed work timings"),
                  value: false,
                  optProps: { disabled: data?.isEmpProfile || data?.isEdit },
                },
              ]
            : []),
          ...(hasFlex
            ? [
                {
                  label: t("flexible work schedule"),
                  value: true,
                  optProps: { disabled: data?.isEmpProfile || data?.isEdit },
                },
              ]
            : []),
        ]}
        optionsContainerStyle="d-flex gap-10"
        containerStyle="d-flex gap-20 mt-4"
        optionInputStyle=" "
        interceptChange={resetEmployee}
        validateBy={"textRequired"}
      />

      <DateTimePickerForm
        {...formProps}
        name="date"
        validateBy="textRequired"
        validationName="input.from"
        label="date"
        placeholder={t("choose day")}
        datePickerContainer="w-100"
        hasIcon
        isClearable
        inputStyle="assignment-form-date-picker date-picker-input-default"
        iconBackground={false}
        rootStyle="w-100 mr-custom-5"
        disabled={formData?.canEditFrom === false}
        onIntercept={handleInterceptFromDate}
      />
      <HasPrivileges
        allowBP
        reqireMain={[Privilages.ADD_EDIT_DELETE_EMPLOYEE_ASSIGNMENTS]}
      >
        <BSelect
          {...formProps}
          label="employees"
          isLoading={loading}
          name="employee_applicable_on_ids"
          validateBy="arrayRequired"
          validationName="input.applicable_on_ids"
          placeholder={t("select employees")}
          keepDefaultStyle
          options={filteredOptions}
          filterOption={handleFilterOptionByEmpCodeOrName}
          getOptionLabel={(opt) => `${opt.name} - ${opt.office.name}`}
          optionValue="id"
          rootStyle="mt-2"
          containerStyle="d-flex align-items-start flex-column"
          inputContainerStyle="w-100"
          isMulti
          icon="person"
          isDisabled={data.isEmpProfile}
        />
      </HasPrivileges>

      {/* Work timing */}

      <BSelect
        {...formProps}
        isLoading={loading || workDayTemplateLoading}
        isDisabled={loading || workDayTemplateLoading}
        name="flex_work_day_template_id"
        validateBy="textRequired"
        validationName="input.flex_work_day_template_id"
        placeholder={t("select work day template")}
        onInterceptInputOnChange={handleUpdateWeight}
        options={workDayTemplateData?.flex_Work_Day_Template_Menu || []}
        optionLabel="name"
        label="Work day template"
        optionValue="id"
        labelStyle="mt-3 mb-2"
        containerStyle="d-flex align-items-start flex-column"
        inputContainerStyle="w-100"
        icon="calendar"
        customComponents={{ Option }}
        skipLocalization
        dependOn={"isFlex"}
        dependancyType={"equal"}
        dependancyValue={[true]}
      />
      <BSelect
        {...formProps}
        isLoading={loading || workTimmingLoading}
        isDisabled={loading || workTimmingLoading}
        name="normal_work_timing_id"
        validateBy="textRequired"
        validationName="input.work_timing"
        placeholder={t("select work timing")}
        onInterceptInputOnChange={handleUpdateWeight}
        options={getWorkTimings(
          normalWorkTimingList,
          formData?.normalWorkTiming
        )}
        optionLabel="name"
        label="Work timing"
        optionValue="id"
        labelStyle="mt-3 mb-2"
        containerStyle="d-flex align-items-start flex-column"
        inputContainerStyle="w-100"
        icon="calendar"
        customComponents={{ Option }}
        skipLocalization
        dependOn={"isFlex"}
        dependancyType={"equal"}
        dependancyValue={[false]}
      />
      {formData?.isFlex ? null : (
        <>
          <CheckboxBooleanForm
            {...formProps}
            containerStyle="mt-3"
            name="allow_employees_to_request_half_days"
            options={["allow employees to request half-days"]}
            // dependOn={"isFlex"}
            // dependancyType={"equal"}
            // dependancyValue={[false]}
          />
          <div className="d-flex align-items-center justify-content-between my-2">
            <BSelect
              {...formProps}
              isLoading={loading}
              validation="textRequired"
              label="first half work timing"
              name="first_half_work_timing_id"
              placeholder={t("select work timing")}
              validateBy="textRequired"
              validationName="input.work_timing"
              keepDefaultStyle
              options={getWorkTimings(
                halfWorkTimingList,
                formData?.firstHalfWorkTiming
              )}
              optionLabel="name"
              optionValue="id"
              inputContainerStyle="w-100"
              dependOn="allow_employees_to_request_half_days"
              dependancyType="equal"
              dependancyValue={[1]}
              icon="user"
              rootStyle="w-100 mr-5"
              customNewStyles
              customComponents={{ Option }}
            />
            <BSelect
              {...formProps}
              isLoading={loading}
              label="second half work timing"
              name="second_half_work_timing_id"
              placeholder={t("select work timing")}
              validateBy="textRequired"
              validationName="input.work_timing"
              keepDefaultStyle
              options={getWorkTimings(
                halfWorkTimingList,
                formData?.secondHalfWorkTiming
              )}
              optionLabel="name"
              optionValue="id"
              inputContainerStyle="w-100"
              dependOn="allow_employees_to_request_half_days"
              dependancyType="equal"
              dependancyValue={[1]}
              icon="user"
              rootStyle="w-100"
              customNewStyles
              customComponents={{ Option }}
            />
          </div>
        </>
      )}

      {allowCostCenter ? (
        <>
          <BSelect
            {...formProps}
            name="cost_center_id"
            validationName="input.cost_center_id"
            placeholder={t("select cost center")}
            options={changeAssignmentQueryData?.cost_centers?.data ?? []}
            label="Cost center"
            labelStyle="mt-3 mb-2"
            containerStyle="d-flex align-items-start flex-column"
            inputContainerStyle="w-100"
            icon="money"
            rootStyle="flex-1"
            skipLocalization
          />

          {!isChangeShift ? ( // if additioanl shift (retains)
            <div className="d-flex align-items-center gap-5 my-2">
              <WarningAmberIcon fontSize="small" color="warning" />
              <p className="m-0 font-weight-bold" style={{ fontSize: 12 }}>
                {t(
                  "Some of the employees selected may not be able to sign in to this assignment in case they have signed in to an alternative shift with a different cost center"
                )}
              </p>
            </div>
          ) : null}
        </>
      ) : null}

      {/* locations */}

      <h4 className="assignment-form-header">{t("locations")}</h4>
      {!formData?.isFlex ? (
        <CheckboxBooleanForm
          {...formProps}
          name="apply_work_timing_location"
          options={
            formData?.isFlex
              ? ["use work day template location settings"]
              : ["use work timing location settings"]
          }
        />
      ) : null}

      {!formData?.apply_work_timing_location || formData?.isFlex ? (
        <WorkPlaces
          formProps={formProps}
          locationsOptions={formData?.offices}
          isFlex={formData?.isFlex}
        />
      ) : null}

      <BSelect
        {...formProps}
        name="space_id"
        validateBy="textRequired"
        validationName="input.space_id"
        placeholder={t("select default location")}
        options={
          !formData?.apply_work_timing_location &&
          formData?.workplace_setting === "DEFAULT"
            ? formData?.offices?.filter((office) =>
                formData?.additional_work_places?.includes(office.id)
              )
            : formData?.offices || []
        }
        label="default location"
        labelStyle="mb-2"
        containerStyle="d-flex align-items-start flex-column"
        inputContainerStyle="w-100"
        icon="money"
        rootStyle="mb-3"
        dependOn="apply_work_timing_location"
        dependancyType="equal"
        dependancyValue={[0]}
      />

      {/* Modal Message */}
      {modalMessage && formSubmitting && (
        <p role="alert" aria-live="assertive" className="warnig-msg-style mt-3">
          {modalMessage}
        </p>
      )}
    </MainModal>
  );
};

export default ChangeAssignmentModal;
