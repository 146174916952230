import React from "react";
import { makeStyles } from "tss-react/mui";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Privilages from "../../Constants/Privilages";
import HelperFns from "../../Helpers/HelperFns";
import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from "react-router-dom";
import i18n from "i18next";
import { useSelector } from "react-redux";
import Constants from "../../Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faIdCard,
  faCalendarWeek,
  faFileAlt,
  faCoins,
  faCoffee,
  faUser,
  faMailBulk,
  faPlaneDeparture,
  faLocationArrow,
  faUserLock,
} from "@fortawesome/free-solid-svg-icons";

const verticalNavbarStyles = makeStyles()(() => Constants.verticalNavbarStyles);
const horizontalNavbarStyles = makeStyles()(
  () => Constants.horizontalNavbarStyles
);

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const SettingsTabs = ({ isRouteSidebar }) => {
  const { classes } = isRouteSidebar
    ? verticalNavbarStyles()
    : horizontalNavbarStyles();
  const { t } = useTranslation();
  const history = useHistory();
  let query = useQuery();
  const activeValue = query.get("tab");

  const handleChange = (event, newValue) => {
    history.push(`/settings?tab=${newValue}`);
  };
  const isRtl =
    i18n.dir(useSelector((state) => state.user.userProfile.lng)) === "rtl"
      ? true
      : false;

  const possibleValues = [
    // "monthly-profiles",
    "offices",
    "departments",
    "positions",
    "att-profile",
    "work-timings",
    // "locations",
    "leaves-breaks",
    "salary-config-presets",
    "allowances-deductibles",
    "holidays",
    "documents",
    "expense-categories",
    "access-levels",
    "announcements",
    "miscellanous",
    // "general-settings",
  ];

  return (
    <>
      <Tabs
        variant="scrollable"
        value={possibleValues.includes(activeValue) ? activeValue : false}
        onChange={handleChange}
        scrollButtons="auto"
        orientation={isRouteSidebar ? "vertical" : "horizontal"}
        classes={{
          root: classes.root,
          indicator: classes.indicator,
          flexContainer: classes.flexContainer,
          scrollButtons: classes.scrollButtons,
        }}
      >
        {/* <Tab
          classes={{
            root: classes.tabRoot,
            // root: `${
            //   HelperFns.checkPrivileges({
            //     allowBP: true,
            //     privileges: [Privilages.VIEW_OFFICES],
            //   })
            //     ? classes.tabRoot
            //     : "d-none"
            // }`,
            wrapper: "top-bar-label",
            selected: isRtl ? classes.selectedRtl : classes.selected,
          }}
          value="monthly-profiles"
          label={
            <div className="mx-2 routeBar-text">
              <FontAwesomeIcon
                icon={faMailBulk}
                className="mx-2 routeBar-icon"
              />
              {t("monthly profiles")}
            </div>
          }
        /> */}

        <Tab
          classes={{
            root: `${
              HelperFns.checkPrivileges({
                allowBP: true,
                privileges: [Privilages.VIEW_OFFICES],
              })
                ? classes.tabRoot
                : "d-none"
            }`,
            wrapper: "top-bar-label",
            selected: isRtl ? classes.selectedRtl : classes.selected,
          }}
          value="offices"
          label={
            <div className="mx-2 routeBar-text">
              <FontAwesomeIcon
                icon={faMailBulk}
                className="mx-2 routeBar-icon"
              />
              {t("offices")} {t("and")} {t("work places")}
            </div>
          }
        />
        <Tab
          classes={{
            root: `${
              HelperFns.checkPrivileges({
                allowBP: true,
                privileges: [Privilages.VIEW_DEPARTMENTS],
              })
                ? classes.tabRoot
                : "d-none"
            }`,
            wrapper: "top-bar-label",
            selected: isRtl ? classes.selectedRtl : classes.selected,
          }}
          value="departments"
          label={
            <div className="mx-2 routeBar-text">
              <FontAwesomeIcon
                icon={faBuilding}
                className="mx-2 routeBar-icon"
              />
              {t("departments")}
            </div>
          }
        />

        <Tab
          classes={{
            root: `${
              HelperFns.checkPrivileges({
                allowBP: true,
                privileges: [Privilages.VIEW_POSITIONS],
              })
                ? classes.tabRoot
                : "d-none"
            }`,
            wrapper: "top-bar-label",
            selected: isRtl ? classes.selectedRtl : classes.selected,
          }}
          value="positions"
          label={
            <div className="mx-2 routeBar-text">
              <FontAwesomeIcon icon={faUser} className="mx-2 routeBar-icon" />
              {t("positions")}
            </div>
          }
        />

        <Tab
          classes={{
            root: `${
              HelperFns.checkPrivileges({
                allowBP: true,
                privileges: [Privilages.VIEW_WORK_TIMINGS],
              })
                ? classes.tabRoot
                : "d-none"
            }`,
            wrapper: "top-bar-label",
            selected: isRtl ? classes.selectedRtl : classes.selected,
          }}
          value="work-timings"
          label={
            <div className="mx-2 routeBar-text">
              <FontAwesomeIcon
                icon={faCalendarWeek}
                className="mx-2 routeBar-icon"
              />
              {t("work timings")}
            </div>
          }
        />
        <Tab
          classes={{
            root: `${
              HelperFns.checkPrivileges({
                allowBP: true,
                privileges: [Privilages.VIEW_LEAVE_AND_BREAK_PROFILES],
              })
                ? classes.tabRoot
                : "d-none"
            }`,
            wrapper: "top-bar-label",
            selected: classes.selected,
          }}
          value="leaves-breaks"
          label={
            <div className="mx-2 routeBar-text">
              <FontAwesomeIcon icon={faCoffee} className="mx-2 routeBar-icon" />
              {t("leave and break profiles")}
            </div>
          }
        />

        <Tab
          classes={{
            root: `${
              HelperFns.checkPrivileges({
                allowBP: true,
                privileges: [Privilages.VIEW_ATTENDANCE_PROFILES],
              })
                ? classes.tabRoot
                : "d-none"
            }`,
            wrapper: "top-bar-label",
            selected: isRtl ? classes.selectedRtl : classes.selected,
          }}
          value="att-profile"
          label={
            <div className="mx-2 routeBar-text">
              <FontAwesomeIcon icon={faIdCard} className="mx-2 routeBar-icon" />
              {t("attendance profiles")}
            </div>
          }
        />

        <Tab
          classes={{
            root: `${
              HelperFns.checkPrivileges({
                allowBP: true,
                privileges: [Privilages.VIEW_EMPLOYEE_SALARY_CONFIGURATION],
              })
                ? classes.tabRoot
                : "d-none"
            }`,
            wrapper: "top-bar-label",
            selected: isRtl ? classes.selectedRtl : classes.selected,
          }}
          value="salary-config-presets"
          label={
            <div className="mx-2 routeBar-text">
              <FontAwesomeIcon icon={faIdCard} className="mx-2 routeBar-icon" />
              {t("salary configuration presets")}
            </div>
          }
        />
        <Tab
          classes={{
            root: `${
              HelperFns.checkPrivileges({
                allowBP: true,
                privileges: [Privilages.VIEW_EMPLOYEE_SALARY_CONFIGURATION],
              })
                ? classes.tabRoot
                : "d-none"
            }`,
            wrapper: "top-bar-label",
            selected: isRtl ? classes.selectedRtl : classes.selected,
          }}
          value="allowances-deductibles"
          label={
            <div className="mx-2 routeBar-text">
              <FontAwesomeIcon icon={faIdCard} className="mx-2 routeBar-icon" />
              {t("allowances & deductibles")}
            </div>
          }
        />

        {/* <Tab
          classes={{
            root: `${
              HelperFns.checkPrivileges({
                allowBP: true,
                privileges: [Privilages.VIEW_LOCATIONS],
              })
                ? classes.tabRoot
                : "d-none"
            }`,
            wrapper: "top-bar-label",
            selected: classes.selected,
          }}
          value="locations"
          label={
            <div className="mx-2 routeBar-text">
              <FontAwesomeIcon
                icon={faLocationArrow}
                className="mx-2 routeBar-icon"
              />
              {t("locations")}
            </div>
          }
        /> */}

        <Tab
          classes={{
            root: `${
              HelperFns.checkPrivileges({
                allowBP: true,
                privileges: [Privilages.VIEW_HOLIDAYS],
              })
                ? classes.tabRoot
                : "d-none"
            }`,
            wrapper: "top-bar-label",
            selected: isRtl ? classes.selectedRtl : classes.selected,
          }}
          value="holidays"
          label={
            <div className="mx-2 routeBar-text">
              <FontAwesomeIcon
                icon={faPlaneDeparture}
                className="mx-2 routeBar-icon"
              />
              {t("holidays")}
            </div>
          }
        />

        <Tab
          classes={{
            root: `${
              HelperFns.checkPrivileges({
                allowBP: true,
                privileges: [Privilages.VIEW_COMPANY_DOCUMENTS],
              })
                ? classes.tabRoot
                : "d-none"
            }`,
            wrapper: "top-bar-label",
            selected: isRtl ? classes.selectedRtl : classes.selected,
            // classes={{
            //   root: classes.tabRoot,
            //   wrapper: "top-bar-label",
            //   selected: classes.selected,
          }}
          value="documents"
          label={
            <div className="mx-2 routeBar-text">
              <FontAwesomeIcon
                icon={faFileAlt}
                className="mx-2 routeBar-icon"
              />
              {t("documents")}
            </div>
          }
        />

        <Tab
          classes={{
            root: `${
              HelperFns.checkPrivileges({
                allowBP: true,
                privileges: [Privilages.VIEW_EXPENSE_CATEGORIES],
              })
                ? classes.tabRoot
                : "d-none"
            }`,
            wrapper: "top-bar-label",
            selected: isRtl ? classes.selectedRtl : classes.selected,
          }}
          value="expense-categories"
          label={
            <div className="mx-2 routeBar-text">
              <FontAwesomeIcon icon={faCoins} className="mx-2 routeBar-icon" />
              {t("Expense Categories")}
            </div>
          }
        />

        <Tab
          classes={{
            root: `${
              HelperFns.checkPrivileges({
                allowBP: true,
                privileges: [Privilages.VIEW_ACCESS_LEVELS],
              })
                ? classes.tabRoot
                : "d-none"
            }`,
            wrapper: "top-bar-label",
            selected: classes.selected,
          }}
          value="access-levels"
          label={
            <div className="mx-2 routeBar-text">
              <FontAwesomeIcon
                icon={faUserLock}
                className="mx-2 routeBar-icon"
              />
              {t("access levels")}
            </div>
          }
        />

        <Tab
          classes={{
            root: `${
              HelperFns.checkPrivileges({
                allowBP: true,
                privileges: [Privilages.VIEW_COMPANY_ANNOUNCEMENT],
              })
                ? classes.tabRoot
                : "d-none"
            }`,
            wrapper: "top-bar-label",
            selected: classes.selected,
          }}
          value="announcements"
          label={
            <div className="mx-2 routeBar-text">
              <FontAwesomeIcon
                icon={faFileAlt}
                className="mx-2 routeBar-icon"
              />
              {t("announcements")}
            </div>
          }
        />

        {/* <Tab
          classes={{
            root: classes.tabRoot,
            wrapper: "top-bar-label",
            selected: classes.selected,
          }}
          value="general-settings"
          label={
            <div className="mx-2 routeBar-text">
              <FontAwesomeIcon
                icon={faUserLock}
                className="mx-2 routeBar-icon"
              />
              {t("general settings")}
            </div>
          }
        /> */}

        <Tab
          classes={{
            root: `${
              HelperFns.checkPrivileges({
                allowBP: true,
                privileges: [Privilages.VIEW_COMPANY_SETTINGS_PRESETS],
              })
                ? classes.tabRoot
                : "d-none"
            }`,
            wrapper: "top-bar-label",
            selected: classes.selected,
          }}
          value="miscellanous"
          label={
            <div className="mx-2 routeBar-text">
              <FontAwesomeIcon
                icon={faFileAlt}
                className="mx-2 routeBar-icon"
              />
              {t("miscellanous")}
            </div>
          }
        />
      </Tabs>
    </>
  );
};

export default SettingsTabs;
