import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import useDidUpdateEffect from "../../../Helpers/Hooks/useDidUpdate";
import {
  InputForm,
  RadioboxForm,
  BSelect,
  DateTimePickerForm,
} from "form-builder";
import { useTranslation } from "react-i18next";
// import { onInputResetAction } from "../../../Store/Actions";
import CalendarToday from "@mui/icons-material/CalendarToday";
import Const from "../../../Constants";

// Icons
import ScheduleIcon from "@mui/icons-material/Schedule";
import Constants from "../../../Constants";
import { onInputResetAction, updateValueAction } from "../../../Store/Actions";
import {
  Requests,
  WorkPlaces,
  Compensation,
  WorkOnDaysOff,
} from "../../../Components/SharedFormSections/DayOffException";
import { useLazyQuery } from "@apollo/client";
import { attendanceTypeConfigOptionsQuery } from "../../../Graphql/query";
import Privilages from "../../../Constants/Privilages";

import { checkCompanyPrivileges } from "../../../Helpers/HelperFns";
import MultipleCheckIn from "../../../Components/SharedFormSections/MultipleCheckIn";
import CheckInSettings from "../../../Components/CheckInSettings";
import WorkOnHolidays from "../../../Components/SharedFormSections/WorkOnHolidays";
import _ from "lodash";
import useCompanyAttType from "../../../Helpers/Hooks/useCompanyAttType";

const WorkScheduleSection = ({ isEditable, FormProps }) => {
  const { t } = useTranslation();

  const isCompanyHasShift = checkCompanyPrivileges({
    privileges: [Privilages.VIEW_EMPLOYEE_WORK_GROUPS],
  });

  //   Redux hooks
  const dispatch = useDispatch();
  const normalWorkTiming = useSelector(
    (state) => state?.super?.editEmployeeFormData?.normalWorkTiming
  );
  const firstHalfWorkTiming = useSelector(
    (state) => state?.super?.editEmployeeFormData?.firstHalfWorkTiming
  );
  const secondHalfWorkTiming = useSelector(
    (state) => state?.super?.editEmployeeFormData?.secondHalfWorkTiming
  );
  const attendance_profiles = useSelector(
    (state) => state?.super?.editEmployeeFormData?.attendance_profiles
  );
  const isEditing = useSelector((state) => state?.super?.editEmployeeForm);
  const employeeForm = useSelector(
    (state) => state?.super?.[FormProps?.formName]
  );
  const workGroupOptions = useSelector(
    (state) => state?.super?.editEmployeeFormData?.workGroups
  );

  const workTeamOptions = useSelector(
    (state) => state?.super?.editEmployeeFormData?.workTeams
  );

  const costCentersOptions = useSelector(
    (state) => state?.super?.editEmployeeFormData?.cost_centers
  );

  const flexWorkGroupOptions = useSelector(
    (state) => state?.super?.editEmployeeFormData?.flexWorkGroups
  );

  const flexWorkTeamOptions = useSelector(
    (state) => state?.super?.editEmployeeFormData?.flexWorkTeams
  );

  const handleInterceptAttendanceProfileChange = () => {
    dispatch(onInputResetAction(FormProps?.formName, "home_days"));
  };

  const [getOptions, { data }] = useLazyQuery(attendanceTypeConfigOptionsQuery);

  /* ↓ State Effects ↓ */

  useDidUpdateEffect(() => {
    if (
      employeeForm?.workdayType === "flexible" ||
      !employeeForm?.allow_work_on_day_off ||
      employeeForm?.attendanceType !== Constants.attendanceTypes.SHIFT_BASED
    ) {
      dispatch(onInputResetAction(FormProps.formName, "allow_overtime"));
      dispatch(onInputResetAction(FormProps.formName, "allow_permission"));
      dispatch(onInputResetAction(FormProps.formName, "apply_compensation"));
    }
  }, [employeeForm?.attendanceType, employeeForm?.allow_work_on_day_off]);

  useEffect(() => {
    dispatch(onInputResetAction(FormProps.formName, "allow_work_on_day_off"));
  }, [employeeForm?.attendanceType]);

  useEffect(() => {
    employeeForm?.attendanceType === Constants.attendanceTypes.SHIFT_BASED &&
      getOptions({ variables: { isOffice: false } });
  }, [employeeForm?.attendanceType]);

  const handleWorkDayTypeChange = () => {
    dispatch(
      onInputResetAction(FormProps.formName, "permissionLeavesBreakSettingId")
    );
    dispatch(
      onInputResetAction(
        FormProps.formName,
        "mainPermissionLeavesBreakSettingId"
      )
    );
  };

  const checkInsWorkPlaces = useSelector(
    (state) => state.super?.employeeFormCheckInsWorkPlaces?.data
  );

  const flexWorkDayTemplateOptions = useSelector(
    (state) => state?.super?.editEmployeeFormData?.flexWorkDayTemplates
  );
  // start of handle work time options consist of worktime menu data and old selected work time data
  const serilazedNormalWorkTimingArray = [
    data?.normal_work_timings ?? [],
    normalWorkTiming ?? [],
  ].flat();

  const serilazedHalfWorkTimingArray = [
    data?.half_work_timings ?? [],
    [firstHalfWorkTiming ?? {}, secondHalfWorkTiming ?? {}] ?? [],
  ].flat();

  const normalWorkTimeOptions = _.uniqBy(serilazedNormalWorkTimingArray, "id");
  const halfWorkTimeOptions = _.uniqBy(serilazedHalfWorkTimingArray, "id");
  // End of handle work time options consist of worktime menu data and old selected work time data

  const { hasFlex, hasFixed } = useCompanyAttType();

  return (
    <div className="boxContainer">
      <div className="name_container">
        <ScheduleIcon />
        <h4 className="sub-title-style mb-0">{t("work schedule")}</h4>
      </div>
      <hr />
      <RadioboxForm
        {...FormProps}
        options={[
          ...(hasFixed
            ? [
                {
                  label: "Fixed Work Timings",
                  value: "fixed",
                  optProps: {
                    disabled: !isEditable,
                  },
                },
              ]
            : []),
          ...(hasFlex
            ? [
                {
                  label: "Flex Work Hours ",
                  value: "flexible",
                  optProps: {
                    disabled: !isEditable,
                  },
                },
              ]
            : []),
        ]}
        label="Work Day Type"
        name="workdayType"
        containerStyle="mt-3"
        labelStyle="label-style"
        optionsContainerStyle="optionsContainerStyle row"
        optionItemStyle="col-6 px-0"
        optionInputStyle=" "
        optionLabelStyle="mb-0 mr-3"
        interceptChange={handleWorkDayTypeChange}
      />
      {employeeForm?.workdayType != "flexible" ? (
        <RadioboxForm
          {...FormProps}
          options={[
            {
              label: "Office Based Hours",
              value: "office based",
            },
            {
              label: "shift based work schedule",
              value: "shifts",
            },
          ]}
          label="Employee Work Schedule"
          name="attendanceType"
          type="radio"
          containerStyle="mt-3"
          labelStyle="label-style"
          optionsContainerStyle="optionsContainerStyle row"
          optionItemStyle="col-6 px-0"
          optionInputStyle=" "
          optionLabelStyle="mb-0 mr-3"
        />
      ) : null}
      {employeeForm?.attendanceType ===
        Constants.attendanceTypes.OFFICE_BASED &&
      employeeForm?.workdayType != "flexible" ? (
        <>
          <div className="mt-3">
            <BSelect
              {...FormProps}
              name="att_profile_id"
              label={t("attendance profile")}
              keepDefaultStyle
              placeholder={t("select option")}
              options={attendance_profiles}
              validateBy="textRequired"
              validationName="input.user_input.att_profile_id"
              skipLocalization
              icon="person"
              isDisabled={!isEditable}
              onInterceptInputOnChange={handleInterceptAttendanceProfileChange}
            />
          </div>
        </>
      ) : null}
      {employeeForm?.attendanceType === Constants.attendanceTypes.SHIFT_BASED ||
      employeeForm?.workdayType == "flexible" ? (
        <div className="row">
          <div className="mt-3 col-lg-6">
            <BSelect
              {...FormProps}
              name="workGroupId"
              label={t("work group")}
              keepDefaultStyle
              placeholder={t("select option")}
              options={
                employeeForm?.workdayType === "flexible"
                  ? flexWorkGroupOptions
                  : workGroupOptions
              }
              validationName="input.user_input.attendanceType.workGroupId"
              skipLocalization
              icon="person"
              isClearable
              isDisabled={!isEditable}
              validateBy="textRequired"
            />
          </div>

          <div className="mt-2 col-lg-6">
            <BSelect
              {...FormProps}
              name="workTeamId"
              label={t("work team")}
              keepDefaultStyle
              placeholder={t("select option")}
              options={
                employeeForm?.workdayType === "flexible"
                  ? flexWorkTeamOptions?.filter(
                      (flexWorkTeam) =>
                        flexWorkTeam?.flexWorkGroup?.id ===
                        employeeForm?.workGroupId
                    )
                  : workTeamOptions?.filter(
                      (workteam) =>
                        workteam?.workGroup?.id === employeeForm?.workGroupId
                    )
              }
              validationName="input.user_input.workTeamId"
              skipLocalization
              icon="person"
              isDisabled={!isEditable}
            />
          </div>

          <div className="col-6 shift_start_at">
            <DateTimePickerForm
              {...FormProps}
              label={t("join team from")}
              labelStyle="mb-2"
              name="workTeamStartDate"
              containerStyle="flex-column"
              placeholder={t("select date")}
              validationName="input.user_input.attendanceType.joiningFrom"
              disabled={!isEditable}
            />
          </div>

          {employeeForm?.workdayType !== "flexible" ? (
            <div className="col-lg-12 mt-2">
              <BSelect
                {...FormProps}
                label={t("first day of the week")}
                name="first_day_of_the_week"
                keepDefaultStyle
                placeholder={t("select option")}
                hideSelectedOptions
                optionLabel="label"
                optionValue="value"
                options={Const.WeekDays}
                containerStyle="row justify-content-between align-items-center my-3"
                labelStyle="col-12 mb-2"
                inputContainerStyle="col-12"
                validationName="input.user_input.weekStartDay"
                validateBy={
                  employeeForm?.attendanceType ===
                  Constants.attendanceTypes.SHIFT_BASED
                    ? "textRequired"
                    : false
                }
                icon={<CalendarToday />}
                change
                isDisabled={!isEditable}
              />
            </div>
          ) : null}
          {employeeForm.workdayType !== "flexible" ? (
            <div className="col-lg-12 mt-2">
              <MultipleCheckIn
                isShifts
                formProps={FormProps}
                name="allowMultipleCheckIns"
                allow_customize_check_ins={
                  employeeForm?.allow_customize_check_ins
                }
                customCheckFormIDs={{
                  in: employeeForm?.check_in_form_id,
                  out: employeeForm?.check_out_form_id,
                }}
              />
            </div>
          ) : null}
        </div>
      ) : null}

      {employeeForm.workdayType == "flexible" ||
      employeeForm?.attendanceType === Constants.attendanceTypes.SHIFT_BASED ? (
        <>
          {/* check in settings start */}
          {employeeForm?.allowMultipleCheckIns ? (
            <CheckInSettings
              reducer="super"
              formProps={FormProps}
              workPlacesOptions={checkInsWorkPlaces}
            />
          ) : null}
          {/* check in settings end */}

          {/* Work On Days Off */}
          <WorkOnDaysOff
            formProps={FormProps}
            serverValidationPrefix="user_input.attendanceType."
            halfWorkTimingOptions={halfWorkTimeOptions}
            normalWorkTimingOptions={normalWorkTimeOptions}
            costCentersOptions={costCentersOptions}
            flexWorkDayTemplateOptions={flexWorkDayTemplateOptions}
            isFlex={employeeForm.workdayType === "flexible"}
          />

          <div className="ml-3">
            {employeeForm?.allow_work_on_day_off ||
            employeeForm?.flex_allow_clockin_in_on_days_off ? (
              <>
                {/* Work Places */}
                <WorkPlaces
                  formProps={FormProps}
                  locationsOptions={data?.offices?.data}
                  serverValidationPrefix="user_input.attendanceType."
                  isFlex={employeeForm.workdayType === "flexible"}
                />

                {!employeeForm?.treat_as_normal ? (
                  <>
                    {/* Compensation */}
                    <Compensation
                      formProps={FormProps}
                      serverValidationPrefix="user_input.attendanceType."
                      compensation_type={employeeForm?.compensation_type}
                      apply_compensation={employeeForm?.apply_compensation}
                      isFlex={employeeForm.workdayType === "flexible"}
                    />

                    {/* Requests */}
                    {employeeForm.workdayType != "flexible" ? (
                      <Requests formProps={FormProps} />
                    ) : null}
                  </>
                ) : null}
              </>
            ) : null}
          </div>

          <WorkOnHolidays
            halfWorkTimingOptions={halfWorkTimeOptions}
            normalWorkTimingOptions={normalWorkTimeOptions}
            formProps={FormProps}
            locationsOptions={data?.offices?.data}
            showWorkPlaceSection={true}
            serverValidationPrefix="input.user_input.attendanceType"
            costCentersOptions={costCentersOptions}
            isFlex={employeeForm.workdayType === "flexible"}
            flexWorkDayTemplateOptions={flexWorkDayTemplateOptions}
          />
        </>
      ) : null}
    </div>
  );
};

export default WorkScheduleSection;
