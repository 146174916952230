import gql from "graphql-tag";

export const UPSERT_EMPLOYEE_LEAVE_BREAK_PROFILE = gql`
  mutation upsertEmployeeLeaveProfile(
    $input: UpsertUserPermissionLeaveBreakSettingsInput
  ) {
    upsert_user_permission_leave_break_settings(input: $input) {
      id
    }
  }
`;

export const DELETE_EMPLOYEE_LEAVE_BREAK_PROFILE = gql`
  mutation deleteUserPermissionLeaveBreakProfile($id: ID!) {
    delete_user_permission_leave_break_settings(id: $id) {
      status
      message
    }
  }
`;

export const upsert_bulk_permission_leave_break_settings_user = gql`
  mutation upsertBulkEmployeeLeaveProfile(
    $input: UpsertBulkPermissionLeaveBreakSettingsUserInput
  ) {
    upsert_bulk_permission_leave_break_settings_user(input: $input) {
      status
      message
    }
  }
`;



export const addRequestLimitSettingsForEmployeesMutation = gql`
	mutation addRequestLimitSettingsForEmployeesMutation($input: RequestSettingConfigurationInput) {
		addRequestSettingConfigurationForEmployees(input: $input) {
			status
			message
		}
	}
`;



