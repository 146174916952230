import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  showAddDocumentsModalAction,
  showEditDocumentModalAction,
  fetchEmployeeDocumnetsAction,
} from "../../Store/Actions";

import Documents from "./Documents";
import Loader from "../../Components/Loader";
import UpsertDocumentsModal from "./UpsertDocumentsModal";
import HistoryDocumentsModal from "./HistoryDocumentsModal";

const page_flag = "user_profile";

const DocumentsTab = () => {
  const { userId } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Reducer State
  const selectedEmployeeDocs = useSelector(
    (state) => state?.super.selectedEmployeeDocs
  );
  const employeeDocumentsLoading = useSelector(
    (state) => state?.super.employeeDocumetsLoading
  );

  /* ↓ State Effects ↓ */

  useEffect(() => {
    dispatch(fetchEmployeeDocumnetsAction(userId));
  }, []);

  /* ↓ Helpers ↓ */

  // for add documents modal
  const handleshowAddDocumentsModal = (
    document_id,
    fields,
    userId,
    document_name,
    country
  ) => {
    dispatch(
      showAddDocumentsModalAction({
        ref: "upsertDocumentsModalActions",
        formName: "upsertDocumentsModal",
        fields,
        document_id,
        inputs: fields?.reduce(
          (obj, item) => Object.assign(obj, { [item.id]: "" }),
          {}
        ),
        userId,
        document_name,
        country,
      })
    );
  };

  // for edit documents modal
  const handleshowEditDocumentModal = (
    document_id,
    fields,
    inputs,
    files,
    user_document_id,
    userId,
    document_name,
    country
  ) => {
    dispatch(
      showEditDocumentModalAction({
        ref: "EditDocumentModalActions",
        formName: "EditDocumentModal",
        fields,
        document_id,
        inputs,
        files,
        user_document_id,
        userId,
        document_name,
        country,
      })
    );
  };

  return (
    <div className="employee-form employee_profile_documents_wrapper_style position-relative">
      {employeeDocumentsLoading ? (
        <div className="loader_wrapper_style position-absolute">
          <Loader />
        </div>
      ) : null}
      {selectedEmployeeDocs?.required?.length ? (
        <>
          <div className="d-flex flex-column flex-md-row justify-content-between">
            <div>
              <h4 className="sub-title-style mb-1">
                {t("required documents")}
              </h4>
              <hr />
            </div>
          </div>
          <div className="mt-4 mx-4 mx-lg-0">
            <div className="row">
              {selectedEmployeeDocs?.required?.map((required_documents) => (
                <Documents
                  key={required_documents?.document?.id}
                  {...required_documents}
                  userId={userId}
                  handleshowAddDocumentsModal={handleshowAddDocumentsModal}
                  handleshowEditDocumentModal={handleshowEditDocumentModal}
                  page_flag={page_flag}
                />
              ))}
            </div>
          </div>
        </>
      ) : null}
      {selectedEmployeeDocs?.optional?.length ? (
        <div className="mt-3">
          <div className="d-flex flex-column flex-md-row justify-content-between">
            <div>
              <h4 className="sub-title-style mb-1">
                {t("optional documents")}
              </h4>
              <hr />
            </div>
          </div>
          <div className="mt-4 mx-4 mx-lg-0">
            <div className="row">
              {selectedEmployeeDocs?.optional?.map((optional_documents) => (
                <Documents
                  key={optional_documents?.document?.id}
                  {...optional_documents}
                  userId={userId}
                  handleshowAddDocumentsModal={handleshowAddDocumentsModal}
                  handleshowEditDocumentModal={handleshowEditDocumentModal}
                  page_flag={page_flag}
                />
              ))}
            </div>
          </div>
        </div>
      ) : null}

      {!selectedEmployeeDocs?.optional?.length &&
      !selectedEmployeeDocs?.required?.length ? (
        <div className="mt-5 w-100 text-center">
          {t("no employee document")}
        </div>
      ) : null}

      {/* Start of Upsert document Modal */}
      <UpsertDocumentsModal page_flag={page_flag} />
      <HistoryDocumentsModal
        page_flag={page_flag}
        handleshowAddDocumentsModal={handleshowAddDocumentsModal}
        handleshowEditDocumentModal={handleshowEditDocumentModal}
      />
      {/* End of Upsert document Modal */}
    </div>
  );
};

export default DocumentsTab;
