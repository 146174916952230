export default {
  FIXED_AMOUNT: "1",
  DAYS_PAYMENT_FACTOR: "2",
  BASE_PERCENTAGE: "3",
  GROSS_PERCENTAGE: "4",
  NET_PERCENTAGE: "5",
  PERCENT_OF_INSURED_SALARY: "6",
  PAYMENT_FACTOR_ACCORDING_TO_CUSTOM_EQUATION: "7",
  PAYMENT_FACTOR_HOURS: "8",
};
