import moment from "moment";
import { safeJsonParse } from ".";

/*
  Packages
*/

export const serializeFetchPackage = (data) => {
  return {
    id: data?.id,
    name: data?.name,
    status: data?.status,
    trial_value: data?.trial_value,
    activeSubscriptions: data?.activeSubscriptions,
  };
};

export const serializeFetchPackageProfile = (data) => {
  return {
    id: data?.id,
    name: data?.name,
    roles: data?.roles,
    status: data?.status,
    trial_value: data?.trial_value,
    is_free: data?.trial_value == null ? 1 : 0,
    activeSubscriptions: data?.activeSubscriptions,
    features: data?.features?.map((feat) => ({
      id: feat.id,
      limit: feat?.pivot?.limits,
    })),
  };
};

/*
  Plans
*/

export const serializeFetchPlan = (data) => {
  return {
    id: data?.id,
    name: data?.name,
    status: data?.status,
    pricing: data?.pricing,
    package: data?.package,
    paymentType: data?.type,
    currency: data?.currency,
    billingPeriod: data?.period,
    contractDuration: data?.duration,
    suspend_after: data?.suspend_after,
    activeSubscribers: data?.activeSubscribers,
    tiers: serializeFetchPlanTiers(data?.configuration),
    companyIds: data?.companyIds || [],
  };
};

export const serializeUpsertPlan = (data) => {
  const tiers = data?.tiers.map((t) => ({
    limit: t?.to,
    name: t?.name,
    amount: t?.price,
  }));

  return {
    id: data?.id,
    name: data?.name,
    companyIds: data?.companyIds ? data?.companyIds?.map((id) => +id) : [],
    currency_id: +data?.currency,
    package_id: +data?.package,
    period: data?.billingPeriod,
    duration: +data?.contractDuration,
    type: data?.paymentType,
    pricing: data?.pricing,
    suspend_after: +data?.suspend_after,
    configuration: JSON.stringify({ tiers }),
  };
};

export const periodToDuration = (period) => {
  switch (period) {
    case "Monthly":
      return "Months";
    case "Quarterly":
      return "Quarters";
    case "Semi-annually":
      return "Semi-annually";
    case "Annually":
      return "Years";
    default:
      break;
  }
};

/*
  Subscriptions
*/

export const serializeFetchSubscription = (data) => {
  return {
    id: data?.id,
    status: data?.status,
    company: data?.company,
    country: data?.country?.name,
    taxation: data?.taxation,

    plan: data?.planName,
    paymentType: data?.paymentType,
    billingPeriod: data?.billingPeriod,
    contractDuration: data?.duration,
    employees: data?.users,
    ratePerPeriod: data?.ratePerPeriod,
    startDate: data?.from,
    endDate: data?.to,
    currentTier: data?.currentTier,
    suspend_after: data?.suspend_after,
    agent: data?.user,

    prorate: data?.prorateAmount,
    payments: data?.payments?.map((p) =>
      serializeFetchPayment({
        ...p,
        company: data?.company?.name,
        method: safeJsonParse(p?.method),
      })
    ),
  };
};

export const serializeUpsertSubscription = (data) => {
  let promotion;
  const promotions = data?.applyPromotionAll
    ? data?.promotions?.map((p, i) => {
        if (i) return promotion;
        promotion = p;
        return p;
      })
    : data?.promotions;

  return {
    id: data?.id,
    user_id: +data?.agent,
    company_id: data?.company ? +data?.company : undefined,
    country_id: data?.country ? +data?.country : undefined,
    taxation: data?.taxation,
    eInvoice: data?.eInvoice,
    applyTax: data?.applyTax,
    taxAmount:
      data?.applyTax && data?.taxation === "Custom"
        ? data?.taxAmount
        : undefined,

    plan_id: +data?.plan || undefined,
    duration: +data?.contractDuration,
    users: +data?.employees,
    from: data?.startDate,
    prorate: data?.prorateAmount,
    suspend_after: +data?.suspend_after || undefined,
    promotions: data?.applyPromotion ? JSON.stringify(promotions) : undefined,
    feeConfiguration:
      data?.applyFees && data?.fees?.length
        ? JSON.stringify(
            data?.fees?.map((f) => ({
              ...f,
              from: f?.from?.format("YYYY-MM-DD"),
            }))
          )
        : undefined,
  };
};

/*
  Subscriptions Profile
*/

export const serializeSubscriptionDetails = (data) => {
  return {
    company: data?.company,
    startDate: data?.from,
    credit: data?.company?.credit,
    endDate: data?.to,
    status: data?.status,

    plan: data?.planName,
    billingPeriod: data?.billingPeriod,
    ratePerPeriod: data?.ratePerPeriod,
    paymentType: data?.paymentType,

    history: safeJsonParse(safeJsonParse(data?.history || "[]") || "[]"),

    currentTier: data?.currentTier,
    isEnded: data?.isEnded,
  };
};

export const serializeAddInvoice = (data) => {
  return {
    name: data?.name,
    from: data?.from,
    methodId: +data?.methodId,
    companyId: +data?.company_id,
    amount: String(data?.amount),
    suspend_after: +data?.suspend_after,
  };
};

/*
  Payments
*/

export const serializeFetchPayment = (data) => {
  return {
    id: data?.id,
    company: data?.company,
    amount: data?.amount,
    isFollowUp: data?.isFollowup,
    isCustom: data?.isCustom,
    company_id: data?.company_id,
    details: data?.details,
    dueDate: data?.due_date,
    invoice: data?.invoice,
    status: data?.status,
    eInvoice: data?.eInvoice,
    collector: data?.collector,
    received_at: data?.received_at,
    paid: data?.paid,
    logs: data?.logs,
    comments: data?.comments,
    from: data?.from,
    contacts: data?.paymentContacts || [],
    receivedAmount: data?.receivedAmount,
    method: safeJsonParse(data?.method),
  };
};

export const serializeEditInvoice = (data) => {
  return {
    id: +data?.id,
    due_date: data?.dueDate,
    methodId: +data?.methodId,
    terms: data?.paymentTerms,
    items: JSON.stringify(
      data?.items.reduce(
        (acc, { userId, ...curr }) => (userId ? [...acc, curr] : acc),
        []
      )
    ),
  };
};

export const serializeEditPayment = (data) => {
  return {
    id: +data?.id,
    due_date: data?.dueDate,
    eInvoice: data?.eInvoice,
    methodId: +data?.methodId,
    amount: String(data?.amount),
    applyTax: Number(data?.applyTax),
    taxAmount:
      data?.applyTax && data?.taxation === "Custom"
        ? data?.taxAmount
        : undefined,
  };
};

export const serializePayInvoice = (data) => {
  const sendFile = () => {
    if (data?.file?.id) return undefined; // saved file case
    if (data?.file) return data?.file; // new file case
    return null; // deleted or no file case
  };

  return {
    id: +data?.id,
    file: sendFile(),
    methodId: +data?.methodId,
    amount: String(data?.amount),
    collector_id: data?.collector,
    received_at: data?.received_at,
  };
};

/*
  Payment Methods
*/

export const serializeFetchPaymentMethod = (data) => {
  return {
    id: data?.id,
    name: data?.name,
    ...serializeFetchPaymentMethodDetails(safeJsonParse(data?.details)),
  };
};

export const serializeUpsertPaymentMethod = (data) => {
  return {
    id: data?.id,
    name: data?.name,
    details: serializeUpsertPaymentMethodDetails(data),
  };
};

export const serializeUpsertPaymentMethodDetails = (data) => {
  let obj = { type: data?.paymentMethod };

  switch (data?.paymentMethod) {
    case "Bank":
      obj = {
        ...obj,
        iban: data?.IBAN,
        name: data?.bankName,
        no: data?.accountNumber,
      };
      break;
    case "Wallet":
      obj = {
        ...obj,
        name: data?.wallet,
        no: data?.phoneNumber,
      };
      break;
    case "Prepaid":
      obj = {
        ...obj,
        name: data?.card,
        no: data?.cardNumber,
      };
      break;

    default:
      break;
  }

  return JSON.stringify(obj);
};

export const serializeFetchPaymentMethodDetails = (payments, skipParse) => {
  const data = skipParse ? payments : safeJsonParse(payments);
  let obj = { paymentMethod: data?.type };

  switch (obj?.paymentMethod) {
    case "Bank":
      obj = {
        ...obj,
        IBAN: data?.iban,
        bankName: data?.name,
        accountNumber: data?.no,
      };
      break;
    case "Wallet":
      obj = {
        ...obj,
        wallet: data?.name,
        phoneNumber: data?.no,
      };
      break;
    case "Prepaid":
      obj = {
        ...obj,
        card: data?.name,
        cardNumber: data?.no,
      };
      break;

    default:
      break;
  }

  return obj;
};

/*
  Others
*/

export const serializeFetchPlanTiers = (config) => {
  const configuration = JSON.parse(config);
  const tiers = JSON.parse(configuration)?.tiers;
  const formatTier = (t, i) => {
    return {
      ...t,
      price: t?.amount,
      from: i ? +tiers[i - 1]?.limit + 1 : 1,
      to: t?.limit,
    };
  };

  return tiers?.length ? tiers.map(formatTier) : [];
};

export const formatRatePerPeriod = (plan, price) => {
  return (
    (plan?.currency?.name || plan?.currency) +
    " " +
    price +
    "/" +
    plan?.billingPeriod
  );
};

export const getTiersOptions = (plan) => {
  const tiers = serializeFetchPlanTiers(plan.configuration);
  return tiers.map((t) => ({
    id: t.from,
    name: `${t.from} - ${t.to}`,
    ratePerPeriod: formatRatePerPeriod(plan, t.price),
  }));
};

export const getPlansOptions = (plans, companyID) => {
  if (companyID) {
    const { ordinaryPlans, companyPlans } = plans.reduce(
      (acc, p) => {
        if (p?.companyIds?.includes(+companyID)) acc.companyPlans.push(p);
        if (!p?.companyIds?.length) acc.ordinaryPlans.push(p);
        return acc;
      },
      { ordinaryPlans: [], companyPlans: [] }
    );

    if (companyPlans?.length) return [...companyPlans, ...ordinaryPlans];
    else return ordinaryPlans;
  } else {
    return plans;
  }
};

export const calcEndDate = (period, duration, startDate) => {
  let endDate;
  const date = moment(startDate);
  switch (period) {
    case "Monthly":
      endDate = date.add("months", +duration);
      break;
    case "Quarterly":
      endDate = date.add("months", 3 * +duration);
      break;
    case "Semi-annually":
      endDate = date.add("months", 6 * +duration);
      break;
    case "Annually":
      endDate = date.add("years", +duration);
      break;
    default:
      break;
  }

  return endDate.subtract("days", 1).format("yyyy-MM-DD");
};