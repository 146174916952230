import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  onInputChangeAction,
  onInputResetAction,
  setInputInValidateAction,
  setInputValidateAction,
} from "../../Store/Actions";
import Validation from "../../Helpers/Validations";
import { withDependency } from "../../Helpers/HOC/withDependency";
import { useFormValidation } from "../../Helpers/Hooks/useFormValidation";

const SelectForm = ({
  children,
  dependOn,
  label,
  value,
  name,
  formNameValidation,
  formSubmitting,
  formName,
  options,
  validateBy,
  ...props
}) => {
  const inputval = props.super[formName][name];
  const isDisabled = props.disabled;
  const validate = useFormValidation(
    inputval,
    name,
    formNameValidation,
    formSubmitting,
    validateBy,
    isDisabled
  );
  const [isValid, setIsValid] = useState(true);
  const [validationError, setvalidationError] = useState("");

  useEffect(() => {
    if (validateBy && !props.disabled) {
      if (formSubmitting) {
        Validation.validationSchema
          .validateAt(validateBy, { [validateBy]: props.super[formName][name] })
          .then((valid) => {
            setIsValid(true);
            setvalidationError("");
            props.setInputValidateAction(props.formNameValidation, name);
          })
          .catch((err) => {
            setIsValid(false);
            setvalidationError(err.message);
            props.setInputInValidateAction(props.formNameValidation, name);
            
          });
      } else {
        Validation.validationSchema
          .validateAt(validateBy, { [validateBy]: props.super[formName][name] })
          .then((valid) =>
            props.setInputValidateAction(props.formNameValidation, name)
          )
          .catch((err) =>
            props.setInputInValidateAction(props.formNameValidation, name)
          );
      }
    }
    return () => {
      if (validateBy && !props.disabled) {
        props.setInputValidateAction(props.formNameValidation, name);
      }
    };
  }, [props.super[formName][name], formSubmitting]);

  const handleInputChange = (e) => {
    e.persist();
    props.onInputChangeAction(formName, e);
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="form-group">
          {label && <label>{label}</label>}
          <select
            // {...props}
            className="select form-control"
            name={name}
            value={props.super[formName] && props.super[formName][name]}
            onChange={handleInputChange}
          >
            {children}
            {options &&
              options.map((opt) => (
                <option key={opt.id} value={opt.id}>
                  {opt.name}
                </option>
              ))}
          </select>
        </div>
        {!validate.validity && (
          <div className="error_message_style alert alert-danger">
            {validate.validityMessage}
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    super: state.super,
  };
};

export default connect(mapStateToProps, {
  onInputChangeAction,
  onInputResetAction,
  setInputValidateAction,
  setInputInValidateAction,
})(withDependency(SelectForm));
