import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  CheckboxBooleanForm,
  BSelect,
  InputForm,
  RadioboxForm,
} from "form-builder";
import { useDispatch, useSelector } from "react-redux";
import Constants, { workPlaceSettingOptions } from "../../Constants";
import HelperFns from "../../Helpers/HelperFns";
import Privilages from "../../Constants/Privilages";
import useAllowCostCenter from "../../Helpers/Hooks/useAllowCostCenter";
import { onInputResetWithValueAction } from "../../Store/Actions";

const WorkOnHolidays = ({
  formProps,
  normalWorkTimingOptions,
  halfWorkTimingOptions,
  showWorkPlaceSection = false,
  locationsOptions = [],
  serverValidationPrefix = "",
  isActivationModal = false,
  costCentersOptions = [],
  isFlex = false,
  flexWorkDayTemplateOptions = [],
  isEditContainer = false,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { allowCostCenter } = useAllowCostCenter();

  const formData = useSelector(
    (state) => state?.[formProps?.reducer ?? "super"]?.[formProps?.formName]
  );

  useEffect(() => {
    if (isEditContainer) {
      dispatch(
        onInputResetWithValueAction(
          formProps?.formName,
          "allow_work_on_holiday",
          true
        )
      );
    }
  }, [isEditContainer]);

  return (
    <div>
      <div className="day-off-and-holiday-divider" />
      {isEditContainer ? null : (
        <strong
          className={
            isActivationModal
              ? "text-16 sec-color d-block mt-3 mb-2"
              : "d-block mt-4 blue-color"
          }
        >
          {t("work on holidays")}
          {isActivationModal ? <hr className="title-line" /> : null}
        </strong>
      )}

      <div className={isEditContainer ? "" : "ml-3"}>
        {isEditContainer ? null : (
          <CheckboxBooleanForm
            {...formProps}
            options={[
              isFlex
                ? "allow employee to clock in on their holidays"
                : "allow employee to sign in on their holidays",
            ]}
            name="allow_work_on_holiday"
            type="checkbox"
          />
        )}

        {formData?.allow_work_on_holiday ? (
          <>
            {formData?.allow_work_on_day_off ? (
              <CheckboxBooleanForm
                {...formProps}
                options={["use the same configuration as day off"]}
                name="same_config"
                type="checkbox"
              />
            ) : null}

            {allowCostCenter ? (
              <BSelect
                {...formProps}
                name="holiday_cost_center_id"
                validationName={`${serverValidationPrefix}.holiday_exception_config.cost_center_id`}
                label={"Cost center"}
                keepDefaultStyle
                {...(isEditContainer
                  ? {}
                  : {
                      rootStyle: formData?.holiday_treat_as_normal
                        ? "col-lg-6"
                        : "col-lg-12",
                    })}
                options={costCentersOptions ?? []}
                labelStyle="custom-label-style"
                inputContainerStyle="w-100"
              />
            ) : null}

            {(formData?.allow_work_on_day_off && !formData?.same_config) ||
            !formData?.allow_work_on_day_off ? (
              <>
                {isFlex ? (
                  <BSelect
                    {...formProps}
                    validation="textRequired"
                    label="work day template"
                    name="holiday_normal_work_timing_id"
                    placeholder={t("select work day template")}
                    validateBy="textRequired"
                    keepDefaultStyle
                    options={flexWorkDayTemplateOptions}
                    inputContainerStyle="w-100"
                    rootStyle={isEditContainer ? "" : "col-lg-6"}
                    customNewStyles
                  />
                ) : (
                  <div className="row mt-2">
                    <BSelect
                      {...formProps}
                      validation="textRequired"
                      label="work timing"
                      name="holiday_normal_work_timing_id"
                      placeholder={t("select work timing")}
                      validateBy="textRequired"
                      validationName={`${serverValidationPrefix}.holiday_exception_config.normal_work_timing_id`}
                      keepDefaultStyle
                      options={normalWorkTimingOptions}
                      optionLabel="name"
                      optionValue="id"
                      inputContainerStyle="w-100"
                      rootStyle={
                        formData?.holiday_treat_as_normal
                          ? "col-lg-6"
                          : "col-lg-12"
                      }
                      customNewStyles
                      skipLocalization
                    />
                    {formData?.holiday_treat_as_normal ? (
                      <InputForm
                        {...formProps}
                        name="holiday_weight"
                        validateBy="textRequired"
                        validationName={`${serverValidationPrefix}.holiday_exception_config.weight`}
                        label="weight"
                        labelStyle="min-w-150 mb-3"
                        containerStyle=" "
                        rootStyle="col-lg-6"
                        type="number"
                      />
                    ) : null}
                  </div>
                )}
                {isEditContainer ? null : (
                  <CheckboxBooleanForm
                    {...formProps}
                    options={["Treat this day as a normal work day"]}
                    name="holiday_treat_as_normal"
                    validationName={`${serverValidationPrefix}.holiday_exception_config.treat_as_normal`}
                    labelStyle="label-style"
                    setWithValue
                    checkedValue={true}
                    unCheckedValue={false}
                  />
                )}
                {formData?.holiday_treat_as_normal && !isFlex ? (
                  <>
                    <CheckboxBooleanForm
                      {...formProps}
                      options={["allow employees to request half-days"]}
                      name="holiday_allow_half_days"
                      labelStyle="label-style"
                    />
                    {formData?.holiday_allow_half_days ? (
                      <div className="row mt-2">
                        <BSelect
                          {...formProps}
                          validation="textRequired"
                          label="first half work timing"
                          name="holiday_first_half_work_timing_id"
                          placeholder={t("select work timing")}
                          validateBy="textRequired"
                          validationName={`${serverValidationPrefix}.holiday_exception_config.first_half_work_timing_id`}
                          keepDefaultStyle
                          options={halfWorkTimingOptions}
                          optionLabel="name"
                          optionValue="id"
                          inputContainerStyle="w-100"
                          rootStyle="col-lg-6"
                          customNewStyles
                          customComponents={{ Option }}
                        />
                        <BSelect
                          {...formProps}
                          label="second half work timing"
                          name="holiday_second_half_work_timing_id"
                          placeholder={t("select work timing")}
                          validateBy="textRequired"
                          validationName={`${serverValidationPrefix}.holiday_exception_config.second_half_work_timing_id`}
                          keepDefaultStyle
                          options={halfWorkTimingOptions}
                          optionLabel="name"
                          optionValue="id"
                          inputContainerStyle="w-100"
                          rootStyle="col-lg-6"
                          customNewStyles
                          customComponents={{ Option }}
                        />
                      </div>
                    ) : null}
                  </>
                ) : null}
                {!formData?.holiday_treat_as_normal && !isEditContainer ? (
                  <>
                    <strong className="d-block mt-4 mb-2 blue-color">
                      {t("compensation")}
                    </strong>
                    <CheckboxBooleanForm
                      {...formProps}
                      name="holiday_apply_compensation"
                      options={[
                        "compensate employee for the extra time worked",
                      ]}
                      containerStyle="my-1"
                    />
                    {formData?.holiday_apply_compensation ? (
                      <div className="info-line-container">
                        <div aria-hidden="true" className="info-line">
                          <div className="testing-top-circle" />
                          <div className="testing-bottom-circle" />
                        </div>
                        <RadioboxForm
                          {...formProps}
                          name="holiday_compensation_type"
                          options={[
                            ...(HelperFns.checkCompanyPrivileges({
                              privileges: [
                                Privilages.ADD_EDIT_DELETE_BONUSES_DEDUCTIONS,
                              ],
                            })
                              ? [{ label: "award bonus days", value: "bonus" }]
                              : []),
                            { label: "award additional days", value: "leaves" },
                          ]}
                          containerStyle="my-1"
                          labelStyle="label-style"
                          optionItemStyle="mr-3"
                          optionInputStyle=" "
                          optionLabelStyle="optionLabelStyle"
                          optionsContainerStyle="d-flex gap-20"
                          validationName={`${serverValidationPrefix}.holiday_exception_config.compensation_type`}
                        />
                        {formData.holiday_compensation_type === "leaves" ? (
                          <InputForm
                            {...formProps}
                            name="holiday_compensation_quantity"
                            validationName={`${serverValidationPrefix}.holiday_exception_config.compensation_quantity`}
                            label={
                              isFlex
                                ? "leave hours / work hour"
                                : "leave days / work day"
                            }
                            labelStyle="min-w-150"
                            containerStyle="d-flex align-items-end justify-content-start mb-md-0 mr-2"
                            inputContainerStyle="ml-3 inputs-days-inputs-style"
                            type="number"
                          />
                        ) : null}

                        <div className="d-flex gap-20">
                          <BSelect
                            {...formProps}
                            name="holiday_payment_factor_id"
                            validateBy="textRequired"
                            label="type"
                            keepDefaultStyle
                            optionLabel="label"
                            optionValue="value"
                            options={Constants.BounsTypes}
                            rootStyle="flex-1"
                            validationName={`${serverValidationPrefix}.holiday_exception_config.payment_factor_id`}
                            inputContainerStyle="flex-grow-1 mr-0 w-100"
                            skipLocalization
                            icon="type"
                            dependOn="holiday_compensation_type"
                            dependancyType="equal"
                            dependancyValue={["bonus"]}
                          />
                          {formData.holiday_compensation_type === "bonus" ? (
                            <InputForm
                              {...formProps}
                              name="holiday_compensation_quantity"
                              validationName={`${serverValidationPrefix}.holiday_exception_config.compensation_quantity`}
                              label="value"
                              containerStyle=""
                              labelStyle="mb-2"
                              type="number"
                              rootStyle="flex-1"
                            />
                          ) : null}
                        </div>
                      </div>
                    ) : null}

                    <strong className="d-block mt-4 mb-2 blue-color">
                      {t("requests")}
                    </strong>
                    <div className="d-flex gap-20">
                      <CheckboxBooleanForm
                        {...formProps}
                        options={["permissions"]}
                        name="holiday_allow_permission"
                        labelStyle="label-style"
                        setWithValue
                        checkedValue={true}
                        unCheckedValue={false}
                      />
                      <CheckboxBooleanForm
                        {...formProps}
                        options={["overtime"]}
                        name="holiday_allow_overtime"
                        labelStyle="label-style"
                        setWithValue
                        checkedValue={true}
                        unCheckedValue={false}
                      />
                    </div>
                  </>
                ) : null}
                {showWorkPlaceSection ? (
                  <>
                    <strong className="d-block mt-4 mb-2 blue-color">
                      {t("work places")}
                    </strong>

                    <RadioboxForm
                      {...formProps}
                      name="holiday_workplace_setting"
                      validateBy="textRequired"
                      label="Employee can sign in From"
                      keepDefaultStyle
                      options={workPlaceSettingOptions}
                      validationName={`${serverValidationPrefix}.holiday_exception_config.workplace_setting`}
                      containerStyle="my-2"
                      labelStyle="label-style font-weight-bold"
                      optionItemStyle="mr-3"
                      optionInputStyle=" "
                      optionLabelStyle="optionLabelStyle"
                      optionsContainerStyle="d-flex flex-column mb-2"
                      skipLocalization
                    />
                    {formData?.holiday_workplace_setting === "DEFAULT" ? (
                      <BSelect
                        {...formProps}
                        isMulti
                        name="holiday_additional_work_places"
                        validateBy="textRequired"
                        label="Additional Workplaces"
                        keepDefaultStyle
                        options={locationsOptions}
                        labelStyle="my-2"
                        containerStyle="containerStyle flex-column align-items-start py-0"
                        validationName={`${serverValidationPrefix}.holiday_exception_config.additional_work_places`}
                        inputContainerStyle="flex-grow-1 mr-0 w-100"
                        skipLocalization
                        icon="people"
                      />
                    ) : null}

                    <CheckboxBooleanForm
                      {...formProps}
                      containerStyle="my-3"
                      name="holiday_workplace_setting_identical"
                      options={["Apply the same settings when signing out"]}
                    />

                    {!formData?.holiday_workplace_setting_identical ? (
                      <>
                        <RadioboxForm
                          {...formProps}
                          name="holiday_workplace_setting_signout"
                          validateBy="textRequired"
                          label="Employee can sign out From"
                          keepDefaultStyle
                          options={workPlaceSettingOptions}
                          validationName={`${serverValidationPrefix}.holiday_exception_config.workplace_setting`}
                          containerStyle="my-2"
                          labelStyle="label-style font-weight-bold"
                          optionItemStyle="mr-3"
                          optionInputStyle=" "
                          optionLabelStyle="optionLabelStyle"
                          optionsContainerStyle="d-flex flex-column mb-2"
                          skipLocalization
                        />

                        {formData?.holiday_workplace_setting_signout ==
                        "DEFAULT" ? (
                          <BSelect
                            {...formProps}
                            isMulti
                            name="holiday_additional_work_places_signout"
                            validateBy="textRequired"
                            label="Additional Workplaces"
                            keepDefaultStyle
                            options={locationsOptions}
                            labelStyle="my-2"
                            validationName={`${serverValidationPrefix}.holiday_exception_config.additional_work_places_signout`}
                            containerStyle="containerStyle flex-column align-items-start py-0"
                            inputContainerStyle="flex-grow-1 mr-0 w-100"
                            skipLocalization
                            icon="people"
                          />
                        ) : null}
                      </>
                    ) : null}
                  </>
                ) : null}{" "}
              </>
            ) : null}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default WorkOnHolidays;
