import React from "react";
import { EmployeesTabs } from "../../Routes/EmployeesPage";
import LogsTabs from "../../Routes/LogsPage/LogsTabs";
import { CRMTabs } from "../../Routes/CRM/CRMPage";
import SettingsTabs from "../../Routes/SettingsPage/SettingsTabs";
import FinancialsTabs from "../../Routes/ViewFinancials/FinancialsTabs";
import ExpensesTabs from "../../Routes/ViewExpenses/ExpensesTabs";
import RecruitmentTabs from "../../Routes/ViewRecruitment/RecruitmentTabs";
import RequestsTabs from "../../Routes/ViewRequests/RequestsTabs";
import { PaymentTrackingTabs } from "../../Routes/PaymentTracking/PaymentTrackingPage";
import { AdminPanelTabs } from "../../Routes/AdminPanel/AdminPanelPage";
import AssetsTabs from "../../Containers/Assets/AssetsTabs";
import FlexibleWorkScheduleTabs from "../../Routes/FlexibleWorkSchedule/FlexibleWorkScheduleTabs";
import useCompanyAttType from "../../Helpers/Hooks/useCompanyAttType";

function RouteSidebar(props) {
  const { hasFlex, hasFixed } = useCompanyAttType();

  if (props?.route === "employees") {
    return <EmployeesTabs isRouteSidebar={true} />;
  }
  if (props.route === "requests") {
    return <RequestsTabs isRouteSidebar={true} />;
  }
  if (props.route === "attendance") {
    return <LogsTabs isRouteSidebar={true} />;
  }
  if (props.route === "financials") {
    return <FinancialsTabs isRouteSidebar={true} />;
  }
  if (props.route === "expenses") {
    return <ExpensesTabs isRouteSidebar={true} />;
  }
  if (props.route === "recruitment") {
    return <RecruitmentTabs isRouteSidebar={true} />;
  }
  if (props.route === "settings") {
    return <SettingsTabs isRouteSidebar={true} />;
  }
  if (props.route === "Admin Panel") {
    return <AdminPanelTabs isRouteSidebar={true} />;
  }
  if (props.route === "crm") {
    return <CRMTabs isRouteSidebar={true} />;
  }
  if (props.route === "Payment Tracking") {
    return <PaymentTrackingTabs isRouteSidebar={true} />;
  }
  if (props?.route === "assets") {
    return <AssetsTabs isRouteSidebar={true} />;
  }
  if (props?.route === "Flexible Work Schedule" && hasFlex) {
    return <FlexibleWorkScheduleTabs isRouteSidebar={true} />;
  }
  return null;
}

export default RouteSidebar;
