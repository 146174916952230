import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  // showEditCheckInsModalAction,
  showViewCheckInsOutsLocationModalAction,
} from "../../Store/Actions";
import moment from "moment";

import CheckNotesModal from "./CheckNotesModal";
import { Alarm, ArrowUpward, ArrowDownward } from "@mui/icons-material";

const modalInitState = { isOpen: false, data: {} };

const CheckInsCard = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [modalState, setModalState] = React.useState(modalInitState);

  // handling edit check ins modal
  // const handleEditCheckInModal = (checkInObj) => {
  //   let data = {
  //     check_in_id: checkInObj?.id,
  //     checkInFace: checkInObj?.checkInFace,
  //     checkOutFace: checkInObj?.checkOutFace,
  //     check_in_date_time: checkInObj?.check_in_time,
  //     check_in_lng: checkInObj?.check_in_lng,
  //     check_in_lat: checkInObj?.check_in_lat,
  //     check_in_space_id: checkInObj?.check_in_space_id,
  //     check_in_description: checkInObj?.check_in_description,
  //     validate_against_check_in_location: true,
  //     check_out_date_time: checkInObj?.check_out_time,
  //     check_out_lng: checkInObj?.check_out_lng,
  //     check_out_lat: checkInObj?.check_out_lat,
  //     check_out_space_id: checkInObj?.check_out_space_id,
  //     check_out_description: checkInObj?.check_out_description,
  //     validate_against_check_out_location: true,
  //     name: props?.employeeRecord?.employee?.name,
  //     date: props?.employeeRecord?.date,
  //   };
  //   dispatch(
  //     showEditCheckInsModalAction({
  //       data,
  //       ref: "editCheckInsModalActions",
  //       formName: "editCheckInsModal",
  //     })
  //   );
  // };

  // handling view check ins location modal
  const handleViewCheckInLocationModal = (checkInObj) => {
    let data = {
      lng: checkInObj?.check_in_lng,
      lat: checkInObj?.check_in_lat,
      face: checkInObj?.checkInFace?.path,
      location_name: checkInObj?.checkInSpace?.name,
      name: props?.employeeRecord?.employee?.name,
      date: props?.employeeRecord?.date,
      checkType: "check_in",
      signinOutLocationsModal: false,
    };
    dispatch(
      showViewCheckInsOutsLocationModalAction({
        data,
        ref: "viewCheckInOutLocationModalActions",
        formName: "viewCheckInOutLocationModal",
      })
    );
  };

  // handling view check outs location modal
  const handleViewCheckOutLocationModal = (checkInObj) => {
    let data = {
      lng: checkInObj?.check_out_lng,
      lat: checkInObj?.check_out_lat,
      face: checkInObj?.checkOutFace?.path,
      location_name: checkInObj?.checkOutSpace?.name,
      name: props?.employeeRecord?.employee?.name,
      date: props?.employeeRecord?.date,
      checkType: "check_out",
      signinOutLocationsModal: false,
    };
    dispatch(
      showViewCheckInsOutsLocationModalAction({
        data,
        ref: "viewCheckInOutLocationModalActions",
        formName: "viewCheckInOutLocationModal",
      })
    );
  };

  // Start of handle check in and out duration calculation of the same record
  function handleCheckInOutDuration(checkInObj) {
    if (checkInObj?.check_out_time && checkInObj?.check_in_time) {
      let checkInOutDuration = moment.duration(
        moment(checkInObj?.check_out_time).diff(
          moment(checkInObj?.check_in_time)
        )
      );

      let checkInOutDurationHours =
        checkInOutDuration.hours() < 10
          ? `0${checkInOutDuration.hours()}`
          : checkInOutDuration.hours();

      let checkInOutDurationMinutes =
        checkInOutDuration.minutes() < 10
          ? `0${checkInOutDuration.minutes()}`
          : checkInOutDuration.minutes();

      return checkInOutDuration
        ? `${checkInOutDurationHours}:${checkInOutDurationMinutes}`
        : "-------";
    }

    return "-------";
  }
  // End of handle check in and out duration calculation of the same record

  // Start of handle check in and out duration calculation between last check out and new check in
  function handleDurationBetweenLastCheckOutAndNewCheckIn(
    lastcheckOutTime,
    newCheckInTime
  ) {
    if (lastcheckOutTime && newCheckInTime) {
      let betweenCheckInOutDuration = moment.duration(
        moment(newCheckInTime).diff(moment(lastcheckOutTime))
      );

      let betweenCheckInOutDurationHours =
        betweenCheckInOutDuration.hours() < 10
          ? `0${betweenCheckInOutDuration.hours()}`
          : betweenCheckInOutDuration.hours();

      let betweenCheckInOutDurationMinutes =
        betweenCheckInOutDuration.minutes() < 10
          ? `0${betweenCheckInOutDuration.minutes()}`
          : betweenCheckInOutDuration.minutes();

      return betweenCheckInOutDuration
        ? `+ ${betweenCheckInOutDurationHours}:${betweenCheckInOutDurationMinutes}`
        : "-------";
    }
    return null;
  }
  // End of handle check in and out duration calculation between last check out and new check in
  return (
    <div>
      {props?.checkIns?.map((checkInObj, i, data) => (
        <div className="attendance-logs-card-container">
          <div className="check-in-card-container">
            <div
              className="att_name_date_container name-date-col-width"
              data-isprofile={props?.isMyProfileHistory}
            >
              <span style={{ opacity: i === 0 ? 1 : 0 }}>
                {t("Check-in details")}
              </span>
            </div>

            <div className="home-col-width"></div>

            <div className="checkin_index_container">
              <div
                className={`checkin_index ${
                  i === 0 && "checkin_index-first"
                } ${i + 1 === data.length && "checkin_index-last"}`}
              >
                {t("checkin-index", {
                  index: i + 1,
                  suffix:
                    i + 1 == 1
                      ? "st"
                      : i + 1 == 2
                        ? "nd"
                        : (i = 1 == 3 ? "rd" : "th"),
                })}
              </div>
            </div>

            <div className="ml-4 pl-1"></div>

            <div className="signin-out-col-width">
              <div className="signin-out-container">
                <div className="sign-in-check-in-container">
                  <div className="icon_time_container">
                    <ArrowDownward className="checkin_icon" />

                    <span
                      className="checkin_time"
                      title={
                        checkInObj?.check_in_time
                          ? moment(checkInObj?.check_in_time).format(
                              "hh:mm:ss A"
                            )
                          : "---------"
                      }
                    >
                      {checkInObj?.check_in_time
                        ? moment(checkInObj?.check_in_time).format("hh:mm A")
                        : "---------"}
                    </span>
                  </div>

                  <div className="check_in_location_container">
                    <p
                      className="checkin_location"
                      onClick={() => handleViewCheckInLocationModal(checkInObj)}
                    >
                      {checkInObj?.checkInSpace?.name
                        ? checkInObj?.checkInSpace?.name
                        : t("unspecified location")}
                    </p>
                  </div>
                </div>

                <div className="sign-out-check-out-container">
                  <div className="icon_time_container">
                    <ArrowUpward className="checkout_icon" />
                    <span
                      className="checkout_time"
                      title={
                        checkInObj?.check_out_time
                          ? moment(checkInObj?.check_out_time).format(
                              "hh:mm:ss A"
                            )
                          : "---------"
                      }
                    >
                      {checkInObj?.check_out_time
                        ? moment(checkInObj?.check_out_time).format("hh:mm A")
                        : "---------"}
                    </span>
                  </div>

                  <div className="sign_out_location_container checkout_container">
                    <p
                      className="checkout_location"
                      onClick={() =>
                        handleViewCheckOutLocationModal(checkInObj)
                      }
                    >
                      {checkInObj?.check_out_time
                        ? checkInObj?.checkOutSpace?.name
                          ? checkInObj?.checkOutSpace?.name
                          : t("unspecified location")
                        : null}
                    </p>
                  </div>
                </div>

                <div
                  className="attendance_duration"
                  // style={{ marginInlineStart: "-44px" }}
                >
                  <Alarm className="attendance_duration_icon" />

                  <span className="attendance_duration_time">
                    {handleCheckInOutDuration(checkInObj)}
                  </span>
                </div>
              </div>
            </div>

            <button
              className="bg-white border-0 sec-color"
              onClick={() => setModalState({ isOpen: true, data: checkInObj })}
              style={{
                textDecoration: "underline",
                backgroundColor: "transparent",
                marginInlineEnd: "clamp(20px, 2vw, 40px)",
              }}
            >
              {t("view notes")}
            </button>

            <div
              className="checkin_description"
              data-isprofile={props?.isMyProfileHistory}
            ></div>

            {props?.employeeRecord?.checkIns?.length > 1 ? (
              <div
                className="checkin_diff"
                data-isprofile={props?.isMyProfileHistory}
              >
                {handleDurationBetweenLastCheckOutAndNewCheckIn(
                  checkInObj?.check_out_time,
                  data[i + 1]?.check_in_time
                )}
              </div>
            ) : null}
          </div>
        </div>
      ))}

      {modalState.isOpen ? (
        <CheckNotesModal
          onClose={() => setModalState(modalInitState)}
          data={{
            ...modalState?.data,
            date: props?.employeeRecord?.date,
            employeeName: props?.employeeRecord?.employee?.name,
          }}
        />
      ) : null}
    </div>
  );
};

export default CheckInsCard;
