import React from "react";
import { useTranslation } from "react-i18next";

import moment from "moment";
import Helper from "../../Helpers/HelperFns";

import CheckLogs from "./CheckLogs";
import { Link } from "react-router-dom";
import * as styles from "./style.module.scss";
import { DateRange } from "@mui/icons-material";
import { Avatar, Divider, Tooltip } from "@mui/material";
import CanViewEmployeeProfile from "../../Helpers/HOC/CanViewEmployeeProfile";
import { FaceIconRow, IconRow, LocationIconRow, TimeIconRow } from "./shared";
import FacialRecognitionCardPlaceholder from "../../LoadingSection/FacialRecognitionCardPlaceholder";

const FacialRecognitionCard = (props) => {
  const {
    employee,
    checkIns,
    sign_in_face,
    sign_out_face,
    sign_in_time,
    sign_in_lng,
    sign_in_lat,
    sign_out_time,
    sign_out_lng,
    sign_out_lat,
    sign_in_location,
    sign_out_location,
    liveness_ratio_in,
    liveness_ratio_out,
    mocked_location_in,
    mocked_location_out,
    onViewLocation,
  } = props;

  return (
    <article className={styles.card}>
      <div className="position-relative d-flex align-items-center gap-10">
        <Avatar
          src={employee?.face?.path ?? props?.clockinContainer?.user?.face}
          className={styles.avatar}
        >
          <span>
            {Helper.getIntials(
              employee?.name ?? props?.clockinContainer?.user?.name
            )}
          </span>
        </Avatar>
        <Divider orientation="vertical" variant="middle" flexItem />
        <div className="flex-fill">
          <CanViewEmployeeProfile
            allowBP
            directManger={
              employee?.manager?.id ??
              props?.clockinContainer?.user?.manager?.id
            }
            copiedManagers={(
              employee?.copied_managers ??
              props?.clockinContainer?.user?.copied_managers
            )?.map((cp) => cp?.id)}
            altChildren={
              <h3 className={styles.name}>
                {employee?.name ?? props?.clockinContainer?.user?.name}
              </h3>
            }
          >
            <Link
              className={styles.name}
              to={`/employees/employee-profile/${employee?.id ?? props?.clockinContainer?.user?.id}`}
            >
              <h3 className={styles.name}>
                {employee?.name ?? props?.clockinContainer?.user?.name}
              </h3>
            </Link>
          </CanViewEmployeeProfile>
          <div className={styles.logs}>
            <Log
              isSignIn
              time={sign_in_time ?? props?.clock_in_time}
              name={employee?.name ?? props?.clockinContainer?.user?.name}
              faceSrc={sign_in_face?.path ?? props?.clockInFace?.path}
              faceSuccessRatio={liveness_ratio_in}
              locationName={sign_in_location?.name}
              isMockedLocation={Boolean(mocked_location_in)}
              lng={sign_in_lng}
              lat={sign_in_lat}
              onViewLocation={onViewLocation}
            />
            <Log
              time={sign_out_time ?? props?.clock_out_time}
              name={employee?.name ?? props?.clockinContainer?.user?.name}
              faceSrc={sign_out_face?.path ?? props?.clockOutFace?.path}
              faceSuccessRatio={liveness_ratio_out}
              locationName={sign_out_location?.name}
              isMockedLocation={Boolean(mocked_location_out)}
              lng={sign_out_lng}
              lat={sign_out_lat}
              onViewLocation={onViewLocation}
            />
          </div>
        </div>
      </div>

      {/* Check-Ins */}
      {checkIns?.length ? (
        <>
          <Divider className="my-2" variant="middle" flexItem />
          <CheckLogs
            name={employee?.name ?? props?.clockinContainer?.user?.name}
            checkIns={checkIns}
            onViewLocation={onViewLocation}
          />
        </>
      ) : null}
    </article>
  );
};

const Log = ({
  isSignIn,
  name,
  time,
  faceSrc,
  locationName,
  faceSuccessRatio,
  isMockedLocation,
  lng,
  lat,
  onViewLocation,
}) => {
  const { t } = useTranslation();

  const handleViewLocation = () => {
    onViewLocation({
      lng,
      lat,
      name,
      date: time,
      face: faceSrc,
      signinOutLocationsModal: true,
      type: isSignIn ? "sign_in" : "sign_out",
      location_name: locationName || t("unspecified location"),
    });
  };

  return (
    <div className="flex-1 text-12">
      {time ? (
        <>
          <div className="d-flex align-items-center gap-10">
            <Tooltip
              title={
                <Avatar
                  src={faceSrc}
                  alt={name}
                  variant="square"
                  sx={{ width: 150, height: 150 }}
                />
              }
            >
              <Avatar
                src={faceSrc}
                alt={name}
                className={styles.smAvatar}
                sx={{ fontSize: "20px !important" }}
              />
            </Tooltip>
            <div className="darkish-gray-color flex-fill">
              <IconRow
                icon={
                  <DateRange
                    color="inherit"
                    sx={{ fontSize: 16 }}
                    style={{ marginInlineEnd: 4 }}
                  />
                }
                txt={moment(time, "YYYY-MM-DD HH:mm:ss").format(
                  "DD - MM - YYYY"
                )}
              />
              <TimeIconRow time={time} isIn={isSignIn} />
            </div>
          </div>

          <div className="mt-2">
            <LocationIconRow
              locationName={locationName}
              isMockedLocation={isMockedLocation}
              handleViewLocation={handleViewLocation}
            />
            <span className="d-none">
              {(+faceSuccessRatio * 100)?.toFixed(2)}
            </span>
            <FaceIconRow face={faceSrc} faceSuccessRatio={faceSuccessRatio} />
          </div>
        </>
      ) : (
        <FacialRecognitionCardPlaceholder />
      )}
    </div>
  );
};

export default FacialRecognitionCard;
