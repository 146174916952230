import React from "react";
import { useTranslation } from "react-i18next";
import { CheckboxBooleanForm, InputForm, RadioboxForm } from "form-builder";
import { useSelector } from "react-redux";

const OvertTimeGraceMinutesForm = (props) => {
  const { t } = useTranslation();

  const salaryConfigPaymentType = useSelector(
    (state) => state?.super?.salaryConfigForm?.calculate_salary_by
  );

  const { FormProps, serverValidationPrefix = "input." } = props;
  return (
    <>
      <>
        <CheckboxBooleanForm
          {...FormProps}
          options={["Calculate Overtime for the interval"]}
          name="allow_overtime"
          type="checkbox"
          setWithValue
        />

        <InputForm
          {...FormProps}
          stepInput
          validateBy="textRequired"
          label="overtime hours rate"
          name="overtime_hour_rate"
          validationName={`${serverValidationPrefix}overtime_hour_rate`}
          type="text"
          containerStyle="container-style-default justify-content-between"
          dependOn="allow_overtime"
          dependancyType="equal"
          dependancyValue={[true]}
        />

        <InputForm
          {...FormProps}
          stepInput
          validateBy="textRequired"
          label="overtime payment factor"
          name="overtime_factor"
          validationName={`${serverValidationPrefix}overtime_factor`}
          type="text"
          containerStyle="container-style-default justify-content-between"
          dependOn="allow_overtime"
          dependancyType="equal"
          dependancyValue={[true]}
        />
        <CheckboxBooleanForm
          {...FormProps}
          options={["Apply overtime cutoff"]}
          name="overtime_cutoff"
          validationName={`${serverValidationPrefix}overtime_cutoff`}
          type="checkbox"
          dependOn="allow_overtime"
          dependancyType="equal"
          dependancyValue={[true]}
          setWithValue
        />

        <div className="sub-container-style">
          <InputForm
            {...FormProps}
            hasSuffix
            suffixTitle="Hours"
            validateBy="textRequired"
            label="Calculate overtime after the expected work hours by"
            name="min_overtime_cutoff"
            validationName={`${serverValidationPrefix}min_overtime_cutoff`}
            type="text"
            inputContainerStyle="m-start-auto"
            dependOn="overtime_cutoff"
            dependancyType="equal"
            dependancyValue={[true]}
          />

          <CheckboxBooleanForm
            {...FormProps}
            options={["Apply Overtime Limits"]}
            name="overtime_limit"
            validationName={`${serverValidationPrefix}overtime_limit`}
            type="checkbox"
            dependOn="allow_overtime"
            dependancyType="equal"
            dependancyValue={[true]}
            setWithValue
          />

          <InputForm
            {...FormProps}
            stepInput
            validateBy="textRequired"
            label="Max. Overtime Hours Per Month"
            name="max_overtime_limit"
            validationName={`${serverValidationPrefix}max_overtime_limit`}
            type="text"
            containerStyle="container-style-default justify-content-between"
            dependOn="overtime_limit"
            dependancyType="equal"
            dependancyValue={[true]}
          />

          <InputForm
            {...FormProps}
            label="Expected Work Hours"
            hasSuffix
            suffixTitle="Hours"
            validateBy="textRequired"
            name="min_work_hours"
            validationName={`${serverValidationPrefix}min_work_hours`}
            type="text"
            containerStyle="container-style-default justify-content-between"
            dependOn="allow_overtime"
            dependancyType="equal"
            dependancyValue={[true]}
          />
        </div>
      </>
    </>
  );
};

export default OvertTimeGraceMinutesForm;
