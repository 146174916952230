import {
  EDIT_FLEX_WORK_GROUP,
  TOGGLE_EDIT_CLOCKINS_CONTAINER,
  TOGGLE_FLEX_ATTENDANCE_ACTION_MODAL,
} from "./types";

export const editFlexWorkGroupAction = (data) => {
  return {
    type: EDIT_FLEX_WORK_GROUP,
    payload: data,
  };
};

export const toggleFlexAttendanceActionModal = () => {
  return { type: TOGGLE_FLEX_ATTENDANCE_ACTION_MODAL };
};

export const toggleEditClockinsContainer = ({ modal = {}, form = {} }) => {
  return {
    type: TOGGLE_EDIT_CLOCKINS_CONTAINER,
    payload: {
      modal,
      form,
    },
  };
};
