import Types from "../Actions/types";
import * as REQUESTS from "../../Constants/Requests";
import moment from "moment";

export const initState = {
  loggingInLoading: false,
  userProfileLoading: true,
  userProfile: {},
  fetchingProfileStatus: false,
  isLoading: false,
  myRequestsList: {
    from: moment().startOf("month").format("YYYY-MM-DD"),
    to: moment().endOf("month").format("YYYY-MM-DD"),
    statusFilter: [2],
    pagination: {
      lastItem: 10,
      total: 10,
      perPage: 10,
      currentPage: 1,
      hasMorePages: true,
      firstItem: 1,
      lastPage: 1,
    },
    sorting: {
      dir: "asc",
      key: "status",
    },
    data: [],
    isLoading: false,
  },
  myBalanceList: {
    yearFilter: new Date().getFullYear(),
    type: REQUESTS.NORMAL_LEAVES,
    pagination: {
      lastItem: 10,
      total: 10,
      perPage: 10,
      currentPage: 1,
      hasMorePages: true,
      firstItem: 1,
      lastPage: 1,
    },
    sorting: {
      dir: "desc",
      key: "date_time",
    },
    data: [],
    isLoading: false,
  },
  attendanceActionModal: {
    action_type: "",
    from: "",
    to: "",
    deducted_from_emergency: false,
    include_weekends_and_holidays_in_custom_requests: 0,
    payment_flag: "paid",
    time_from: "",
    time_to: "",
    half_day_will_attend: "",
    home_day: "",
    id: null,
    incurred_at: "",
    amount: "",
    reason: "",
    assignment_id: null,
    from_home_field: "home",
  },
  attendanceModalActions: {
    isVissible: false,
    isLoading: false,
    modalMessage: "",
  },
  attendanceActionModalValidation: [],

  historyList: {
    from: "",
    to: "",
    status: [],
    missing_sign_out: 0,
    attendance_configuration_conflict: 0,
    pagination: {
      lastItem: 20,
      total: 20,
      perPage: 20,
      currentPage: 1,
      hasMorePages: false,
      firstItem: 1,
      lastPage: 1,
    },
    data: [],
    isLoading: false,
  },
  signInLoading: false,
  signOutLoading: false,
  isSignedIn: false,
  homeSignInAlert: {
    open: false,
    message: "",
  },
  forceSignOutAlert: {
    open: false,
    message: "",
  },
  forgotPasswordLoading: false,
  resetPasswordLoading: false,

  totalPendingRequestsCount: 0,
  pendingRequests: 0,
  pendingClaims: 0,

  myClaimsList: {
    from: moment().startOf("month").format("YYYY-MM-DD"),
    to: moment().endOf("month").format("YYYY-MM-DD"),
    status: [2, 4, 3, 5, 1],
    pagination: {
      lastItem: 10,
      total: 10,
      perPage: 10,
      currentPage: 1,
      hasMorePages: true,
      firstItem: 1,
      lastPage: 1,
    },
    sorting: {
      dir: "desc",
      key: "created_at",
    },
    data: [],
    isLoading: false,
  },
  requestFormServerValidation: {},
  requstsLoader: [],
  myPayroll: {
    currency_id: "",
    basic_salary: 1,
    additions: 1,
    allowances: 1,
    bonuses: 1,
    overtime: 1,
    deductions: 1,
    penalties: 1,
    social_insurance: 1,
    medical_insurance: 1,
    taxes: 1,
    other_deductions: 1,
    others: 1,
    sorting: {
      dir: "desc",
      key: "month_year_payroll",
    },
    data: [],
    isLoading: false,
  },

  isUpsertLocale: false,
  editAttRequestModal: {
    isVissible: false,
    isLoading: false,
    modalMessage: "",
  },
  editAttRequestModalData: {
    from_home: "",
    date: "",
    sign_in_time: "",
    sign_out_time: "",
    sign_in_date: "",
    sign_out_date: "",
    from_home_field: "home",
  },
  editAttRequestModalValidation: [],
  viewAttRequestModal: {
    isVissible: false,
    isLoading: false,
    modalMessage: "",
  },
  viewAttRequestModalData: {},

  verifyPhoneLoading: false,
  sendPhoneVerificationLoading: false,

  userPettyCashList: {
    currency_id: "",
    from: moment().startOf("month").format("YYYY-MM-DD"),
    to: moment().endOf("month").format("YYYY-MM-DD"),
    type: ["add", "deduct", "expense"],
    pagination: {
      lastItem: 30,
      total: 30,
      perPage: 30,
      currentPage: 1,
      hasMorePages: true,
      firstItem: 1,
      lastPage: 1,
    },
    data: [],
    isLoading: false,
  },

  userProfileCurrentBalanceData: [],

  userProfilePettyCashIsLoading: false,

  verifyEmailLoading: false,
  sendEmailVerificationLoading: false,

  editLeavesModalActions: {
    isVissible: false,
    isLoading: false,
    modalMessage: "",
  },
  editLeavesModal: { end_date: "" },
  editLeavesModalValidation: [],

  myloansList: {
    status: 4,
    user_id: "",
    pagination: {
      lastItem: 10,
      total: 10,
      perPage: 10,
      currentPage: 1,
      hasMorePages: false,
      firstItem: 1,
      lastPage: 1,
    },
    data: [],
  },
};

export const userReducer = (state = initState, action) => {
  switch (action.type) {
    case Types.SET_DATATABLE_SORTING:
      if (state[action.payload.ref]) {
        return {
          ...state,
          [action.payload.ref]: {
            ...state[action.payload.ref],
            sorting: {
              key: action.payload.key,
              dir: action.payload.dir,
            },
          },
        };
      }
      return state;

    case Types.FETCH_PROFILE_SUCCESS:
      return {
        ...state,
        userProfile: {
          ...state?.userProfile,
          ...action?.payload?.user,
        },
        userProfileLoading: false,
        myRequestsList: {
          ...state.myRequestsList,
          isLoading: false,
        },
      };

    // Fetching Requests
    case Types.FETCH_HISTORY_LIST_ATTEMPT:
    case Types.FETCH_MY_PAYROLL_ATTEMPT:
    case Types.EDIT_ATT_REQUEST_MODAL_ATTEMPT:
    case Types.VIEW_ATT_REQUEST_MODAL_ATTEMPT:
      return {
        ...state,
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: true,
        },
      };
    case Types.FETCH_USER_PETTY_CASH_ATTEMPT:
      return {
        ...state,
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: true,
        },
        userProfilePettyCashIsLoading: true,
      };
    case Types.FETCH_HISTORY_LIST_SUCCESS:
    case Types.FETCH_MY_PAYROLL_SUCCESS:
    case Types.FETCH_MY_LOANS_LIST_SUCCESS:
      return {
        ...state,
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: false,
          data: action?.payload?.list?.data,
          pagination: action?.payload?.list?.paginatorInfo,
        },
      };
    case Types.FETCH_USER_PETTY_CASH_SUCCESS:
      return {
        ...state,
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: false,
          data: action?.payload?.list?.data,
          pagination: action?.payload?.list?.paginatorInfo,
        },
        userProfilePettyCashIsLoading: false,
      };

    case Types.FETCH_HISTORY_LIST_FAILED:
      return {
        ...state,
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: false,
        },
      };
    case Types.FETCH_USER_PETTY_CASH_FAILED:
      return {
        ...state,
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: false,
        },
        userProfilePettyCashIsLoading: false,
      };
    // Fetching User History
    case Types.FETCH_ATTENDANCE_RECORDS_ATTEMPT:
      return {
        ...state,
        fetchingAttendanceRecordsStatus: true,
      };
    case Types.FETCH_ATTENDANCE_RECORDS_SUCCESS:
      return {
        ...state,
        attendanceHistory: action.payload,
        fetchingAttendanceRecordsStatus: false,
      };
    case Types.FETCH_ATTENDANCE_RECORDS_FAILED:
      return {
        ...state,
        fetchingAttendanceRecordsStatus: false,
      };
    case Types.LOG_OUT:
    case "SWITCH_ACCOUNT":
      return { ...initState };

    //handle Input Change

    case Types.ON_INPUT_CHANGE:
      if (state[action.payload.formName]) {
        return {
          ...state,
          [action.payload.formName]: {
            ...state[action.payload.formName],
            [action.payload.event.target.name]:
              action.payload.event.target.value,
          },
        };
      }
      return state;

    case Types.ON_APPEND_INPUT_CHANGE:
      if (state[action.payload.formName]) {
        return {
          ...state,
          [action.payload.formName]: {
            ...state[action.payload.formName],
            [action.payload.event.target.name]: state[action.payload.formName][
              action.payload.event.target.name
            ].map((el, i) =>
              i == action.payload.value ? action.payload.event.target.value : el
            ),
          },
        };
      }
      return state;
    case Types.ON_SELECT_INPUT_CHANGE:
      if (state[action.payload.formName]) {
        return {
          ...state,
          [action.payload.formName]: {
            ...state[action.payload.formName],
            [action.payload.event.name]: action.payload.value,
          },
        };
      }
      return state;

    case Types.ON_INPUT_INCREMENT:
      if (state[action.payload.formName]) {
        return {
          ...state,
          [action.payload.formName]: {
            ...state[action.payload.formName],
            [action.payload.name]: (
              Number(state[action.payload.formName][action.payload.name]) + 1
            ).toString(),
          },
        };
      }
      return state;
    case Types.ON_INPUT_DECREMENT:
      if (state[action?.payload?.formName]) {
        return {
          ...state,
          [action.payload.formName]: {
            ...state[action?.payload?.formName],
            [action?.payload?.name]:
              Number(state[action?.payload?.formName][action?.payload?.name]) -
                1 >
              0
                ? (
                    Number(
                      state[action?.payload?.formName][action?.payload?.name]
                    ) - 1
                  )?.toString()
                : "0",
          },
        };
      }
      return state;
    case Types.ON_RADIO_INPUT_CHANGE:
      if (state[action.payload.formName]) {
        return {
          ...state,
          [action.payload.formName]: {
            ...state[action.payload.formName],
            [action.payload.event.target.name]: action.payload.value,
          },
        };
      }
      return state;
    case Types.ON_CHECKBOX_INPUT_CHANGE:
      if (state[action.payload.formName]) {
        return {
          ...state,
          [action.payload.formName]: {
            ...state[action.payload.formName],
            [action.payload.event.target.name]: action.payload.event.target
              .checked
              ? [
                  ...state[action.payload.formName][
                    action.payload.event.target.name
                  ],
                  action.payload.value,
                ]
              : state[action.payload.formName][
                  action.payload.event.target.name
                ].filter((val) => val !== action.payload.value),
          },
        };
      }
      return state;

    case Types.ON_CHECKBOX_BOOLEAN_INPUT_CHANGE:
      if (state[action.payload.formName]) {
        return {
          ...state,
          [action.payload.formName]: {
            ...state[action.payload.formName],
            [action.payload.event.target.name]: action.payload.event.target
              .checked
              ? 1
              : 0,
          },
        };
      }
      return state;

    case Types.ON_INPUT_RESET:
      if (state[action.payload.formName]) {
        return {
          ...state,
          [action.payload.formName]: {
            ...state[action.payload.formName],
            [action.payload.name]:
              initState[action.payload.formName][action.payload.name],
          },
        };
      }
      return state;

    case Types.ON_INPUT_RESET_WITH_VALUE:
      if ([action.payload.formName] in state) {
        return {
          ...state,
          [action.payload.formName]: {
            ...state[action.payload.formName],
            [action.payload.name]: action.payload?.resetValue,
          },
        };
      } else {
        return state;
      }

    case Types.SET_INPUT_VALID:
      if (state[action?.payload?.formNameValidation]) {
        return {
          ...state,
          [action?.payload?.formNameValidation]: state[
            action?.payload?.formNameValidation
          ]?.filter((input) => input !== action?.payload?.name),
        };
      }
      return state;

    case Types.SET_INPUT_INVALID:
      if (state[action?.payload?.formNameValidation]) {
        if (
          !state[action?.payload?.formNameValidation]?.includes(
            action?.payload?.name
          )
        ) {
          return {
            ...state,
            [action?.payload?.formNameValidation]: [
              ...state[action?.payload?.formNameValidation],
              action?.payload?.name,
            ],
          };
        }
        return state;
      }
      return state;

    case Types.SEND_NORMAL_REQUEST_ATTEMPT:
    case Types.SEND_EMERGENCY_REQUEST_ATTEMPT:
    case Types.SEND_PERMISSION_REQUEST_ATTEMPT:
    case Types.SEND_HALFDAY_REQUEST_ATTEMPT:
    case Types.SEND_HOME_EXCHANGE_REQUEST_ATTEMPT:
    case Types.SEND_HOME_FLEX_REQUEST_ATTEMPT:
    case Types.SEND_UNPAID_REQUEST_ATTEMPT:
    case Types.UPSERT_CLAIM_REQUEST_ATTEMPT:
    case Types.UPSERT_SICK_REQUEST_ATTEMPT:
    case Types.UPSERT_CUSTOM_REQUEST_ATTEMPT:
      return {
        ...state,
        attendanceModalActions: {
          ...state.attendanceModalActions,
          isLoading: true,
          modalMessage: "",
        },
      };

    case Types.TOGGLE_ATTENDANCE_ACTION_MODAL:
      return {
        ...state,
        requestFormServerValidation: {},
        attendanceModalActions: {
          ...state.attendanceModalActions,
          isVissible: !state.attendanceModalActions.isVissible,
          isFlex: !!action.payload?.isFlex,
        },
      };

    case Types.ATTENDANCE_ACTION_MODAL_SUCCESS:
    case Types.UPSERT_CLAIM_REQUEST_SUCCESS:
    case Types.UPSERT_SICK_REQUEST_SUCCESS:
    case Types.UPSERT_CUSTOM_REQUEST_SUCCESS:
      return {
        ...state,
        attendanceModalActions: {
          isVissible: false,
          isLoading: false,
          modalMessage: "",
        },
      };

    case Types.ATTENDANCE_ACTION_MODAL_FAILED:
    case Types.UPSERT_CLAIM_REQUEST_FAILED:
    case Types.UPSERT_CUSTOM_REQUEST_FAILED:
      return {
        ...state,
        attendanceModalActions: {
          ...state.attendanceModalActions,
          isLoading: false,
          modalMessage: action.payload.message,
        },
        [action.payload?.serverRef]: action.payload?.errors ?? {},
      };

    case Types.SIGN_IN_ATTEMPT:
    case Types.SIGN_IN_INFO_ATTEMPT:
      return {
        ...state,
        signInLoading: true,
      };
    case Types.SIGN_IN_SUCCESS:
      return {
        ...state,
        signInLoading: false,
        isSignedIn: true,
        userProfile: {
          ...state.userProfile,
          ...action.payload,
        },
      };
    case Types.SIGN_IN_FAILED:
      return {
        ...state,
        signInLoading: false,
      };

    case Types.SIGN_OUT_ATTEMPT:
    case Types.SIGN_OUT_INFO_ATTEMPT:
      return {
        ...state,
        signOutLoading: true,
      };
    case Types.SIGN_OUT_SUCCESS:
      return {
        ...state,
        signOutLoading: false,
        isSignedIn: false,
        userProfile: {
          ...state.userProfile,
          ...action.payload,
        },
      };
    case Types.SIGN_OUT_FAILED:
      return {
        ...state,
        signOutLoading: false,
      };

    case Types.SHOW_DISMISS_HOME_ALERT:
      return {
        ...state,
        homeSignInAlert: {
          open: true,
          message: action.payload.message,
        },
      };

    case Types.HIDE_DISMISS_HOME_ALERT:
      return {
        ...state,
        homeSignInAlert: {
          open: false,
          message: "",
        },
      };

    case Types.SHOW_FORCE_SIGN_OUT_ALERT:
      return {
        ...state,
        forceSignOutAlert: {
          open: true,
          message: action.payload.message,
        },
      };

    case Types.HIDE_FORCE_SIGN_OUT_ALERT:
      return {
        ...state,
        forceSignOutAlert: {
          open: false,
          message: "",
        },
      };

    case Types.REFRESH_SIGN_IN_OUT_SUCCESS:
      return {
        ...state,
        isSignedIn: action.payload.hide == "sign_in",
      };
    case Types.LOGING_IN_ATTEMPT:
      return { ...state, loggingInLoading: true };

    case Types.LOGED_IN_SUCCESS:
    case Types.LOGED_IN_FAILED:
      return { ...state, loggingInLoading: false };

    case Types.FORGOT_PASSWORD_ATTEMPT:
      return { ...state, forgotPasswordLoading: true };

    case Types.FORGOT_PASSWORD_FINISH:
      return { ...state, forgotPasswordLoading: false };

    case Types.RESET_PASSWORD_ATTEMPT:
      return { ...state, resetPasswordLoading: true };

    case Types.RESET_PASSWORD_SUCCESS:
    case Types.RESET_PASSWORD_FAILED:
      return { ...state, resetPasswordLoading: false };

    case Types.FETCH_TOTAL_EMPLOYEE_REQUESTS:
      return {
        ...state,
        pendingRequests: action.payload?.requests,
        pendingClaims: action.payload?.claims,
        totalPendingRequestsCount:
          action.payload?.requests + action.payload?.claims,
      };

    case Types.FETCH_TOTAL_PENDING_REQUESTS:
      return {
        ...state,
        pendingRequests: action.payload,
        totalPendingRequestsCount: action.payload + state.pendingClaims,
      };

    case Types.FETCH_TOTAL_PENDING_CLAIMS:
      return {
        ...state,
        pendingClaims: action.payload,
        totalPendingRequestsCount: action.payload + state.pendingRequests,
      };

    case Types.CANCEL_REQUEST_ATTEMPT:
      return {
        ...state,
        requstsLoader: [...state.requstsLoader, action.payload.id],
        editLeavesModalActions: {
          ...state.editLeavesModalActions,
          isLoading: true,
        },
      };

    case Types.CANCEL_REQUEST_SUCCESS:
    case Types.CANCEL_REQUEST_FAILED:
      return {
        ...state,
        requstsLoader: state.requstsLoader.filter(
          (id) => id !== action.payload.id
        ),
        editLeavesModalActions: {
          ...state.editLeavesModalActions,
          isLoading: false,
        },
      };

    case Types.UPSERT_USER_LOCALE_ATTEMPT:
      return {
        ...state,
        isUpsertLocale: true,
      };
    case Types.UPSERT_USER_LOCALE_SUCCESS:
      return {
        ...state,
        isUpsertLocale: false,
        userProfile: { ...state.userProfile, ...action.payload.user },
      };
    case Types.UPSERT_USER_LOCALE_FAILED:
      return {
        ...state,
        isUpsertLocale: false,
      };

    case Types.SHOW_VIEW_ATT_REQUEST_MODAL:
    case Types.VIEW_ATT_REQUEST_MODAL_SUCCESS:
    case Types.SHOW_EDIT_LEAVES_MODAL:
      return {
        ...state,
        [action.payload.ref]: {
          isVissible: !action.payload?.notification ? true : false,
          isNotificationVissible: action.payload?.notification ? true : false,
          isLoading: false,
          modalMessage: "",
        },
        [action.payload.formName]: {
          ...state[action.payload.formName],
          ...action.payload.data,
        },
      };

    case Types.SHOW_EDIT_ATT_REQUEST_MODAL:
      return {
        ...state,
        editAttRequestModal: {
          isVissible: true,
          isLoading: false,
          modalMessage: "",
        },
        editAttRequestModalData: {
          ...state.editAttRequestModalData,
          ...action.payload.data,
          sign_in_time: !!action.payload.data.sign_in_time
            ? moment(action.payload.data.sign_in_time).format("HH:mm")
            : "",
          sign_out_time: !!action.payload.data.sign_out_time
            ? moment(action.payload.data.sign_out_time).format("HH:mm")
            : "",
          sign_in_date: action.payload.data.sign_in_time
            ? moment(action.payload.data.sign_in_time).format("YYYY-MM-DD")
            : moment(action.payload.data.date, "YYYY-MM-DD").format(
                "YYYY-MM-DD"
              ),
          sign_out_date: action.payload.data.sign_out_time
            ? moment(action.payload.data.sign_out_time).format("YYYY-MM-DD")
            : moment(action.payload.data.date, "YYYY-MM-DD").format(
                "YYYY-MM-DD"
              ),
        },
      };

    case Types.HIDE_EDIT_ATT_REQUEST_MODAL:
    case Types.EDIT_ATT_REQUEST_MODAL_SUCCESS:
    case Types.HIDE_VIEW_ATT_REQUEST_MODAL:
    case Types.HIDE_EDIT_LEAVES_MODAL:
      return {
        ...state,
        [action.payload.ref]: {
          isVissible: false,
          isLoading: false,
          modalMessage: "",
        },
        [action.payload.formName]: initState[action.payload.formName],
      };

    case Types.EDIT_ATT_REQUEST_MODAL_FAILED:
    case Types.VIEW_ATT_REQUEST_MODAL_FAILED:
      return {
        ...state,
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: false,
          modalMessage: action.payload.message,
        },
      };

    case Types.VERIFY_USER_PHONE_ATTEMPT:
      return {
        ...state,
        verifyPhoneLoading: true,
      };

    case Types.VERIFY_USER_PHONE_SUCCESS:
    case Types.VERIFY_USER_PHONE_FAILED:
      return {
        ...state,
        verifyPhoneLoading: false,
      };

    case Types.SEND_PHONE_VERIFICATION_ATTEMPT:
      return {
        ...state,
        sendPhoneVerificationLoading: true,
      };

    case Types.SEND_PHONE_VERIFICATION_SUCCESS:
    case Types.SEND_PHONE_VERIFICATION_FAILED:
      return {
        ...state,
        sendPhoneVerificationLoading: false,
      };
    case Types.FETCH_USER_PROFILE_CURRENT_BALANCE_SUCCESS:
      return {
        ...state,
        userProfileCurrentBalanceData: action?.payload,
      };
    case Types.FETCH_USER_PROFILE_CURRENT_BALANCE_FAILED:
      return {
        ...state,
        userProfileCurrentBalanceData: [],
      };

    case Types.VERIFY_USER_EMAIL_ATTEMPT:
      return {
        ...state,
        verifyEmailLoading: true,
      };

    case Types.VERIFY_USER_EMAIL_SUCCESS:
    case Types.VERIFY_USER_EMAIL_FAILED:
      return {
        ...state,
        verifyEmailLoading: false,
      };

    case Types.SEND_EMAIL_VERIFICATION_ATTEMPT:
      return {
        ...state,
        sendEmailVerificationLoading: true,
      };

    case Types.SEND_EMAIL_VERIFICATION_SUCCESS:
    case Types.SEND_EMAIL_VERIFICATION_FAILED:
      return {
        ...state,
        sendEmailVerificationLoading: false,
      };

    default:
      return state;
  }
};
