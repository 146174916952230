import React from "react";
import { togglefailedSignInsLocationMapModal } from "../../../Store/Actions";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Config } from "../../../Config";
import MainModal from "../../../Components/MainModal";

const FailedSignInLocationMap = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Redux States
  const failedSignInsLocationMapModal = useSelector(
    (state) => state?.admin?.failedSignInsLocationMapModal
  );

  /* ↓ Helpers ↓ */

  // handle dismiss modal function
  const handleDismissModal = () => {
    dispatch(
      togglefailedSignInsLocationMapModal({ isOpen: false, modalData: null })
    );
  };

  return (
    <MainModal
      isOpen={failedSignInsLocationMapModal?.isOpen}
      toggle={handleDismissModal}
      modalTitle={t("sign in location")}
    >
      <div className="row">
        <div className="col-12">
          {failedSignInsLocationMapModal?.modalData?.user_lat &&
          failedSignInsLocationMapModal?.modalData?.user_lng ? (
            <div style={{ height: "250px" }}>
              <div className="w-100 h-100 position-relative">
                <iframe
                  title={t("sign in location")}
                  width="100%"
                  height="auto"
                  style={{ border: 0, width: "100%", height: "100%" }}
                  loading="eager"
                  allowfullscreen
                  referrerPolicy="no-referrer-when-downgrade"
                  src={`https://www.google.com/maps/embed/v1/place?key=${Config.googleMapKey}&q=${failedSignInsLocationMapModal?.modalData?.user_lat},${failedSignInsLocationMapModal?.modalData?.user_lng}`}
                ></iframe>
              </div>
            </div>
          ) : (
            <div>
              <p>
                {failedSignInsLocationMapModal?.modalData?.space?.name ||
                  t("unspecified location")}
              </p>
            </div>
          )}
        </div>
      </div>
    </MainModal>
  );
};

export default FailedSignInLocationMap;
