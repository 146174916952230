import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

import moment from "moment";
import Chart from "chart.js";
import gql from "graphql-tag";
import { connect } from "react-redux";
import { showAttChartEmployeeNameModalAction } from "../../../Store/Actions";

import { BSelect, DateTimePickerForm } from "form-builder";
import ShowEmployeesNameModal from "./ShowEmployeesNameModal";
import { AttchartPlaceholder } from "../../../LoadingSection/Dashboard";

const attendanceChartQuery = gql`
  query attendanceChart(
    $to: String
    $from: String
    $office_id: ID
    $firstRender: Boolean!
  ) {
    attendance_chart(to: $to, from: $from, office_id: $office_id) {
      attended {
        dates
        data
      }
      absent {
        dates
        data
      }
      leave {
        dates
        data
      }
      late {
        dates
        data
      }
    }
    company_offices(first: 999) @include(if: $firstRender) {
      data {
        value: id
        label: name
      }
    }
  }
`;

let Line;
const AttendanceHistoryChart = (props) => {
  const [mQuery, setMQuery] = useState(window.innerWidth);
  const [offices, setOffices] = useState([]);
  const [filter, setFilter] = useState({
    office_id: null,
    from: moment().startOf("month"),
    to: moment().endOf("month"),
  });

  const { data, loading } = useQuery(attendanceChartQuery, {
    variables: {
      office_id: filter?.office_id,
      firstRender: offices.length === 0,
      to: filter.to ? filter.to.format("YYYY-MM-DD") : null,
      from: filter.from ? filter.from.format("YYYY-MM-DD") : null,
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (data?.company_offices) {
      setOffices(data.company_offices.data);
    }
  }, [data]);

  useEffect(() => {
    window.addEventListener("resize", handleWindowChange);
    // this is the cleanup function to remove the listener
    return () => window.removeEventListener("resize", handleWindowChange);
  }, []);

  const handleWindowChange = (query) => {
    setMQuery(query.currentTarget.innerWidth);
  };

  const { t } = useTranslation();

  // Start of handling fetch and show employees name in modal on Click chart points
  const handleFetchAndShowEmployeeNameModalOnClick = (
    date,
    status,
    label,
    value,
    office_id
  ) => {
    if (value > 0) {
      let data = {
        date: date,
        status: status,
        label: label,
        value: value,
        office_id: office_id,
      };

      props.showAttChartEmployeeNameModalAction({
        data,
        ref: "attendanceChartEmployeeNamesModalActions",
        formName: "attendanceChartEmployeeNamesModal",
      });
    }
  };
  // End of handling fetch and show employees name in modal on Click chart points

  useEffect(() => {
    if (!loading) {
      // apply space between legends and chart
      Chart.Legend.prototype.afterFit = function () {
        this.height = this.height + 20;
      };

      var ctx = document.getElementById("LineChart").getContext("2d");
      if (typeof Line !== "undefined") Line.destroy();
      ctx.height = 500;
      // intiliazing Line Chart and its options
      Line = new Chart(ctx, {
        type: "line",
        PositionType: "chartArea",
        data: {
          labels: data?.attendance_chart?.late?.dates?.map((date, i) => date),
          datasets: [
            {
              label: t("present"),
              data: data?.attendance_chart?.["attended"]?.data,
              borderColor: "#27b40c",
              backgroundColor: "#27b40c",
              fill: false,
              status: "Ontime",
              date: data?.attendance_chart?.attended?.dates,
              pointHoverRadius: 4,
            },
            {
              label: t("absent"),
              data: data?.attendance_chart?.["absent"]?.data,
              borderColor: "#d9d9d9",
              backgroundColor: "#d9d9d9",
              fill: false,
              status: "Absent",
              date: data?.attendance_chart?.absent?.dates,
              pointHoverRadius: 11,
            },
            {
              label: t("Late Comers"),
              data: data?.attendance_chart?.["late"]?.data,
              backgroundColor: "#ff6a6a",
              fill: false,
              status: "Tardiness",
              date: data?.attendance_chart?.late?.dates,
              borderColor: "rgb(200,0,0)",
              pointHoverRadius: 20,
            },
            {
              label: t("on leave"),
              data: data?.attendance_chart?.["leave"]?.data,
              borderColor: "rgb(251 202 65)",
              backgroundColor: "rgb(251 202 65)",
              fill: false,
              status: "Leave",
              date: data?.attendance_chart?.leave?.dates,
              pointHoverRadius: 30,
              pointHoverBackgroundColor: "rgb(251 202 65)",
              pointHoverBorderColor: "rgb(251 202 65)",
            },
          ],
        },
        aspectRatio: 1,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: true,
            position: "top",
            align: "end",
          },
          onClick: function (evt) {
            var activePoints = Line.getElementAtEvent(evt);
            if (activePoints.length > 0) {
              var clickedDatasetIndex = activePoints[0]._datasetIndex;
              var clickedElementIndex = activePoints[0]._index;
              var clickedDatasetPoint = Line.data.datasets[clickedDatasetIndex];
              var status = clickedDatasetPoint.status;
              var label = clickedDatasetPoint.label;
              var value = clickedDatasetPoint.data[clickedElementIndex];
              var date = clickedDatasetPoint.date?.filter(
                (obj, i) => i == clickedElementIndex
              )[0];

              handleFetchAndShowEmployeeNameModalOnClick(
                date,
                status,
                label,
                value,
                filter?.office_id
              );
            }
          },
          tooltips: {
            mode: "single",
            footerFontStyle: "normal",
            footerFontSize: 10,
            callbacks: {
              footer: function (tooltipItem) {
                var pointValue = tooltipItem[0].yLabel;
                let content = "";
                if (pointValue > 0) {
                  content = t("click to see more");
                }
                return content;
              },
            },
          },
          hover: {
            mode: "nearest",
            intersect: true,
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  fontColor: "#5c6974",
                },
                gridLines: {
                  display: false,
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  fontColor: "#313030",
                },
                gridLines: {
                  display: true,
                },
              },
            ],
          },
        },
      });
    }
  }, [loading]);

  const handleOfficeSelect = (v) => {
    if (v) {
      setFilter((prev) => ({ ...prev, office_id: v?.value }));
    } else {
      setFilter((prev) => ({ ...prev, office_id: null }));
    }
  };

  const handleFromTo = (date, name) => {
    setFilter((prev) => ({ ...prev, [name]: date }));
  };

  return (
    <>
      <div className="d-flex flex-column flex-md-row justify-content-between chart-title">
        <h4 className="ml-1 pt-1 mb-0">{t("attendance_chart")}</h4>
        <div className="d-flex mr-2 gap-10 align-items-center">
          <BSelect
            optionLabel="label"
            optionValue="value"
            options={offices}
            onChange={(v) => handleOfficeSelect(v)}
            bValue={filter.office_id}
            isClearable
            containerStyle="month-picker mt-1"
            isLoading={!offices?.length && loading}
            placeholder={t("all offices")}
            icon="office"
            label="office"
          />

          <DateTimePickerForm
            placeholder="from"
            label="from"
            value={filter.from}
            onChange={(val) => handleFromTo(val, "from")}
            containerStyle={"month-picker mt-2"}
          />
          <DateTimePickerForm
            placeholder="to"
            label="to"
            value={filter.to}
            onChange={(val) => handleFromTo(val, "to")}
            containerStyle={"month-picker mt-2"}
          />
        </div>
      </div>
      {loading ? (
        <AttchartPlaceholder />
      ) : (
        <div
          style={{
            overflow: "auto",
            overflowX:
              data?.attendance_chart?.late?.dates?.length * 60 > mQuery
                ? "scroll"
                : "auto",
          }}
          className="lineChart_wrapper_style"
        >
          {loading ? null : (
            <div
              style={{
                width:
                  data?.attendance_chart?.late?.dates?.length * 60 > mQuery
                    ? data?.attendance_chart?.late?.dates?.length * 60
                    : "auto",
              }}
            >
              <canvas id="LineChart"></canvas>
            </div>
          )}
        </div>
      )}
      {/* Start of Show Employee Names Modal */}
      <ShowEmployeesNameModal />
      {/* End of Show Employee Names Modal */}
    </>
  );
};

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, {
  showAttChartEmployeeNameModalAction,
})(AttendanceHistoryChart);
