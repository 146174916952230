import React from "react";
import { connect } from "react-redux";

import Privilages from "../../Constants/Privilages";

const CanViewEmployeeProfile = ({
  children,
  altChildren = null,
  allowBP = false,
  directManger = "",
  copiedManagers = [],
  main,
  allScope,
  managedScope,
  authId,
}) => {
  const isManagedByAuth = () => {
    let mangers = [directManger, ...copiedManagers];
    return mangers?.includes(authId);
  };

  if (allowBP && main?.includes(Privilages.BUSINESS_PARTNER_PRIVILEGES)) {
    return <>{children}</>;
  }
  if (
    (allScope?.includes(Privilages.VIEW_EMPLOYEES) &&
      main?.includes(Privilages.VIEW_EMPLOYEE_PROFILES)) ||
    main?.some((prevId) =>
      [
        Privilages.VIEW_EMPLOYEE_SALARY_CONFIGURATION,
        Privilages.VIEW_EMPLOYEE_ATTENDANCE_TYPE_CONFIGURATIONS,
        Privilages.VIEW_EMPLOYEE_PETTY_CASH_BALANCE_IN_EMPLOYEE_PROFILE,
        Privilages.VIEW_EMPLOYEE_DOCUMENTS,
        Privilages.VIEW_EMPLOYEE_PROFILES,
        Privilages.VIEW_EMPLOYEE_SCHEDULES,
      ].includes(prevId)
    )
  ) {
    return <>{children}</>;
  }
  if (
    (managedScope?.includes(Privilages.VIEW_EMPLOYEES) &&
      main?.includes(Privilages.VIEW_EMPLOYEE_PROFILES) &&
      isManagedByAuth()) ||
    main?.some((prevId) =>
      [
        Privilages.VIEW_EMPLOYEE_SALARY_CONFIGURATION,
        Privilages.VIEW_EMPLOYEE_ATTENDANCE_TYPE_CONFIGURATIONS,
        Privilages.VIEW_EMPLOYEE_PETTY_CASH_BALANCE_IN_EMPLOYEE_PROFILE,
        Privilages.VIEW_EMPLOYEE_DOCUMENTS,
        Privilages.VIEW_EMPLOYEE_PROFILES,
        Privilages.VIEW_EMPLOYEE_SCHEDULES,
      ].includes(prevId)
    )
  ) {
    return <>{children}</>;
  } else return <>{altChildren}</>;
};

const mapStateToProps = (state) => {
  return {
    authId: state.auth.userProfile?.id,
    main: state.auth.userProfile?.prviliege_ids,
    allScope: state.auth.userProfile?.prviliege_ids_with_all_employee_scope,
    managedScope:
      state.auth.userProfile?.prviliege_ids_with_managed_employee_scope,
  };
};

export default connect(mapStateToProps)(CanViewEmployeeProfile);
