import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { EmployeeTypes } from "../../Constants";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import { fetchEmployeeWorkSchedulesQuery } from "../../Graphql/query";
import { editEmployeeWorkScheduleAction } from "../../Store/Actions/shiftsActions/actions";
import Loader from "../../Components/Loader";
import { AddButton } from "../../Components/Buttons";
import { AttendanceTypeModal } from "../../Components/AttendanceTypeModal";
import { showErrorToast } from "../../Store/Actions";
import { useQuery } from "@apollo/client";
import AttTypeConfig from "./AttTypeConfig";

const AttendanceTypeTab = () => {
  const { t } = useTranslation();
  const { userId } = useParams();
  const dispatch = useDispatch();



  // Local State
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Server State
  const { loading, data, refetch, error, networkStatus } = useQuery(
    fetchEmployeeWorkSchedulesQuery,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        id: userId,
      },
    }
  );

  const employeeType = data?.employee?.__typename;

  /* ↓ State Effects ↓ */

  useEffect(() => {
    if (error) {
      dispatch(showErrorToast(error?.message));
    }
  }, [error]);

  /* ↓ Helpers ↓ */

  const handleEditEmployeeSchedule = (config, event) => {
    event.stopPropagation();
    dispatch(editEmployeeWorkScheduleAction(config));
    setIsModalOpen(true);
  };

  const employeeAttendanceTypeConfigs =
    employeeType === EmployeeTypes.FIXED
      ? data?.employee?.attendanceTypeConfigurations
      : data?.employee?.flexAttendanceTypeConfigurations;

  return (
    <>
      <div className="work_schedule_tab">
        {data?.employee?.user?.active ? (
          <HasPrivileges
            reqireMain={[Privilages.ADD_EDIT_ATTENDANCE_TYPE_CONFIGURATION]}
            allowBP
          >
            <div className="tab_header mb-4">
              <AddButton onClick={() => setIsModalOpen(true)}>
                {t("Add New Configuration")}
              </AddButton>
            </div>
          </HasPrivileges>
        ) : null}

        {loading ? ( // && networkStatus !== NetworkStatus.refetch
          <Loader />
        ) : (
          employeeAttendanceTypeConfigs?.map((config) => (
            <AttTypeConfig
              key={config?.id}
              employeeType={employeeType}
              config={config}
              refetch={refetch}
              networkStatus={networkStatus}
              onEditAttTypeConfig={handleEditEmployeeSchedule}
              loading={loading}
            />
          ))
        )}
      </div>

      {isModalOpen ? (
        <AttendanceTypeModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          fetchEmployeeWorkSchedules={refetch}
        />
      ) : null}
    </>
  );
};

export default AttendanceTypeTab;
