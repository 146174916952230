import React from "react";
import { useFormValidation } from "../../Helpers/Hooks/useFormValidation";
import { connect } from "react-redux";
import {
  onInputChangeAction,
  onInputResetAction,
  setInputInValidateAction,
  setInputValidateAction,
} from "../../Store/Actions";
import { withDependency } from "../../Helpers/HOC/withDependency";
import { useTranslation } from "react-i18next";

const CheckboxForm = ({
  name,
  dependOn,
  label,
  formName,
  validateBy,
  formSubmitting,
  formNameValidation,
  containerStyle,
  labelStyle,
  inputStyle,
  optionsContainerStyle,
  optionItemStyle,
  optionInputStyle,
  optionLabelStyle,
  optionLabelActiveStyle,
  validateContainerStyle,
  validateMessage,
  formServerValidation,
  validationName = undefined,
  ...props
}) => {
  const inputval = props.value ?? props.super[formName][name];
  const isDisabled = props.disabled;
  const validate = useFormValidation(
    inputval,
    validationName ?? name,
    formNameValidation,
    formSubmitting,
    validateBy,
    isDisabled
  );
  const isServerValid = !Boolean(props.super[formServerValidation])
    ? false
    : props.super[formServerValidation][validationName ?? name]
      ? true
      : false;

  const { t } = useTranslation();

  const handleInputChange = (event, val) => {
    event.persist();
    props.onInputChangeAction(formName, event, "checkbox", val);
  };

  const vaildContainer = () => {
    if (!validate.validity || isServerValid) {
      return validateContainerStyle ?? "invalid-container-style";
    }
    return " ";
  };
  return (
    <div className={vaildContainer()}>
      <div className={`${containerStyle ? containerStyle : "form-group"}`}>
        {label && (
          <label
            className={`${labelStyle ? labelStyle : ""} validity-label-style`}
          >
            {t(label)}
          </label>
        )}

        <div
          className={`${optionsContainerStyle ? optionsContainerStyle : ""}`}
        >
          {props.options.map((opt) => (
            <div
              key={opt.value}
              className={`${optionItemStyle ? optionItemStyle : ""}`}
            >
              <label
                className={`${
                  optionInputStyle
                    ? `checkbox ${optionInputStyle}`
                    : "form-check-input"
                }`}
              >
                <input
                  type="checkbox"
                  name={name}
                  value={opt.value}
                  onChange={(e) => handleInputChange(e, opt.value)}
                  checked={inputval?.includes(opt?.value)}
                  {...props}
                />
                <span></span>

                <div
                  className={`${
                    optionLabelStyle
                      ? `${optionLabelStyle} ${
                          inputval?.includes(opt?.value)
                            ? optionLabelActiveStyle
                            : ""
                        }`
                      : "form-check-label"
                  }`}
                >
                  {t(opt?.label)}
                </div>
              </label>
            </div>
          ))}
        </div>
      </div>
      <div className="validity-msg-style text-left">
        {validateMessage ? validateMessage : validate.validityMessage}
        {validate.validityMessage && <br />}
        {Boolean(props.super[formServerValidation])
          ? (props.super[formServerValidation][validationName ?? name] ?? "")
          : null}{" "}
      </div>
    </div>
  );
};

const mapStateToProps = (state, { reducer = "super" }) => {
  return {
    super: state[reducer],
  };
};
export default connect(mapStateToProps, {
  onInputChangeAction,
  onInputResetAction,
  setInputInValidateAction,
  setInputValidateAction,
})(withDependency(CheckboxForm));
