const RESET_SYSTEM_PLAN_FORM = "RESET_SYSTEM_PLAN_FORM";

const FETCH_PLAN_PRIVILEGES_ATTEMPT = "FETCH_PLAN_PRIVILEGES_ATTEMPT";
const FETCH_PLAN_PRIVILEGES_SUCCESS = "FETCH_PLAN_PRIVILEGES_SUCCESS";
const FETCH_PLAN_PRIVILEGES_FAILED = "FETCH_PLAN_PRIVILEGES_FAILED";

const UPDTAE_PLAN_FETAURE_INPUT = "UPDTAE_PLAN_FETAURE_INPUT";

const FETCH_EDIT_ROLE_SUCCESS = "FETCH_EDIT_ROLE_SUCCESS";

// fetch holidays list actions
const FETCH_ADMIN_HOLIDAYS_LIST_ATTEMPT = "FETCH_ADMIN_HOLIDAYS_LIST_ATTEMPT";
const FETCH_ADMIN_HOLIDAYS_LIST_SUCCESS = "FETCH_ADMIN_HOLIDAYS_LIST_SUCCESS";
const FETCH_ADMIN_HOLIDAYS_LIST_FAILED = "FETCH_ADMIN_HOLIDAYS_LIST_FAILED";

// fetch holidays countries filter option
const FETCH_ADMIN_HOLIDAYS_COUNTRIES_OPTIONS =
  "FETCH_ADMIN_HOLIDAYS_COUNTRIES_OPTIONS";

// handle admin holiday modal actions
const SHOW_ADMIN_HOLIDAYS_MODAL = "SHOW_ADMIN_HOLIDAYS_MODAL";
const HIDE_ADMIN_HOLIDAYS_MODAL = "HIDE_ADMIN_HOLIDAYS_MODAL";
const UPSERT_ADMIN_HOLIDAYS_ATTEMPT = "UPSERT_ADMIN_HOLIDAYS_ATTEMPT";
const UPSERT_ADMIN_HOLIDAYS_SUCCESS = "UPSERT_ADMIN_HOLIDAYS_SUCCESS";
const UPSERT_ADMIN_HOLIDAYS_FAILED = "UPSERT_ADMIN_HOLIDAYS_FAILED";

// handle delete admin holidays action
const DELETE_ADMIN_HOLIDAYS_ATTEMPT = "DELETE_ADMIN_HOLIDAYS_ATTEMPT";
const DELETE_ADMIN_HOLIDAYS_SUCCESS = "DELETE_ADMIN_HOLIDAYS_SUCCESS";
const DELETE_ADMIN_HOLIDAYS_FAILED = "DELETE_ADMIN_HOLIDAYS_FAILED";

const GENERATE_DEMO_ATTEMPT = "GENERATE_DEMO_ATTEMPT";
const GENERATE_DEMO_SUCCESS = "GENERATE_DEMO_SUCCESS";
const GENERATE_DEMO_FAILED = "GENERATE_DEMO_FAILED";

// handle faild sign ins actions types
const TOGGLE_FAILED_SIGN_INS_LOCATION_MAP_MODAL =
  "TOGGLE_FAILED_SIGN_INS_LOCATION_MAP_MODAL";

export default {
  FETCH_PLAN_PRIVILEGES_ATTEMPT,
  FETCH_PLAN_PRIVILEGES_SUCCESS,
  FETCH_PLAN_PRIVILEGES_FAILED,

  RESET_SYSTEM_PLAN_FORM,

  UPDTAE_PLAN_FETAURE_INPUT,

  FETCH_EDIT_ROLE_SUCCESS,

  // fetch holidays list actions
  FETCH_ADMIN_HOLIDAYS_LIST_ATTEMPT,
  FETCH_ADMIN_HOLIDAYS_LIST_SUCCESS,
  FETCH_ADMIN_HOLIDAYS_LIST_FAILED,

  // fetch holidays countries filter option
  FETCH_ADMIN_HOLIDAYS_COUNTRIES_OPTIONS,

  // handle admin holiday modal actions
  SHOW_ADMIN_HOLIDAYS_MODAL,
  HIDE_ADMIN_HOLIDAYS_MODAL,
  UPSERT_ADMIN_HOLIDAYS_ATTEMPT,
  UPSERT_ADMIN_HOLIDAYS_SUCCESS,
  UPSERT_ADMIN_HOLIDAYS_FAILED,

  // handle delete admin holidays action
  DELETE_ADMIN_HOLIDAYS_ATTEMPT,
  DELETE_ADMIN_HOLIDAYS_SUCCESS,
  DELETE_ADMIN_HOLIDAYS_FAILED,

  GENERATE_DEMO_ATTEMPT,
  GENERATE_DEMO_SUCCESS,
  GENERATE_DEMO_FAILED,

  // handle faild sign ins actions types
  TOGGLE_FAILED_SIGN_INS_LOCATION_MAP_MODAL,
};
