import React from "react";
import { useQuery } from "@apollo/client";

import { paymentMethodsMenu } from "../../Graphql/query/PaymentTracking";

import { BSelect } from "form-builder";

const PaymentInfo = ({ FormProps, ...props }) => {
  // Server State
  const { data: options, loading } = useQuery(paymentMethodsMenu);

  return (
    <>
      <BSelect
        {...FormProps}
        name="methodId"
        label="Payment Method"
        icon="money"
        isLoading={loading}
        options={options?.paymentMethods?.data || []}
        {...props}
      />
    </>
  );
};

export default PaymentInfo;
