import React, { useEffect, useRef, useState } from "react";
import MainModal from "../../Components/MainModal";
import { InputForm, CheckboxBooleanForm, BSelect } from "form-builder";
import { connect } from "react-redux";
import {
  dismissJobPostModalAction,
  upsertJobPostAction,
  onInputChangeAction,
  fetchJobPostsProcessesAndIntakeFormsOptionsAction,
  addNewProcessStagesInJobPost,
  onInputResetAction,
  recruitmentChildInputChange,
} from "../../Store/Actions";
import { useTranslation } from "react-i18next";
import Loader from "../../Components/Loader";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";
import usePrevious from "../../Helpers/Hooks/usePrevious";
import Select from "react-select";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const formName = "jobPostModal";
const formNameValidation = "jobPostModalValidation";
const formServerValidation = "jobPostModalServerValidation";

const JobPostModal = (props) => {
  const { t } = useTranslation();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const FormProps = {
    formName,
    formNameValidation,
    formServerValidation,
    formSubmitting: isSubmitting,
  };

  const [stages_map, setStages_map] = useState({});

  // handel text area change
  const handleTextArea = (value) => {
    let e = {
      target: {
        name: "description",
        value,
      },
    };
    props.onInputChangeAction(formName, e);
  };

  const handleSubmitBtn = () => {
    setIsSubmitting(true);

    if (props.modalValidation.length) {
      return;
    }

    props.upsertJobPostAction({
      ref: "jobPostModalActions",
      formName,
      data: {
        ...props?.jobPostModal,
        stages_map: isShowNewProcessStagesContainer
          ? currentProcess?.stages?.map((stage) => ({
              old_stage_id: stages_map?.[stage?.id],
              new_stage_id: stage.id,
            }))
          : [],
      },
    });
  };

  const toggleModal = () => {
    props.dismissJobPostModalAction({
      ref: "jobPostModalActions",
      formName,
    });
  };

  // handle toggle change process stages in job post
  // const [isShowNewProcessStagesContainer, setShowNewProcessStagesContainer] =
  //   useState(false);

  const initialProceesId = useRef(null);

  let currentProcessId =
    props?.jobPostModal?.process_id ??
    props?.data?.JobPostProcessesOptions?.find(
      (processForm) => processForm.is_default
    )?.id;

  useEffect(() => {
    if (props?.modalData?.isVissible) {
      initialProceesId.current = currentProcessId;
    }
    return () => {
      initialProceesId.current = null;
      setStages_map({});
    };
  }, [props?.modalData?.isVissible, props?.data?.JobPostProcessesOptions]);

  let isShowNewProcessStagesContainer =
    currentProcessId !== initialProceesId.current && props?.jobPostModal?.id;

  useEffect(() => {
    if (isShowNewProcessStagesContainer) {
      let stagesMap = {};
      currentProcess?.stages?.forEach((stage) => (stagesMap[stage?.id] = ""));

      let currentHiredStageId = currentProcess?.stages?.find(
        (stage) => stage?.name?.toLowerCase() === "hired"
      )?.id;
      let initialHiredStageId = initialProcess?.stages?.find(
        (stage) => stage?.name?.toLowerCase() === "hired"
      )?.id;

      setStages_map({
        ...stagesMap,
        [currentHiredStageId]: initialHiredStageId,
      });
    }

    return () => {};
  }, [isShowNewProcessStagesContainer, currentProcessId]);

  // to get the previous values using usePrevious hook
  const prevCustom_intakeForm_and_processValue = usePrevious(
    props?.jobPostModal?.custom_intakeForm_and_process
  );
  const prevSet_limit_for_recurring_applicationValue = usePrevious(
    props?.jobPostModal?.set_limit_for_recurring_application
  );
  const prevProcessId = usePrevious(props?.jobPostModal?.process_id);

  // Start of handling change in process in edit job post case
  const handleChangeInProcess = () => {
    if (
      props.jobPostModal?.id != null &&
      prevProcessId &&
      prevProcessId !== props?.jobPostModal?.process_id
    ) {
      // setShowNewProcessStagesContainer(true);
      // to add stages map array
      props.addNewProcessStagesInJobPost();
    } else {
      return;
    }
  };

  const useDidProcessUpdate = useDidUpdateEffect(handleChangeInProcess, [
    props?.jobPostModal?.process_id,
  ]);
  // End of handling change in process in edit job post case

  // Start of handling change in customize intake forms and process
  // const handleChangeInCustomizeIntakeFormsAndProcess = () => {
  //   if (
  //     prevCustom_intakeForm_and_processValue &&
  //     prevCustom_intakeForm_and_processValue !==
  //     props?.jobPostModal?.custom_intakeForm_and_process
  //   ) {
  //     props.onInputResetAction(formName, "process_id");
  //     props.onInputResetAction(formName, "intake_form_id");
  //   } else {
  //     return;
  //   }
  // };

  // const useDidCustomizeIntakeFormsAndProcessUpdate = useDidUpdateEffect(
  //   handleChangeInCustomizeIntakeFormsAndProcess,
  //   [props?.jobPostModal?.custom_intakeForm_and_process]
  // );
  // End of handling change in customize intake forms and process

  // Start of handling change in apply limit
  const handleChangeApplyLimit = () => {
    if (
      prevSet_limit_for_recurring_applicationValue &&
      prevSet_limit_for_recurring_applicationValue !==
        props?.jobPostModal?.set_limit_for_recurring_application
    ) {
      props.onInputResetAction(formName, "limit_of_recurring_applications");
    } else {
      return;
    }
  };

  const useDidApplyLimitUpdate = useDidUpdateEffect(handleChangeApplyLimit, [
    props?.jobPostModal?.set_limit_for_recurring_application,
  ]);
  // End of handling change in apply limit

  // handle map stages children select change
  const handleSelectInputChange = (val, event, childName, index) => {
    props.recruitmentChildInputChange(
      formName,
      childName,
      event?.name,
      index,
      val ? val?.id : ""
    );
  };

  const currentProcess = props?.data?.JobPostProcessesOptions?.find(
    (jobPostProcess) => jobPostProcess?.id == currentProcessId
  );

  const initialProcess = props?.data?.JobPostProcessesOptions?.find(
    (jobPostProcess) => jobPostProcess?.id == initialProceesId.current
  );

  useEffect(() => {
    setIsSubmitting(false);
    // setShowNewProcessStagesContainer(false);
    // to fetch processes and intakeforms options
    if (props?.modalData?.isVissible) {
      props.fetchJobPostsProcessesAndIntakeFormsOptionsAction(
        props.jobPostModal?.id
      );
    }
  }, [props?.modalData?.isVissible]);

  useEffect(() => {
    if (
      props?.jobPostModal?.custom_intakeForm_and_process &&
      props?.modalData?.isVissible
    ) {
      if (
        !+props?.jobPostModal?.intake_form_id &&
        !+props?.jobPostModal?.process_id
      ) {
        let intakeFormValue = {
          target: {
            name: "intake_form_id",
            value:
              props?.data?.JobPostIntakeFormsOptions?.find(
                (intakeForm) => intakeForm.is_default
              )?.id ?? "",
          },
        };
        props.onInputChangeAction(formName, intakeFormValue);
        let processFormValue = {
          target: {
            name: "process_id",
            value:
              props?.data?.JobPostProcessesOptions?.find(
                (processForm) => processForm.is_default
              )?.id ?? "",
          },
        };
        props.onInputChangeAction(formName, processFormValue);
      }
    }
    if (
      !props?.jobPostModal?.custom_intakeForm_and_process &&
      props?.modalData?.isVissible
    ) {
      props.onInputResetAction(formName, "process_id");
      props.onInputResetAction(formName, "intake_form_id");
    }
  }, [props?.jobPostModal?.custom_intakeForm_and_process]);

  if (props?.data?.fetchJobPostFormOptionsLoading) {
    return (
      <div className="loader_wrapper_style">
        <Loader />
      </div>
    );
  }

  return (
    <MainModal
      isOpen={props?.modalData?.isVissible}
      toggle={toggleModal}
      modalTitle={t(
        `${props.jobPostModal?.id != null ? "Edit" : "Add"} Job Post`
      )}
      btnOnClick={handleSubmitBtn}
      btnSubmitLoading={props?.modalData?.isLoading}
      className="job_post_form_wrapper_style"
    >
      <div>
        <div className="row">
          <div className="col-12">
            <InputForm
              label="Job Title"
              labelStyle="mb-2"
              {...FormProps}
              reducer="recruitment"
              validateBy="textRequired"
              name="title"
              validationName={`input.title`}
              containerStyle=""
              inputContainerStyle="w-100"
            />
          </div>
        </div>

        <div className="row my-2">
          <div
            className={`col-12 ${
              "input.description" in props?.serverVaildation
                ? "invalid-container-style"
                : ""
            }`}
          >
            <label className="mb-2 validity-label-style">
              {t("Job Description")}
            </label>

            <ReactQuill
              theme="snow"
              value={props?.jobPostModal?.description}
              onChange={handleTextArea}
            />

            <span className="validity-msg-style">
              {"input.description" in props?.serverVaildation
                ? props?.serverVaildation["input.description"]
                : null}
            </span>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <CheckboxBooleanForm
              {...FormProps}
              options={[
                "Allow applicants to apply multiple times on the same post",
              ]}
              name="allow_multiple_apply"
              type="checkbox"
              reducer="recruitment"
              validationName={`input.allow_multiple_apply`}
            />
          </div>
        </div>

        <div className="row align-items-baseline">
          <div className="col-9">
            <CheckboxBooleanForm
              {...FormProps}
              options={["Set Limit for recurring applications"]}
              name="set_limit_for_recurring_application"
              type="checkbox"
              reducer="recruitment"
            />
          </div>

          {props?.jobPostModal?.set_limit_for_recurring_application ? (
            <div className="col-3">
              <InputForm
                {...FormProps}
                reducer="recruitment"
                name="limit_of_recurring_applications"
                validationName={`input.limit_of_recurring_applications`}
                containerStyle=""
                inputContainerStyle=""
                type="number"
              />
            </div>
          ) : null}
        </div>

        <div className="row">
          <div className="col-12">
            <CheckboxBooleanForm
              {...FormProps}
              options={["Customize Intake Form and Recruitment Process"]}
              name="custom_intakeForm_and_process"
              type="checkbox"
              reducer="recruitment"
              validationName={`input.custom_intakeForm_and_process`}
            />
          </div>
        </div>

        {props?.jobPostModal?.custom_intakeForm_and_process ? (
          <>
            <div className="row">
              <div className="col-12">
                <BSelect
                  {...FormProps}
                  name="intake_form_id"
                  optionLabel="name"
                  optionValue="id"
                  label="Intake Form"
                  labelStyle="mb-2"
                  reducer="recruitment"
                  validationName={`input.intake_form_id`}
                  options={props?.data?.JobPostIntakeFormsOptions}
                  keepDefaultStyle
                  containerStyle=""
                  placeholder={t("Select Intake Form")}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <BSelect
                  {...FormProps}
                  name="process_id"
                  optionLabel="name"
                  optionValue="id"
                  label="Recruitment Process"
                  labelStyle="mb-2"
                  reducer="recruitment"
                  validationName={`input.process_id`}
                  options={props?.data?.JobPostProcessesOptions}
                  keepDefaultStyle
                  containerStyle=""
                  placeholder={t("Select Recruitment Process")}
                  // isDisabled={!!+props.jobPostModal?.id}
                />
              </div>
            </div>
          </>
        ) : null}

        {isShowNewProcessStagesContainer ? (
          <>
            <div className="row">
              <div className="col-12">
                <div className="notes-style h2 mt-2 mb-0 fit-width">
                  {t("jobPostChangeProcessMessage")}
                </div>
              </div>
            </div>

            <div className="new_process_wrapper_style p-3">
              <>
                <div className="row align-items-baseline">
                  <div className="col-12">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="w-100">
                        <label>{currentProcess?.name}</label>
                      </div>

                      <div className="w-100">
                        <label>{initialProcess?.name}</label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row align-items-baseline">
                  <div className="col-12">
                    {currentProcess?.stages?.map((stage, i) => (
                      <div
                        className={`d-flex justify-content-between align-items-center ${props?.serverVaildation?.[`input.stages_map.${i}.new_stage_id`] ? "invalid-container-style" : ""}`}
                      >
                        <div className="w-100">
                          <label className="validity-label-style">
                            {stage?.name}
                          </label>
                        </div>

                        <div className={`w-100 select-def-container my-1`}>
                          <Select
                            className="select-def-input-container"
                            classNamePrefix="b-select-style"
                            name="new_stage_id"
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            reducer="recruitment"
                            validationName={`input.stages_map.${i}.new_stage_id`}
                            options={initialProcess?.stages}
                            value={initialProcess?.stages?.find(
                              (initStage) =>
                                initStage?.id == stages_map?.[stage?.id]
                            )}
                            isDisabled={stage?.name?.toLowerCase() === "hired"}
                            onChange={(val) => {
                              
                              setStages_map((prevState) => ({
                                ...prevState,
                                [stage?.id]: val.id,
                              }));
                            }}
                            placeholder={t("select status")}
                          />
                          {props.serverVaildation?.[
                            `input.stages_map.${i}.new_stage_id`
                          ] ? (
                            <span className="error_message_style">
                              {" "}
                              {
                                props?.serverVaildation?.[
                                  `input.stages_map.${i}.new_stage_id`
                                ]
                              }{" "}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            </div>
          </>
        ) : null}

        {props?.jobPostModal?.link ? (
          <div className="row mt-2">
            <div className="col-12">
              <label>{t("Link")}</label>
              <div className="job_post_form_link_style">
                {props?.jobPostModal?.link}
              </div>
            </div>
          </div>
        ) : (
          <div className="row mt-2">
            <div className="col-12">
              <div className="notes-style h2 mt-2 mb-3 fit-width">
                {t("jobPostLinkMessage")}
              </div>
            </div>
          </div>
        )}

        {/* (Start) Error Message */}
        {props?.modalData?.modalMessage && isSubmitting && (
          <div className="warnig-msg-style text-break">
            {props?.modalData?.modalMessage}
          </div>
        )}
        {/* (End) Error Message */}
      </div>
    </MainModal>
  );
};

const mapStateToProps = (state) => ({
  data: state.recruitment,
  modalData: state.recruitment.jobPostModalActions,
  modalValidation: state.recruitment[formNameValidation],
  jobPostModal: state.recruitment[formName],
  serverVaildation: state.recruitment[formServerValidation],
});

export default connect(mapStateToProps, {
  dismissJobPostModalAction,
  upsertJobPostAction,
  onInputChangeAction,
  fetchJobPostsProcessesAndIntakeFormsOptionsAction,
  addNewProcessStagesInJobPost,
  onInputResetAction,
  recruitmentChildInputChange,
})(JobPostModal);
