import { Flag } from "@mui/icons-material";
import React from "react";
import { InputForm } from "form-builder";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { EgyptId, SaudiId } from "../../../Constants";

const CountryFieldsSection = ({ countryId, formProps }) => {
  const { t } = useTranslation();

  const employeeFormID = useSelector(
    (state) => state.super?.[formProps?.formName]?.id
  );

  return (
    <div className="boxContainer">
      <div className="name_container mb-2">
        <Flag />
        <h4 class={"sub-title-style mb-0"}>{t("country fields")}</h4>
      </div>

      {countryId == EgyptId ? (
        <>
          <div className="d-flex align-items-center mb-2">
            <div className="w-100 mr-3">
              <div className="d-flex align-items-center justify-content-start">
                <label className="label-style-default validity-label-style mb-2 mr-2">
                  {t("National ID")}
                </label>
                <p className="label-extra-text-style">({t("14 characters")})</p>
              </div>
              <InputForm
                {...formProps}
                name="national_id"
                placeholder={t("National ID")}
                type="text"
                containerStyle=" "
                inputContainerStyle=" "
                validationName="input.user_input.national_id"
                icon="person"
                rootStyle="w-100"
                maxLength="14"
                isNumberInput
              />
            </div>

            <div className="w-100">
              <div className="d-flex align-items-center justify-content-start">
                <label className="label-style-default validity-label-style mb-2 mr-2">
                  {t("insurance number")}
                </label>
                <p className="label-extra-text-style">({t("8 characters")})</p>
              </div>

              <InputForm
                {...formProps}
                name="insurance_number"
                placeholder={t("insurance number")}
                type="text"
                containerStyle=" "
                inputContainerStyle=" "
                validationName="input.user_input.insurance_number"
                icon="person"
                rootStyle="w-100"
                maxLength="8"
                isNumberInput
              />
            </div>
          </div>

          {!employeeFormID ? (
            <div className="d-flex align-items-center">
              <div className="w-100 mr-3">
                <div className="d-flex align-items-center justify-content-start">
                  <label className="label-style-default validity-label-style mb-2 mr-2">
                    {t("total income")}
                  </label>
                </div>
                <InputForm
                  {...formProps}
                  name="total_income_amount"
                  placeholder={t("total income")}
                  type="text"
                  containerStyle=" "
                  inputContainerStyle=" "
                  validationName="input.user_input.previousIncome.total_income_amount"
                  icon="person"
                  rootStyle="w-100"
                  maxLength="14"
                  isNumberInput
                />
              </div>

              <div className="w-100">
                <div className="d-flex align-items-center justify-content-start">
                  <label className="label-style-default validity-label-style mb-2 mr-2">
                    {t("total tax")}
                  </label>
                </div>

                <InputForm
                  {...formProps}
                  name="total_tax_amount"
                  placeholder={t("total tax")}
                  type="text"
                  containerStyle=" "
                  inputContainerStyle=" "
                  validationName="input.user_input.previousIncome.total_tax_amount"
                  icon="person"
                  rootStyle="w-100"
                  maxLength="8"
                  isNumberInput
                />
              </div>
            </div>
          ) : null}
        </>
      ) : null}

      {countryId == SaudiId ? (
        <div className="d-flex align-items-center mb-2">
          <div className="w-100 mr-3">
            <div className="d-flex align-items-center justify-content-start">
              <label className="label-style-default validity-label-style mb-2 mr-2">
                {t("Number ID")}
              </label>
              <p className="label-extra-text-style">({t("10 characters")})</p>
            </div>
            <InputForm
              {...formProps}
              name="number_id"
              placeholder={t("Number ID")}
              type="text"
              containerStyle=" "
              inputContainerStyle=" "
              validationName="input.user_input.number_id"
              icon="person"
              rootStyle="w-100"
              maxLength="10"
              isNumberInput
            />
          </div>

          <div className="w-100">
            <div className="d-flex align-items-center justify-content-start">
              <label className="label-style-default validity-label-style mb-2 mr-2">
                {t("residency number")}
              </label>
              <p className="label-extra-text-style">({t("10 characters")})</p>
            </div>

            <InputForm
              {...formProps}
              name="residency_number"
              placeholder={t("residency number")}
              type="text"
              containerStyle=" "
              inputContainerStyle=" "
              validationName="input.user_input.residency_number"
              icon="person"
              rootStyle="w-100"
              maxLength="10"
              isNumberInput
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default CountryFieldsSection;
