import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import Constants from "../../Helpers/Constants";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";
import {
  fetchIntakeFormsAction,
  onInputResetAction,
  deleteIntakeFormsAction,
  cloneIntakeFormAction,
  updateIntakeFormsStatusAction,
  showErrorToast,
} from "../../Store/Actions";
import { InputForm, RadioboxForm } from "form-builder";
import AntSwitch from "../../Components/AntSwitch";
import Loader from "../../Components/Loader";
import DataTable from "react-data-table-component";
import Pagination from "../../Components/Pagination";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import { useTranslation } from "react-i18next";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import {
  EditIconButton,
  CopyIconButton,
  RemoveIconButton,
} from "../../Components/IconButtonWithTooltip";

const dataTableRef = "intakeFormsList";

const IntakeFormsList = (props) => {
  const { t } = useTranslation();

  const history = useHistory();

  const dispatch = useDispatch();

  useEffect(() => {
    props.fetchIntakeFormsAction({
      ref: dataTableRef,
      [dataTableRef]: props.data[dataTableRef],
    });
    return () => {
      props.onInputResetAction(dataTableRef, "status");
      props.onInputResetAction(dataTableRef, "name");
    };
  }, []);

  const handleFilter = () => {
    props.fetchIntakeFormsAction({
      ref: dataTableRef,
      [dataTableRef]: {
        ...props.data[dataTableRef],
        pagination: {
          ...props.data[dataTableRef].pagination,
          currentPage: 1,
        },
      },
    });
  };

  const useDidFilterUpdate = useDidUpdateEffect(handleFilter, [
    props.data[dataTableRef].status,
    props.data[dataTableRef].name,
  ]);

  const handlePaginate = (
    page = props.data[dataTableRef].pagination.currentPage
  ) => {
    props.fetchIntakeFormsAction({
      ref: dataTableRef,
      [dataTableRef]: {
        ...props.data[dataTableRef],
        pagination: {
          ...props.data[dataTableRef].pagination,
          currentPage: page,
        },
      },
    });
  };

  // to delete Intake Form
  const handleDeleteIntakeForm = (row) => {
    swal({
      title: t("are you sure"),
      text: t("defaut_warning_messsage"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: [t("Cancel"), t("OK")],
    }).then((willDelete) => {
      if (willDelete) {
        props.deleteIntakeFormsAction(row?.id);
      }
    });
  };

  const handleCloneIntakeForm = (row) => {
    dispatch(cloneIntakeFormAction(row.id));
  };

  // handle update Intake Form status active/inactive
  const handleUpdateIntakeFormStatus = (row) => {
    props.updateIntakeFormsStatusAction(row?.id);
  };

  const handleAddIntakeForm = () => {
    history.push(`/recruitment/new-intake`);
  };

  const handleEditIntakeForm = (intakeForm) => {
    let hasActiveJobPost = intakeForm?.jobPosts?.reduce(
      (accu, curr) => +curr.is_active + +accu,
      0
    );
    if (hasActiveJobPost > 0) {
      dispatch(showErrorToast("can't edit intake Form with active job posts"));
      return;
    }
    history.push(`/recruitment/intake/${intakeForm.id}`);
  };

  const columns = [
    {
      name: t("form name"),
      wrap: true,
      selector: "name",
      sortable: false,
      grow: 12,
    },
    {
      name: t("active"),
      wrap: true,
      selector: "is_active",
      sortable: false,
      grow: 1.2,
      cell: (row) => (
        <HasPrivileges
          reqireMain={[Privilages.ACTIVATE_DEACTIVATE_INTAKE_FORMS]}
          allowBP
          altChildren={
            <AntSwitch
              inputProps={{ "aria-label": "Switch demo" }}
              checked={row?.is_active}
              disabled
              color="primary"
            />
          }
          avalibleOnExpire={false}
          altExpireChildren={
            <AntSwitch
              inputProps={{ "aria-label": "Switch demo" }}
              checked={row?.is_active}
              disabled
              color="primary"
            />
          }
        >
          <AntSwitch
            inputProps={{ "aria-label": "Switch demo" }}
            checked={row?.is_active}
            onChange={(e, val) => handleUpdateIntakeFormStatus(row, val)}
            color="primary"
          />
        </HasPrivileges>
      ),
    },
    {
      name: "",
      wrap: true,
      sortable: false,
      grow: 1.5,
      cell: (row) => (
        <HasPrivileges
          reqireMain={[Privilages.ADD_EDIT_DELETE_INTAKE_FORMS]}
          allowBP
          avalibleOnExpire={false}
          altExpireChildren={
            <div className="cards_table_actions">
              <CopyIconButton />
              <EditIconButton />
              <RemoveIconButton />
            </div>
          }
        >
          <div className="cards_table_actions">
            <CopyIconButton onClick={() => handleCloneIntakeForm(row)} />
            <EditIconButton onClick={() => handleEditIntakeForm(row)} />
            <RemoveIconButton onClick={() => handleDeleteIntakeForm(row)} />
          </div>
        </HasPrivileges>
      ),
    },
  ];

  return (
    <div className="extra_bonus_wrapper_style">
      {props?.isDeleteOrCloneIntakeFormLoading ||
      props?.isUpdateIntakeFormStatusLoading ? (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      ) : null}

      <div className="row my-3 align-items-baseline">
        <div className="col-12 col-lg-5">
          <RadioboxForm
            reducer="recruitment"
            formName={dataTableRef}
            options={Constants.recruitmentProcessStatusFilter?.map(
              ({ label, value }) => ({
                label: t(label),
                value,
              })
            )}
            name="status"
            type="radio"
            containerStyle="my-2 my-lg-0"
            labelStyle="label-style"
            optionsContainerStyle="optionsContainerStyle"
            optionItemStyle="optionItemStyle"
            optionInputStyle=" "
            optionLabelStyle="optionLabelStyle"
          />
        </div>

        <div className="col-12 col-lg-7">
          <div className="row align-items-baseline">
            <div className="d-flex align-items-baseline col-12 my-2 my-lg-0">
              <div className="col">
                <InputForm
                  reducer="recruitment"
                  name="name"
                  formName={dataTableRef}
                  placeholder={t("search")}
                  inputContainerStyle=" "
                  containerStyle=" "
                />
              </div>

              <HasPrivileges
                reqireMain={[Privilages.ADD_EDIT_DELETE_INTAKE_FORMS]}
                allowBP
                avalibleOnExpire={false}
                altExpireChildren={
                  <button
                    className="btn add_new_btn_style"
                    type="button"
                    disabled
                  >
                    {t("add new")}
                  </button>
                }
              >
                <button
                  className="btn add_new_btn_style"
                  type="button"
                  onClick={handleAddIntakeForm}
                >
                  {t("add new")}
                </button>
              </HasPrivileges>
            </div>
          </div>
        </div>
      </div>

      <DataTable
        noDataComponent={<div className="p-4"> {t("no_records")} </div>}
        className="cards_table"
        columns={columns}
        data={props.data[dataTableRef].data}
        noHeader
        persistTableHead
        paginationComponent={() => (
          <Pagination
            tableRef={dataTableRef}
            styleWraper=""
            onPaginate={handlePaginate}
            reducer="recruitment"
          />
        )}
        pagination={true}
        paginationServer={true}
        progressPending={props.data[dataTableRef].isLoading}
        progressComponent={<Loader />}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.recruitment,
    isDeleteOrCloneIntakeFormLoading:
      state?.recruitment?.deleteOrCloneIntakeFormLoading,
    isUpdateIntakeFormStatusLoading:
      state?.recruitment?.updateIntakeFormStatusLoading,
  };
};

export default connect(mapStateToProps, {
  fetchIntakeFormsAction,
  onInputResetAction,
  deleteIntakeFormsAction,
  updateIntakeFormsStatusAction,
})(IntakeFormsList);
