import React from "react";

import {
  CheckboxBooleanForm,
  DateTimePickerForm,
  InputForm,
} from "form-builder";

import { useTranslation } from "react-i18next";

import AttSignOutSection from "../../../Components/AttProfileSections/AttSignOutSection";
import AttOvertimeSection from "../../../Components/AttProfileSections/AttOvertimeSection";

const WorkTimingSignOutStep = ({ onNext, onBack, ...commonFormProps }) => {
  //   const { commonFormProps } = props;

  const { t } = useTranslation();

  
  return (
    <div className="pt-lg-4 pt-1 pb-1">
      <AttSignOutSection formProps={commonFormProps} />
      <AttOvertimeSection formProps={commonFormProps} />

      <div className="mt-3 mb-1 text-right">
        <button
          type="submit"
          onClick={onBack}
          // onClick={()=>}

          className="btn btn-secondray pervious_btn_style mr-2"
        >
          {t("previous")}
        </button>

        <button
          type="submit"
          onClick={onNext}
          // onClick={()=>}

          className="btn btn-primary submit_btn_style"
        >
          {t("next")}
        </button>
      </div>
    </div>
  );
};

export default WorkTimingSignOutStep;
