import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import MyGeneralTab from "./MyGeneralTab";
import MyProfileTabs from "./MyProfileTabs";
import MyHistoryTab from "./MyHistoryTab";
// import MyPayrollTab from "./MyPayrollView";
import MyPettyCashTab from "./MyPettyCashTab";
import MyLoansTab from "./MyLoansTab";
import ProfileCard from "../../Components/ProfileCard";
import WorkCalendarTab from "../../Containers/EmployeeProfile/WorkCalendarTab";
import HelperFns from "../../Helpers/HelperFns";
import MyPayrollView from "./MyPayrollView";
import { EmployeeTypes } from "../../Constants";
import MyTimeSheet from "./MyTimeSheet";
import EmployeeAssets from "../../Containers/Assets/EmployeeAssets/EmployeeAssets";

const MyProfilePage = (props) => {
  const [selected, setSelected] = useState(1);
  const userId = useSelector((state) => state?.user?.userProfile?.id);

  const handleChange = (event, newValue) => {
    setSelected(newValue);
  };

  const isFlexUser =
    useSelector((state) => state.auth.userProfile?.employee?.__typename) ===
    EmployeeTypes.FLEX ;

  const renderSelectedTab = () => {
    switch (selected) {
      case 1:
        return <MyGeneralTab />;
      case 2:
        return;
      case 3:
        return (
          <HasPrivileges
            reqireMain={[Privilages.VIEW_ATTENDANCE_HISTORY]}
            allowBP
          >
            {isFlexUser ? <MyTimeSheet /> : <MyHistoryTab />}
          </HasPrivileges>
        );
      case 4:
        return (
          <HasPrivileges reqireMain={[Privilages.VIEW_PAYROLL]} allowBP>
            <MyPayrollView />
          </HasPrivileges>
        );
      case 5:
        return (
          <HasPrivileges reqireMain={[Privilages.VIEW_PETTY_CASH_BALANCE_LIST]}>
            <MyPettyCashTab />
          </HasPrivileges>
        );
      case 6:
        return (
          <HasPrivileges reqireMain={[Privilages.VIEW_MY_LOANS]} allowBP>
            <MyLoansTab />
          </HasPrivileges>
        );
      case 7:
        if (
          HelperFns.checkPrivileges({
            privileges: [Privilages.VIEW_PROFILE_CALENDAR],
          })
        ) {
          return (
            <WorkCalendarTab
              userId={userId}
              pageFlag="auth_profile_work_calendar"
            />
          );
        }
        break;

      case 8:
        return (
          <HasPrivileges
            reqireMain={[
              Privilages.VIEW_MY_ASSETS,
              Privilages.VIEW_MY_ASSET_REQUEST,
              Privilages.SUBMIT_ASSET_REQUEST,
            ]}
          >
            <EmployeeAssets />
          </HasPrivileges>
        );
      default:
        break;
    }
  };

  return (
    <div className="employee_profile_content_wrapper content">
      <ProfileCard
        {...props.profile}
        isEmployee={true}
        isLoading={props.isMyProfileLoading}
      />

      <MyProfileTabs
        activeValue={selected}
        handleChangeTab={handleChange}
        {...props.profile}
      />

      <div className="pt-4">{renderSelectedTab()}</div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isMyProfileLoading: state.user.userProfileLoading,
    profile: state.user.userProfile,
    data: state.user,
  };
};

export default connect(mapStateToProps)(MyProfilePage);
