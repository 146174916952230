import React from "react";
import { CheckboxBooleanForm } from "form-builder";
import { InputForm } from "../../Builder/Form";
import { useSelector } from "react-redux";

const ExtraSettings = ({ formProps }) => {
  return (
    <>
      <InputForm
        {...formProps}
        validateBy="textRequired"
        label="Number of hours per 1 day of holiday"
        name="holiday_hours_count"
        validationName="input.holiday_hours_count"
        type="number"
        hasSuffix
        suffixTitle="Hours"
        containerStyle="leaves_field_container mt-2"
        inputContainerStyle="d-flex align-items-center"
        labelStyle={"font-weight-bold"}
      />

      <InputForm
        {...formProps}
        validateBy="textRequired"
        label="Number of hours per 1 day off"
        name="day_off_hours_count"
        validationName="input.day_off_hours_count"
        type="number"
        hasSuffix
        suffixTitle="Hours"
        containerStyle="leaves_field_container mt-2"
        inputContainerStyle="d-flex align-items-center"
        labelStyle={"font-weight-bold"}
      />
    </>
  );
};

export default ExtraSettings;
