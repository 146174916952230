import React from "react";
import { useRouteMatch } from "react-router-dom";

import Privileges from "../../Constants/Privilages";
import { AdminPanelSidebarPrivileges } from "../../Constants/Nav";

import AdminPanelPage from "./AdminPanelPage";
import CompanyProfile from "./CompanyProfile";
import AccountManagerProfile from "./AccountManagerProfile";
import ProtectedRouter from "../../Helpers/HOC/ProtectedRouter";

const AdminPanelRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <ProtectedRouter
        privileges={AdminPanelSidebarPrivileges}
        allowBP={false}
        exact={true}
        path={path}
      >
        <AdminPanelPage />
      </ProtectedRouter>
      <ProtectedRouter
        privileges={[Privileges.VIEW_COMPANIES, Privileges.SUPER_PRIVILEGE]}
        allowBP={false}
        exact={true}
        path={`${path}/company-profile/:companyId`}
      >
        <CompanyProfile />
      </ProtectedRouter>
      <ProtectedRouter
        privileges={[Privileges.VIEW_COMPANY_SAAS, Privileges.SUPER_PRIVILEGE]}
        allowBP={false}
        exact={true}
        path={`${path}/account-manager/:id`}
      >
        <AccountManagerProfile />
      </ProtectedRouter>
    </>
  );
};

export default AdminPanelRoutes;
