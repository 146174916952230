import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  ViewWorkGroups,
  ViewFlexWorkGroups,
} from "../../Containers/WorkGroupsList";
import HelperFns from "../../Helpers/HelperFns";
import Privilages from "../../Constants/Privilages";
import useCompanyAttType from "../../Helpers/Hooks/useCompanyAttType";

const ShiftsAndFlexWorkGroups = () => {
  const { hasFlex, hasFixed } = useCompanyAttType();
  const { t } = useTranslation();

  const tabs = [
    ...(HelperFns.checkPrivileges({
      privileges: [Privilages.VIEW_EMPLOYEE_WORK_GROUPS],
      allowBP: true,
    }) && hasFixed
      ? [
          {
            label: "shifts",
            Panel: ViewWorkGroups,
          },
        ]
      : []),

    ...(HelperFns.checkPrivileges({
      privileges: [Privilages.VIEW_EMPLOYEE_WORK_GROUPS],
      allowBP: true,
    }) && hasFlex
      ? [
          {
            label: "flex",
            Panel: ViewFlexWorkGroups,
          },
        ]
      : []),
  ];

  // Local State
  const [value, setValue] = React.useState("0");

  /* ↓ Helpers ↓ */

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="content payroll_wrapper_style no-padding-first-child">
      <TabContext value={value}>
        {/* Tabs */}
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange}>
            {tabs.map(({ label }, i) => (
              <Tab key={label} label={t(label)} value={String(i)} />
            ))}
          </TabList>
        </Box>

        {/* Panels */}
        {tabs.map(({ Panel, props }, i) => (
          <TabPanel key={i} value={String(i)}>
            <Panel {...props} />
          </TabPanel>
        ))}
      </TabContext>
    </div>
  );
};

export default ShiftsAndFlexWorkGroups;
