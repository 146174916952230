import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleDoubleLeft,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import "./pagination.scss";
import { useTranslation } from "react-i18next";

const Pagination = ({ tableRef, pagination = {}, styleWraper, ...props }) => {
  const {
    firstItem = 0,
    lastItem = 0,
    total = 0,
    currentPage = 1,
  } = pagination;

  const { t } = useTranslation();

  const numPages = Math.ceil(total / pagination.perPage);

  const handleNext = () => {
    if (pagination?.hasMorePages) {
      handlePaginate(currentPage != numPages ? currentPage + 1 : numPages);
    }
  };
  const handlePrevious = () => {
    if (currentPage !== 1) {
      handlePaginate(currentPage != 1 ? currentPage - 1 : 1);
    }
  };

  const handlePaginate = (page) => {
    if (page !== currentPage) {
      props.onPaginate(page);
    }
  };

  // if (currentPage == 1 && !pagination?.hasMorePages) {
  //   return null;
  // }
  return (
    <div
      className={`${styleWraper} custom-pagination dataTables_paginate paging_simple_numbers d-flex pt-2 m-0 justify-content-end`}
      id="DataTables_Table_0_paginate"
    >
      <div
        className="dataTables_info pr-2"
        id="DataTables_Table_0_info"
        role="status"
        aria-live="polite"
      >
        {t("pagination", { firstItem, lastItem, total: total || "" })}
        {/* Showing {firstItem} to {lastItem} of {total} entries */}
      </div>
      <ul className="pagination">
        {currentPage !== 1 && !total ? (
          <li
            className="paginate_button page-item previous"
            id="DataTables_Table_0_previous"
          >
            <a
              aria-controls="DataTables_Table_0"
              data-dt-idx="0"
              tabIndex="0"
              className="page-link"
              onClick={() => handlePaginate(1)}
            >
              <FontAwesomeIcon icon={faAngleDoubleLeft} />
            </a>
          </li>
        ) : null}
        <li
          className="paginate_button page-item previous"
          id="DataTables_Table_0_previous"
        >
          <a
            title={t("previous")}
            aria-controls="DataTables_Table_0"
            data-dt-idx="0"
            tabIndex="0"
            className="page-link"
            onClick={handlePrevious}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
          </a>
        </li>
        {Array.from(
          {
            length:
              currentPage == numPages
                ? currentPage
                : currentPage + 3 > numPages
                  ? numPages
                  : currentPage + 3,
          },
          (_, i) => i + 1
        )
          .slice(currentPage - 4 > 0 ? currentPage - 4 : 0)
          .map((page) => (
            <li
              key={page}
              className={`paginate_button page-item ${
                page == currentPage ? "active" : " "
              }`}
            >
              <a
                aria-controls="DataTables_Table_0"
                data-dt-idx="1"
                tabIndex="0"
                className="page-link"
                onClick={() => handlePaginate(page)}
              >
                {page}
              </a>
            </li>
          ))}
        <li
          className="paginate_button page-item next "
          id="DataTables_Table_0_next"
        >
          <a
            title={t("next")}
            aria-controls="DataTables_Table_0"
            data-dt-idx="2"
            tabIndex="0"
            className="page-link"
            onClick={handleNext}
          >
            <FontAwesomeIcon icon={faAngleRight} />
          </a>
        </li>
      </ul>
    </div>
  );
};

const mapStateToProps = (
  state,
  { tableRef, reducer = "super", customPaginator }
) => {
  return {
    pagination: customPaginator
      ? customPaginator
      : state?.[reducer]?.[tableRef]?.pagination,
  };
};
export default connect(mapStateToProps)(Pagination);
