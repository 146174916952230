import React from "react";
import { useTranslation } from "react-i18next";

import clsx from "clsx";
import moment from "moment";

import {
  MoreHoriz,
  ArrowUpward,
  ArrowDownward,
  LocationOnOutlined,
  LocationOffOutlined,
} from "@mui/icons-material";
import * as styles from "./style.module.scss";
import { Avatar, SvgIcon, Tooltip, useMediaQuery } from "@mui/material";

export const LocationIconRow = ({
  locationName,
  isMockedLocation,
  handleViewLocation,
  isListView = false,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <IconRow
        classes={clsx("flex-1", { "align-items-center gap-5": isListView })}
        txt={
          <button
            onClick={handleViewLocation}
            style={{ width: isListView ? "100%" : 50 }}
            className={clsx("btn-link flex-1 text-left bg-none border-0 p-0", {
              "text-truncate": !isListView,
              "error-color": isMockedLocation,
            })}
          >
            {locationName || t("unspecified location")}
          </button>
        }
        icon={
          isMockedLocation ? (
            <LocationOffOutlined
              sx={{ fontSize: 16 }}
              className="error-color"
            />
          ) : (
            <LocationOnOutlined
              sx={{ fontSize: 16 }}
              className="darkish-grey-color"
            />
          )
        }
      />
    </>
  );
};

export const FaceIconRow = ({
  face,
  faceSuccessRatio,
  isFaceOnly = false,
  isListView = false,
}) => {
  const { t } = useTranslation();
  const isMatches = useMediaQuery("(min-width: 1400px)");

  let xyz = () =>
    faceSuccessRatio == "100" ||
    faceSuccessRatio == 0 ||
    faceSuccessRatio == null ? (
      isListView ? (
        <Tooltip
          title={
            <Avatar
              src={face}
              variant="square"
              sx={{
                width: isListView ? 250 : 150,
                height: isListView ? 250 : 150,
              }}
            />
          }
        >
          <MoreHoriz />
        </Tooltip>
      ) : null
    ) : faceSuccessRatio >= 0.1 ? (
      <IconRow
        classes={clsx("px-1 mt-1 align-items-center", {
          "d-block": isListView,
        })}
        txt={
          isFaceOnly ? (
            ""
          ) : (
            <span
              style={{ width: isListView ? undefined : 60 }}
              className="mx-1 text-truncate green-color"
            >
              {t("live photo")}
            </span>
          )
        }
        icon={
          <Tooltip
            title={
              <Avatar
                src={face}
                variant="square"
                sx={{
                  width: isListView ? 250 : 150,
                  height: isListView ? 250 : 150,
                }}
              />
            }
          >
            <SvgIcon
              viewBox="0 0 640 512"
              className="green-color"
              sx={{ fontSize: 14 }}
            >
              <FaceCheckPath />
            </SvgIcon>
          </Tooltip>
        }
      />
    ) : (
      <IconRow
        classes={clsx({ "d-block": isListView })}
        txt={
          isFaceOnly ? (
            ""
          ) : (
            <span
              style={{
                width: !isListView ? undefined : isMatches ? 120 : 90,
              }}
              className={clsx("error-color mx-1 ", {
                "font-11 text-truncate": !isListView,
              })}
            >
              {t("spoofed image")}
            </span>
          )
        }
        icon={
          <Tooltip
            title={
              <Avatar
                src={face}
                variant="square"
                sx={{
                  width: isListView ? 250 : 150,
                  height: isListView ? 250 : 150,
                }}
              />
            }
          >
            <SvgIcon
              sx={{ fontSize: 14 }}
              viewBox="0 0 640 512"
              className="error-color"
            >
              <FaceTimesPath />
            </SvgIcon>
          </Tooltip>
        }
      />
    );

  return (
    <>
      {isFaceOnly ? (
        <Tooltip
          title={
            <Avatar
              src={face}
              variant="square"
              sx={{ width: 150, height: 150 }}
            />
          }
        >
          <Avatar src={face} sx={{ width: 20, height: 20 }} />
        </Tooltip>
      ) : null}
      {xyz()}
    </>
  );
};

export const TimeIconRow = ({ time, isIn }) => (
  <IconRow
    txt={
      <span className="px-1">
        {moment(time, "YYYY-MM-DD HH:mm:ss").format("hh:mm A")}
      </span>
    }
    icon={
      isIn ? (
        <ArrowDownward sx={{ fontSize: 16 }} className="green-color" />
      ) : (
        <ArrowUpward sx={{ fontSize: 16 }} className="error-color" />
      )
    }
  />
);

export const IconRow = ({ icon, txt, classes }) =>
  txt ? (
    <span className={clsx(styles.IconRow, classes)}>
      {icon}
      {txt}
    </span>
  ) : (
    icon
  );

const FaceCheckPath = () => (
  <path d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4zm323-128.4l-27.8-28.1c-4.6-4.7-12.1-4.7-16.8-.1l-104.8 104-45.5-45.8c-4.6-4.7-12.1-4.7-16.8-.1l-28.1 27.9c-4.7 4.6-4.7 12.1-.1 16.8l81.7 82.3c4.6 4.7 12.1 4.7 16.8.1l141.3-140.2c4.6-4.7 4.7-12.2.1-16.8z" />
);

const FaceTimesPath = () => (
  <path d="M589.6 240l45.6-45.6c6.3-6.3 6.3-16.5 0-22.8l-22.8-22.8c-6.3-6.3-16.5-6.3-22.8 0L544 194.4l-45.6-45.6c-6.3-6.3-16.5-6.3-22.8 0l-22.8 22.8c-6.3 6.3-6.3 16.5 0 22.8l45.6 45.6-45.6 45.6c-6.3 6.3-6.3 16.5 0 22.8l22.8 22.8c6.3 6.3 16.5 6.3 22.8 0l45.6-45.6 45.6 45.6c6.3 6.3 16.5 6.3 22.8 0l22.8-22.8c6.3-6.3 6.3-16.5 0-22.8L589.6 240zM224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z" />
);
