import React from "react";
import { useLazyQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

import moment from "moment";
import gql from "graphql-tag";
import HelperFns from "../../Helpers/HelperFns";

import Loader from "../Loader";
import MainModal from "../MainModal";
import Avatar from "@mui/material/Avatar";
import { DateTimePickerForm } from "form-builder";

const GET_EMPLOYEES = gql`
  query getEmployees($id: ID, $date: Date, $isWorkgroup: Boolean!) {
    employees: flexWorkgroupEmployeesAtDate(
      flex_work_group_id: $id
      date: $date
    ) @include(if: $isWorkgroup) {
      user {
        id
        name
        profile_picture {
          path
        }
      }
    }

    team_employees: flexWorkTeamEmployeesAtDate(
      flex_work_team_id: $id
      date: $date
    ) @skip(if: $isWorkgroup) {
      user {
        id
        name
        profile_picture {
          path
        }
      }
    }
  }
`;

const FlexEmployeesModal = ({ data, onClose }) => {
  const { t } = useTranslation();

  // Local State
  const [modalData, setModalData] = React.useState({ employees: [], date: "" });

  // Server State
  const [getEmployees, { called, loading }] = useLazyQuery(GET_EMPLOYEES);

  /* ↓ Helpers ↓ */

  const onSelectDate = (val) => {
    if (val) {
      const date = val.format("yyyy-MM-DD");

      handleGetEmployees(date);
      setModalData((prev) => ({ ...prev, date }));
    }
  };

  const handleGetEmployees = (date) => {
    getEmployees({
      variables: {
        date,
        id: data?.id,
        isWorkgroup: data?.isWorkgroup,
      },
      onCompleted: (res) => {
        const key = data?.isWorkgroup ? "employees" : "team_employees";
        const employees = res?.[key] || [];

        setModalData((prev) => ({ ...prev, employees }));
      },
    });
  };

  return (
    <MainModal
      isOpen
      toggle={onClose}
      modalTitle="View Employees"
      hasModalFooter={false}
    >
      {loading ? <Loader fixed /> : null}
      <DateTimePickerForm
        containerStyle="w-100"
        onChange={onSelectDate}
        onSelect={onSelectDate}
        value={modalData?.date ? moment(modalData?.date) : null}
      />

      {modalData?.employees?.length ? (
        <ul
          className="d-flex flex-wrap mt-4 mb-0"
          style={{ "padding-inline-start": 0 }}
        >
          {modalData?.employees.map((emp) => (
            <li
              key={emp.user.id}
              className="w-50 d-flex align-items-center gap-20 mb-2 show_all_modal_avatar_style"
            >
              <Avatar
                src={emp.user?.profile_picture?.path}
                className="rounded-circle blackish-avatar"
              >
                {HelperFns.getIntials(emp.user.name)}
              </Avatar>
              {emp.user.name}
            </li>
          ))}
        </ul>
      ) : (
        <p className="mt-3 mb-0 text-center">
          {t(
            !loading && called
              ? "no data available"
              : "set date to preview employees"
          )}
        </p>
      )}
    </MainModal>
  );
};

export default FlexEmployeesModal;
