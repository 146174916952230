import React from "react";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { showToast } from "../../Helpers/HelperFns";
import { onFormResetAction } from "../../Store/Actions";
import { addInvoiceMutation } from "../../Graphql/mutation/PaymentTracking";
import { serializeAddInvoice } from "../../Helpers/HelperFns/PaymentTracking";

import MainModal from "../MainModal";
import { PaymentInfo } from "../PaymentTracking";
import { InputForm, DateTimePickerForm } from "form-builder";

const reducer = "paymentTracking";
const formName = "addInvoice";
const formNameValidation = "paymentTrackingClientValidation";
const formServerValidation = "paymentTrackingServerValidation";

const AddInvoice = ({ data, onClose, refetchList }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Local State
  const [errMsg, setErrMsg] = React.useState("");
  const [formSubmitting, setFormSubmitting] = React.useState(false);
  const formClientValidation = useSelector(
    (state) => state?.[reducer]?.[formNameValidation]
  );

  // Reducer State
  const formData = useSelector((state) => state?.[reducer]?.[formName]);

  // Server State
  const [addInvoice, { loading: addInvoiceLoading }] =
    useMutation(addInvoiceMutation);

  // Constants
  const FormProps = {
    reducer,
    formName,
    formSubmitting,
    formNameValidation,
    formServerValidation,
  };

  /* ↓ State Effects ↓ */

  /* ↓ Helpers ↓ */

  const handleCloseModal = () => {
    onClose();
    dispatch(onFormResetAction(formName));
    dispatch(onFormResetAction(formNameValidation));
    dispatch(onFormResetAction(formServerValidation));
  };

  const handleSubmit = () => {
    setFormSubmitting(true);
    if (formClientValidation.length) return;

    addInvoice({
      variables: serializeAddInvoice({ ...formData, ...data }),
      onCompleted: () => {
        refetchList();
        handleCloseModal();
        showToast("success");
      },
      onError: (err) => {
        const validation = err?.graphQLErrors?.[0]?.extensions?.validation;
        dispatch(onFormResetAction(formServerValidation, validation || {}));

        const msg =
          validation?.json?.[0] ||
          err?.graphQLErrors?.[0]?.extensions?.reason ||
          err?.graphQLErrors?.[0]?.message ||
          err?.message;
        setErrMsg(msg || "");
      },
    });
  };

  return (
    <MainModal
      isOpen
      modalTitle="Add Invoice"
      toggle={handleCloseModal}
      btnOnClick={handleSubmit}
      btnSubmitLoading={addInvoiceLoading}
    >
      <InputForm
        {...FormProps}
        name="name"
        label="Name"
        rootStyle="mb-2"
        containerStyle="w-100"
        validateBy="textRequired"
        validationName="name"
      />

      <InputForm
        {...FormProps}
        name="amount"
        label="Amount"
        icon="money"
        rootStyle="w-50 d-inline-block pr-2 mb-2"
        containerStyle="w-100"
        validateBy="textRequired"
        validationName="amount"
      />
      <DateTimePickerForm
        {...FormProps}
        name="from"
        label="invoice date"
        hasIcon
        containerStyle="w-100"
        rootStyle="d-inline-block w-50 mb-2"
      />

      <InputForm
        {...FormProps}
        type="number"
        name="suspend_after"
        label="suspense after days"
        labelStyle="mb-2 pt-1"
        containerStyle="w-100"
        rootStyle="mb-2"
        placeholder="suspense after days"
        icon="time"
      />

      <PaymentInfo FormProps={FormProps} />

      {errMsg ? (
        <p role="alert" className="red-color mt-2 mb-0">
          {t(errMsg)}
        </p>
      ) : null}
    </MainModal>
  );
};

export default AddInvoice;
