import { useLazyQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { globalSearchQuery } from "../../Graphql/query";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "../Loader";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { Avatar, Box } from "@mui/material";
import HelperFns, { showToast } from "../../Helpers/HelperFns";
import { Link } from "react-router-dom";
import "./globalSearch.scss";
import AsyncSelect from "react-select/async";
import { components } from "react-select";

const GlobalSearch = (props) => {
  const { t } = useTranslation();
  const location = useLocation();

  const [selectedOption, setSelectedOption] = useState(null);

  const defStyles = {
    control: (styles, state) => {
      return {
        ...styles,
        border: "none",
        borderBottom: "1px solid #d6d3d3",
        borderRadius: "0px !important",
        borderColor: "#d6d3d3 !important",
        padding: "0px",
        marginBottom: "1",
      };
    },
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: "none",
    }),
    input: (styles) => ({ ...styles }),
    placeholder: (styles) => ({ ...styles, color: "#b8b8b8" }),
  };

  const Placeholder = ({ children, ...props }) => (
    <components.Placeholder {...props}>
      <div
        className="d-flex align-items-center "
        style={{ gap: 3, color: "#b8b8b8" }}
      >
        <div className={`bselect_icon`}>
          <FontAwesomeIcon
            icon={faSearch}
            color="#b8b8b8"
            style={{ fontSize: 12 }}
          />
        </div>
        {children}
      </div>
    </components.Placeholder>
  );

  const loadOptions = (inputValue, callback) => {
    searchRequest({
      variables: {
        input: {
          name: inputValue,
        },
      },
      onCompleted: (res) => {
        callback(res?.employees?.data);
      },
      onError: (err) => {
        showToast(
          "error",
          err.graphQLErrors?.[0]?.extensions?.reason ?? err?.message
        );
      },
    });
  };

  const [searchRequest, { data, loading }] = useLazyQuery(globalSearchQuery, {
    onError: (err) => {
      showToast(
        "error",
        err.graphQLErrors?.[0]?.extensions?.reason ?? err?.message
      );
    },
  });

  useEffect(() => {
    searchRequest({
      variables: {
        input: {
          status: ["Active"],
        },
      },
      onError: (err) => {
        showToast(
          "error",
          err.graphQLErrors?.[0]?.extensions?.reason ?? err?.message
        );
      },
    });
  }, [location]);

  const onChangeSelectedOption = (opt) => {
    setSelectedOption(opt);
  };

  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        {t("no options")}
      </components.NoOptionsMessage>
    );
  };

  const LoadingMessage = (props) => {
    return (
      <components.LoadingMessage {...props}>
        {t("loading") + "..."}
      </components.LoadingMessage>
    );
  };

  return (
    <div style={{ width: "30vw" }}>
      <AsyncSelect
        value={selectedOption}
        onChange={onChangeSelectedOption}
        cacheOptions={false}
        defaultOptions={data?.employees?.data ? data?.employees?.data : []}
        loadOptions={loadOptions}
        components={{
          Option: GlobalSearchItem,
          Placeholder,
          NoOptionsMessage,
          LoadingMessage,
        }}
        placeholder={t("Enter employee name, email, phone number, or code")}
        styles={defStyles}
        className={"select-def-input-container"}
        classNamePrefix={"b-select-style"}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        isClearable={true}
        isLoading={loading}
      />
    </div>
  );
};

export default GlobalSearch;

const GlobalSearchItem = ({ data, innerRef, innerProps }) => {
  const { t } = useTranslation();
  return (
    <Link
      ref={innerRef}
      {...innerProps}
      to={`/employees/employee-profile/${data?.id}`}
    >
      <div className="d-flex px-2 rounded-left border-border-bottom border-secondary py-1 shadow-sm align-items-center global-search-item ">
        {/* Image Side */}
        <Box
          className="employee-img"
          sx={{
            py: 0.5,
            minWidth: 60,
            display: "grid",
            alignItems: "center",
            justifyItems: "center",
          }}
        >
          <Avatar
            src={data?.profile_picture?.path ?? data?.face?.path}
            className={
              +data?.on_break
                ? "avatar blackish-avatar on_break_status_style"
                : "avatar blackish-avatar"
            }
          >
            {HelperFns.getIntials(data?.name)}
          </Avatar>
        </Box>
        <div className="w-100">
          <div className="d-flex flex-column w-100">
            <div
              className="d-flex w-100 justify-content-between align-items-center "
              style={{ gap: 1 }}
            >
              <span className="px-2 font-weight-bold text-black-50 w-50  text-truncate">
                {data?.name}
              </span>
              {data?.active === 0 && (
                <span
                  style={{
                    color: "#ffb700",
                    fontSize: 11.5,
                    background: "#fff4d7",
                    padding: 1,
                    borderRadius: 2,
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}
                >
                  {t("suspended")}
                </span>
              )}
            </div>

            <span className="px-2 text-secondary " style={{ fontSize: 12 }}>
              {data?.office?.name}
            </span>
          </div>
          <div className="d-flex">
            <span className="px-2 text-secondary " style={{ fontSize: 12 }}>
              {data?.department?.name}
            </span>
            -
            <span className="px-2 text-secondary " style={{ fontSize: 12 }}>
              {data?.position?.name}
            </span>
          </div>
        </div>
      </div>
    </Link>
  );
};
