import React from "react";

import Privileges from "../../../Constants/Privilages";

import {
  CompanyRemindersList,
  CompanyActivitiesList,
} from "../../../Components/AdminPanel";
import { MeetingsList } from "../../CRMLists";
import HasPrivileges from "../../../Helpers/HOC/HasPrivileges";

const Dashboard = () => {
  return (
    <>
      <HasPrivileges
        reqireMain={[
          Privileges.VIEW_COMPANY_REMINDERS,
          Privileges.SUPER_PRIVILEGE,
        ]}
      >
        <div className="boxContainer py-2">
          <strong className="font-18 blue-color">Reminders</strong>
          <CompanyRemindersList dashboardView />
        </div>
      </HasPrivileges>
      <HasPrivileges
        reqireMain={[
          Privileges.VIEW_COMPANY_ACTIVITIES,
          Privileges.SUPER_PRIVILEGE,
        ]}
      >
        <div className="boxContainer py-2">
          <strong className="font-18 blue-color">Activities</strong>
          <CompanyActivitiesList dashboardView />
        </div>
      </HasPrivileges>
      <HasPrivileges
        reqireMain={[
          Privileges.VIEW_COMPANY_MEETING,
          Privileges.SUPER_PRIVILEGE,
        ]}
      >
        <div className="boxContainer py-2">
          <strong className="font-18 blue-color mb-3 d-block">Meetings</strong>
          <MeetingsList dashboardView />
        </div>
      </HasPrivileges>
    </>
  );
};

export default Dashboard;
