import Types from "../types";

// Start handle recruitment Processes Form

export const onRecruitemtProccessFormFieldsChangeAction = (stageId, fields) => {
  return {
    type: Types.ON_RECRUITMENT_PROCESS_FORM_FORM_FIELDS_CHANGE,
    payload: {
      stageId,
      fields,
    },
  };
};

export const onRecruitemtProccessFormChangeAction = (stageId, field) => {
  return {
    type: Types.ON_RECRUITMENT_PROCESS_FORM_CHANGE,
    payload: {
      stageId,
      field,
    },
  };
};

// End handle recruitment Processes Form

// Start of handle children input action
export const recruitmentChildInputChange = (
  formName,
  childName,
  name,
  index,
  value
) => {
  return {
    type: Types.ON_RECRUITMENT_CHILD_INPUT_CHANGE,
    payload: {
      formName,
      childName,
      name,
      index,
      value,
    },
  };
};
// End of handle children input action

// Start of fetch recruitment process list
export const fetchRecruitmentProcessesAction = (data) => {
  return {
    type: Types.FETCH_RECRUITMENT_PROCESSES_LIST_ATTEMPT,
    payload: {
      ...data,
    },
  };
};

export const fetchRecruitmentProcessesSuccess = (ref, list) => {
  return {
    type: Types.FETCH_RECRUITMENT_PROCESSES_LIST_SUCCESS,
    payload: {
      ref,
      list,
    },
  };
};

export const fetchRecruitmentProcessesFailed = (ref) => {
  return {
    type: Types.FETCH_RECRUITMENT_PROCESSES_LIST_FAILED,
    payload: {
      ref,
    },
  };
};
// End of fetch recruitment process list

// Start of delete & clone  recruitment process action
export const deleteRecruitmentProcessesAction = (data) => ({
  type: Types.DELETE_RECRUITMENT_PROCESSES_ATTEMPT,
  payload: data,
});

export const deleteRecruitmentProcessesSuccess = () => ({
  type: Types.DELETE_RECRUITMENT_PROCESSES_SUCCESS,
});

export const deleteRecruitmentProcessesFailed = () => ({
  type: Types.DELETE_RECRUITMENT_PROCESSES_FAILED,
});

export const cloneRecruitmentProcessAction = (data) => ({
  type: Types.CLONE_RECRUITMENT_PROCESSES_ATTEMPT,
  payload: data,
});

export const cloneRecruitmentProcessesSuccess = () => ({
  type: Types.CLONE_RECRUITMENT_PROCESSES_SUCCESS,
});

export const cloneRecruitmentProcessesFailed = () => ({
  type: Types.CLONE_RECRUITMENT_PROCESSES_FAILED,
});
// End of delete recruitment process action

// Start of update process status actions
export const updateRecruitmentProcessesStatusAction = (data) => {
  return {
    type: Types.UPDTAE_RECRUITMENT_PROCESSES_STATUS_ATTEMPT,
    payload: data,
  };
};

export const updateRecruitmentProcessesStatusSuccess = () => {
  return {
    type: Types.UPDTAE_RECRUITMENT_PROCESSES_STATUS_SUCCESS,
  };
};

export const updateRecruitmentProcessesStatusFailed = () => {
  return {
    type: Types.UPDTAE_RECRUITMENT_PROCESSES_STATUS_FAILED,
  };
};
// End of update process status actions

// Start of fetch intake-forms list
export const fetchIntakeFormsAction = (data) => {
  return {
    type: Types.FETCH_INTAKE_FORMS_LIST_ATTEMPT,
    payload: {
      ...data,
    },
  };
};

export const fetchIntakeFormsSuccess = (ref, list) => {
  return {
    type: Types.FETCH_INTAKE_FORMS_LIST_SUCCESS,
    payload: {
      ref,
      list,
    },
  };
};

export const fetchIntakeFormsFailed = (ref) => {
  return {
    type: Types.FETCH_INTAKE_FORMS_LIST_FAILED,
    payload: {
      ref,
    },
  };
};
// End of fetch intake-forms list

// Start of delete & clone intake-forms action
export const deleteIntakeFormsAction = (data) => ({
  type: Types.DELETE_INTAKE_FORMS_ATTEMPT,
  payload: data,
});

export const deleteIntakeFormsSuccess = () => ({
  type: Types.DELETE_INTAKE_FORMS_SUCCESS,
});

export const deleteIntakeFormsFailed = () => ({
  type: Types.DELETE_INTAKE_FORMS_FAILED,
});

export const cloneIntakeFormAction = (data) => ({
  type: Types.CLONE_INTAKE_FORMS_ATTEMPT,
  payload: data,
});

export const cloneIntakeFormsSuccess = () => ({
  type: Types.CLONE_INTAKE_FORMS_SUCCESS,
});

export const cloneIntakeFormsFailed = () => ({
  type: Types.CLONE_INTAKE_FORMS_FAILED,
});
// End of delete intake-forms action

// Start of update intake-forms status actions
export const updateIntakeFormsStatusAction = (data) => {
  return {
    type: Types.UPDTAE_INTAKE_FORMS_STATUS_ATTEMPT,
    payload: data,
  };
};

export const updateIntakeFormsStatusSuccess = () => {
  return {
    type: Types.UPDTAE_INTAKE_FORMS_STATUS_SUCCESS,
  };
};

export const updateIntakeFormsStatusFailed = () => {
  return {
    type: Types.UPDTAE_INTAKE_FORMS_STATUS_FAILED,
  };
};
// End of update intake-forms status actions

// Start of fetch job posts list
export const fetchJobPostsAction = (data) => {
  return {
    type: Types.FETCH_JOB_POSTS_LIST_ATTEMPT,
    payload: {
      ...data,
    },
  };
};

export const fetchJobPostsSuccess = (ref, list) => {
  return {
    type: Types.FETCH_JOB_POSTS_LIST_SUCCESS,
    payload: {
      ref,
      list,
    },
  };
};

export const fetchJobPostsFailed = (ref) => {
  return {
    type: Types.FETCH_JOB_POSTS_LIST_FAILED,
    payload: {
      ref,
    },
  };
};
// End of fetch job posts list

// Start of delete job posts action
export const deleteJobPostsAction = (data) => ({
  type: Types.DELETE_JOB_POSTS_ATTEMPT,
  payload: data,
});

export const deleteJobPostsSuccess = () => ({
  type: Types.DELETE_JOB_POSTS_SUCCESS,
});

export const deleteJobPostsFailed = () => ({
  type: Types.DELETE_JOB_POSTS_FAILED,
});
// End of delete job posts action

// Start of update job posts status actions
export const updateJobPostsStatusAction = (data) => {
  return {
    type: Types.UPDTAE_JOB_POSTS_STATUS_ATTEMPT,
    payload: data,
  };
};

export const updateJobPostsStatusSuccess = () => {
  return {
    type: Types.UPDTAE_JOB_POSTS_STATUS_SUCCESS,
  };
};

export const updateJobPostsStatusFailed = () => {
  return {
    type: Types.UPDTAE_JOB_POSTS_STATUS_FAILED,
  };
};
// End of update job postsstatus actions

// Start of fetch application list
export const fetchApplicationsListAction = (data) => {
  return {
    type: Types.FETCH_APPLICATION_LIST_ATTEMPT,
    payload: {
      ...data,
    },
  };
};

export const fetchApplicationsListSuccess = (ref, list, jobPostName) => {
  return {
    type: Types.FETCH_APPLICATION_LIST_SUCCESS,
    payload: {
      ref,
      list,
      jobPostName,
    },
  };
};

export const fetchApplicationsListFailed = (ref) => {
  return {
    type: Types.FETCH_APPLICATION_LIST_FAILED,
    payload: {
      ref,
    },
  };
};
// End of fetch application list

// Start of process Stages filter action
export const fetchProcessStagesOptionsAction = (stages) => {
  return {
    type: Types.FETCH_PROCESS_STAGES_FILTER_OPTIONS,
    payload: stages,
  };
};
// End of process Stages filter action

// Start of handle form builder action

export const addFormFieldAction = (
  formName,
  stageIndex,
  newField,
  formType = "PROCESS_FORM"
) => {
  return {
    type: Types.ADD_FORM_FIELD,
    payload: { formName, stageIndex, newField },
    formType,
  };
};

export const deleteProcessFieldsAction = (formName, stageIndex) => {
  return {
    type: Types.DELETE_STAGE_PROCESS_FIELDS,
    payload: { formName, stageIndex },
  };
};

export const deleteFormFieldAction = (formName, stageIndex, fieldId) => {
  return {
    type: Types.DELETE_FORM_FIELD,
    payload: { formName, stageIndex, fieldId },
  };
};

export const addProcessStageAction = (stage) => {
  return {
    type: Types.ADD_PROCESS_STAGE,
    payload: { stage },
  };
};

export const deleteProcessStageAction = (stageId, stageIndex) => {
  return {
    type: Types.DELETE_PROCESS_STAGE,
    payload: { stageId, stageIndex },
  };
};

// start handle Process Form Change actions

export const onInputFieldChange = (
  formName,
  stageIndex,
  fieldId,
  name,
  value
) => {
  return {
    type: Types.ON_INPUT_FIELD_CHANGE,
    payload: { formName, stageIndex, fieldId, name, value },
  };
};

export const onStageInputFieldChange = (formName, stageIndex, name, value) => {
  return {
    type: Types.ON_STAGE_INPUT_FIELD_CHANGE,
    payload: { formName, stageIndex, name, value },
  };
};
// end handle Process Form Change actions

// Start Upsert Recruitment Process action

export const initRecruitmentProcessAction = (id = null) => {
  return {
    type: Types.INIT_RECRUITMENT_PROCESS_FORM_ATTEMPT,
    payload: id,
  };
};

export const initRecruitmentProcessSuccess = (validationsOpts) => {
  return {
    type: Types.INIT_RECRUITMENT_PROCESS_FORM_SUCCESS,
    payload: validationsOpts,
  };
};

export const fetchRecruitmentProcessSuccess = (recruitmentProcessesForm) => {
  return {
    type: Types.FETCH_RECRUITMENT_PROCESS_FORM_SUCCESS,
    payload: { recruitmentProcessesForm },
  };
};

export const initRecruitmentProcessFailed = () => {
  return {
    type: Types.INIT_RECRUITMENT_PROCESS_FORM_FAILED,
  };
};

export const setRecruitmentFormSubmitting = () => {
  return {
    type: Types.SET_RECRUITMENT_FORM_SUBMITTING,
  };
};

export const resetRecruitmentProcessForm = () => {
  return {
    type: Types.RESET_RECRUITMENT_PROCESS_FORM,
  };
};

export const upsertRecruitmentProcessAction = (data) => {
  return {
    type: Types.UPSERT_RECRUITMENT_PROCESS_ATTEMPT,
    payload: { data },
  };
};
export const upsertRecruitmentProcessSuccess = (data) => {
  return {
    type: Types.UPSERT_RECRUITMENT_PROCESS_SUCCESS,
    payload: data,
  };
};
// Start of reset application list action
export const resetApplicationListAction = () => {
  return {
    type: Types.RESET_APPLICATION_LIST,
  };
};
// End of reset application list action

// Start of update Application Stage status actions
export const updateApplicationsStatusAction = (
  stage_id,
  applicant_job_post_id,
  jobPostId
) => {
  return {
    type: Types.UPDATE_APPLICATION_STAGE_STATUS_ATTEMPT,
    payload: {
      stage_id,
      applicant_job_post_id,
      jobPostId,
    },
  };
};

export const updateApplicationsStatusSuccess = (applicant_job_post_id) => {
  return {
    type: Types.UPDATE_APPLICATION_STAGE_STATUS_SUCCESS,
    payload: { applicant_job_post_id },
  };
};

export const updateApplicationsStatusFailed = (applicant_job_post_id) => {
  return {
    type: Types.UPDATE_APPLICATION_STAGE_STATUS_FAILED,
    payload: { applicant_job_post_id },
  };
};
// End of update Application Stage statu actions

// Start of fetch applicants list
export const fetchApplicantsAction = (data) => {
  return {
    type: Types.FETCH_APPLICANTS_LIST_ATTEMPT,
    payload: {
      ...data,
    },
  };
};

export const fetchApplicantsSuccess = (ref, list) => {
  return {
    type: Types.FETCH_APPLICANTS_LIST_SUCCESS,
    payload: {
      ref,
      list,
    },
  };
};

export const fetchApplicantsFailed = (ref) => {
  return {
    type: Types.FETCH_APPLICANTS_LIST_FAILED,
    payload: {
      ref,
    },
  };
};
// End of fetch applicants list

// Start of delete applicants action
export const deleteApplicantsAction = (data) => ({
  type: Types.DELETE_APPLICANTS_ATTEMPT,
  payload: data,
});

export const deleteApplicantsSuccess = () => ({
  type: Types.DELETE_APPLICANTS_SUCCESS,
});

export const deleteApplicantsFailed = () => ({
  type: Types.DELETE_APPLICANTS_FAILED,
});
// End of delete applicants action

// Start of upsert application modal action
export const showApplicationModalAction = (data) => {
  return {
    type: Types.SHOW_APPLICATION_MODAL,
    payload: data,
  };
};

export const dismissApplicationModalAction = (data) => {
  return {
    type: Types.HIDE_APPLICATION_MODAL,
    payload: data,
  };
};

export const upsertApplicationAction = ({ data, ref, external = false }) => ({
  type: Types.UPSERT_APPLICATION_ATTEMPT,
  payload: data,
  external,
  ref,
});

export const upsertApplicationSuccess = (ref) => ({
  type: Types.UPSERT_APPLICATION_SUCCESS,
  payload: { ref },
});

export const upsertApplicationFailed = (error) => ({
  type: Types.UPSERT_APPLICATION_FAILED,
  payload: {
    ...error,
  },
});
// End of upsert application modal action

// Start of job posts filter action
export const fetchJobPostsOptionsAction = (jobPosts) => {
  return {
    type: Types.FETCH_JOB_POSTS_FILTER_OPTIONS,
    payload: jobPosts,
  };
};
// End of job posts filter action

// Start of upsert job post modal action
export const showJobPostModalAction = (data) => {
  return {
    type: Types.SHOW_JOB_POST_MODAL,
    payload: data,
  };
};

export const upsertRecruitmentProcessFailed = (serverError) => {
  return {
    type: Types.UPSERT_RECRUITMENT_PROCESS_FAILED,
    payload: serverError,
  };
};

// End Upsert Recruitment Process action

// Start Intake Form Actions

export const initRecruitmentIntakeFormAction = (id = null) => {
  return {
    type: Types.INIT_RECRUITMENT_INTAKE_FORM_ATTEMPT,
    payload: id,
  };
};

export const initRecruitmentIntakeFormSuccess = (validationsOpts) => {
  return {
    type: Types.INIT_RECRUITMENT_INTAKE_FORM_SUCCESS,
    payload: validationsOpts,
  };
};

export const fetchRecruitmentIntakeFormSuccess = (recruitmentIntakeForm) => {
  return {
    type: Types.FETCH_RECRUITMENT_INTAKE_FORM_SUCCESS,
    payload: { recruitmentIntakeForm },
  };
};

export const initRecruitmentIntakeFormFailed = () => {
  return {
    type: Types.INIT_RECRUITMENT_INTAKE_FORM_FAILED,
  };
};

export const resetRecruitmentIntakeForm = () => {
  return {
    type: Types.RESET_RECRUITMENT_INTAKE_FORM,
  };
};

export const onIntakeFormFieldsChangeAction = (updatedFields) => {
  return {
    type: Types.HANDLE_RECRUITMENT_INTAKE_FORM_CHANGE,
    payload: updatedFields,
  };
};

export const upsertRecruitmentIntakeAction = (data) => {
  return {
    type: Types.UPSERT_RECRUITMENT_INTAKE_ATTEMPT,
    payload: { data },
  };
};
export const upsertRecruitmentIntakeSuccess = (data) => {
  return {
    type: Types.UPSERT_RECRUITMENT_INTAKE_SUCCESS,
    payload: data,
  };
};
export const dismissJobPostModalAction = (data) => {
  return {
    type: Types.HIDE_JOB_POST_MODAL,
    payload: data,
  };
};

export const upsertRecruitmentIntakeFailed = (serverError) => {
  return {
    type: Types.UPSERT_RECRUITMENT_INTAKE_FAILED,
    payload: serverError,
  };
};
export const upsertJobPostAction = (data) => ({
  type: Types.UPSERT_JOB_POST_ATTEMPT,
  payload: data,
});

export const upsertJobPostSuccess = (ref) => ({
  type: Types.UPSERT_JOB_POST_SUCCESS,
  payload: { ref },
});

export const upsertJobPostFailed = (error) => ({
  type: Types.UPSERT_JOB_POST_FAILED,
  payload: {
    ...error,
  },
});
// End of upsert job post modal action

// Start of job posts form intake and process options actions
export const fetchJobPostsProcessesAndIntakeFormsOptionsAction = (
  jobPostId
) => {
  return {
    type: Types.FETCH_JOB_POST_FORM_PROCESSES_AND_INTAKE_FORMS_OPTIONS,
    payload: { jobPostId },
  };
};

export const fetchJobPostsProcessesAndIntakeFormsOptionsSuccess = (
  jobPostFormProcesses,
  jobPostFormIntakeForms
) => {
  return {
    type: Types.FETCH_JOB_POST_FORM_PROCESSES_AND_INTAKE_FORMS_OPTIONS_SUCCESS,
    payload: {
      jobPostFormProcesses,
      jobPostFormIntakeForms,
    },
  };
};

export const fetchJobPostsProcessesAndIntakeFormsOptionsFailed = () => {
  return {
    type: Types.FETCH_JOB_POST_FORM_PROCESSES_AND_INTAKE_FORMS_OPTIONS_FAILED,
  };
};
// End of job posts form intake and process options actions

// Start of add job post process stages action
export const addNewProcessStagesInJobPost = () => {
  return {
    type: Types.ADD_JOB_POST_PROCESS_STAGES_INPUT,
  };
};
// End of add job post process stages action

// Start of fetch applicant Profile actions
export const fetchApplicantProfileAction = (data) => {
  return {
    type: Types.FETCH_APPLICANT_PROFILE_ATTEMPT,
    payload: data,
  };
};

export const fetchApplicantProfileSuccess = (applicantProfile) => {
  return {
    type: Types.FETCH_APPLICANT_PROFILE_SUCCESS,
    payload: { applicantProfile },
  };
};

export const fetchApplicantProfileFailed = () => {
  return {
    type: Types.FETCH_APPLICANT_PROFILE_FAILED,
  };
};
// End of fetch applicant Profile actions

// Start of update applicant email modal action
export const showEditApplicantEmailModalAction = (data) => {
  return {
    type: Types.SHOW_EDIT_APPLICANT_EMAIL_MODAL,
    payload: data,
  };
};

export const dismissApplicantEmailModalAction = (data) => {
  return {
    type: Types.HIDE_EDIT_APPLICANT_EMAIL_MODAL,
    payload: data,
  };
};

export const updateApplicantEmailAction = (data) => ({
  type: Types.UPDATE_APPLICANT_EMAIL_ATTEMPT,
  payload: data,
});

export const updateApplicantEmailSuccess = (ref) => ({
  type: Types.UPDATE_APPLICANT_EMAIL_SUCCESS,
  payload: { ref },
});

export const updateApplicantEmailFailed = (error) => ({
  type: Types.UPDATE_APPLICANT_EMAIL_FAILED,
  payload: {
    ...error,
  },
});
// End of update applicant email modal action

// Start of create applicant phone modal action
export const showAddApplicantPhoneModalAction = (data) => {
  return {
    type: Types.SHOW_ADD_APPLICANT_PHONE_MODAL,
    payload: data,
  };
};

export const dismissAddApplicantPhoneModalAction = (data) => {
  return {
    type: Types.HIDE_ADD_APPLICANT_PHONE_MODAL,
    payload: data,
  };
};

export const createApplicantPhoneAction = (data) => ({
  type: Types.CREATE_APPLICANT_PHONE_ATTEMPT,
  payload: data,
});

export const createApplicantPhoneSuccess = (ref) => ({
  type: Types.CREATE_APPLICANT_PHONE_SUCCESS,
  payload: { ref },
});

export const createApplicantPhoneFailed = (error) => ({
  type: Types.CREATE_APPLICANT_PHONE_FAILED,
  payload: {
    ...error,
  },
});
// End of create applicant phone modal action

// Start of update applicant phone modal action
export const showEditApplicantPhoneModalAction = (data) => {
  return {
    type: Types.SHOW_EDIT_APPLICANT_PHONE_MODAL,
    payload: data,
  };
};

export const dismissApplicantPhoneModalAction = (data) => {
  return {
    type: Types.HIDE_EDIT_APPLICANT_PHONE_MODAL,
    payload: data,
  };
};

export const updateApplicantPhoneAction = (data) => ({
  type: Types.UPDATE_APPLICANT_PHONE_ATTEMPT,
  payload: data,
});

export const updateApplicantPhoneSuccess = (ref) => ({
  type: Types.UPDATE_APPLICANT_PHONE_SUCCESS,
  payload: { ref },
});

export const updateApplicantPhoneFailed = (error) => ({
  type: Types.UPDATE_APPLICANT_PHONE_FAILED,
  payload: {
    ...error,
  },
});
// End of update applicant phone modal action

// Start of delete applicant phone number action
export const deleteApplicantPhoneNumberAction = (
  id,
  applicant_id,
  phone_number
) => ({
  type: Types.DELETE_APPLICANT_PHONE_NUMBER_ATTEMPT,
  payload: {
    id,
    applicant_id,
    phone_number,
  },
});

export const deleteApplicantPhoneNumberSuccess = () => ({
  type: Types.DELETE_APPLICANT_PHONE_NUMBER_SUCCESS,
});

export const deleteApplicantPhoneNumberFailed = () => ({
  type: Types.DELETE_APPLICANT_PHONE_NUMBER_FAILED,
});
// End of delete applicant phone number action

// start fetch Intake Form action

export const fetchIntakeFormAction = (jobPostId, external = false) => {
  
  return {
    type: Types.FETCH_INTAKE_FORM_ATTEMPT,
    payload: { jobPostId, external },
  };
};

export const fetchIntakeFormSuccess = ({ intakeForm, ...jobPost }) => {
  return {
    type: Types.FETCH_INTAKE_FORM_SUCCESS,
    payload: { intakeForm, jobPost },
  };
};

export const fetchIntakeFormFailed = () => {
  return {
    type: Types.FETCH_INTAKE_FORM_FAILED,
  };
};

// end fetch Intake Form action

// START FILL STAGE DATA ACTION

export const fillStageDataAction = (data, applicant_id) => {
  return {
    type: Types.FILL_STAGE_DATA_ATTEMPT,
    payload: data,
    applicant_id,
  };
};

export const fillStageDataSuccess = (res) => {
  return {
    type: Types.FILL_STAGE_DATA_SUCCESS,
    payload: res,
  };
};

export const fillStageDataFailed = (errors) => {
  
  return {
    type: Types.FILL_STAGE_DATA_FAILED,
    payload: { errors },
  };
};

// END FILL STAGE DATA ACTION
