import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLazyQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { leavesBreaksQuery } from "../../Graphql/query";

// Privileges
import Privilages from "../../Constants/Privilages";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";

// Ui
import { LeavesBreaksModal } from "../../Components/LeavesBreaksModal";
import { LeavesBreaksCard } from "../../Components/LeavesBreaksCard";
import Loader from "../../Components/Loader";
import swal from "sweetalert";
import Pagination from "../../Components/Pagination";

// Icons
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";

// Utils
import { normalizefetchedLeavesBreaks } from "../../Helpers/HelperFns/normalizeLeavesBreaks";
import { showErrorToast } from "../../Store/Actions";
import { RadioboxForm } from "form-builder";
import useCompanyAttType from "../../Helpers/Hooks/useCompanyAttType";

const ViewLeavesBreaks = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [search, setSearch] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [leavesBreaks, setLeavesBreaks] = useState({
    data: [],
    pagination: {
      lastItem: 20,
      total: 20,
      perPage: 20,
      currentPage: 1,
      hasMorePages: true,
      firstItem: 1,
      lastPage: 4,
    },
  });

  const [applicableType, setApplicableType] = useState("all");

  const [fetchLeavesBreaks, { loading }] = useLazyQuery(leavesBreaksQuery, {
    variables: {
      name: search,
      first: leavesBreaks?.pagination?.perPage,
      page: leavesBreaks?.pagination?.currentPage,
      applicableType, // should change with fixed or flex
    },
    onCompleted: (data) => {
      setLeavesBreaks({
        data: normalizefetchedLeavesBreaks(data?.PermissionLeavesBreakSettings),
        pagination: data?.PermissionLeavesBreakSettings?.paginatorInfo,
      });
    },
    onError: (error) => {
      dispatch(showErrorToast(error?.message));
    },
  });

  useEffect(() => {
    fetchLeavesBreaks();
  }, []);

  const handleSearch = (e) => {
    setSearch(e.target.value);

    fetchLeavesBreaks({
      variables: {
        page: 1,
      },
    });
  };

  const handlePaginate = (page) => {
    fetchLeavesBreaks({
      variables: {
        page,
      },
    });
  };

  const handleChangeApplicableType = (value) => {
    setApplicableType(value);
  };

  const { hasFlex, hasFixed } = useCompanyAttType();

  return (
    <div className="leaves_breaks_view">
      <div className="d-flex align-items-center gap-20">
        <div className="input-container-style-default search_container">
          <div className="input_icon">
            <SearchIcon />
          </div>
          <input
            type="text"
            placeholder={t("search")}
            className="input-style-default search_input"
            onChange={handleSearch}
          />
        </div>

        <HasPrivileges
          reqireMain={[Privilages.ADD_EDIT_CLONE_LEAVE_BREAK_PROFILES]}
          allowBP
          avalibleOnExpire={false}
        >
          <button
            className="btn add_new_btn_style"
            onClick={() => setIsModalOpen(true)}
          >
            <AddIcon className="add_btn_icon" />
            {t("add new")}
          </button>
        </HasPrivileges>
      </div>

      <RadioboxForm
        validateBy="textRequired"
        keepDefaultStyle
        options={[
          {
            value: "all",
            label: "All",
          },
          ...(hasFixed
            ? [
                {
                  label: "fixed",
                  value: "fixed",
                },
              ]
            : []),
          ...(hasFlex ? [{ label: "flexible", value: "flexible" }] : []),
        ]}
        containerStyle="my-4"
        labelStyle="label-style font-weight-bold"
        optionItemStyle="mr-3"
        optionInputStyle=" "
        optionLabelStyle="optionLabelStyle"
        optionsContainerStyle="d-flex my-2"
        onChange={(e) => handleChangeApplicableType(e?.target?.value)}
        value={applicableType}
      />

      <div className="leaves_breaks_view_header row align-items-center">
        <div className="leaves_breaks_view_header_item col-md-2">
          <h3>{t("name")}</h3>
        </div>

        <div className="leaves_breaks_view_header_item col-md-3">
          <h3>{t("leaves")}</h3>
          <div className="sub_items row align-items-center">
            <span className="sub_items_text col-md-3">{t("annual")}</span>
            <span className="sub_items_text col-md-3">
              {t("emergency_leaves")}
            </span>
            <span className="sub_items_text sick_text col-md-3">
              {t("sick")}
            </span>
            <span className="sub_items_text col-md-3">{t("unpaid")}</span>
          </div>
        </div>

        <div className="leaves_breaks_view_header_item col-md-3">
          <h3>{t("permissions")}</h3>
          <div className="sub_items row align-items-center">
            <span className="sub_items_text col-md-4">{t("min-duration")}</span>
            <span className="sub_items_text col-md-4">{t("max-per-day")}</span>
            <span className="sub_items_text col-md-4">
              {t("max-per-month")}
            </span>
          </div>
        </div>

        <div className="leaves_breaks_view_header_item col-md-3">
          <h3>{t("breaks")}</h3>
          <div className="sub_items row align-items-center">
            <span className="sub_items_text col-md-3">
              {t("deduct from work hours")}
            </span>
            <span className="sub_items_text col-md-3">
              {t("max-sessions-per-day")}
            </span>
            <span className="sub_items_text col-md-3">
              {t("max-session-duration")}
            </span>
            <span className="sub_items_text col-md-3">
              {t("max-duration-per-day")}
            </span>
          </div>
        </div>

        <div className="col-md-1"></div>
      </div>

      <div className="leaves_breaks_view_body">
        {loading ? (
          <Loader />
        ) : (
          <>
            {leavesBreaks?.data?.map((item) => (
              <LeavesBreaksCard
                key={item?.id}
                data={item}
                setIsModalOpen={setIsModalOpen}
                fetchLeavesBreaks={fetchLeavesBreaks}
              />
            ))}
            <Pagination
              customPaginator={leavesBreaks?.pagination}
              styleWraper=" "
              onPaginate={handlePaginate}
            />
          </>
        )}
      </div>

      {isModalOpen ? (
        <LeavesBreaksModal
          setIsModalOpen={setIsModalOpen}
          fetchLeavesBreaks={fetchLeavesBreaks}
        />
      ) : null}
    </div>
  );
};

export default ViewLeavesBreaks;
