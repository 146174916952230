import gql from "graphql-tag";

import {
  PlanFragment,
  PaymentFragment,
  PackageFragment,
  paginatorFragment,
  SubscriptionFragment,
} from "../fragments";

/*
  Packages
*/

export const packagesListQuery = gql`
  query packagesList($first: Int!, $page: Int, $input: PlanFilterInput) {
    packages: plans(first: $first, page: $page, input: $input) {
      data {
        id
        name
        status
        trial_value
        activeSubscriptions: activeSubscribtions
      }
      pagination: paginatorInfo {
        ...paginator
      }
    }
  }
  ${paginatorFragment}
`;

export const packageFormQuery = gql`
  query packageForm($id: ID, $includePackage: Boolean!) {
    system_privileges
    package: plan(id: $id) @include(if: $includePackage) {
      ...package
      privileges {
        id
      }
    }
  }
  ${PackageFragment}
`;

export const packageProfileQuery = gql`
  query packageProfile($id: ID!) {
    package: plan(id: $id) {
      ...package
      status
      activeSubscriptions: activeSubscribtions
      roles {
        id
        name
      }
    }
  }
  ${PackageFragment}
`;

export const packageRolesQuery = gql`
  query packageRolesQuery($id: ID!) {
    package: plan(id: $id) {
      id
      name
      roles {
        id
        name
      }
    }
  }
`;

export const rolePackagesFormQuery = gql`
  query rolePackagesForm($packageId: ID!, $roleId: ID, $includeRole: Boolean!) {
    plan_privileges: planPrivileges(id: $packageId)
    role(id: $roleId) @include(if: $includeRole) {
      id
      name
      main: prviliege_ids
      all: prviliege_ids_with_all_employee_scope
      managed: prviliege_ids_with_managed_employee_scope
    }
  }
`;

/*
  Plans
*/

export const plansListQuery = gql`
  query plansList(
    $first: Int!
    $page: Int
    $name: String
    $duration: Int
    $period: String
    $status: String
    $package_id: Int
    $currency_id: Int
  ) {
    plans: payment_tracking_plans(
      first: $first
      page: $page
      name: $name
      status: $status
      period: $period
      duration: $duration
      package_id: $package_id
      currency_id: $currency_id
    ) {
      data {
        ...plan
      }
      pagination: paginatorInfo {
        ...paginator
      }
    }
  }
  ${PlanFragment}
  ${paginatorFragment}
`;
export const plansListOptionsQuery = gql`
  query plansListOptions {
    companies: saasCompaniesMenu {
      id
      name
    }
    currencies {
      id
      name
    }
    packages: plans(first: 9999, input: { type: "active", page_flag: "menu" }) {
      data {
        id
        name
      }
    }
  }
`;
export const planFormQuery = gql`
  query planForm($id: ID, $includePlan: Boolean! = false) {
    plan: payment_tracking_plan(id: $id) @include(if: $includePlan) {
      ...plan
    }
  }
  ${PlanFragment}
`;

/*
  Subscriptions
*/

export const subscriptionsListOptionsQuery = gql`
  query subscriptionsListOptions {
    companies: saasCompaniesMenu {
      id
      name
    }
    packages: plans(first: 9999, input: { type: "active", page_flag: "menu" }) {
      data {
        id
        name
      }
    }
    countries {
      id
      name
    }
  }
`;
export const subscriptionsListQuery = gql`
  query subscriptionsList(
    $page: Int
    $first: Int!
    $to: String
    $from: String
    $status: String
    $period: String
    $company_id: Int
    $package_id: Int
    $isSuspended: Int
  ) {
    subscriptions: payment_tracking_subscriptions(
      to: $to
      from: $from
      page: $page
      first: $first
      status: $status
      period: $period
      package_id: $package_id
      company_id: $company_id
      isSuspended: $isSuspended
    ) {
      data {
        ...subscription
        company {
          id
          name
          primarySAAS {
            user {
              name
            }
          }
        }
        payments {
          ...payment
        }
      }
      pagination: paginatorInfo {
        ...paginator
      }
    }
  }
  ${PaymentFragment}
  ${paginatorFragment}
  ${SubscriptionFragment}
`;

export const subscriptionFormQuery = gql`
  query subscriptionForm($id: ID, $isEdit: Boolean! = false) {
    subscription: payment_tracking_subscription(id: $id) @include(if: $isEdit) {
      ...subscription
      company {
        id
        name
      }
    }

    # Options
    countries {
      id
      name
    }
    companies: saasCompaniesMenu {
      id
      name
    }
    plans: payment_tracking_plans(first: 999) {
      data {
        id
        name
        companyIds
        configuration
        billingPeriod: period
        contractDuration: duration
        currency {
          name
        }
      }
    }
    agents: getCrmAgentsMenu {
      id
      name
    }
  }
  ${SubscriptionFragment}
`;

/*
  Subscription Profile
*/

export const subscriptionProfileQuery = gql`
  query subscriptionProfile($id: ID) {
    data: payment_tracking_subscription(id: $id) {
      ...subscription
      isEnded
      history
      company {
        id
        name
        credit
      }
    }
  }
  ${SubscriptionFragment}
`;

export const getPlanAvailableDatesQuery = gql`
  query getPlanAvailableDates($from: String, $companyId: Int, $period: String) {
    availableDates: payment_tracking_get_available_dates(
      from: $from
      period: $period
      companyId: $companyId
    )
  }
`;

export const getGeneratedInvoicesQuery = gql`
  query getGeneratedInvoicesDates(
    $date: String
    $from: String
    $companyId: Int
  ) {
    generatedInvoices: payment_tracking_get_generated_payments_from(
      date: $date
      from: $from
      companyId: $companyId
    ) {
      id
      from
    }
  }
`;

export const changePlanModalQuery = gql`
  query changePlanModal {
    # Options
    plans: payment_tracking_plans(first: 999) {
      data {
        id
        name
        companyIds
        configuration
        billingPeriod: period
        contractDuration: duration
        currency {
          name
        }
      }
    }
  }
`;
export const getInvoicesQuery = gql`
  query getInvoicesNum(
    $from: String
    $date: String
    $companyId: Int
    $period: String
  ) {
    invoices: payment_tracking_get_remaining_payments_for_promotions(
      from: $from
      date: $date
      period: $period
      companyId: $companyId
    )
  }
`;

export const getCanDecreaseTierQuery = gql`
  query getCanDecreaseTier($from: String, $companyId: Int) {
    canSelectDateInDecreaseTier: payment_tracking_can_decrease_limit_from(
      from: $from
      companyId: $companyId
    )
  }
`;

export const changeTiersModalQuery = gql`
  query changeTiersModal(
    $from: String
    $date: String
    $type: String
    $company_id: Int
  ) {
    # Options
    tiers: payment_tracking_tiers(
      from: $from
      date: $date
      type: $type
      company_id: $company_id
    )
  }
`;

export const endSubscriptionQuery = gql`
  query endSubscriptionModal($to: String, $from: String, $companyId: Int) {
    data: payment_tracking_end_subscription(
      to: $to
      from: $from
      companyId: $companyId
    ) {
      dueAmount
      paidAmount
      remainingAmount
      paidItems {
        name
        amount
      }
      dueItems {
        name
        amount
      }
    }
  }
`;

/*
  Payments
*/

export const paymentsListOptionsQuery = gql`
  query paymentsListOptions {
    plans: payment_tracking_plans(first: 999, isFilter: 1) {
      data {
        id
        name
      }
    }
    companies: saasCompaniesMenu {
      id
      name
    }
  }
`;
export const paymentsListQuery = gql`
  query paymentsList(
    $page: Int
    $first: Int!
    $plan_id: Int
    $status: String
    $due_to: String
    $company_id: Int
    $isFollowUp: Int
    $due_from: String
    $payments_ids: [ID]
  ) {
    payments: payment_tracking_payments(
      page: $page
      first: $first
      status: $status
      due_to: $due_to
      plan_id: $plan_id
      due_from: $due_from
      company_id: $company_id
      isFollowup: $isFollowUp
      payments_ids: $payments_ids
    ) {
      data {
        ...payment
        isFollowup
        company_id
        received_at
        file {
          id
          path
        }
        collector {
          id
          name
        }
      }
      pagination: paginatorInfo {
        ...paginator
      }
    }
  }
  ${PaymentFragment}
  ${paginatorFragment}
`;

export const editPaymentFormQuery = gql`
  query editPaymentForm($id: ID) {
    payment: payment_tracking_payment(id: $id) {
      methodId
      eInvoice
      due_date
      firstItem
      tax {
        type
        amount
        isApplied
      }
    }

    # Options
    countries {
      id
      name
    }
  }
`;

export const payInvoiceFormQuery = gql`
  query payInvoiceForm($id: ID) {
    payment: payment_tracking_payment(id: $id) {
      amount
      methodId
      receivedAmount
      received_at
      file {
        id
        path
      }
      collector {
        id
        name
      }
    }

    # Options
    users: payment_tracking_saas {
      id
      name
    }
  }
`;

export const paymentsInvoiceDetailsQuery = gql`
  query paymentsInvoiceDetails($id: ID) {
    payment: payment_tracking_payment(id: $id) {
      invoiceInfo
      invoice
      status
      tax {
        amount
      }
    }
  }
`;

export const exportInvoiceQuery = gql`
  query exportInvoice($id: ID) {
    export_invoice(id: $id) {
      file
      status
      message
    }
  }
`;

/*
  Payment Methods
*/

export const paymentMethodsListQuery = gql`
  query paymentMethodsList($first: Int!, $page: Int) {
    paymentMethods: payment_tracking_payment_methods(
      page: $page
      first: $first
    ) {
      data {
        id
        name
        details
      }
      pagination: paginatorInfo {
        ...paginator
      }
    }
  }
  ${paginatorFragment}
`;

export const paymentMethodsMenu = gql`
  query paymentMethodsMenu {
    paymentMethods: payment_tracking_payment_methods(first: 999) {
      data {
        id
        name
      }
    }
  }
`;

/*
  Reports
*/

export const overviewReportQuery = gql`
  query overviewReport($input: filterCharts) {
    # Options
    currencies {
      id
      name
    }
    countries {
      id
      name
    }

    # Data
    overViewCharts(input: $input) {
      generalInfo {
        activeCount
        canceledCount
        totalOutstandingPaymentsAmount
      }
      totalActiveSubscriptionsOverTime {
        monthly {
          year
          month_year
          users_count
          subscriptions_count
        }
        quarterly {
          year
          quarter
          users_count
          subscriptions_count
        }
        yearly {
          year
          users_count
          subscriptions_count
        }
      }
      totalClientsPerPackage {
        monthly {
          month_year
          plans {
            plan_name
            users_count
            subscriptions_count
          }
        }
        quarterly {
          year
          quarter
          plans {
            plan_name
            users_count
            subscriptions_count
          }
        }
        yearly {
          year
          plans {
            plan_name
            users_count
            subscriptions_count
          }
        }
      }
      churnRate {
        monthly {
          month_year
          users_churn_rate
          clients_churn_rate
          cancelled_companies_ids
          active_subscriptions_count
          cancelled_subscriptions_count
          active_subscriptions_users_count
          cancelled_subscriptions_users_count
        }
        quarterly {
          year
          quarter
          users_churn_rate
          clients_churn_rate
          cancelled_companies_ids
          active_subscriptions_count
          cancelled_subscriptions_count
          active_subscriptions_users_count
          cancelled_subscriptions_users_count
        }
        yearly {
          year
          users_churn_rate
          clients_churn_rate
          cancelled_companies_ids
          active_subscriptions_count
          cancelled_subscriptions_count
          active_subscriptions_users_count
          cancelled_subscriptions_users_count
        }
      }
      totalRevenueOverTime: totalRevenuOverTime {
        monthly {
          year
          month
          amount
          month_year
        }
        quarterly {
          year
          amount
          quarter
        }
        yearly {
          year
          amount
        }
      }
      topTenClientsByRevenue: topTenClientsByRevenu {
        company_name
        total_amount
        package_name: package
      }
      topTenClientsByUserLimit {
        user_limit
        company_name
        package_name
      }
      outStandingPayments {
        period
        amount
        percentage
        currency_id
        payments_ids
      }
    }
  }
`;

export const revenueReportQuery = gql`
  query revenueReport($input: filterCharts) {
    # Options
    currencies {
      id
      name
    }
    countries {
      id
      name
    }

    # Data
    revenueCharts: revenusCharts(input: $input) {
      totalRevenueOverTime: totalRevenuOverTime {
        monthly {
          year
          month
          amount
          month_year
        }
        quarterly {
          year
          amount
          quarter
        }
        yearly {
          year
          amount
        }
      }
      mrrChartData {
        monthly {
          year
          month
          amount
          month_year
        }
      }
      arrChartData {
        monthly {
          year
          month
          amount
          month_year
        }
      }
      totalRevenuePerPackage: totalRevenuPerPackage {
        monthly {
          month_year
          packages {
            amount
            package_name
          }
        }
        quarterly {
          year
          quarter
          packages {
            amount
            package_name
          }
        }
        yearly {
          year
          packages {
            amount
            package_name
          }
        }
      }
      growthRateOverTime {
        month_year
        mrr_amount
        currency_id
        growth_rate
      }
    }
  }
`;

export const paymentReportQuery = gql`
  query paymentReport($input: filterCharts) {
    # Options
    currencies {
      id
      name
    }
    countries {
      id
      name
    }

    # Data
    paymentsCharts(input: $input) {
      receivedAndPendingPayments {
        monthly {
          paid_amount
          payment_month
          pending_amount
        }
        quarterly {
          year
          quarter
          paid_amount
          pending_amount
        }
        yearly {
          year
          paid_amount
          pending_amount
        }
      }
    }
  }
`;

export const subscriptionsReportQuery = gql`
  query subscriptionsReport($input: filterCharts) {
    # Options
    currencies {
      id
      name
    }
    countries {
      id
      name
    }

    # Data
    subscriptionsCharts(input: $input) {
      totalActiveSubscriptionsOverTime {
        monthly {
          year
          month_year
          users_count
          subscriptions_count
        }
        quarterly {
          year
          quarter
          users_count
          subscriptions_count
        }
        yearly {
          year
          users_count
          subscriptions_count
        }
      }
      totalClientsPerPackage {
        monthly {
          month_year
          plans {
            plan_name
            users_count
            subscriptions_count
          }
        }
        quarterly {
          year
          quarter
          plans {
            plan_name
            users_count
            subscriptions_count
          }
        }
        yearly {
          year
          plans {
            plan_name
            users_count
            subscriptions_count
          }
        }
      }
      newVsCancelledSubscriptions {
        monthly {
          month_year
          new_users_count
          new_subscriptions
          cancelled_users_count
          cancelled_subscriptions
        }
        quarterly {
          year
          quarter
          new_users_count
          new_subscriptions
          cancelled_users_count
          cancelled_subscriptions
        }
        yearly {
          year
          new_users_count
          new_subscriptions
          cancelled_users_count
          cancelled_subscriptions
        }
      }
    }
  }
`;

export const churnReportQuery = gql`
  query subscriptionsReport($input: filterCharts) {
    # Options
    currencies {
      id
      name
    }
    countries {
      id
      name
    }

    # Data
    churnCharts(input: $input) {
      churnRate {
        monthly {
          month_year
          users_churn_rate
          clients_churn_rate
          cancelled_companies_ids
          active_subscriptions_count
          cancelled_subscriptions_count
          active_subscriptions_users_count
          cancelled_subscriptions_users_count
        }
        quarterly {
          year
          quarter
          users_churn_rate
          clients_churn_rate
          cancelled_companies_ids
          active_subscriptions_count
          cancelled_subscriptions_count
          active_subscriptions_users_count
          cancelled_subscriptions_users_count
        }
        yearly {
          year
          users_churn_rate
          clients_churn_rate
          cancelled_companies_ids
          active_subscriptions_count
          cancelled_subscriptions_count
          active_subscriptions_users_count
          cancelled_subscriptions_users_count
        }
      }
    }
  }
`;
