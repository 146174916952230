import React from "react";
import { useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Pagination from "../../Components/Pagination";
import HrEmployeeCard from "../../Components/HrEmployeeCard";

const HrEmployees = (props) => {
  // Reducer State
  const employees = useSelector((state) => state.super?.allEmployeesList?.data);

  return (
    <>
      <Box
        sx={{
          gap: 1,
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(365px, 1fr))",
        }}
      >
        {employees?.map((employee) => (
          <HrEmployeeCard
            key={employee?.id}
            openSignOnBehalfModal={props?.openSignOnBehalfModal}
            {...employee}
          />
        ))}
      </Box>
      <Pagination
        styleWraper=""
        tableRef={props?.dataTableRef}
        onPaginate={props?.handlePaginate}
      />
    </>
  );
};

export default HrEmployees;
