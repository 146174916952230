import React, { useState } from "react";
import { connect } from "react-redux";

import {
  VisibilityOff,
  Visibility,
  VpnKey,
  EmailOutlined,
} from "@mui/icons-material";
import { IconButton, InputAdornment, OutlinedInput } from "@mui/material";

import {
  resetPasswordAttempt,
  updateValueAction,
  logoutAction,
} from "../../Store/Actions";
import { Spinner } from "reactstrap";
import LoginLayout from "../../Components/LoginLayout";
import { useParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import {
  ADD_TEMPORARY_PASSWORD,
  ADD_WASSAGE_PASSWORD,
} from "../../Graphql/mutation";
import { useDispatch } from "react-redux";
import { showToast } from "../../Helpers/HelperFns";
import { useHistory } from "react-router-dom";
import { ColorButton } from "../../Components/Buttons";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ResetPasswordPage = (props) => {
  const { t } = useTranslation();
  // Data from sent email
  const { token } = useParams();
  let query = useQuery();

  const email = props?.updateTempPassword
    ? props?.user?.email || props?.user?.phone
    : query.get("email") || query.get("phone");

  // to handle show/hide password btn
  const [showPasswordState, setShowPasswordState] = useState({
    newPassword: false,
    confirmPassword: false,
  });

  // password inputs value
  const [passwordValue, setPasswordValue] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  // password confirm validation message
  const [validationMsg, setValidationMsg] = useState("");

  const handleToggleShowPassword = (type) => {
    setShowPasswordState((state) => ({
      ...state,
      [type]: !state[type],
    }));
  };

  const handlePasswordInput = (e, type) => {
    setValidationMsg("");
    setPasswordValue((state) => ({
      ...state,
      [type]: e.target.value,
    }));
  };

  const dispatch = useDispatch();

  const [attemptSetTemporaryPassword, { loading }] = useMutation(
    ADD_TEMPORARY_PASSWORD,
    {
      onCompleted: (data) => {
        const { status, message } = data?.addTemporaryPassword ?? {};
        if (status == "success") {
          dispatch(
            updateValueAction("userProfile", "has_temporary_password", false)
          );
          showToast("success", message);
        } else {
          showToast("error", message);
        }
      },
      onError: (err) => {
        showToast(
          "error",
          err?.graphQLErrors[0]?.extensions?.reason ||
            err?.graphQLErrors[0]?.message ||
            err?.message
        );
      },
    }
  );

  const history = useHistory();
  const [SetWassagePassword, { loading: wassageLoading }] = useMutation(
    ADD_WASSAGE_PASSWORD,
    {
      onCompleted: (data) => {
        const { status, message } =
          data?.updateForgottenPasswordForPlatform ?? {};
        if (status == "success") {
          showToast("success", message);
          history.push("/");
        } else {
          showToast("error", message);
        }
      },
      onError: (err) => {
        if (err?.graphQLErrors?.[0]?.extensions?.validation) {
          setValidationMsg(
            err?.graphQLErrors?.[0].extensions?.validation?.["input.password"]
          );
          return;
        }

        showToast(
          "error",
          err?.graphQLErrors[0]?.extensions?.reason ||
            err?.graphQLErrors[0]?.message ||
            err?.message
        );
      },
    }
  );

  const handleSubmitBtn = (e) => {
    e.preventDefault();

    // Handle Empty password
    if (!passwordValue.newPassword || !passwordValue.confirmPassword) {
      return setValidationMsg("Please Enter your password");
    }
    // Handle Not matching password
    if (passwordValue.newPassword !== passwordValue.confirmPassword) {
      return setValidationMsg("Please make sure your passwords match");
    }

    if (props?.updateTempPassword) {
      attemptSetTemporaryPassword({
        variables: {
          userId: +props?.user?.id,
          password: passwordValue?.newPassword,
        },
      });
    }
    if (props?.wassagePlatform) {
      SetWassagePassword({
        variables: {
          input: {
            phone: email,
            token: token.trim(),
            password: passwordValue.newPassword,
            password_confirmation: passwordValue.confirmPassword,
          },
        },
      });
    } else {
      props.resetPasswordAttempt({
        email: email,
        token: token.trim(),
        password: passwordValue.newPassword,
        password_confirmation: passwordValue.confirmPassword,
      });
    }
  };

  const handleLogout = () => {
    dispatch(logoutAction());
    dispatch({
      type: "REMOVE_ACCOUNT",
      payload: props?.user?.id,
    });
  };

  return (
    <LoginLayout>
      <form method="POST" onSubmit={handleSubmitBtn}>
        <h4 className="text-capitalize">{t("reset password")}</h4>
        <div className="d-flex flex-column login_input_wrapper_style">
          <label>
            {t("email")} / {t("phone")}
          </label>
          <OutlinedInput
            type="text"
            value={email}
            disabled
            classes={{
              root: "custom_outlined_input_style",
            }}
          />
        </div>

        <div className="my-2 d-flex flex-column login_input_wrapper_style">
          <label>{t("new password")}</label>
          <OutlinedInput
            type={showPasswordState.newPassword ? "text" : "password"}
            value={passwordValue.newPassword}
            onChange={(e) => handlePasswordInput(e, "newPassword")}
            placeholder={t("new password")}
            classes={{
              root: "custom_outlined_input_style",
            }}
            startAdornment={
              <InputAdornment position="start">
                <IconButton>
                  <VpnKey />
                </IconButton>
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => handleToggleShowPassword("newPassword")}
                  // onMouseDown={handleMouseDownPassword}
                >
                  {showPasswordState.newPassword ? (
                    <Visibility />
                  ) : (
                    <VisibilityOff />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
        </div>

        <div className="my-2 d-flex flex-column login_input_wrapper_style">
          <label>{t("confirm new password")}</label>
          <OutlinedInput
            type={showPasswordState.confirmPassword ? "text" : "password"}
            value={passwordValue.confirmPassword}
            onChange={(e) => handlePasswordInput(e, "confirmPassword")}
            placeholder={t("confirm new password")}
            classes={{
              root: "custom_outlined_input_style",
            }}
            startAdornment={
              <InputAdornment position="start">
                <IconButton>
                  <VpnKey />
                </IconButton>
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => handleToggleShowPassword("confirmPassword")}
                  // onMouseDown={handleMouseDownPassword}
                >
                  {showPasswordState.confirmPassword ? (
                    <Visibility />
                  ) : (
                    <VisibilityOff />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
        </div>

        {validationMsg && (
          <div className="warnig-msg-style mt-2">{validationMsg}</div>
        )}

        <div className="d-flex mt-4 pt-2">
          <button
            className="btn btn-block login_btn_style mt-0"
            onClick={handleSubmitBtn}
            type="submit"
            disabled={props.resetPasswordLoading || loading || wassageLoading}
          >
            {props.resetPasswordLoading || loading || wassageLoading ? (
              <Spinner
                style={{ width: "1rem", height: "1rem" }}
                size="sm"
                color="#fff"
              />
            ) : (
              t("submit")
            )}
          </button>
        </div>

        {props?.updateTempPassword ? (
          <ColorButton
            color="#ff6a6a"
            label="log out"
            onClick={handleLogout}
            sx={{ width: 1, mt: 1, height: 37 }}
          />
        ) : null}
      </form>
    </LoginLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    resetPasswordLoading: state.user.resetPasswordLoading,
  };
};

export default connect(mapStateToProps, {
  resetPasswordAttempt,
})(ResetPasswordPage);
