import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  onFormResetAction,
  updateValueAction,
} from "../../../../../Store/Actions";
import { useMutation } from "@apollo/client";
import { DELETE_COMPANY_ACTIVITIES_REMENIDERS_ATTACHMENTS } from "../../../../../Graphql/mutation";
import { showToast } from "../../../../../Helpers/HelperFns";
import { BSelect, DateTimePickerForm } from "form-builder";
import HasPrivileges from "../../../../../Helpers/HOC/HasPrivileges";
import Privileges from "../../../../../Constants/Privilages";
import { EditIconButton } from "../../../../IconButtonWithTooltip";
import { AddButton } from "../../../../Buttons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Box } from "@mui/material";
import Dropzone from "react-dropzone-uploader";
import InputForm from "../../../../../Builder/Form/InputForm";
import ContactForm from "./ContactForm";

const activityTypeOptions = [
  { name: "Call", id: "Call" },
  { name: "Message", id: "Message" },
  { name: "Email", id: "Email" },
  { name: "Meeting", id: "Meeting" },
];

const CompanyActivityForm = ({ formSubmitting, hasReminder }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const formProps = {
    formName: "comapnyActivityForm",
    formNameValidation: "comapnyActivityFormValidation",
    formServerValidation: "comapnyActivityFormServerValidation",
    formSubmitting,
  };

  const companyActivityInput = useSelector(
    (state) => state?.super?.comapnyActivityForm
  );

  const contactOptionsLoading = useSelector(
    (state) => state?.super?.contactOptionsLoading
  );

  const companyID = useSelector(
    (state) => state.super.selectedCompanyProfile?.id
  );

  const contactList = useSelector((state) => state?.super?.companyContactOpt);

  const isEdit = Boolean(companyActivityInput?.id);

  const [showContactModal, setShowContactModal] = useState(false);

  const handleAddContact = () => {
    setShowContactModal(true);
  };

  const handleEditContactModal = () => {
    let selectedContact = contactList?.find((contact) => {
      return contact?.id === companyActivityInput?.contact_id;
    });
    dispatch({
      type: "UPDATE_ALL_FORM_DATA",
      payload: {
        formName: "addContactForm",
        formData: {
          id: selectedContact.id,
          name: selectedContact.name,
          company_id: companyID,
          email: selectedContact.email,
          position: selectedContact.position,
          phone: selectedContact.phone,
          phone_country_code: selectedContact.phone_country_code,
          description: selectedContact.description,
        },
      },
    });
    setShowContactModal(true);
  };

  const closeContactForm = () => {
    setShowContactModal(false);
  };

  const handleAttachmentChange = ({ file }, status) => {
    if (status === "done") {
      dispatch(
        updateValueAction(formProps.formName, "attachments", [
          ...companyActivityInput?.attachments,
          file,
        ])
      );
    }
    if (status === "removed") {
      dispatch(
        updateValueAction(
          formProps.formName,
          "attachments",
          companyActivityInput?.attachments.filter(
            (item) => item?.name !== file?.name
          )
        )
      );
    }
  };

  const [deleteAttachments, { loading: deletAttLoading }] = useMutation(
    DELETE_COMPANY_ACTIVITIES_REMENIDERS_ATTACHMENTS
  );

  const handleDeleteImage = (id, path) => {
    // delete image mutation
    if (deletAttLoading) return;
    deleteAttachments({
      variables: {
        id,
        path,
      },
      onCompleted: (res) => {
        if (res?.delete_attachments?.status === "success") {
          dispatch(
            updateValueAction(
              formProps.formName,
              "savedAttachments",
              companyActivityInput?.savedAttachments.filter(
                (att) => att.id !== id
              )
            )
          );
        }
      },
      onError: (err) => {
        showToast(
          "error",
          err?.graphQLErrors?.[0]?.extensions?.reason ?? err?.message
        );
      },
    });
  };

  return (
    <>
      <div className="d-flex flex-column gap-10">
        <BSelect
          {...formProps}
          label="activity type"
          name="type"
          icon="employee"
          keepDefaultStyle
          labelStyle="w-100"
          options={activityTypeOptions}
          validateBy="textRequired"
          isDisabled={deletAttLoading}
        />

        <div>
          <div className="d-flex align-items-center gap-20 font-weight-bold">
            {t("contact")}
            <HasPrivileges reqireMain={[Privileges.ADD_EDIT_COMPANY_CONTACTS]}>
              <AddButton
                isIconOnly
                disabled={deletAttLoading}
                onClick={handleAddContact}
              />
              <EditIconButton
                onClick={handleEditContactModal}
                disabled={
                  !Boolean(companyActivityInput.contact_id) || deletAttLoading
                }
                tooltipProps={{
                  isDisable: !Boolean(companyActivityInput.contact_id),
                }}
              />
            </HasPrivileges>
          </div>

          <BSelect
            {...formProps}
            name="contact_id"
            icon="employees"
            keepDefaultStyle
            options={contactList || []}
            validateBy="textRequired"
            isLoading={contactOptionsLoading}
            isDisabled={deletAttLoading}
          />
        </div>

        <DateTimePickerForm
          {...formProps}
          hasIcon
          dateTime
          label="date"
          labelStyle="w-100"
          datePickerContainer="w-100"
          validateBy="textRequired"
          validationName="input.date"
          isDisabled={deletAttLoading}
          name={"date"}
          requestFormat="YYYY-MM-DD HH:mm:ss"
        />

        <InputForm
          {...formProps}
          label="notes"
          name="notes"
          validationName="input.notes"
          containerStyle=""
          inputContainerStyle="w-100"
          type="text"
          placeholder={t("notes")}
          labelStyle="name_label"
          isMultiLines
        />

        <span>{t("Attachments")}</span>

        {isEdit ? (
          <div className="d-flex flex-wrap m-2">
            {companyActivityInput?.savedAttachments?.map((attachment) => (
              <div className="d-flex flex-column w-25 border p-2 m-1 justify-content-center align-items-center">
                <div
                  className="d-flex w-100 justify-content-end"
                  style={{ cursor: "pointer" }}
                >
                  <FontAwesomeIcon
                    icon={faTimes}
                    onClick={() =>
                      handleDeleteImage(attachment?.id, attachment?.file?.path)
                    }
                  />
                </div>
                <img
                  src={attachment?.file?.path}
                  style={{ width: 50, height: 50, objectFit: "cover" }}
                />
                <div className="text-truncate w-100">
                  <a target="_blank" href={attachment?.file?.path}>
                    {attachment?.name}
                  </a>
                </div>
              </div>
            ))}
          </div>
        ) : null}

        <Box
          className="documents_dropzone_style"
          sx={{
            ".dzu-dropzone .dzu-previewContainer": {
              height: 60,
              justifyContent: "center",
              minHeight: "initial !important",
              ".dzu-previewStatusContainer": { position: "static" },
            },
          }}
        >
          <Dropzone
            onChangeStatus={handleAttachmentChange}
            disabled={deletAttLoading}
          />
        </Box>
      </div>

      {showContactModal ? (
        <ContactForm
          closeContactForm={closeContactForm}
          isModalVissible={showContactModal}
        />
      ) : null}
    </>
  );
};

export default CompanyActivityForm;
