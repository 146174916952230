import React, { useEffect, useState } from "react";
import MainModal from "../../Components/MainModal";
import {
  BSelect,
  InputForm,
  DateTimePickerForm,
  RadioboxForm,
} from "form-builder";
import { connect, useDispatch } from "react-redux";
import {
  dismissAddNewDeductionsModalAction,
  upsertDeductionAction,
  onInputChangeAction,
  showErrorToast,
} from "../../Store/Actions";
import Const from "../../Constants";
import { useTranslation } from "react-i18next";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import { UPSERT_DEDUCTION_POLICY_MUTATION } from "../../Graphql/mutation/Finanical";

const formName = "AddNewDeductionPolicyModal";
const formNameValidation = "AddNewDeductionPolicyModalValidation";
const formServerValidation = "AddNewDeductionPolicyModalServerValidation";

const DeductionsPolicyModal = (props) => {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const dispatch = useDispatch();
  const [upsertDeductionPolicy, { loading, data, error }] = useMutation(
    UPSERT_DEDUCTION_POLICY_MUTATION,
    {
      onCompleted(res) {
        // do someting
        if (
          res?.store_deduction_policy?.id ||
          res?.update_deduction_policy?.id
        ) {
          props.toggle();
          props.refetch();
        }
      },
      onError(error) {
        // do something
        if (error) {
          if (error?.graphQLErrors?.[0]?.extensions.validation) {
            showErrorToast(
              Object.values(
                error?.graphQLErrors?.[0]?.extensions.validation
              )?.[0]?.toString()
            );
            return;
          }
          dispatch(
            showErrorToast(
              error?.graphQLErrors?.[0]?.extensions?.reason ||
                error?.graphQLErrors?.[0]?.message ||
                error?.message
            )
          );
        }
      },
    }
  );

  const [formData, setFormData] = useState({
    name: "",
    id: null,
    violations: [
      {
        type: "",
        amount: "",
      },
    ],
  });

  const toggleModal = () => {
    props.toggle();
  };

  useEffect(() => {
    setIsSubmitting(false);
    if (props?.modalData?.isOpen && props?.modalData?.data) {
      setFormData(props?.modalData?.data);
    }
  }, [props?.modalData?.isOpen, props?.modalData?.data]);

  const handleSubmitBtn = () => {
    setIsSubmitting(true);
    upsertDeductionPolicy({
      variables: {
        name: formData.name,
        violations: formData?.violations?.map((violation) => ({
          type: violation?.type,
          amount: violation?.amount?.toString(),
        })),
        id: formData?.id,
        isEdit: Boolean(formData?.id),
      },
    });
  };

  const handleAddViolation = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      violations: [
        ...prevState.violations,
        {
          type: "",
          amount: 0,
        },
      ],
    }));
  };

  const handleRemoveViolation = (key) => {
    setFormData((prevState) => ({
      ...prevState,
      violations: _.filter(
        prevState.violations,
        (violation, index) => index !== key
      ),
    }));
  };

  const handleViolationTypeChange = (val, e, key) => {
    setFormData((prevState) => ({
      ...prevState,
      violations: _.map(prevState.violations, (violation, index) =>
        index == key ? { ...violation, [e.name]: val.value } : violation
      ),
    }));
  };

  return (
    <MainModal
      isOpen={props?.modalData?.isOpen}
      btnLabelCancel="Cancel"
      btnOnCancelClick={toggleModal}
      modalTitle={
        props?.modalData?.data?.id != null
          ? t("Edit Deduction Policy")
          : t("Add New Deduction Policy")
      }
      btnOnClick={handleSubmitBtn}
      btnSubmitLoading={loading}
      className="add_deduction_modal_wrapper_style"
    >
      <div className="add_violations_wrapper_style">
        <InputForm
          label="name"
          name="name"
          placeholder={t("name")}
          formName={formName}
          formNameValidation={formNameValidation}
          validationName={`input.name`}
          formServerValidation={formServerValidation}
          formSubmitting={isSubmitting}
          labelStyle="mb-2"
          containerStyle=""
          inputContainerStyle="w-100"
          validateBy="textRequired"
          value={formData.name}
          onChange={(e) =>
            setFormData((prevState) => ({
              ...prevState,
              name: e.target?.value,
            }))
          }
          // icon=""
        />

        <div className="d-flex align-items-baseline mt-3">
          <div className="mr-3 mb-0">
            <h5>
              <b>{t("Violations")} </b>
            </h5>
          </div>

          <div className="">
            <button
              className="btn add_new_btn_style"
              type="button"
              onClick={handleAddViolation}
            >
              <FontAwesomeIcon icon={faPlus} />
            </button>
          </div>
        </div>

        {formData?.violations?.map((violation, key) => (
          <>
            <h6 className="mt-2 sub-title-style">
              {t("Violation", {
                suffix:
                  key == 0
                    ? "1st"
                    : key == 1
                      ? "2nd"
                      : key == 2
                        ? "3rd"
                        : `${key + 1}th`,
                order: key + 1,
              })}
            </h6>
            <div key={key} className="row align-items-end">
              <div className="col-12 col-md mb-2 mb-lg-0">
                <BSelect
                  label="type"
                  formName={formName}
                  formNameValidation={formNameValidation}
                  formSubmitting={isSubmitting}
                  validationName={`input.type`}
                  formServerValidation={formServerValidation}
                  name="type"
                  optionLabel="label"
                  optionValue="value"
                  options={Const.DeductionPolicyTypes}
                  keepDefaultStyle
                  containerStyle=""
                  validateBy="textRequired"
                  icon="type"
                  bValue={violation?.type}
                  onChange={(val, e) => handleViolationTypeChange(val, e, key)}
                />
              </div>
              <div className="col-12 col-md">
                <InputForm
                  type="number"
                  name="amount"
                  label="value"
                  placeholder={t("value")}
                  formName={formName}
                  formNameValidation={formNameValidation}
                  formSubmitting={isSubmitting}
                  validationName={`input.value`}
                  formServerValidation={formServerValidation}
                  labelStyle="mb-2"
                  containerStyle=""
                  inputContainerStyle="w-100"
                  validateBy="textRequired"
                  value={violation.amount}
                  onChange={({ target }) =>
                    handleViolationTypeChange(target, target, key)
                  }
                />
              </div>
              {formData?.violations?.length > 1 && (
                <div className="text-intial col-md-1">
                  <button
                    className="btn delete_btn_style"
                    type="button"
                    onClick={() => handleRemoveViolation(key)}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </div>
              )}
            </div>
          </>
        ))}

        {/* (Start) Error Message */}
        {/* {modalMessage  && (
          <div className="warnig-msg-style">{modalMessage}</div>
        )} */}
        {/* (End) Error Message */}
      </div>
    </MainModal>
  );
};

export default DeductionsPolicyModal;
