import gql from "graphql-tag";

/*
  Attendance History
*/

/*
  Penalties
*/

/*
  Assignments
*/

export const upsertAssignmentMutation = gql`
  mutation upsertAssignment($input: UpdateOrCreateAssignmentInput) {
    updateOrCreateAssignment(input: $input) {
      ... on Assignment {
        id
        name
        from
        to
        __typename
      }
      ... on GeneralException {
        message
        __typename
      }
    }
  }
`;

export const upsertFlexAssignmentMutation = gql`
  mutation upsertFlexAssignment($input: UpdateOrCreateFlexAssignmentInput) {
    updateOrCreateFlexAssignment(input: $input) {
      ... on FlexAssignment {
        id
        name
        from
        to
        __typename
      }
      ... on GeneralException {
        message
        __typename
      }
    }
  }
`;

export const deleteFlexAssignmentMutation = gql`
  mutation deleteFlexAssignment($id: ID!, $force_delete: Boolean) {
    deleteFlexAssignment(id: $id, force_delete: $force_delete) {
      status
      message
      __typename
    }
  }
`;
export const deleteAssignmentMutation = gql`
  mutation deleteAssignment($id: ID!, $force_delete: Boolean) {
    deleteAssignment(id: $id, force_delete: $force_delete) {
      status
      message
      __typename
    }
  }
`;

/*
  Monthly Report
*/

/*
  Yearly Report
*/

/*
  Facial Report
*/
