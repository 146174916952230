export const dateFormat = "YYYY-MM-DD";
export const dateTimeFormat = "YYYY-MM-D hh:mm:ss";
export const dateTimeListFormat = "DD-MM-YYYY hh:mm A";

export const leadSourcesOptions = [
  "Facebook",
  "Linkedin",
  "Referral",
  "Others",
];

export const leadStatusOptions = [
  "Interested",
  "Unreachable",
  "Not Interested",
  "Follow-Up",
  "Reviewing Proposal",
  "Trial",
  "Client",
  "Recycled",
  "Demo",
  "Change Status",
];

export const subStatusOptions = {
  Interested: ["Highly Interested", "Semi-Interested", "Low interest"],
  Unreachable: [
    "First Call",
    "Second Call",
    "Third Call",
    "Fourth Call",
    "Fifth Call",
    "Sixth Call",
    "Details Sent",
  ],
  "Not Interested": [
    "Misunderstood",
    "Issues with pricing",
    "Client is using another system",
    "System is not fulfilling client requirements",
    "Others",
  ],
  "Follow-Up": ["Requested By Client", "Busy at the moment"],
  "Reviewing Proposal": [
    "In Progress",
    "Accepted Proposal",
    "Rejected Proposal",
  ],
};

export const activityTypeOptions = [
  "Call",
  "Message",
  "Email",
  "Meeting",
  "Demo",
  "Proposal",
];

export const crmYearsOptions = (() => {
  const currentYear = new Date().getFullYear();
  const arr = [];
  for (let i = 2023; i <= currentYear; i++) {
    arr.push({ id: i, name: i });
  }
  return arr;
})();

export const quartersOptions = [
  { id: 1, name: "Q1" },
  { id: 2, name: "Q2" },
  { id: 3, name: "Q3" },
  { id: 4, name: "Q4" },
];

export const conditionalFooterRowStyles = [
  {
    when: (row) => row.name === "Total",
    style: {
      fontSize: "14px",
      color: "white !important",
      backgroundColor: "#17a2b8",
      "*": {
        fontWeight: 700,
        fontStyle: "italic",
      },
      "&:hover": {
        backgroundColor: "#17a2b8 !important",
      },
    },
  },
];

export const CRM_PRIVILEGES = {
  ACCESS_CRM: "213",

  // Lead
  VIEW_LEADS: "214",
  VIEW_OTHER_LEADS: "215",
  VIEW_UNASSIGNED_LEADS: "216",
  ASSIGN_LEADS: "217",
  ASSIGN_LEADS_TO_OTHER_AGENTS: "218",
  ADD_EDIT_LEADS: "219",
  DELETE_LEADS: "220",
  CAN_BE_ASSIGNED_LEADS: "221",
  VIEW_LEAD_PROFILE: "243",
  VIEW_LEAD_CONTACT: "244",
  VIEW_COMMENTS_ON_LEADS: "301",
  COMMENT_ON_LEADS: "302",

  // Activity
  VIEW_ACTIVITIES: "222",
  VIEW_ACTIVITIES_OF_ASSIGNED_LEAD: "223",
  VIEW_ACTIVITIES_OF_OTHER_AGENTS: "224",
  ADD_EDIT_ACTIVITIES: "225",
  DELETE_ACTIVITIES: "226",
  VIEW_COMMENTS_ON_ACTIVITIES: "227",
  COMMENT_ON_ACTIVITIES: "228",

  // Reminder
  VIEW_REMINDERS: "229",
  VIEW_REMINDERS_OF_ASSIGNED_LEADS: "230",
  ADD_EDIT_REMINDERS: "231",
  DELETE_REMINDERS: "232",
  SET_REMINDERS_AS_COMPLETED: "233",
  VIEW_COMMENTS_ON_REMINDERS: "234",
  COMMENT_ON_REMINDERS: "235",

  // Trial
  VIEW_TRIALS: "236",
  VIEW_TRIALS_OF_ASSIGNED_LEADS: "237",
  ADD_EDIT_TRIALS: "238",
  DELETE_TRIALS: "239",

  // Subscription Request
  VIEW_SUBSCRIPTION_REQUESTS: "380",

  // Meetings
  VIEW_MEETINGS: "363",
  ADD_EDIT_MEETING: "364",

  // Others
  VIEW_CRM_CALENDAR: "240",
  VIEW_CRM_DASHBOARD: "241",
  VIEW_CRM_REPORTS: "242",
  VIEW_OTHER_CRM_REPORTS: "326",
  VIEW_CRM_AGENTS: "314",
  VIEW_FOODICS_LEADS: "328",
};

export const crmSidebarPrivileges = [
  CRM_PRIVILEGES.VIEW_CRM_REPORTS,
  // CRM_PRIVILEGES.VIEW_CRM_DASHBOARD,
  CRM_PRIVILEGES.VIEW_CRM_CALENDAR,
  CRM_PRIVILEGES.VIEW_LEADS,
  CRM_PRIVILEGES.VIEW_ACTIVITIES,
  CRM_PRIVILEGES.VIEW_REMINDERS,
  CRM_PRIVILEGES.VIEW_TRIALS,
  CRM_PRIVILEGES.VIEW_SUBSCRIPTION_REQUESTS,
];
