import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  fetchMyLoansListSuccess,
  onInputResetAction,
  showErrorToast,
} from "../../Store/Actions";
import { useLazyQuery } from "@apollo/client";
import { myLoansQuery } from "../../Graphql/query";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";
import Constants from "../../Helpers/Constants";
import LoanCard from "../../Components/LoanCard";
import { RadioboxForm } from "form-builder";
import Loader from "../../Components/Loader";
import Pagination from "../../Components/Pagination";
import swal from "sweetalert";

const MyLoans = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // states from reducer
  const loansListStates = useSelector((state) => state?.user?.myloansList);
  const userProfileData = useSelector((state) => state.user.userProfile);

  const [requestsStatus, setRequestsStatus] = useState({
    label: "accepted",
    value: 1,
  });

  // handle fetch query data
  const [
    handleFetchLoansData,
    { loading: isFetchingLoansLoading, error, data },
  ] = useLazyQuery(myLoansQuery, {
    variables: {
      input: {
        status: loansListStates?.status,
        user_id: userProfileData?.id,
      },
      requestsInput: {
        status: requestsStatus.value,
      },
      rows: loansListStates.pagination?.perPage,
      page: loansListStates.pagination?.currentPage,
    },
    onError(err) {
      dispatch(
        showErrorToast(
          err?.graphQLErrors[0]?.extensions?.reason ||
            err?.graphQLErrors[0]?.message
        )
      );
    },
    onCompleted: (data) => {
      // to fetch my loans list data
      dispatch(
        fetchMyLoansListSuccess("myloansList", {
          data: [...data?.getMyLoans.data, ...data.myLoanRequests.data],
          pagination: data.myLoanRequests.paginatorInfo,
        })
      );
    },
  });

  useEffect(() => {
    handleFetchLoansData();
    return () => {
      dispatch(onInputResetAction("myloansList", "status"));
    };
  }, []);

  // handle filter for list
  const handleFilter = () => {
    handleFetchLoansData({
      variables: {
        input: {
          user_id: userProfileData?.id,
        },
        page: 1,
      },
    });
  };

  const useDidFilterUpdate = useDidUpdateEffect(handleFilter, [
    loansListStates?.status,
    requestsStatus,
  ]);

  // handle pagination for list
  const handlePaginate = (page = loansListStates?.pagination.currentPage) => {
    handleFetchLoansData({
      variables: {
        input: {
          user_id: userProfileData?.id,
        },
        page: page,
        rows: loansListStates.pagination?.perPage,
      },
    });
  };

  const requestsStatuOpts = [
    { label: "accepted", value: 1 },
    { label: "pending", value: 2 },
    { label: "rejected", value: 5 },
  ];

  return (
    <>
      <div className="content loans_wrapper_style">
        <div className="row my-3 align-items-baseline">
          <div className="col-12 d-flex ">
            <RadioboxForm
              formName="myloansList"
              options={Constants.loanStatusFilter}
              name="status"
              type="radio"
              reducer="user"
              containerStyle="my-2 my-lg-0"
              labelStyle="label-style"
              optionsContainerStyle="optionsContainerStyle"
              optionItemStyle="optionItemStyle"
              optionInputStyle=" "
              optionLabelStyle="optionLabelStyle"
            />
            <span className="px-4 font-weight-bold py-0 ">
              {" "}
              {t("Requests filter") + ": "}
            </span>
            <RadioboxForm
              formName=""
              options={requestsStatuOpts}
              interceptChange={(_, val) =>
                setRequestsStatus(
                  requestsStatuOpts.find((opt) => opt.value == val)
                )
              }
              name="status"
              type="radio"
              reducer="user"
              containerStyle="my-2 my-lg-0"
              labelStyle="label-style"
              optionsContainerStyle="optionsContainerStyle"
              optionItemStyle="optionItemStyle"
              optionInputStyle=" "
              optionLabelStyle="optionLabelStyle"
              value={requestsStatus.value}
            />
          </div>
        </div>

        <div className="loans_view">
          <div className="loans_view_header">
            <span className="loans_view_header_item">{t("name")}</span>
            <span className="loans_view_header_item">{t("Total Amount")}</span>
            <span className="loans_view_header_item">{t("Remaining")}</span>
            <span className="loans_view_header_item">{t("Installments")}</span>
            <span className="loans_view_header_item">{t("created by")}</span>
            <span className="loans_view_header_item">{t("Note")}</span>
            <span className="loans_view_header_item">{t("Granted On")}</span>
            <span className="loans_view_header_item">{t("status")}</span>
          </div>

          <div className="loans_view_list">
            {isFetchingLoansLoading ? (
              <Loader />
            ) : (
              <>
                {loansListStates?.data?.length > 0 ? (
                  <>
                    {loansListStates?.data.map((loan) => (
                      <LoanCard
                        key={loan?.id}
                        {...loan}
                        isInMyProfile={true}
                        userId={userProfileData?.id}
                        refetch={handleFetchLoansData}
                      />
                    ))}
                  </>
                ) : (
                  <div className="d-flex justify-content-center align-items-center px-4 pt-4 pb-0">
                    {t("no_records")}
                  </div>
                )}
              </>
            )}
          </div>
        </div>

        <Pagination
          tableRef="myloansList"
          styleWraper=""
          onPaginate={handlePaginate}
          reducer="user"
        />
      </div>
    </>
  );
};

export default MyLoans;
