import React from "react";
import { useRouteMatch } from "react-router-dom";

import { CRMPrivileges } from "../../Constants/Nav";
import Privileges from "../../Constants/Privilages";

import CRMPage from "./CRMPage";
import CRMAgentProfile from "./CRMAgentProfile";
import LeadProfilePage from "./LeadProfilePage";
import ProtectedRouter from "../../Helpers/HOC/ProtectedRouter";

const CRMRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <ProtectedRouter
        privileges={CRMPrivileges}
        allowBP={false}
        exact={true}
        path={path}
      >
        <CRMPage />
      </ProtectedRouter>
      <ProtectedRouter
        privileges={[Privileges.VIEW_LEAD_PROFILE, Privileges.SUPER_PRIVILEGE]}
        allowBP={false}
        exact={true}
        path={`${path}/lead/:id`}
      >
        <LeadProfilePage />
      </ProtectedRouter>
      <ProtectedRouter
        privileges={[Privileges.VIEW_CRM_AGENTS, Privileges.SUPER_PRIVILEGE]}
        allowBP={false}
        exact={true}
        path={`${path}/agent/:id`}
      >
        <CRMAgentProfile />
      </ProtectedRouter>
    </>
  );
};

export default CRMRoutes;
