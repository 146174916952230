import React from "react";
import { useQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";

import {
  showToast,
  getToDate,
  quarterToMonth,
} from "../../../Helpers/HelperFns";
import { clsx } from "clsx";
import moment from "moment";
import { onFormResetAction } from "../../../Store/Actions";
import Constants, { quartersOptions } from "../../../Constants";
import { paymentReportQuery } from "../../../Graphql/query/PaymentTracking";

import BSelect from "form-builder/BSelect";
import {
  CRMReportBarChart,
  CRMReportLinearChart,
} from "../../../Components/CRM";
import { Skeleton, ToggleButton, ToggleButtonGroup } from "@mui/material";

const reducer = "paymentTracking";
const filtersFormName = "overviewReportsListFilters";
const modalInitState = { isOpen: false, data: null };
const FiltersFormProps = { reducer, formName: filtersFormName };
const getFiltersVariables = (filters) => {
  let input = {
    to_date: null,
    from_date: null,
    display_currency_id: +filters?.display_currency_id || 0,
    payments_currency_id: +filters?.payments_currency_id || 0,
    country_id: filters?.country_id ? filters?.country_id?.map((c) => +c) : [],
  };

  switch (filters.view) {
    case "monthly":
      input.from_date = `${filters?.fromYear}-${filters?.fromMonth}-01`;
      input.to_date = getToDate(`${filters?.toYear}-${filters?.toMonth}`);
      break;

    case "quarterly":
      (input.from_date = `${filters?.fromYear}-${quarterToMonth(
        filters?.fromQuarter,
        "from"
      )}-01`),
        (input.to_date = getToDate(
          `${filters?.toYear}-${quarterToMonth(filters?.toQuarter, "to")}`
        ));
      break;

    case "annually":
      input.from_date = moment()
        .year(filters?.fromYear)
        .startOf("year")
        .format("YYYY-MM-DD");
      input.to_date = moment()
        .year(filters?.toYear)
        .endOf("year")
        .format("YYYY-MM-DD");
      break;

    default:
      break;
  }

  return { input };
};

const Payment = () => {
  const dispatch = useDispatch();

  // Local State
  const [options, setOptions] = React.useState({
    currencies: [],
    countries: [],
  });

  // Reducer State
  const filters = useSelector((state) => state?.[reducer]?.[filtersFormName]);

  // Server State
  const { loading, data } = useQuery(paymentReportQuery, {
    notifyOnNetworkStatusChange: true,
    variables: getFiltersVariables(filters),
    onCompleted: (res) => {
      setOptions({
        countries: res?.countries || [],
        currencies: res?.currencies || [],
      });
    },
    onError: (err) => {
      showToast(
        "error",
        err?.graphQLErrors?.[0]?.extensions?.reason ||
          err?.graphQLErrors?.[0]?.message ||
          err?.message
      );
    },
  });

  /* ↓ Helpers ↓ */

  const handleViewChange = (_, view) => {
    dispatch(onFormResetAction(filtersFormName, { ...filters, view }));
  };

  const renderDateFilters = () => {
    switch (filters.view) {
      case "monthly":
        return (
          <>
            <div className="d-flex align-items-center gap-10 mr-2">
              <b>from</b>
              <BSelect
                {...FiltersFormProps}
                name="fromMonth"
                inputContainerStyle="w-100"
                options={Constants.MonthsData}
                rootStyle="min-w-150"
                optionLabel="key"
                optionValue="key"
              />
              <BSelect
                {...FiltersFormProps}
                name="fromYear"
                inputContainerStyle="w-100"
                options={Constants.YearData}
                rootStyle="min-w-150"
                optionLabel="value"
                optionValue="value"
              />
            </div>
            <div className="d-flex align-items-center gap-10">
              <b>to</b>
              <BSelect
                {...FiltersFormProps}
                name="toMonth"
                inputContainerStyle="w-100"
                options={Constants.MonthsData}
                rootStyle="min-w-150"
                optionLabel="key"
                optionValue="key"
              />
              <BSelect
                {...FiltersFormProps}
                name="toYear"
                inputContainerStyle="w-100"
                options={Constants.YearData}
                rootStyle="min-w-150"
                optionLabel="value"
                optionValue="value"
              />
            </div>
          </>
        );

      case "quarterly":
        return (
          <>
            <div className="d-flex align-items-center gap-10 mr-2">
              <b>from</b>
              <BSelect
                {...FiltersFormProps}
                name="fromQuarter"
                inputContainerStyle="w-100"
                options={quartersOptions}
                rootStyle="min-w-150"
              />
              <BSelect
                {...FiltersFormProps}
                name="fromYear"
                inputContainerStyle="w-100"
                options={Constants.YearData}
                rootStyle="min-w-150"
                optionLabel="value"
                optionValue="value"
              />
            </div>
            <div className="d-flex align-items-center gap-10">
              <b>to</b>
              <BSelect
                {...FiltersFormProps}
                name="toQuarter"
                inputContainerStyle="w-100"
                options={quartersOptions}
                rootStyle="min-w-150"
              />
              <BSelect
                {...FiltersFormProps}
                name="toYear"
                inputContainerStyle="w-100"
                options={Constants.YearData}
                rootStyle="min-w-150"
                optionLabel="value"
                optionValue="value"
              />
            </div>
          </>
        );

      case "annually":
        return (
          <div className="d-flex gap-10">
            <BSelect
              {...FiltersFormProps}
              label="from"
              name="fromYear"
              labelStyle="font-weight-bold"
              containerStyle="d-flex gap-10 align-items-center"
              placeholder="year"
              inputContainerStyle="w-100"
              options={Constants.YearData}
              rootStyle="min-w-150"
              optionLabel="value"
              optionValue="value"
            />
            <BSelect
              {...FiltersFormProps}
              label="to"
              name="toYear"
              labelStyle="font-weight-bold"
              containerStyle="d-flex gap-10 align-items-center"
              placeholder="year"
              inputContainerStyle="w-100"
              options={Constants.YearData}
              rootStyle="min-w-150"
              optionLabel="value"
              optionValue="value"
            />
          </div>
        );

      default:
        break;
    }
  };

  return (
    <>
      {/* Filters */}
      <div className="d-flex align-items-center gap-20 mb-2">
        <ToggleButtonGroup
          exclusive
          size="small"
          color="primary"
          value={filters.view}
          onChange={handleViewChange}
          aria-label="Select View Mode"
        >
          <ToggleButton value="monthly">monthly</ToggleButton>
          <ToggleButton value="quarterly">quarterly</ToggleButton>
          <ToggleButton value="annually">annually</ToggleButton>
        </ToggleButtonGroup>
        {renderDateFilters()}
        <div className="d-flex gap-10 flex-1">
          <BSelect
            isClearable
            {...FiltersFormProps}
            placeholder="Select Display Currency"
            name="display_currency_id"
            icon="money"
            rootStyle="flex-1"
            options={options?.currencies || []}
          />
          <BSelect
            isClearable
            {...FiltersFormProps}
            placeholder="Select Payment Currency"
            name="payments_currency_id"
            icon="money"
            rootStyle="flex-1"
            options={options?.currencies || []}
          />
          <BSelect
            isMulti
            isClearable
            {...FiltersFormProps}
            placeholder="Select Countries"
            name="country_id"
            rootStyle="flex-1"
            options={options?.countries || []}
          />
        </div>
      </div>

      {/* Reports */}
      <ReceivedAndPendingPayments
        loading={loading}
        view={filters.view}
        data={data?.paymentsCharts?.receivedAndPendingPayments}
      />
    </>
  );
};

export default Payment;

/*  
  Received & Pending Payments
*/

const formatReceivedAndPendingPaymentsChart = (view, data) => {
  if (!data) return { labels: [], datasets: [] };
  let d = data?.monthly || [];
  if (view === "annually") d = data?.yearly;
  if (view === "quarterly") d = data?.quarterly;

  const formattedData = d?.reduce(
    (acc, curr) => {
      // Labels
      curr?.year && acc.years.push(curr.year);
      curr?.payment_month && acc.months.push(curr.payment_month);
      curr?.quarter &&
        acc.quarters.push("Q" + curr.quarter + " - " + curr.year);

      // Data
      acc.paidAmounts.push((curr.paid_amount || 0)?.toString());
      acc.pendingAmounts.push((curr.pending_amount || 0)?.toString());

      return acc;
    },
    {
      years: [],
      months: [],
      quarters: [],
      paidAmounts: [],
      pendingAmounts: [],
    }
  );

  let labels = formattedData?.months;
  if (view === "annually") labels = formattedData.years;
  if (view === "quarterly") labels = formattedData?.quarters;
  return {
    labels,
    datasets: [
      { label: "Paid", data: formattedData.paidAmounts },
      { label: "Pending", data: formattedData.pendingAmounts },
    ],
  };
};

const ReceivedAndPendingPayments = ({ view, data, loading }) => {
  return (
    <Widget loading={loading}>
      <b className="d-block text-16">Received vs Pending Payments</b>
      <CRMReportLinearChart
        chartData={formatReceivedAndPendingPaymentsChart(view, data)}
        options={{
          scales: {
            yAxes: [{ ticks: { callback: (val) => val?.toLocaleString() } }],
          },
          tooltips: {
            callbacks: {
              label: (context) => (+context.value).toLocaleString(),
            },
          },
        }}
      />
    </Widget>
  );
};

/*
  Utils
*/

const Widget = ({ loading, children, classes, style }) => (
  <div className={clsx("card overflow-hidden", classes)} style={style}>
    {loading ? (
      <Skeleton width="50vw" height={200} variant="rectangular" />
    ) : (
      <div className="card-body">{children}</div>
    )}
  </div>
);
