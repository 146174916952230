import React, { useState } from "react";
import { connect } from "react-redux";
import MainModal from "../../Components/MainModal";

import { InputForm, BSelect, CheckboxBooleanForm } from "form-builder";

import { hideUpsertDocModal, upsertDocumentAttempt } from "../../Store/Actions";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const formName = "upsertDocModal";
const formNameValidation = "upsertDocModalValidation";

const fieldsItems = [
  {
    name: "exp_date", // State Name
    key: 1, // Database Key
  },
  {
    name: "issuing_date",
    key: 2,
  },
  {
    name: "attachment",
    key: 3,
  },
  {
    name: "id_number",
    key: 4,
  },
];

const UpsertDocModal = (props) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { isVissible, modalMessage, isLoading } = props.modalActions;
  const isEditCase = !!props?.modalData?.id;

  const { t } = useTranslation();

  const fields = fieldsItems
    ?.filter((item) => !!props?.modalData?.[item?.name])
    ?.map((item) => item?.key);

  const handleSubmitBtn = () => {
    setIsSubmitting(true);
    if (!props.modalValidation.length) {
      if (fields?.length) {
        props?.upsertDocumentAttempt({
          input: {
            id: props?.modalData?.id,
            name: props?.modalData?.name,
            country_id: props?.modalData?.country_id,
            fields,
          },
          company_id: props?.company_id,
        });
      }
    }
  };

  useEffect(() => {
    setIsSubmitting(false);
  }, [isVissible]);

  return (
    <MainModal
      isOpen={isVissible}
      toggle={props.hideUpsertDocModal}
      modalTitle={t(`${isEditCase ? "edit" : "new"} document`)}
      btnOnClick={handleSubmitBtn}
      btnSubmitLoading={isLoading}
      modalClassName="unset-min-width"
    >
      <div className="mb-2">
        <InputForm
          reducer="documents"
          formName={formName}
          formNameValidation={formNameValidation}
          formSubmitting={isSubmitting}
          validateBy="textRequired"
          name="name"
          type="text"
          placeholder={t("name")}
          label={t("document name")}
          labelStyle="mb-2"
          containerStyle="mt-0 mb-3"
          inputContainerStyle=" "
          // containerStyle="mb-3 d-flex align-items-center"
          // inputContainerStyle="col"
          icon="person"
        />
        <BSelect
          reducer="documents"
          formName={formName}
          formNameValidation={formNameValidation}
          formSubmitting={isSubmitting}
          name="country_id"
          label={t("select country")}
          options={[
            { name: t("global"), id: null },
            ...props?.countriesOptions,
          ]}
          keepDefaultStyle
          optionLabel="name"
          optionValue="id"
          rootStyle="my-3"
          icon="document"
        />
        <CheckboxBooleanForm
          reducer="documents"
          formName={formName}
          formNameValidation={formNameValidation}
          formSubmitting={isSubmitting}
          options={[t("requires expiration date")]}
          name="exp_date"
          type="checkbox"
          // optionLabelStyle="content-header-sub-label"
          containerStyle="my-2"
        />
        <CheckboxBooleanForm
          reducer="documents"
          formName={formName}
          formNameValidation={formNameValidation}
          formSubmitting={isSubmitting}
          options={[t("requires issuing date")]}
          name="issuing_date"
          type="checkbox"
          // optionLabelStyle="content-header-sub-label"
          containerStyle="my-2"
        />

        <CheckboxBooleanForm
          reducer="documents"
          formName={formName}
          formNameValidation={formNameValidation}
          formSubmitting={isSubmitting}
          options={[t("requires attachments")]}
          name="attachment"
          type="checkbox"
          // optionLabelStyle="content-header-sub-label"
          containerStyle="my-2"
        />
        <CheckboxBooleanForm
          reducer="documents"
          formName={formName}
          formNameValidation={formNameValidation}
          formSubmitting={isSubmitting}
          options={[t("this document has an id number")]}
          name="id_number"
          type="checkbox"
          // optionLabelStyle="content-header-sub-label"
          containerStyle="my-2"
        />
        {/* (Start) Error Message */}
        {modalMessage && isSubmitting && (
          <div className="warnig-msg-style">{modalMessage}</div>
        )}

        {isSubmitting && !fields?.length ? (
          <p className="warnig-msg-style">
            {t("at least one of them should be checked")}
          </p>
        ) : null}
        {/* (End) Error Message */}
      </div>
    </MainModal>
  );
};

const mapStateToProps = (state) => ({
  modalData: state.documents.upsertDocModal,
  modalValidation: state.documents.upsertDocModalValidation,
  modalActions: state.documents.upsertDocModalActions,
  countriesOptions: state.documents?.countries,
  company_id: state.auth?.userProfile?.company?.id,
});

export default connect(mapStateToProps, {
  hideUpsertDocModal,
  upsertDocumentAttempt,
})(UpsertDocModal);
