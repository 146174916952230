import React from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/client";

import {
  removeAllowanceMutation,
  removeDeductibleMutation,
} from "../../Graphql/mutation";
import Privileges from "../../Constants/Privilages";
import { showToast, openSwalConfirm } from "../../Helpers/HelperFns";
import { allowancesListQuery, deductiblesListQuery } from "../../Graphql/query";

import {
  EditIconButton,
  RemoveIconButton,
} from "../../Components/IconButtonWithTooltip";
import {
  UpsertAllowanceModal,
  UpsertDeductibleModal,
} from "../../Components/SettingsModals";
import Loader from "../../Components/Loader";
import { Box, Tabs, Tab } from "@mui/material";
import DataTable from "react-data-table-component";
import { AddButton } from "../../Components/Buttons";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";

const modalInitState = { isOpen: false, data: null };

const AllowancesDeductiblesList = () => {
  const { t } = useTranslation();

  // Local State
  const [data, setData] = React.useState([]);
  const [tabVal, setTabVal] = React.useState(0);
  const [modalState, setModalState] = React.useState(modalInitState);

  // Constants
  const isAllowancesTab = tabVal === 0;

  // Server State
  const [remove, { loading: removeLoading }] = useMutation(
    isAllowancesTab ? removeAllowanceMutation : removeDeductibleMutation
  );
  const { loading: listLoading, refetch: refetchList } = useQuery(
    isAllowancesTab ? allowancesListQuery : deductiblesListQuery,
    {
      notifyOnNetworkStatusChange: true,
      onCompleted: (res) => {
        setData(isAllowancesTab ? res?.allowances : res?.deductibles);
      },
      onError: (err) => {
        setData([]);
        showToast(
          "error",
          err?.graphQLErrors?.[0]?.extensions?.reason ||
            err?.graphQLErrors?.[0]?.message ||
            err?.message
        );
      },
    }
  );

  // Constants
  const columns = [
    { name: t("name"), selector: "name" },
    { name: t("created at"), selector: "created_at" },
    { name: t("updated at"), selector: "updated_at" },
    {
      cell: (row) => (
        <HasPrivileges
          allowBP
          reqireMain={[Privileges.ADD_EMPLOYEE_SALARY_CONFIGURATION]}
        >
          <EditIconButton onClick={() => handleEdit(row)} />
          <RemoveIconButton onClick={() => handleRemove(row?.id)} />
        </HasPrivileges>
      ),
    },
  ];

  /* ↓ Helpers ↓ */

  const handleChangeTab = (_, newValue) => {
    setTabVal(newValue);
  };

  const handleEdit = (data) => {
    handleOpenModal({ isEdit: true, ...data });
  };

  const handleRemove = (id) => {
    openSwalConfirm((isConfirmed) => {
      isConfirmed &&
        remove({
          variables: { id },
          onCompleted: ({ remove }) => {
            if (remove?.message) {
              showToast("error", remove.message);
            } else {
              refetchList();
              showToast("success");
            }
          },
          onError: (err) => {
            showToast(
              "error",
              err?.graphQLErrors[0]?.extensions?.reason ||
                err?.graphQLErrors[0]?.message ||
                err?.message
            );
          },
        });
    });
  };

  const handleOpenModal = (data) => {
    setModalState({
      isOpen: true,
      data: { isEdit: false, ...data },
    });
  };

  const handleCloseModal = () => {
    setModalState(modalInitState);
  };

  return (
    <>
      {/* Tabs */}
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={tabVal} onChange={handleChangeTab}>
          <Tab label={t("allowances")} />
          <Tab label={t("deductibles")} />
        </Tabs>
      </Box>

      {removeLoading ? <Loader fixed /> : null}

      {/* Actions */}
      <div className="d-flex justify-content-end mt-3">
        <HasPrivileges
          allowBP
          reqireMain={[Privileges.ADD_EMPLOYEE_SALARY_CONFIGURATION]}
        >
          <AddButton onClick={handleOpenModal} />
        </HasPrivileges>
      </div>

      {/* List */}
      <DataTable
        noHeader
        columns={columns}
        data={data || []}
        className="cards_table mt-4"
        progressPending={listLoading}
        progressComponent={<Loader inner />}
      />

      {/* Modals */}
      {modalState?.isOpen ? (
        <>
          {isAllowancesTab ? (
            <UpsertAllowanceModal
              data={modalState?.data}
              refetchList={refetchList}
              onClose={handleCloseModal}
            />
          ) : (
            <UpsertDeductibleModal
              data={modalState?.data}
              refetchList={refetchList}
              onClose={handleCloseModal}
            />
          )}
        </>
      ) : null}
    </>
  );
};

export default AllowancesDeductiblesList;
