import {
  put,
  call,
  takeLatest,
  takeEvery,
  select,
  delay,
} from "redux-saga/effects";
import Types from "../Actions/types";
import Helper from "../../Helpers/HelperFns";

import {
  fetchPettyCashListSuccess,
  fetchPettyCashListFailed,
  fetchPettyCashEmployeesAction,
  upsertPettyCashBalanceSuccess,
  dismissModifyPettyCashBalanceModalAction,
  fetchPettyCashListAction,
  upsertPettyCashBalanceFailed,
  fetchEmployeeCurrentBalanceSuccess,
  fetchEmployeeCurrentBalanceFailed,
  fetchCompanySettingsListSuccess,
  fetchCompanySettingsListFailed,
  upsertCompanySettingSuccess,
  dismissCompanySettingsModalAction,
  fetchCompanySettingsListAction,
  upsertCompanySettingFailed,
  deleteCompanySettingSuccess,
  deleteCompanySettingFailed,
  fetchCompanyExpensesSuccess,
  fetchCompanyExpensesFailed,
  dismissCompanyExpenseModalAction,
  fetchCompanyExpensesListAction,
  upsertCompanyExpensesSuccess,
  upsertCompanyExpensesFailed,
  fetchCompanyExpensesCategoriesAction,
  fetchCompanyExpensesSubCategoriesSuccess,
  fetchCompanyExpensesSubCategoriesFailed,
  fetchTotalExpenseBalanceSuccess,
  fetchTotalExpenseBalanceFailed,
  exportCompanyExpensesSuccess,
  exportCompanyExpensesFailed,
  fetchSubCatgoriesInExpenseFormSuccess,
  fetchSubCatgoriesInExpenseFormFailed,
  fetchCurrenciesInExpenseFormSuccess,
  fetchCurrenciesInExpenseFormFailed,
  fetchEmployeesInExpenseFormSuccess,
  fetchEmployeesInExpenseFormFailed,
  deleteCompanyExpenseSuccess,
  deleteCompanyExpenseFailed,
  fetchPettyCashCurrenciesSuccess,
  fetchPettyCashCurrenciesFailed,
  fetchUserPettyCashListAction,
  fetchUserProfileCurrentBalanceAction,
  fetchTotalExpenseBalanceAction,
  fetchEmployeeOfficeCurrencyInPettyCashSuccess,
  fetchEmployeeOfficeCurrencyInPettyCashFailed,
  deleteCompanyExpenseAttachedFileSuccess,
  deleteCompanyExpenseAttachedFileFailed,
  deletePettyCashAttachedFileSuccess,
  deletePettyCashAttachedFileFailed,
  fetchPettyCashCurrenciesAction,
  fetchAvaliablePettyCashEmployeesFilterOptionsAction,
  showErrorToast,
  showSuccessToast,
} from "../Actions";

import {
  fetchPettyCashList,
  upsertPettyCashBalance,
  fetchEmployeeCurrentBalance,
  fetchCompanySettings,
  upsertCompanySettings,
  deleteCompanySettings,
  fetchCompanyExpenseList,
  upsertCompanyExpense,
  fetchSubCategory,
  fetchExpenseTotalBalance,
  exportCompanyExpenses,
  fetchExpenseFormSubCatgories,
  fetchExpenseFormCurrency,
  fetchExpenseFormEmployees,
  deleteCompanyExpense,
  deletePettyCashAttachmentFile,
  fetchUserProfilePettyCashCurrencies,
  fetchEmployeesPettyCashCurenciess,
} from "../../Services/Client";
import HelperFns from "../../Helpers/HelperFns";

export const getFinancesReducer = (state) => state.finances;
export const getUserReducer = (state) => state.user;

export function* handleFetchPettyCashListSaga({ payload }) {
  try {
    const response = yield call(fetchPettyCashList, payload);
    if (!response?.errors) {
      yield put(
        fetchPettyCashListSuccess(payload.ref, response.data?.petty_cashes)
      );
      yield put(fetchPettyCashEmployeesAction(response?.data?.users_by_role));
      yield put(
        fetchAvaliablePettyCashEmployeesFilterOptionsAction(
          response?.data?.get_available_petty_cashes
        )
      );
      yield put(
        fetchCurrenciesInExpenseFormSuccess(response?.data?.currencies)
      );
    } else {
      yield put(fetchPettyCashListFailed(payload.ref));
      yield put(
        showErrorToast(
          response.errors[0]?.extensions?.reason || response.errors[0]?.message
        )
      );
    }
  } catch (error) {
    yield put(fetchPettyCashListFailed(payload.ref));
    yield put(showErrorToast(error?.message));
  }
}

function* onFetchPettyCashList() {
  yield takeLatest(
    Types.FETCH_PETTY_CASH_LIST_ATTEMPT,
    handleFetchPettyCashListSaga
  );
}

export function* handleUpsertPettyCashBalanceSaga({ payload }) {
  try {
    const response = yield call(upsertPettyCashBalance, payload);
    if (!response.errors) {
      yield put(upsertPettyCashBalanceSuccess(payload.ref));
      yield put(
        dismissModifyPettyCashBalanceModalAction({
          ref: payload.ref,
          formName: "modifyPettyCashBalanceModal",
        })
      );
      yield put(showSuccessToast());

      if (window.location.pathname.includes("employee-profile")) {
        let userReducer = yield select(getUserReducer);
        yield put(
          fetchUserPettyCashListAction({
            id: response?.data?.add_or_update_petty_cash?.emp_id,
            ref: "userPettyCashList",
            userPettyCashList: userReducer["userPettyCashList"],
          })
        );
        yield put(
          fetchUserProfileCurrentBalanceAction({
            emp_id: response?.data?.add_or_update_petty_cash?.emp_id,
          })
        );
        yield put(
          fetchPettyCashCurrenciesAction({
            emp_id: response?.data?.add_or_update_petty_cash?.emp_id,
            type: "",
          })
        );
        return;
      }
      let financesReducer = yield select(getFinancesReducer);
      yield put(
        fetchPettyCashListAction({
          ref: "pettyCashList",
          pettyCashList: financesReducer["pettyCashList"],
        })
      );
      yield put(
        fetchPettyCashCurrenciesAction({
          emp_id: "",
          type: "",
        })
      );
    }
    if (response?.errors[0]?.extensions?.validation) {
      yield put(
        upsertPettyCashBalanceFailed({
          ref: payload.ref,
          serverRef: "modifyPettyCashBalanceModalServerValidation",
          errors: Helper.parseValidatorErrors(
            response?.errors[0]?.extensions?.validation
          ),
        })
      );
    } else {
      yield put(
        upsertPettyCashBalanceFailed({
          ref: payload.ref,
          serverRef: "modifyPettyCashBalanceModalServerValidation",
          errors: {},
          modalMessage:
            response?.errors[0]?.extensions?.reason ??
            response?.errors[0]?.message,
        })
      );
    }
  } catch (error) {
    yield put(
      upsertPettyCashBalanceFailed({
        ref: payload.ref,
        serverRef: "modifyPettyCashBalanceModalServerValidation",
        errors: {},
        modalMessage:
          error.message ?? HelperFns.localizeFns("something went wrong"),
      })
    );
    
  }
}

function* onUpsertPettyCashBalance() {
  yield takeLatest(
    Types.UPSERT_PETTY_CASH_BALANCE_ATTEMPT,
    handleUpsertPettyCashBalanceSaga
  );
}

export function* handleFetchEmployeeCurrentBalanceSaga({ payload }) {
  try {
    const response = yield call(fetchEmployeeCurrentBalance, payload);
    yield put(fetchEmployeeCurrentBalanceSuccess(response?.data?.employee));
  } catch (error) {
    yield put(fetchEmployeeCurrentBalanceFailed());
    
  }
}

function* onFetchEmployeeCurrentBalance() {
  yield takeEvery(
    Types.FETCH_EMPLOYEE_CURRENT_BALANCE_ATTEMPT,
    handleFetchEmployeeCurrentBalanceSaga
  );
}

export function* handleFetchCompanySettingsListSaga({ payload }) {
  try {
    const response = yield call(fetchCompanySettings, payload);
    if (!response?.errors) {
      yield put(
        fetchCompanySettingsListSuccess(payload.ref, response.data?.categories)
      );
    } else {
      yield put(fetchCompanySettingsListFailed(payload.ref));
      yield put(
        showErrorToast(
          response.errors[0]?.extensions?.reason || response.errors[0]?.message
        )
      );
    }
  } catch (error) {
    yield put(fetchCompanySettingsListFailed(payload.ref));
    yield put(showErrorToast(error?.message));
  }
}

function* onFetchCompanySettingsList() {
  yield takeLatest(
    Types.FETCH_COMPANY_SETTINGS_LIST_ATTEMPT,
    handleFetchCompanySettingsListSaga
  );
}

export function* handleUpsertCompanySetingsSaga({ payload }) {
  try {
    const response = yield call(upsertCompanySettings, payload.data);
    if (!response.errors) {
      yield put(upsertCompanySettingSuccess(payload.ref));
      yield put(
        dismissCompanySettingsModalAction({
          ref: payload.ref,
          formName: "companySettingsModalActions",
        })
      );
      yield put(showSuccessToast());

      let financesReducer = yield select(getFinancesReducer);
      yield put(
        fetchCompanySettingsListAction({
          ref: "companySettingsList",
          companySettingsList: financesReducer["companySettingsList"],
        })
      );
    }
    if (response?.errors[0]?.extensions?.validation) {
      yield put(
        upsertCompanySettingFailed({
          ref: payload.ref,
          serverRef: "companySettingsModalServerValidation",
          errors: Helper.parseValidatorErrors(
            response?.errors[0]?.extensions?.validation
          ),
        })
      );
    } else {
      yield put(
        upsertCompanySettingFailed({
          ref: payload.ref,
          serverRef: "companySettingsModalServerValidation",
          errors: {},
          modalMessage:
            response?.errors[0]?.extensions?.reason ??
            response?.errors[0]?.message,
        })
      );
    }
  } catch (error) {
    yield put(
      upsertCompanySettingFailed({
        ref: payload.ref,
        serverRef: "companySettingsModalServerValidation",
        errors: {},
        modalMessage:
          error.message ?? HelperFns.localizeFns("something went wrong"),
      })
    );
  }
}

function* onUpsertCompanySetings() {
  yield takeLatest(
    Types.UPSERT_COMPANY_SETTINGS_ATTEMPT,
    handleUpsertCompanySetingsSaga
  );
}

export function* handleDeleteCompanySettingsSaga({ payload }) {
  try {
    const response = yield call(deleteCompanySettings, payload);
    if (!response.errors) {
      yield put(deleteCompanySettingSuccess());
      yield put(showSuccessToast());
      let financesReducer = yield select(getFinancesReducer);
      yield put(
        fetchCompanySettingsListAction({
          ref: "companySettingsList",
          companySettingsList: financesReducer["companySettingsList"],
        })
      );
    } else {
      yield put(deleteCompanySettingFailed());
      yield put(showErrorToast(response.errors[0]?.extensions?.reason));
    }
  } catch (error) {
    yield put(deleteCompanySettingFailed());
    yield put(showErrorToast(error?.message));
  }
}

function* onDeleteCompanySettings() {
  yield takeLatest(
    Types.DELETE_COMPANY_SETTINGS_ATTEMPT,
    handleDeleteCompanySettingsSaga
  );
}

export function* handleFetchCompanyExpensesListSaga({ payload }) {
  try {
    yield delay(500);
    const response = yield call(fetchCompanyExpenseList, payload);
    if (!response?.errors) {
      yield put(
        fetchCompanyExpensesSuccess(payload.ref, response.data?.petty_cashes)
      );
      yield put(
        fetchCompanyExpensesCategoriesAction(response?.data?.categories)
      );
      yield put(
        fetchAvaliablePettyCashEmployeesFilterOptionsAction(
          response?.data?.get_available_petty_cashes
        )
      );
    } else {
      yield put(fetchCompanyExpensesFailed(payload.ref));
      yield put(
        showErrorToast(
          response.errors[0]?.extensions?.reason || response.errors[0]?.message
        )
      );
    }
  } catch (error) {
    yield put(fetchCompanyExpensesFailed(payload.ref));
    yield put(showErrorToast(error?.message));
  }
}

function* onFetchCompanyExpensesList() {
  yield takeLatest(
    Types.FETCH_COMPANY_EXPENSE_ATTEMPT,
    handleFetchCompanyExpensesListSaga
  );
}

export function* handleUpsertCompanyExpensesSaga({ payload }) {
  try {
    const response = yield call(upsertCompanyExpense, payload);
    if (!response.errors) {
      yield put(upsertCompanyExpensesSuccess(payload.ref));
      yield put(
        dismissCompanyExpenseModalAction({
          ref: payload.ref,
          formName: "companyExpensesModal",
        })
      );
      yield put(showSuccessToast());

      if (window.location.pathname.includes("employee-profile")) {
        let userReducer = yield select(getUserReducer);
        yield put(
          fetchUserPettyCashListAction({
            id: response?.data?.add_or_update_petty_cash?.emp_id,
            ref: "userPettyCashList",
            userPettyCashList: userReducer["userPettyCashList"],
          })
        );
        yield put(
          fetchUserProfileCurrentBalanceAction({
            emp_id: response?.data?.add_or_update_petty_cash?.emp_id,
          })
        );
        yield put(
          fetchPettyCashCurrenciesAction({
            emp_id: response?.data?.add_or_update_petty_cash?.emp_id,
            type: "expenses_list",
          })
        );
        return;
      }
      let financesReducer = yield select(getFinancesReducer);
      yield put(
        fetchCompanyExpensesListAction({
          ref: "companyExpensesList",
          companyExpensesList: financesReducer["companyExpensesList"],
        })
      );
      yield put(
        fetchTotalExpenseBalanceAction({
          ref: "companyExpensesList",
          companyExpensesList: financesReducer["companyExpensesList"],
        })
      );
      yield put(
        fetchPettyCashCurrenciesAction({
          emp_id: "",
          type: "expenses_list",
        })
      );
    }
    if (response?.errors[0]?.extensions?.validation) {
      yield put(
        upsertCompanyExpensesFailed({
          ref: payload.ref,
          serverRef: "companyExpensesModalServerValidation",
          errors: Helper.parseValidatorErrors(
            response?.errors[0]?.extensions?.validation
          ),
        })
      );
    } else {
      yield put(
        upsertCompanyExpensesFailed({
          ref: payload.ref,
          serverRef: "companyExpensesModalServerValidation",
          errors: {},
          modalMessage:
            response?.errors[0]?.extensions?.reason ??
            response?.errors[0]?.message,
        })
      );
    }
  } catch (error) {
    yield put(
      upsertCompanyExpensesFailed({
        ref: payload.ref,
        serverRef: "companyExpensesModalServerValidation",
        errors: {},
        modalMessage:
          error.message ?? HelperFns.localizeFns("something went wrong"),
      })
    );
    
  }
}

function* onUpsertCompanyExpense() {
  yield takeLatest(
    Types.UPSERT_COMPANY_EXPENSE_ATTEMPT,
    handleUpsertCompanyExpensesSaga
  );
}

export function* handleFetchCompanyExpenseSubCategorySaga({ payload }) {
  try {
    const response = yield call(fetchSubCategory, payload);
    yield put(
      fetchCompanyExpensesSubCategoriesSuccess(response?.data?.sub_categories)
    );
  } catch (error) {
    yield put(fetchCompanyExpensesSubCategoriesFailed());
    
  }
}

function* onFetchCompanyExpenseSubCatg() {
  yield takeLatest(
    Types.FETCH_COMPANY_EXPENSES_SUBCATEGORIES_ATTEMPT,
    handleFetchCompanyExpenseSubCategorySaga
  );
}

export function* handleFetchExpenseTotalBalanceSaga({ payload }) {
  try {
    yield delay(500);
    const response = yield call(fetchExpenseTotalBalance, payload);
    yield put(
      fetchTotalExpenseBalanceSuccess(
        response?.data?.get_total_expenses_balance
      )
    );
  } catch (error) {
    yield put(fetchTotalExpenseBalanceFailed());
    
  }
}

function* onFetchCompanyExpenseTotalBalance() {
  yield takeLatest(
    Types.FETCH_EXPENSE_TOTAL_BALANCE_ATTEMPT,
    handleFetchExpenseTotalBalanceSaga
  );
}

export function* handleExportCompanyExpensesSaga({ payload }) {
  try {
    const response = yield call(exportCompanyExpenses, payload);
    if (
      !response.errors &&
      response.data?.export_petty_cash?.status == "success"
    ) {
      yield put(exportCompanyExpensesSuccess());
      yield put(showSuccessToast());
      HelperFns.downloadFile(response.data?.export_petty_cash?.file);
    } else {
      yield put(exportCompanyExpensesFailed());
      yield put(
        showErrorToast(
          response?.errors[0]?.extensions?.reason ||
            response?.errors[0]?.extensions?.validation
            ? Object.values(
                response?.errors[0]?.extensions?.validation
              )[0]?.toString()
            : response?.errors[0]?.message
        )
      );
    }
  } catch (error) {
    yield put(exportCompanyExpensesFailed());
    yield put(showErrorToast(error?.message));
  }
}

function* onExportCompanyExpenses() {
  yield takeLatest(
    Types.EXPORT_COMPANY_EXPENSES_ATTEMPT,
    handleExportCompanyExpensesSaga
  );
}

export function* handleFetchExpenseFormSubCategoreisSaga({ payload }) {
  try {
    const response = yield call(fetchExpenseFormSubCatgories, payload);
    yield put(
      fetchSubCatgoriesInExpenseFormSuccess(response?.data?.sub_categories)
    );
  } catch (error) {
    yield put(fetchSubCatgoriesInExpenseFormFailed());
    
  }
}

function* onFetchExpenseFormSubCatgories() {
  yield takeLatest(
    Types.FETCH_EXPENSE_FORM_SUBCATGERORIES_ATTEMPT,
    handleFetchExpenseFormSubCategoreisSaga
  );
}

export function* handleFetchExpenseFormCurrencySaga({ payload }) {
  try {
    const response = yield call(fetchExpenseFormCurrency, payload);
    yield put(fetchCurrenciesInExpenseFormSuccess(response?.data?.currencies));
  } catch (error) {
    yield put(fetchCurrenciesInExpenseFormFailed());
    
  }
}

function* onFetchExpenseFormCurrency() {
  yield takeLatest(
    Types.FETCH_EXPENSE_FORM_CUREENCIES_ATTEMPT,
    handleFetchExpenseFormCurrencySaga
  );
}

export function* handleFetchExpenseFormEmployeesSaga({ payload }) {
  try {
    const response = yield call(fetchExpenseFormEmployees, payload);
    yield put(
      fetchEmployeesInExpenseFormSuccess(
        response?.data?.get_available_petty_cashes
      )
    );
  } catch (error) {
    yield put(fetchEmployeesInExpenseFormFailed());
    
  }
}

function* onFetchExpenseFormEmployees() {
  yield takeLatest(
    Types.FETCH_EXPENSE_FORM_EMPLOYEES_ATTEMPT,
    handleFetchExpenseFormEmployeesSaga
  );
}

export function* handleDeleteCompanyExpenseSaga({ payload }) {
  try {
    const response = yield call(deleteCompanyExpense, payload);
    if (!response.errors) {
      yield put(deleteCompanyExpenseSuccess());
      yield put(showSuccessToast());
      let financesReducer = yield select(getFinancesReducer);
      yield put(
        fetchCompanyExpensesListAction({
          ref: "companyExpensesList",
          companyExpensesList: financesReducer["companyExpensesList"],
        })
      );
      yield put(
        fetchTotalExpenseBalanceAction({
          ref: "companyExpensesList",
          companyExpensesList: financesReducer["companyExpensesList"],
        })
      );
    } else {
      yield put(deleteCompanyExpenseFailed());
      yield put(
        showErrorToast(
          response?.errors[0]?.extensions?.reason ||
            response?.errors[0]?.extensions?.validation
            ? Object.values(
                response?.errors[0]?.extensions?.validation
              )[0]?.toString()
            : response?.errors[0]?.message
        )
      );
    }
  } catch (error) {
    yield put(deleteCompanyExpenseFailed());
    yield put(showErrorToast(error?.message));
  }
}

function* onDeleteCompanyExpense() {
  yield takeLatest(
    Types.DELETE_COMPANY_EXPENSE_ATTEMPT,
    handleDeleteCompanyExpenseSaga
  );
}

export function* handleDeleteCompanyExpenseAttachmentFileSaga({ payload }) {
  try {
    const response = yield call(deletePettyCashAttachmentFile, payload);
    if (!response.errors) {
      yield put(
        deleteCompanyExpenseAttachedFileSuccess(
          response?.data?.delete_petty_cash_file
        )
      );
      yield put(showSuccessToast());

      if (window.location.pathname.includes("employee-profile")) {
        let userReducer = yield select(getUserReducer);
        yield put(
          fetchUserPettyCashListAction({
            id: response?.data?.delete_petty_cash_file?.emp_id,
            ref: "userPettyCashList",
            userPettyCashList: userReducer["userPettyCashList"],
          })
        );
        return;
      }
      let financesReducer = yield select(getFinancesReducer);
      yield put(
        fetchCompanyExpensesListAction({
          ref: "companyExpensesList",
          companyExpensesList: financesReducer["companyExpensesList"],
        })
      );
    } else {
      yield put(deleteCompanyExpenseAttachedFileFailed());
      yield put(
        showErrorToast(
          response?.errors[0]?.extensions?.reason ||
            response?.errors[0]?.extensions?.validation
            ? Object.values(
                response?.errors[0]?.extensions?.validation
              )[0]?.toString()
            : response?.errors[0]?.message
        )
      );
    }
  } catch (error) {
    yield put(deleteCompanyExpenseAttachedFileFailed());
    yield put(showErrorToast(error?.message));
  }
}

function* onDeleteCompanyExpenseAttachmentFile() {
  yield takeLatest(
    Types.DELETE_COMPANY_EXPENSE_ATTACHMENT_FILE_ATTEMPT,
    handleDeleteCompanyExpenseAttachmentFileSaga
  );
}

export function* handleDeletePettyCashAttachmentFileSaga({ payload }) {
  try {
    const response = yield call(deletePettyCashAttachmentFile, payload);
    if (!response.errors) {
      yield put(
        deletePettyCashAttachedFileSuccess(
          response?.data?.delete_petty_cash_file
        )
      );
      yield put(showSuccessToast());

      if (window.location.pathname.includes("employee-profile")) {
        let userReducer = yield select(getUserReducer);
        yield put(
          fetchUserPettyCashListAction({
            id: response?.data?.delete_petty_cash_file?.emp_id,
            ref: "userPettyCashList",
            userPettyCashList: userReducer["userPettyCashList"],
          })
        );
        return;
      }
      let financesReducer = yield select(getFinancesReducer);
      yield put(
        fetchPettyCashListAction({
          ref: "pettyCashList",
          pettyCashList: financesReducer["pettyCashList"],
        })
      );
    } else {
      yield put(deletePettyCashAttachedFileFailed());
      yield put(
        showErrorToast(
          response?.errors[0]?.extensions?.reason ||
            response?.errors[0]?.extensions?.validation
            ? Object.values(
                response?.errors[0]?.extensions?.validation
              )[0]?.toString()
            : response?.errors[0]?.message
        )
      );
    }
  } catch (error) {
    yield put(deletePettyCashAttachedFileFailed());
    yield put(showErrorToast(error?.message));
  }
}

function* onDeletePettyCashAttachmentFile() {
  yield takeLatest(
    Types.DELETE_PETTY_CASH_ATTACHMENT_FILE_ATTEMPT,
    handleDeletePettyCashAttachmentFileSaga
  );
}

export function* handleFetchUserProfilePettyCashCurrenciesSaga({ payload }) {
  try {
    const response = yield call(fetchUserProfilePettyCashCurrencies, payload);
    yield put(
      fetchPettyCashCurrenciesSuccess(
        response?.data?.get_petty_cashes_currencies
      )
    );
  } catch (error) {
    yield put(fetchPettyCashCurrenciesFailed());
    
  }
}

function* onFetchUserProfilePettyCashCurrencies() {
  yield takeEvery(
    Types.FETCH_PETTY_CASH_CURRENCIES_ATTEMPT,
    handleFetchUserProfilePettyCashCurrenciesSaga
  );
}

export function* handleFetchEmployeesOfficeCurrencyInModifyBalanceSaga({
  payload,
}) {
  try {
    const response = yield call(fetchEmployeesPettyCashCurenciess, payload);
    yield put(
      fetchEmployeeOfficeCurrencyInPettyCashSuccess(response?.data?.currencies)
    );
  } catch (error) {
    yield put(fetchEmployeeOfficeCurrencyInPettyCashFailed());
    
  }
}

function* onFetchEmployeeCurrenciesInModifyBalance() {
  yield takeLatest(
    Types.FETCH_EMPLOYEE_OFFICE_CURRENCY_PETTY_CASH_ATTEMPT,
    handleFetchEmployeesOfficeCurrencyInModifyBalanceSaga
  );
}

const sagaWatchers = {
  onFetchPettyCashList,
  onUpsertPettyCashBalance,
  onFetchEmployeeCurrentBalance,
  onFetchCompanySettingsList,
  onUpsertCompanySetings,
  onDeleteCompanySettings,
  onFetchCompanyExpensesList,
  onUpsertCompanyExpense,
  onFetchCompanyExpenseSubCatg,
  onFetchCompanyExpenseTotalBalance,
  onExportCompanyExpenses,
  onFetchExpenseFormSubCatgories,
  onFetchExpenseFormCurrency,
  onFetchExpenseFormEmployees,
  onDeleteCompanyExpense,
  onFetchUserProfilePettyCashCurrencies,
  onFetchEmployeeCurrenciesInModifyBalance,
  onDeleteCompanyExpenseAttachmentFile,
  onDeletePettyCashAttachmentFile,
};

export default sagaWatchers;
