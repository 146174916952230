import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toggleSetTemporaryPasswordModal } from "../../Store/Actions";
import MainModal from "../../Components/MainModal";
import { IconButton, InputAdornment, OutlinedInput } from "@mui/material";
import { Visibility, VisibilityOff, VpnKey } from "@mui/icons-material";
import { useMutation } from "@apollo/client";
import { ADD_TEMPORARY_PASSWORD } from "../../Graphql/mutation";
import { showToast } from "../../Helpers/HelperFns";

const SetTemporaryPasswordModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [validationMsg, setValidationMsg] = useState("");
  const { isOpen, user } = useSelector(
    (state) => state?.super?.setTemporaryPasswordModal
  );

  const [attemptSetTemporaryPassword, { loading }] = useMutation(
    ADD_TEMPORARY_PASSWORD,
    {
      variables: {
        userId: +user?.id,
        password,
      },
      onCompleted: (data) => {
        
        const { status, message } = data?.addTemporaryPassword ?? {};
        if (status == "success") {
          dispatch(
            toggleSetTemporaryPasswordModal({
              isOpen: false,
              user: null,
            })
          );
          showToast("success", message);
        } else {
          showToast("error", message);
        }
      },
      onError: (err) => {
        showToast(
          "error",
          err?.graphQLErrors[0]?.extensions?.reason ||
            err?.graphQLErrors[0]?.message ||
            err?.message
        );
      },
    }
  );

  const handleCloseModal = () => {
    dispatch(toggleSetTemporaryPasswordModal({ isOpen: false, user: null }));
  };

  const handleSubmit = () => {
    setValidationMsg("");
    // Handle Empty password
    if (!password || !confirmPassword) {
      return setValidationMsg("Please Enter your password");
    }
    // Handle Not matching password
    if (password !== confirmPassword) {
      return setValidationMsg("Please make sure your passwords match");
    }

    attemptSetTemporaryPassword();
  };

  const handleChangePassword = (e) => {
    setPassword(e?.target?.value ?? "");
  };
  const handleChangeConfirmPassword = (e) => {
    setConfirmPassword(e?.target?.value ?? "");
  };

  return (
    <MainModal
      isOpen={isOpen}
      toggle={handleCloseModal}
      modalTitle={`${t("set temporary password")} - ${user?.name}`}
      className="work_schedule_modal"
      btnLabel={t("save")}
      btnOnClick={handleSubmit}
      btnSubmitLoading={loading}
      size={"lg"}
    >
      <>
        <div className="my-3 d-flex flex-column login_input_wrapper_style">
          <label className="mb-2">{t("password")}</label>
          <OutlinedInput
            type={showPassword ? "text" : "password"}
            value={password}
            name="password"
            onChange={handleChangePassword}
            placeholder={t("password")}
            classes={{
              root: "custom_outlined_input_style h-40px overflow-hidden",
            }}
            startAdornment={
              <InputAdornment position="start">
                <IconButton tabIndex="-1">
                  <VpnKey />
                </IconButton>
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => {
                    setShowPassword((prev) => !prev);
                  }}
                  tabIndex="-1"
                  // onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </div>
        <div className="my-3 d-flex flex-column login_input_wrapper_style">
          <label className="mb-2">{t("confirm password")}</label>
          <OutlinedInput
            type={showConfirmPassword ? "text" : "password"}
            value={confirmPassword}
            name="confirmPassword"
            onChange={handleChangeConfirmPassword}
            placeholder={t("confirm password")}
            classes={{
              root: "custom_outlined_input_style  h-40px overflow-hidden",
            }}
            startAdornment={
              <InputAdornment position="start">
                <IconButton tabIndex="-1">
                  <VpnKey />
                </IconButton>
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => {
                    setShowConfirmPassword((prev) => !prev);
                  }}
                  tabIndex="-1"
                  // onMouseDown={handleMouseDownPassword}
                >
                  {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </div>
        {validationMsg && (
          <div className="warnig-msg-style mt-2">{validationMsg}</div>
        )}
      </>
    </MainModal>
  );
};

export default SetTemporaryPasswordModal;
