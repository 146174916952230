import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import {
  Eventcalendar,
  setOptions,
  localeAr,
  localeEn,
} from "@mobiscroll/react";
import moment from "moment";
import { Link, useParams } from "react-router-dom";
import Loader from "../../Components/Loader";
import { useQuery, gql } from "@apollo/client";
import { viewWorkTimeAction } from "../../Store/Actions";

import { calendarQuery } from "../../Graphql/query";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Box, Tooltip } from "@mui/material";
import EditWorkTimePreview from "../../Components/AttProfileSections/EditWorkTimePreview";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import { AddButton } from "../../Components/Buttons";
import ChangeAssignmentModal from "../Assignments/ChangeAssignmentModal";
import { showToast } from "../../Helpers/HelperFns";
import { EmployeeTypes } from "../../Constants";

setOptions({
  theme: "ios",
  themeVariant: "light",
});
const modalInitState = { isOpen: false, data: null, actions: null };

function WorkCalendarTab({ userId, pageFlag }) {
  const [employeesView, setEmployeesView] = React.useState(0);
  const [firstDay, setFirstDay] = React.useState(moment());

  const isMyProfile = pageFlag == "auth_profile_work_calendar";

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isPreviewModalLoading = useSelector(
    (state) => state.super.isViewWorkTimeLoading
  );

  const isAuthFlex = useSelector(
    (state) => state?.auth?.userProfile?.employee?.__typename == "FlexEmployee"
  );
  const isSelectedEmployeeFlex =
    useSelector(
      (state) => state?.super?.selectedEmployeeProfile?.employee?.__typename
    ) === EmployeeTypes.FLEX;

  const isFlex = isMyProfile ? isAuthFlex : isSelectedEmployeeFlex;

  const {
    data,
    loading: fetchCalendarDataLoading,
    error,
    refetch,
  } = useQuery(calendarQuery, {
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        start: moment(firstDay).startOf("month").format("YYYY-MM-DD"),
        end: moment(firstDay).endOf("month").format("YYYY-MM-DD"),
        employees: [userId],
        work_teams: [],
        work_groups_search: [],
        type: employeesView ? "employees" : "locations",
      },
      page_flag: pageFlag,
      includeDrafts: false,
    },
    onError: (err) => {
      showToast(
        "error",
        err?.graphQLErrors?.[0]?.extensions?.reason ?? err?.message
      );
    },
  });

  const renderHeader = () => {
    return (
      <div className="d-flex align-items-center justify-content-between w-100 p-3">
        <div className="flex-1 d-flex align-items-center justify-content-center">
          <button
            className="bg-none border-0 outline-none"
            onClick={handlePrevButton}
          >
            <ChevronLeft
              className="calendar-date-toggling-button"
              style={{ fontSize: 40 }}
            />
          </button>
          <p className="calendar-header-date-style">
            {firstDay.startOf("month").format("MMM DD, YYYY")} -{" "}
            {firstDay.endOf("month")?.format("MMM DD, YYYY")}
          </p>
          <button
            className="bg-none border-0 outline-none"
            onClick={handleNextButton}
          >
            <ChevronRight
              className="calendar-date-toggling-button"
              style={{ fontSize: 40 }}
            />
          </button>
        </div>
      </div>
    );
  };

  const handlePrevButton = () => {
    setFirstDay((prev) => moment(prev).add(-1, "months").startOf("month"));
  };

  const handleNextButton = () => {
    setFirstDay((prev) => moment(prev).add(1, "months").startOf("month"));
  };

  const handleWorkTimingPreview = (id) => {
    // dispatch(viewWorkTimeAction(929));
  };

  useEffect(() => {
    document.querySelectorAll(".mbsc-calendar-text[title]").length &&
      document
        .querySelectorAll(".mbsc-calendar-text")
        .forEach((e) => e.removeAttribute("title"));
  }, [document.querySelectorAll(".mbsc-calendar-text[title]").length]);

  const [changeAssignmentModal, setChangeAssignmentModal] =
    React.useState(modalInitState);
  const handleOpenChangeAssignmentModal = (data) => {
    setChangeAssignmentModal({
      isOpen: true,
      actions: { refetch },
      data: { ...data, isEmpProfile: true, userId, isFlex },
    });
  };

  return (
    <div className="work_calendar_page content px-5 position-relative">
      {fetchCalendarDataLoading || isPreviewModalLoading ? (
        <div className="loader_wrapper_style position-absolute">
          <Loader />
        </div>
      ) : null}
      <EditWorkTimePreview />

      <div className="d-flex justify-content-end">
        <HasPrivileges allowBP scope="all" requireScope={[Privilages.VIEW_EMPLOYEE_ASSIGNMENTS]}>
          <HasPrivileges allowBP reqireMain={[Privilages.CHANGE_SHIFT]}>
            <AddButton
              prefix="additional"
              label="shifts"
              variant="outlined"
              className="mx-2"
              onClick={() =>
                handleOpenChangeAssignmentModal({ isChangeShift: false })
              }
            />
            <AddButton
              prefix="change"
              label="shifts"
              variant="outlined"
              onClick={() =>
                handleOpenChangeAssignmentModal({ isChangeShift: true })
              }
            />
          </HasPrivileges>
        </HasPrivileges>
      </div>
      {changeAssignmentModal?.isOpen ? (
        <ChangeAssignmentModal
          data={changeAssignmentModal?.data}
          actions={changeAssignmentModal?.actions}
          refetch={refetch}
          onClose={() => setChangeAssignmentModal(modalInitState)}
        />
      ) : null}

      <Box
        sx={{
          ".mbsc-calendar": {
            minHeight: 500,
            height: "calc(100vh - 300px)",
          },
          ".mbsc-calendar-body-inner": { overflowY: "auto" },
          ".mbsc-calendar-scroll-wrapper": { overflow: "initial" },
          ".mbsc-ios.mbsc-calendar-text-more .mbsc-calendar-label-text": {
            display: "none",
          },
        }}
      >
        <Eventcalendar
          view={{
            calendar: {
              type: "month",
            },
          }}
          min={moment(firstDay).startOf("month").toDate()}
          max={moment(firstDay).endOf("month").toDate()}
          refDate={firstDay}
          locale={
            document.documentElement.lang?.includes("ar") ? localeAr : localeEn
          }
          rtl={document.documentElement.lang?.includes("ar")}
          data={data?.calendar.results || []}
          dragToCreate={false}
          dragToResize={false}
          dragToMove={false}
          clickToCreate={false}
          renderLabel={() => {}}
          renderLabelContent={() => {}}
          renderDayContent={({ events = [] }) => {
            return events?.map((event) =>
              event?.work_timing_names ? (
                <div className="event-day my-2">
                  {/* <Tooltip arrow title={event?.work_timing_names}> */}
                  {[
                    event?.work_timing_names,
                    // ...event?.alternative_normal_work_timing_names,
                  ]?.map((workTimingName) => {
                    return (
                      <div className="text-truncate w-100">
                        {workTimingName}
                      </div>
                    );
                  })}

                  {/* </Tooltip> */}
                  {/* <Tooltip arrow title={event?.location_name}> */}
                  <div className="text-truncate w-100 text-info">
                    {event?.location_name}
                  </div>
                  {/* </Tooltip> */}
                </div>
              ) : (
                <div className="event-day my-2">{t("Day Off")}</div>
              )
            );
          }}
          renderEventContent={({ original }) =>
            original?.work_timing_names ? (
              <div style={{ display: "grid" }}>
                <span className="w-100">{original?.work_timing_names}</span>
                <span className="w-100 text-info">
                  {original?.location_name}
                </span>
              </div>
            ) : (
              t("Day Off")
            )
          }
          renderHeader={renderHeader}
        />
      </Box>
    </div>
  );
}

export default WorkCalendarTab;
