import moment from "moment";
import { dateFormat } from "../../Constants";

export const checkIsContact = (contact) => {
  const c = contact.replace("-", "").replaceAll(" ", "");
  return !isNaN(c) || c.includes("@");
};

export const buildOption = (val = "", labelKey = "name", ValKey = "id") => ({
  [labelKey]: val,
  [ValKey]: val,
});

export const formatOptions = (options = [], labelKey = "name", ValKey = "id") =>
  options.map((opt) => ({ [labelKey]: opt, [ValKey]: opt }));

export const getToDate = (monthYear) => {
  const days = moment(monthYear, "YYYY-MM").daysInMonth();
  return `${monthYear}-${days}`;
};

export const quarterToMonth = (quarter, type) => {
  const quartersFirstMonth = ["01", "04", "07", "10"];
  const quartersEndMonth = ["03", "06", "09", "12"];

  return type === "from"
    ? quartersFirstMonth[quarter - 1]
    : quartersEndMonth[quarter - 1];
};

export const formatSendingInputs = (inputs = {}) => {
  const formattedInput = {};

  Object.entries(inputs).forEach(([key, value]) => {
    // Date Value
    if (moment.isMoment(value)) {
      formattedInput[key] = moment(value).format(dateFormat);
    }

    // Select Value
    else if (typeof value === "object") {
      if (inputs[key]?.id) {
        const val = isNaN(+value.id) ? value.id : +value.id;
        formattedInput[key] = val || undefined;
      }

      if (Array.isArray(value)) {
        if (value[0]?.id) {
          formattedInput[key] = value.map(({ id }) => (isNaN(id) ? id : +id));
        } else {
          const arr = value.filter((v) => Boolean(v));
          formattedInput[key] = arr?.length ? arr : undefined;
        }
      }
      return;
    }

    // Boolean Value
    else if (typeof value === "boolean") {
      formattedInput[key] = value;
    }

    // Text Value
    else {
      const val = isNaN(+value) ? value : +value;
      formattedInput[key] = val || undefined;
    }
  });

  return formattedInput;
};
