import React from "react";
import { useSelector } from "react-redux";

import Store from "../../Store";
import { closeModalContainer } from "../../Store/Actions";

import { Modal } from "reactstrap";
import LoanModal from "../LoanModal";
import AddNewBonusModal from "../../Containers/Bonus/AddNewBonusModal";
import AttendanceActionModal from "../ProfileCard/AttendanceActionModal";
import WorkTimingForm from "../../Routes/WorkTimingsListPage/WorkTimingForm";
import AddNewDeductionsModal from "../../Containers/Deductions/AddNewDeductionsModal";
import UpsertAssignmentModal from "../../Containers/Assignments/UpsertAssignmentModal";
import ChangeAssignmentModal from "../../Containers/Assignments/ChangeAssignmentModal";
import MainFlexRequestModal from "../ProfileCard/MainRequestModal";

const closeLocalModal = () => Store.dispatch(closeModalContainer());
const modals = {
  newLoan: <LoanModal />,
  newBonus: <AddNewBonusModal />,
  newWorkTiming: <WorkTimingForm />,
  newDeduction: <AddNewDeductionsModal />,
  newAssignment: <UpsertAssignmentModal />,
  newRequest: <MainFlexRequestModal isEmployee />,
  newRequestOnBehalf: <MainFlexRequestModal hasEmpInput />,
  changeShifts: (
    <ChangeAssignmentModal
      onClose={closeLocalModal}
      data={{ isEmpProfile: false, isChangeShift: true }}
    />
  ),
};

const ModalContainer = () => {
  const { name } = useSelector((state) => state?.feedback?.modalContainer);
  return name ? <>{modals?.[name]}</> : null;
};

export default ModalContainer;
