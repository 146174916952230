import { useQuery } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputForm } from "form-builder";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { GET_COMPANY_SETTINGS_MENU } from "../../Graphql/query";
import {
  ADDITIONAL_SHIFT,
  CHANGE_SHIFT,
  DAY_OFF,
} from "../../Constants/Requests";
import HelperFns, {
  normalizeCompanySettingsPresetFetchedData,
} from "../../Helpers/HelperFns";
import { IconButton } from "@mui/material";
import MiscellanousSettingsModal from "./MiscellanousSettingsModal";
import { useDispatch } from "react-redux";
import { onFormResetAction } from "../../Store/Actions";

const formName = "miscellanousForm";

const MiscellanousSettings = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [modal, setModal] = useState({
    isOpen: false,
    section: null,
  });

  const { loading, data, refetch } = useQuery(GET_COMPANY_SETTINGS_MENU);

  const dayOffRequestConfig =
    data?.getCompanySetting?.request_configuration_settings?.find(
      (conf) => conf?.request_type_id == DAY_OFF
    ) ?? {};

  const changeShiftRequestConfig =
    data?.getCompanySetting?.request_configuration_settings?.find(
      (conf) => conf?.request_type_id == CHANGE_SHIFT
    ) ?? {};

  const additionalRequestConfig =
    data?.getCompanySetting?.request_configuration_settings?.find(
      (conf) => conf?.request_type_id == ADDITIONAL_SHIFT
    ) ?? {};

  const handleEditSection = (section) => {
    setModal({
      isOpen: true,
      section,
    });
    dispatch(
      onFormResetAction(
        formName,
        normalizeCompanySettingsPresetFetchedData(data?.getCompanySetting ?? {})
      )
    );
  };

  const handleClose = () => {
    setModal({
      isOpen: false,
      section: null,
    });
  };

  return (
    <div>
      <div className="my-4 border p-4 rounded">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <h4 className="text-dark">{t("probation settings")}</h4>
          <IconButton onClick={() => handleEditSection("probation")}>
            <FontAwesomeIcon
              icon="fa-regular fa-pen-to-square"
              style={{
                fontSize: 18,
                color: "#23aaeb",
              }}
            />
          </IconButton>
        </div>
        <InputForm
          disabled
          label="Probation Period"
          hasSuffix
          suffixTitle={data?.getCompanySetting?.probation_unit ?? "--"}
          value={data?.getCompanySetting?.probation_value ?? "--"}
          containerStyle="d-flex justify-content-between align-items-center"
        />
      </div>

      <div className="my-4 border p-4 rounded">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <h4 className="text-dark">{t("requests limits")}</h4>
          <IconButton onClick={() => handleEditSection("requests limits")}>
            <FontAwesomeIcon
              icon="fa-regular fa-pen-to-square"
              style={{
                fontSize: 18,
                color: "#23aaeb",
              }}
            />
          </IconButton>
        </div>
        <div className="d-flex flex-column gap-10">
          <InputForm
            disabled
            label="Dayoff request limit"
            hasSuffix
            suffixTitle={"days"}
            value={dayOffRequestConfig?.request_limits ?? "--"}
            containerStyle="d-flex justify-content-between align-items-center"
          />
          <InputForm
            disabled
            label="Change shift request limit"
            hasSuffix
            suffixTitle={"days"}
            value={changeShiftRequestConfig?.request_limits ?? "--"}
            containerStyle="d-flex justify-content-between align-items-center"
          />
          <InputForm
            disabled
            label="Additional shift request limit"
            hasSuffix
            suffixTitle={"days"}
            value={additionalRequestConfig?.request_limits ?? "--"}
            containerStyle="d-flex justify-content-between align-items-center"
          />
        </div>
      </div>

      <div className="my-4 border p-4 rounded">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <h4 className="text-dark">
            {t("end of day behaviour (flexible work schedule)")}
          </h4>
          <IconButton onClick={() => handleEditSection("flex shifts linking")}>
            <FontAwesomeIcon
              icon="fa-regular fa-pen-to-square"
              style={{
                fontSize: 18,
                color: "#23aaeb",
              }}
            />
          </IconButton>
        </div>
        {data?.getCompanySetting?.allow_linked_shifts ? (
          data?.getCompanySetting?.time_limit ? (
            <InputForm
              disabled
              label="resume shifts if the employee clocks in within"
              hasSuffix
              suffixTitle="minutes"
              value={
                HelperFns.timeToMinutes(data?.getCompanySetting?.time_limit) ??
                "--"
              }
              containerStyle="d-flex justify-content-between align-items-center"
            />
          ) : (
            <p>{t("shifts linking is allowed")}</p>
          )
        ) : (
          <p>{t("shifts linking is not allowed")}</p>
        )}
      </div>

      {modal?.isOpen ? (
        <MiscellanousSettingsModal
          modalData={modal}
          handleClose={handleClose}
          refetch={refetch}
        />
      ) : null}
    </div>
  );
};

export default MiscellanousSettings;
