import React, { useEffect, useState } from "react";
import { InputForm, RadioboxForm } from "form-builder";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toggleAcceptAssetRequestModal } from "../../../Store/Actions";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_COMPANY_ASSETS_REQUESTS } from "../../../Graphql/query";
import DataTable from "react-data-table-component";
import Loader from "../../../Components/Loader";
import Pagination from "../../../Components/Pagination";
import {
  AcceptIconButton,
  TimesIconButton,
} from "../../../Components/IconButtonWithTooltip";
import Swal from "sweetalert2";
import { REJECT_ASSET_REQUEST } from "../../../Graphql/mutation";
import { showToast } from "../../../Helpers/HelperFns";
import AcceptAssetRequestModal from "../EmployeeAssets/AcceptAssetRequestModal";
import HasPrivileges from "../../../Helpers/HOC/HasPrivileges";
import Privilages from "../../../Constants/Privilages";

const paginationInitState = {
  total: 20,
  perPage: 20,
  lastPage: 1,
  lastItem: 20,
  firstItem: 1,
  currentPage: 1,
  hasMorePages: false,
};

const AssetRequests = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("all");

  const [pagination, setPagination] = useState(paginationInitState);

  const isAcceptRequestModalOpen = useSelector(
    (state) => state?.assets?.acceptAssetRequestModal?.isOpen
  );

  const [fetchCompanyAssetsRequests, { loading, data, refetch }] = useLazyQuery(
    GET_COMPANY_ASSETS_REQUESTS,
    {
      variables: {
        page: pagination?.currentPage,
        input: {
          filters: {
            ...(!!search
              ? {
                  name: search,
                }
              : {}),
            ...(status != "all"
              ? {
                  AssetRequestScope: status,
                }
              : {}),
          },
        },
      },
      onCompleted: (data) => {
        setPagination(data?.companyAssetRequests?.paginatorInfo);
      },
    }
  );

  useEffect(() => {
    fetchCompanyAssetsRequests();
  }, []);

  const companyAssetRequests = data?.companyAssetRequests?.data ?? [];

  const handleRefetchData = () => {
    fetchCompanyAssetsRequests();
  };

  const handleChange = (e) => {
    setSearch(e?.target?.value ?? "");
  };

  const handlePaginate = (page = pagination?.currentPage) => {
    setPagination((prev) => ({ ...prev, currentPage: page }));
  };

  const [attemptRejectAssetRequest] = useMutation(REJECT_ASSET_REQUEST, {
    onCompleted: (data) => {
      if (data?.rejectAssetRequest?.__typename == "GeneralException") {
        showToast("error", data?.rejectAssetRequest?.message);

        return;
      }

      handleRefetchData();
    },
    onError(err) {
      showToast(
        "error",
        err?.graphQLErrors?.[0].extensions?.reason ?? err?.message
      );
    },
  });

  const handleAccept = (request) => {
    dispatch(toggleAcceptAssetRequestModal({ isOpen: true, request }));
  };

  const handleReject = (request) => {
    Swal.fire({
      icon: "warning",
      title: t("are you sure"),
      showConfirmButton: true,
      confirmButtonText: t("Yes"),
      confirmButtonColor: "#7cd1f9",
      showDenyButton: true,
      denyButtonText: t("No"),
      showCancelButton: false,
      customClass: {
        popup: "swal-warning-style",
        icon: "swal-icon",
        denyButton: "order-2",
        confirmButton: "order-3",
        htmlContainer: "attendance-type-alert-text",
      },
    }).then(async (values) => {
      if (values.isConfirmed) {
        attemptRejectAssetRequest({
          variables: {
            id: request?.id,
          },
        });
      }
    });
  };

  const columns = [
    {
      name: t("employee"),
      cell: (assetRequest) => (
        <p className="m-0">{assetRequest?.employee?.user?.name}</p>
      ),
    },

    {
      name: t("type"),
      cell: (assetRequest) => (
        <p className="m-0">{assetRequest?.assetType?.name}</p>
      ),
    },

    {
      width: "100px",
      cell: (request) => {
        if (request?.status?.toLowerCase() == "pending") {
          return (
            <div className="d-flex align-items-center gap-5">
              <HasPrivileges
                allowBP
                reqireMain={[Privilages.MANAGE_ASSET_REQUEST]}
              >
                <AcceptIconButton onClick={() => handleAccept(request)} />
                <TimesIconButton
                  label="reject"
                  onClick={() => handleReject(request)}
                />
              </HasPrivileges>
            </div>
          );
        } else {
          return <p className="m-0">{request?.status}</p>;
        }
      },
    },
  ];

  return (
    <div className="py-3">
      <div className="d-flex align-items-center justify-content-between">
        <RadioboxForm
          options={[
            { label: "all", value: "all" },
            { label: "accepted", value: "accepted" },
            { label: "rejected", value: "rejected" },
            { label: "pending", value: "pending" },
          ]}
          value={status}
          onChange={(e) => setStatus(e?.target?.value)}
          labelStyle="d-block font-weight-bold gray-color"
          optionsContainerStyle="penalty-days-style fit-width flex-md-row flex-column"
          optionItemStyle=" "
          optionLabelStyle="mb-0 mr-3"
          optionInputStyle=" "
          containerStyle="d-flex flex-wrap align-items-center gap-20"
        />

        <InputForm
          placeholder={t("search")}
          containerStyle="w-100"
          icon="search"
          value={search}
          onChange={handleChange}
          rootStyle="w-100"
        />
      </div>

      <DataTable
        noHeader
        data={companyAssetRequests}
        columns={columns}
        className="cards_table my-3"
        progressPending={loading}
        progressComponent={<Loader />}
        pagination
        paginationServer
        paginationComponent={() => (
          <Pagination
            styleWraper=""
            onPaginate={handlePaginate}
            customPaginator={pagination}
          />
        )}
      />

      {isAcceptRequestModalOpen ? (
        <AcceptAssetRequestModal handleRefetch={handleRefetchData} />
      ) : null}
    </div>
  );
};

export default AssetRequests;
