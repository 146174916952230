import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { AddButton } from "../../../Components/Buttons";
import ClockinCard from "../../../Containers/FlexibleLists/ClockinCard";
import MyClockinCard from "./MyClockinCard";
import { useDispatch, useSelector } from "react-redux";
import EditClockinsContainerModal from "../../../Components/FlexibleModals/EditClockinsContainerModal";
import { toggleEditClockinsContainer } from "../../../Store/Actions";
import { normalizedFetchedContainerForEditing } from "../../../Helpers/HelperFns";
import { EditIconButton } from "../../../Components/IconButtonWithTooltip";
import HasPrivileges from "../../../Helpers/HOC/HasPrivileges";
import Privilages from "../../../Constants/Privilages";

const MyClockinsContainer = ({
  container,
  refetchTimeSheet,
  editFlexAttendanceModal,
  setEditFlexAttendanceModal,
  isMyProfile = true,
  handleOpenClockinModal,
  ...props
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isClockinsOpened, setIsClockinsOpened] = useState(false);

  const handleToggleClockins = () => {
    setIsClockinsOpened((prev) => !prev);
  };

  const handleAddOrEditClockin = (clockInId = null) => {
    handleOpenClockinModal(container?.id, clockInId, container?.status);
  };

  const workdayTemplateName = container?.flexWorkDayTemplate?.name;
  const penalties = container?.flexPenalties;
  const clockins = container?.clockins;
  const totalClockIns =
    container?.calculatedExtraDataPerContainer?.totalClockIns;
  const totalHours = container?.calculatedExtraDataPerContainer?.totalHours;

  const actualTotalHours =
    container?.calculatedExtraDataPerContainer?.actualTotalHours;
  const totalOverTime =
    container?.calculatedExtraDataPerContainer?.totalOverTime;
  const actualTotalOverTime =
    container?.calculatedExtraDataPerContainer?.actualTotalOverTime;
  const totalLeave = container?.calculatedExtraDataPerContainer?.totalLeave;

  const isContainerDayoffOrHoliday =
    container?.status?.toLowerCase() == "day off" ||
    container?.status?.toLowerCase() == "holiday";

  const handleEditContainer = (container) => {
    dispatch(
      toggleEditClockinsContainer({
        modal: {
          isOpen: true,
        },
        form: normalizedFetchedContainerForEditing(container),
      })
    );
  };

  return (
    <div>
      <div
        onClick={handleToggleClockins}
        className="clockins-container"
        style={{ backgroundColor: "#f7f7f7", cursor: "pointer" }}
      >
        {isContainerDayoffOrHoliday ? (
          <>
            {/* <p className="clockins-container-date">
              <div className="new-entry-button">
                <AddButton
                  label="new entry"
                  className="text-nowrap"
                  onClick={() => handleAddOrEditClockin(null)}
                />
              </div>
            </p> */}
            <p className="font-weight-bold clockins-container-total-hours">
              {t(container?.status)}
            </p>
          </>
        ) : (
          <>
            <div className="clockins-container-workday-template-name">
              {workdayTemplateName}
              {!!penalties?.length ? (
                <Tooltip arrow placement="top" title={t("penalties")}>
                  <InfoOutlined fontSize="small" color="inherit" />
                </Tooltip>
              ) : null}
            </div>

            <HasPrivileges
              allowBP
              reqireMain={[Privilages.MANAGE_TIMESHEET_RECORDS]}
            >
              <p className="clockins-container-date">
                <div className="new-entry-button">
                  <AddButton
                    label="new entry"
                    onClick={() => handleAddOrEditClockin(null)}
                  />
                </div>
              </p>
            </HasPrivileges>

            <div className="clockins-container-total-sign-ins d-flex align-items-center">
              <div className="mr-2">{t("total sign-ins")}</div>
              <div>{totalClockIns}</div>
            </div>

            <div className="clockins-container-total-hours d-flex align-items-center">
              <div className="font-weight-bold mr-2">{t("total hours")}</div>
              <div>
                {totalHours}({actualTotalHours})
              </div>
            </div>

            <div className="clockins-container-overtime d-flex align-items-center">
              <div className="font-weight-bold mr-2">{t("overtime")}</div>
              <div>
                {totalOverTime}({actualTotalOverTime})
              </div>
            </div>

            <div className="clockins-container-leave d-flex align-items-center">
              <div className="font-weight-bold mr-2">leave</div>
              <div>{totalLeave}</div>
            </div>
          </>
        )}

        <HasPrivileges
          allowBP
          reqireMain={[Privilages.MANAGE_TIMESHEET_RECORDS]}
        >
          <EditIconButton
            className="clockins-container-edit"
            onClick={() => handleEditContainer(container)}
          />
        </HasPrivileges>
      </div>

      {isClockinsOpened
        ? clockins?.map((clockIn, index) => (
            <>
              <MyClockinCard
                key={clockIn?.id}
                sign_in_order={t("sign-in-index", {
                  index: index + 1,
                  suffix:
                    index + 1 == 1
                      ? "st"
                      : index + 1 == 2
                        ? "nd"
                        : index + 1 == 3
                          ? "rd"
                          : "th",
                })}
                clockIn={clockIn}
                hasPenalties={!!penalties?.length}
                refetchTimeSheet={refetchTimeSheet}
                editFlexAttendanceModal={editFlexAttendanceModal}
                setEditFlexAttendanceModal={setEditFlexAttendanceModal}
                {...props}
                isMyProfile={isMyProfile}
                handleAddOrEditClockin={handleAddOrEditClockin}
              />
            </>
          ))
        : null}
    </div>
  );
};

export default MyClockinsContainer;
