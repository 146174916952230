import React from "react";
import { useMutation } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";

import { showToast } from "../../Helpers/HelperFns";
import { onFormResetAction } from "../../Store/Actions";
import { addCreditMutation } from "../../Graphql/mutation/PaymentTracking";

import MainModal from "../MainModal";
import { InputForm } from "form-builder";

const reducer = "paymentTracking";
const formName = "addCreditModal";
const formNameValidation = "paymentTrackingClientValidation";
const formServerValidation = "paymentTrackingServerValidation";

const AddCreditModal = ({ data, onClose, refetchList }) => {
  const dispatch = useDispatch();

  // Local State
  const [errMsg, setErrMsg] = React.useState("");
  const [formSubmitting, setFormSubmitting] = React.useState(false);

  // Server State
  const [addCredit, { loading: addCreditLoading }] =
    useMutation(addCreditMutation);

  // Reducer State
  const formData = useSelector((state) => state?.[reducer]?.[formName]);
  const formClientValidation = useSelector(
    (state) => state?.[reducer]?.[formNameValidation]
  );

  // Constants
  const FormProps = {
    reducer,
    formName,
    formSubmitting,
    formNameValidation,
    formServerValidation,
  };

  /* ↓ Helpers ↓ */

  const handleCloseModal = () => {
    onClose();
    dispatch(onFormResetAction(formName));
    dispatch(onFormResetAction(formNameValidation));
    dispatch(onFormResetAction(formServerValidation));
  };

  const isInvalidClientValidation = () => {
    if (formClientValidation.length) return true;

    if (isNaN(formData?.amount)) {
      setErrMsg("You should enter a numeric value as an amount");
      return true;
    }
  };

  const handleSubmit = () => {
    setFormSubmitting(true);
    if (isInvalidClientValidation()) return;

    addCredit({
      variables: { companyId: +data?.company_id, amount: formData?.amount },
      onCompleted: () => {
        refetchList();
        handleCloseModal();
        showToast("success");
      },
      onError: (err) => {
        showToast(
          "error",
          err?.graphQLErrors?.[0]?.extensions?.reason ||
            err?.graphQLErrors?.[0]?.message ||
            err?.message
        );
      },
    });
  };

  return (
    <MainModal
      isOpen
      toggle={handleCloseModal}
      btnOnClick={handleSubmit}
      modalTitle="Attach Credit"
      btnSubmitLoading={addCreditLoading}
    >
      <InputForm
        {...FormProps}
        name="amount"
        label="amount"
        labelStyle="mb-2"
        containerStyle="w-100"
        validateBy="textRequired"
      />

      {errMsg ? (
        <p role="alert" className="red-color mt-2 mb-0">
          {errMsg}
        </p>
      ) : null}
    </MainModal>
  );
};

export default AddCreditModal;
