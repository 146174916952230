import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import MainModal from "../../Components/MainModal";
import { ColorButton } from "../../Components/Buttons";
import { useDispatch, useSelector } from "react-redux";
import {
  onFormResetAction,
  toggleActivationSuspensionRecordsModal,
  toggleEditActivationModal,
  toggleActivationModal,
  addEmployeeIdToActivation,
  fetchInitSuspendManagerAction,
} from "../../Store/Actions";
import { useLazyQuery } from "@apollo/client";
import { fetchSuspensionActivationRecordsQuery } from "../../Graphql/query";
import DataTable from "react-data-table-component";
import Loader from "../../Components/Loader";
import IconButtonWithTooltip from "../../Components/IconButtonWithTooltip";
import { Edit } from "@mui/icons-material";
import { showToast } from "../../Helpers/HelperFns";
import EditActiviationModal from "./EditActiviationModal";

const ActivaitionSuspensionRecordsModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Redux States
  const activiationSuspensionModal = useSelector(
    (state) => state?.super?.activiationSuspensionModal
  );
  const isEditActivationModalOpen = useSelector(
    (state) => state?.super?.editActivationModal?.isOpen
  );

  // constant for flex user condition
  const isFlexUser =
    activiationSuspensionModal?.modalData?.employee?.__typename ===
    "FlexEmployee";

  // handle fetch activation and suspension list
  const handleFetchActivationSuspensionList = () => {
    fetchActivationSuspensionList({
      variables: {
        employeeID: activiationSuspensionModal?.modalData?.id,
        isFlexUser: (isFlexUser ),
      },
    });
  };

  // Start of handle fetch activation and suspension query
  const [
    fetchActivationSuspensionList,
    { data: dataList, loading: listLoading },
  ] = useLazyQuery(fetchSuspensionActivationRecordsQuery, {
    onError(err) {
      // error toaster
      showToast(
        "error",
        err?.graphQLErrors[0]?.extensions?.reason ||
          err?.graphQLErrors[0]?.message ||
          err?.message
      );
    },
    onCompleted: (data) => {
      
    },
  });
  // End of handle fetch activation and suspension query

  // Server State
  useEffect(() => {
    handleFetchActivationSuspensionList();
  }, []);

  /* ↓ Helpers ↓ */

  // handle edit record function
  const handleEdit = (record, index) => {
    

    let editData = {
      user_id: activiationSuspensionModal?.modalData?.id,
      from: index === 0 ? record?.from : "",
      old_from: record?.from,
      ...(isFlexUser 
        ? {
            to:
              index ===
              dataList?.getFlexActiveAndSuspensionIntervals?.length - 1
                ? record?.to
                : "",
            disableToDatePicker:
              index ===
              dataList?.getFlexActiveAndSuspensionIntervals?.length - 1
                ? true
                : false,
          }
        : {
            to:
              index === dataList?.getActiveAndSuspensionIntervals?.length - 1
                ? record?.to
                : "",
            disableToDatePicker:
              index === dataList?.getActiveAndSuspensionIntervals?.length - 1
                ? true
                : false,
          }),
      old_to: record?.to,
      employee_attendance: "",
      disbaleFromDatePicker: index === 0 ? true : false,
    };

    dispatch(toggleEditActivationModal({ isOpen: true }));
    dispatch(onFormResetAction("editActiviationModalForm", { ...editData }));
  };

  // handle suspend action btn function
  const handleSuspensionBtn = () => {
    // dismiss modal
    handleDismissModal();

    dispatch(
      fetchInitSuspendManagerAction(activiationSuspensionModal?.modalData?.id)
    );
  };

  // handle activation action btn function
  const handleActivationBtn = () => {
    // dismiss modal
    handleDismissModal();

    dispatch(
      toggleActivationModal(
        activiationSuspensionModal?.modalData?.attendance_profile
          ?.activeWorkTiming?.overtime_permissible,
        activiationSuspensionModal?.modalData?.office?.country?.id
      )
    );
    dispatch(
      addEmployeeIdToActivation(
        activiationSuspensionModal?.modalData.id,
        activiationSuspensionModal?.modalData?.office,
        activiationSuspensionModal?.modalData?.office?.country?.id
      )
    );
  };

  // handle dismiss modal function
  const handleDismissModal = () => {
    dispatch(
      toggleActivationSuspensionRecordsModal({
        isOpen: false,
        modalData: null,
      })
    );
  };

  // data table columns
  const columns = [
    {
      name: t("from"),
      wrap: true,
      grow: 1,
      cell: (row) => (
        <>
          {row?.from != null
            ? moment(row?.from).format("DD - MM - YYYY")
            : "------------------"}
        </>
      ),
    },
    {
      name: t("to"),
      wrap: true,
      grow: 1,
      cell: (row) => (
        <>
          {row?.to != null
            ? moment(row?.to).format("DD - MM - YYYY")
            : "------------------"}
        </>
      ),
    },
    {
      name: t("action"),
      wrap: true,
      grow: 1,
      cell: (row) => (
        <div
          style={{
            color:
              row?.status.toLowerCase() === "active" ? "#27b40c" : "#ff6a6a",
          }}
        >
          {t(row?.status)}
        </div>
      ),
    },
    {
      grow: 0.25,
      cell: (row, index) => (
        <div>
          <HasPrivileges
            reqireMain={[Privilages.ACTIVATE_SUSPEND_EMPLOYEES]}
            allowBP
          >
            {row?.status.toLowerCase() === "active" ? (
              <IconButtonWithTooltip
                label="edit"
                onClick={() => handleEdit(row, index)}
                icon={<Edit fontSize="small" sx={{ color: "#5c6974" }} />}
              />
            ) : null}
          </HasPrivileges>
        </div>
      ),
    },
  ];

  return (
    <>
      {listLoading ? (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      ) : (
        <MainModal
          isOpen={activiationSuspensionModal?.isOpen}
          toggle={handleDismissModal}
          className="suspension_activation_modal_wrapper_style"
          modalTitle={`${t("Suspension/Activation Records For")} ${
            activiationSuspensionModal?.modalData?.name
          } - ${t("status")} ${
            activiationSuspensionModal?.modalData?.active === 1
              ? t("active")
              : t("suspended")
          }`}
        >
          <>
            <div className="d-flex align-items-center gap-10 justify-content-end">
              <HasPrivileges
                reqireMain={[Privilages.ACTIVATE_SUSPEND_EMPLOYEES]}
                allowBP
              >
                {activiationSuspensionModal?.modalData?.active === 1 && (
                  <ColorButton
                    onClick={handleSuspensionBtn}
                    color="#ff6a6a"
                    label="suspend"
                  />
                )}

                <ColorButton
                  onClick={handleActivationBtn}
                  color="#27b40c"
                  label="activate"
                />
              </HasPrivileges>
            </div>

            <DataTable
              noHeader
              data={
                isFlexUser 
                  ? (dataList?.getFlexActiveAndSuspensionIntervals ?? [])
                  : (dataList?.getActiveAndSuspensionIntervals ?? [])
              }
              columns={columns}
              className="cards_table my-3"
            />

            {/* Start of edit activation record modal */}
            {isEditActivationModalOpen ? (
              <EditActiviationModal
                handleRefetch={handleFetchActivationSuspensionList}
                workTimingOptions={
                  isFlexUser 
                    ? (dataList?.flex_Work_Day_Template_Menu ?? [])
                    : (dataList?.work_timings_menu ?? [])
                }
                workPlaceOptions={dataList?.company_offices?.data ?? []}
                isFlexUser={isFlexUser }
              />
            ) : null}
            {/* End of edit activation record modal */}
          </>
        </MainModal>
      )}
    </>
  );
};

export default ActivaitionSuspensionRecordsModal;
