import React, { useEffect } from "react";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  onFormResetAction,
  onInputResetAction,
  onInputResetWithValueAction,
  showErrorToast,
} from "../../Store/Actions";

import MainModal from "../MainModal";
import { RadioboxForm, InputForm, CheckboxBooleanForm } from "form-builder";
import { UPSERT_MONTHLY_PROFILE } from "../../Graphql/mutation";
import { treatExtraHoursAsOptions } from "../../Constants";
import { normalizeUpsertFlexMonthlyProfile } from "../../Helpers/HelperFns";

const reducer = "flex";
const formName = "MonthlyProfileForm";
const formNameValidation = "flexibleClientValidation";
const formServerValidation = "flexibleServerValidation";

const WrapperComponent = ({ children, isModal, ...props }) => {
  if (isModal) {
    return <MainModal {...props}>{children}</MainModal>;
  } else {
    return children;
  }
};

const MonthlyProfileForm = ({
  isOpen,
  handleCloseModal: onClose,
  refetchMonthlyProfiles,
  isModal = false,
  formSubmitting,
  isMonthly = false,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Local State
  // const [formSubmitting, setFormSubmitting] = React.useState(false);

  // Reducer State
  const formData = useSelector((state) => state?.[reducer]?.[formName]);

  const formClientValidation = useSelector(
    (state) => state?.[reducer]?.[formNameValidation]
  );

  const [
    attemptUpsertMonthlyProfile,
    { loading: upsertMonthlyProfileLoading },
  ] = useMutation(UPSERT_MONTHLY_PROFILE, {
    variables: {
      input: normalizeUpsertFlexMonthlyProfile(formData),
    },
    onCompleted: () => {
      refetchMonthlyProfiles();
      handleCloseModal();
    },
    onError: (err) => {
      dispatch(
        showErrorToast(
          err?.graphQLErrors[0]?.extensions?.reason ||
            err?.graphQLErrors[0]?.message ||
            "Something went wrong"
        )
      );
    },
  });

  // Constants
  const FormProps = {
    reducer,
    formName,
    formNameValidation,
    formServerValidation,
    formSubmitting,
  };

  /* ↓ Helpers ↓ */

  const handleCloseModal = () => {
    onClose();
    dispatch(onFormResetAction(formName));
    dispatch(onFormResetAction(formNameValidation));
    dispatch(onFormResetAction(formServerValidation));
    // setFormSubmitting(false);
  };

  const handleSubmit = () => {
    // setFormSubmitting(true);

    if (formClientValidation?.length) {
      return;
    }

    attemptUpsertMonthlyProfile();
  };

  useEffect(() => {
    dispatch(
      onInputResetWithValueAction(formName, "applyRestrictionsPer", "monthly")
    );
  }, []);

  return (
    <WrapperComponent
      isOpen={isOpen}
      modalTitle={formData?.id ? "Edit Monthly Profile" : "New Monthly Profile"}
      toggle={handleCloseModal}
      btnOnClick={handleSubmit}
      btnSubmitLoading={upsertMonthlyProfileLoading}
      isModal={isModal}
    >
      <div className="monthly-profile-form-style">
        {/* <InputForm
          {...FormProps}
          name="name"
          label="name"
          placeholder={t("name")}
          rootStyle="w-100"
          containerStyle="w-100"
          validateBy="textRequired"
          // icon="search"
        /> */}

        <CheckboxBooleanForm
          {...FormProps}
          name="setRestrictions"
          containerStyle="my-3"
          options={["Set restrictions to the number of work Hrs"]}
        />

        {formData?.setRestrictions ? (
          <>
            <div className="options_container">
              <span className="options_container_title">{t("Work Hours")}</span>
              <div
                className={"d-flex justify-content-between align-items-center"}
              >
                <CheckboxBooleanForm
                  {...FormProps}
                  name="setMinimumHrs"
                  containerStyle="my-3"
                  options={
                    isMonthly
                      ? ["Set minimum Monthly Hrs"]
                      : ["Set minimum Weekly Hrs"]
                  }
                />

                {formData?.setMinimumHrs ? (
                  <InputForm
                    {...FormProps}
                    name="minimumHrs"
                    labelStyle="mr-2"
                    hasSuffix
                    suffixTitle="hours"
                    validateBy={"number_req_non_negative"}
                    validationName="input.salaryConfiguration.flexSettings.minimum_work_hours"
                  />
                ) : null}
              </div>
              {formData?.setMinimumHrs ? (
                <>
                  <InputForm
                    {...FormProps}
                    name="penaltyFactor"
                    label="Calculate penalty by multiplying the missing Hrs by"
                    containerStyle="d-flex align-items-center justify-content-between"
                    stepInput
                    validateBy={"number_req_non_negative"}
                    validationName="input.salaryConfiguration.flexSettings.penalty_factor"
                  />
                  <RadioboxForm
                    {...FormProps}
                    name="deductFrom"
                    label="Deduct Penalty From"
                    options={[
                      { label: "Annual Leaves", value: "Leaves" },
                      { label: "Salary", value: "Salary" },
                    ]}
                    validateBy="textRequired"
                    optionItemStyle=" "
                    optionInputStyle=" "
                    containerStyle="d-flex align-items-center justify-content-between mt-2 mb-3"
                    optionsContainerStyle="d-flex gap-10"
                    validationName="input.salaryConfiguration.flexSettings.deduct_from"
                  />
                </>
              ) : null}
            </div>

            <div className="options_container">
              <span className="options_container_title">{t("Overtime")}</span>
              <CheckboxBooleanForm
                {...FormProps}
                name="calculateOvertime"
                containerStyle="my-3"
                options={["Calculate Overtime hours"]}
              />
              {formData?.calculateOvertime ? (
                <>
                  <InputForm
                    {...FormProps}
                    name="hoursPerMonth"
                    label={
                      isMonthly
                        ? "Normal Work hours Per Month"
                        : "Normal Work hours Per Week"
                    }
                    containerStyle="d-flex align-items-center justify-content-between mb-2"
                    hasSuffix
                    suffixTitle="hours"
                    validateBy={"number_req_non_negative"}
                    validationName="input.salaryConfiguration.flexSettings.total_hours"
                  />

                  <InputForm
                    {...FormProps}
                    name="calculateOvertimeAfterWorkHoursBy"
                    label="Calculate Overtime after normal Hrs by"
                    containerStyle="d-flex align-items-center justify-content-between"
                    validateBy={"number_req_non_negative"}
                    hasSuffix
                    suffixTitle="minutes"
                    validationName="input.salaryConfiguration.flexSettings.calculate_overtime_after"
                    rootStyle="mb-2"
                  />

                  <InputForm
                    {...FormProps}
                    name="overtimeHourRate"
                    label="overtime hours rate"
                    containerStyle="d-flex align-items-center justify-content-between"
                    stepInput
                    validateBy={"number_req_non_negative"}
                    validationName="input.salaryConfiguration.flexSettings.overtime_hour_rate"
                    rootStyle="mb-2"
                  />

                  <InputForm
                    {...FormProps}
                    name="overtimeFactor"
                    label="overtime payment factor"
                    containerStyle="d-flex align-items-center justify-content-between"
                    stepInput
                    validateBy={"number_req_non_negative"}
                    validationName="input.salaryConfiguration.flexSettings.payment_factor"
                  />

                  <div
                    style={{ height: 40 }}
                    className={
                      "d-flex justify-content-between align-items-center my-3"
                    }
                  >
                    <CheckboxBooleanForm
                      {...FormProps}
                      name="setOvertimeMonthLimit"
                      containerStyle="my-3"
                      options={[
                        isMonthly
                          ? "Set overtime limit per Month"
                          : "Set overtime limit per Week",
                      ]}
                      validationName="input.salaryConfiguration.flexSettings.overtime_monthly_limit"
                    />
                    {formData?.setOvertimeMonthLimit ? (
                      <InputForm
                        {...FormProps}
                        name="overtimeMonthLimit"
                        labelStyle="mr-2"
                        validateBy={"number_req_non_negative"}
                        hasSuffix
                        suffixTitle="hours"
                      />
                    ) : null}
                  </div>

                  <div
                    style={{ height: 40 }}
                    className={
                      "d-flex justify-content-between align-items-center my-3"
                    }
                  >
                    <CheckboxBooleanForm
                      {...FormProps}
                      name="setMaxHrsPerMonth"
                      containerStyle="my-3"
                      options={[
                        isMonthly
                          ? "Set maximum work Hours per month"
                          : "Set maximum work Hours per week",
                      ]}
                      validationName="input.salaryConfiguration.flexSettings.maximum_work_hours"
                    />
                    {formData?.setMaxHrsPerMonth ? (
                      <InputForm
                        {...FormProps}
                        name="maxHrsPerMonth"
                        labelStyle="mr-2"
                        validateBy={"number_req_non_negative"}
                        hasSuffix
                        suffixTitle="hours"
                      />
                    ) : null}
                  </div>

                  {formData?.setOvertimeMonthLimit &&
                  formData?.setMaxHrsPerMonth ? (
                    <RadioboxForm
                      {...FormProps}
                      name="treatExtraHoursAs"
                      validateBy="textRequired"
                      label="Treat the extra hours (more than the overtime limit) as"
                      keepDefaultStyle
                      options={treatExtraHoursAsOptions}
                      containerStyle="my-2"
                      labelStyle="label-style font-weight-bold"
                      optionItemStyle="mr-3"
                      optionInputStyle=" "
                      optionLabelStyle="optionLabelStyle"
                      validationName="input.salaryConfiguration.flexSettings.treat_extra_overtime_hours_as"
                    />
                  ) : null}
                </>
              ) : null}
            </div>
          </>
        ) : null}
      </div>
    </WrapperComponent>
  );
};

export default MonthlyProfileForm;
