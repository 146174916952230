import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";

import {
  fetchAllEmployeesAction,
  changeToListDataViewAction,
  changeToGridDataViewAction,
  // changeEmployeeData,
  // updateEmployeeBreakStatus,
  showExportEmployeeListModal,
  onFormResetAction,
} from "../../Store/Actions";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload, faIdCard } from "@fortawesome/free-solid-svg-icons";
import {
  InputForm,
  CheckboxForm,
  CheckboxBooleanForm,
  BSelect,
} from "form-builder";
import Tooltip from "@mui/material/Tooltip";
import HrEmployees from "../../Containers/HrEmployees";
import HrEmployeesList from "../../Containers/HrEmployeesList";
import ToggleModal from "../../Containers/HrEmployeesList/ToggleModal";
import EditEmployeeModal from "../../Components/HrEmployeeCard/EditEmployeeModal";
import SuspensionModal from "../../Components/HrEmployeeCard/SuspensionModal";
import ActivationModal from "../../Components/HrEmployeeCard/ActivationModal";
import ManagePrivilagesModal from "../../Components/HrEmployeeCard/ManagePrivilagesModal";
import ConfirmDeleteManagerModal from "../../Components/ConfirmDeleteManagerModal";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import Loader from "../../Components/Loader";
import FloatingMenu from "../../Components/FloatingMenu";
import { Box, Divider } from "@mui/material";
import { BarsIconButton } from "../../Components/IconButtonWithTooltip";
import { ColorButton } from "../../Components/Buttons";
import { Add, Apps, FormatListBulleted } from "@mui/icons-material";
import SignInOutOnBehalfModal from "../../Components/HrEmployeeCard/SignInOutOnBehalfModal";
import ExportModal from "../../Containers/HrEmployeesList/ExportModal";
import ActivaitionSuspensionRecordsModal from "../../Components/ActivaitionSuspensionRecordsModal";

const filterSusbendOptions = [
  { label: "active", value: "Active" },
  { label: "suspended", value: "Suspended" },
];

const filterOptions = [
  { label: "Present", value: "Present" },
  { label: "Absent", value: "Absent" },
  { label: "Late", value: "Late" },
  { label: "On Leave", value: "Leave" },
  { label: "Not Signed In", value: "Not signed in" },
  { label: "Day Off", value: "Day Off" },
  { label: "Holiday", value: "holiday" },
];

const dataTableRef = `allEmployeesList`;

const ViewHrEmployees = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Local State
  const [isToggleModalVissible, setIsToggleModalVissible] = useState(false);
  const [signOnBehalfModal, setSignOnBehalfModal] = React.useState({
    isOpen: false,
    data: null,
  });

  // Reducer State
  const hasActiveSubscription = useSelector(
    (state) => state.auth?.userProfile?.company?.hasActiveSubscription
  );
  const tableData = useSelector((state) => state.super?.[dataTableRef]);
  const allOffices = useSelector((state) => state.super?.allOffices);
  const allPositions = useSelector((state) => state.super?.allPositions);
  const managerOptions = useSelector((state) => state.super?.managerOptions);
  const listView = useSelector(
    (state) => state.super?.allEmployeesList?.listView
  );
  const allWorkTimingsMenu = useSelector(
    (state) => state.super?.allWorkTimingsMenu
  );
  const activationModalActions = useSelector(
    (state) => state.super?.activationModalActions
  );
  const allDepartmentsByCompany = useSelector(
    (state) => state.super?.allDepartmentsByCompany
  );
  const isResendCredentailsLoading = useSelector(
    (state) => state.super?.resendCredentailsLoading
  );
  const isInitDeleteManagerLoading = useSelector(
    (state) => state.super?.isInitDeleteManagerLoading
  );
  const isInitSuspendManagerLoading = useSelector(
    (state) => state.super?.isInitSuspendManagerLoading
  );
  const sendPhoneVerificationLoading = useSelector(
    (state) => state.super?.user?.sendPhoneVerificationLoading
  );
  const sendEmailVerificationLoading = useSelector(
    (state) => state.super?.user?.sendEmailVerificationLoading
  );
  const isListLoading = useSelector(
    (state) => state.super?.allEmployeesList?.isLoading
  );
  const canAddNewEmployee = useSelector(
    (state) => state?.super?.allEmployeesList?.total
  );
  const activiationSuspensionModalIsOpen = useSelector(
    (state) => state?.super?.activiationSuspensionModal?.isOpen
  );

  // Constants

  /* ↓ State Effects ↓ */

  useEffect(() => {
    fetchEmployeesData();
    return () => {
      dispatch(onFormResetAction(dataTableRef));
    };
  }, []);

  const useDidFilterUpdate = useDidUpdateEffect(
    () => handleFilter(),
    [
      tableData?.filter,
      tableData?.statusFilter,
      tableData?.suspendFilter,
      tableData?.from_home,
      tableData?.on_break,
      tableData?.filterByManager,
      tableData?.filterByOffice,
      tableData?.filterByPosition,
      tableData?.filterByDepartment,
      tableData?.showManagedEmployeesFilter,
      tableData?.work_timing_id,
      tableData?.directManaged,
      tableData?.on_probation,
      tableData?.is_blacklisted,
    ]
  );

  /* ↓ Helpers ↓ */

  const openSignOnBehalfModal = (data) => {
    setSignOnBehalfModal({ isOpen: true, data });
  };

  const handleFilter = () => {
    dispatch(
      fetchAllEmployeesAction({
        ref: dataTableRef,
        [dataTableRef]: {
          ...tableData,
          pagination: {
            ...tableData?.pagination,
            currentPage: 1,
          },
        },
      })
    );
  };

  const fetchEmployeesData = () => {
    dispatch(
      fetchAllEmployeesAction({
        ref: dataTableRef,
        [dataTableRef]: tableData,
      })
    );
  };

  const handlePaginate = (page = tableData?.pagination.currentPage) => {
    dispatch(
      fetchAllEmployeesAction({
        ref: dataTableRef,
        [dataTableRef]: {
          ...tableData,
          pagination: {
            ...tableData?.pagination,
            currentPage: page,
          },
        },
      })
    );
  };

  // Start of handling change of employee data list view
  const handleListDataViewChange = () => {
    dispatch(
      changeToListDataViewAction({
        dataTableRef,
      })
    );
    dispatch(
      fetchAllEmployeesAction({
        ref: dataTableRef,
        [dataTableRef]: {
          ...tableData,
          listView: true,
          pagination: {
            ...tableData?.pagination,
            currentPage: 1,
          },
        },
      })
    );
  };
  // End of handling change of employee data list view

  // Start of handling change of employee data grid view
  const handleGridDataViewChange = () => {
    dispatch(
      changeToGridDataViewAction({
        dataTableRef,
      })
    );
  };
  // End of handling change of employee data grid view

  return (
    <div className="hr-employee_content_wrapper_style">
      {isResendCredentailsLoading ||
      isInitDeleteManagerLoading ||
      sendPhoneVerificationLoading ||
      sendEmailVerificationLoading ||
      isInitSuspendManagerLoading ? (
        <Loader fixed />
      ) : null}

      {/* Filters */}
      <div className="row mt-3">
        <InputForm
          name="filter"
          formName={dataTableRef}
          labelStyle="focus-label mx-2"
          containerStyle=" "
          rootStyle="col-12 col-lg-6 mr-xl-auto"
          inputContainerStyle="form-group d-flex mb-0"
          placeholder={t("search")}
          icon="search"
        />
        <BSelect
          name="filterByOffice"
          optionLabel="name"
          optionValue="id"
          options={allOffices}
          formName={dataTableRef}
          placeholder={t("select office")}
          keepDefaultStyle
          containerStyle="form-group"
          rootStyle="col-md-6 col-xl-3"
          isClearable
          skipLocalization
          icon="office"
        />

        <BSelect
          name="filterByDepartment"
          optionLabel="name"
          optionValue="id"
          options={allDepartmentsByCompany}
          formName={dataTableRef}
          placeholder={t("select department")}
          keepDefaultStyle
          containerStyle="form-group"
          rootStyle="col-md-6 col-xl-3"
          isClearable
          className=""
          skipLocalization
          icon="department"
        />

        <BSelect
          name="filterByPosition"
          optionLabel="name"
          optionValue="id"
          options={allPositions}
          formName={dataTableRef}
          placeholder={t("select position")}
          keepDefaultStyle
          containerStyle="form-group"
          rootStyle="col-md-6 col-xl-3"
          isClearable
          className=""
          skipLocalization
          icon="person"
        />

        <BSelect
          name="filterByManager"
          optionLabel="name"
          optionValue="id"
          options={managerOptions}
          formName={dataTableRef}
          placeholder={t("select manager")}
          keepDefaultStyle
          containerStyle="form-group"
          rootStyle="col-md-6 col-xl-3"
          isClearable
          skipLocalization
          icon="person"
        />
        <BSelect
          name="work_timing_id"
          optionLabel="name"
          optionValue="id"
          options={allWorkTimingsMenu}
          formName={dataTableRef}
          placeholder={t("select work timing")}
          keepDefaultStyle
          containerStyle="form-group"
          rootStyle="col-md-6 col-xl-3"
          isClearable
          skipLocalization
          icon={<FontAwesomeIcon icon={faIdCard} />}
        />
      </div>

      <div className="d-flex flex-wrap gap-10 align-items-center justify-content-between mb-3 mt-2">
        <div className="d-flex gap-20">
          <HasPrivileges
            requireScope={[Privilages.VIEW_EMPLOYEES]}
            scope="all"
            allowBP
          >
            <CheckboxBooleanForm
              formName={dataTableRef}
              name="showManagedEmployeesFilter"
              options={[t("Only show employees I manage")]}
              type="checkbox"
              containerStyle="mt-1"
            />
          </HasPrivileges>
          <CheckboxBooleanForm
            formName={dataTableRef}
            name="directManaged"
            options={[t("Only show employees I manage directly")]}
            type="checkbox"
            containerStyle="mt-1"
            setWithValue
          />
          <CheckboxBooleanForm
            formName={dataTableRef}
            name="on_probation"
            options={[t("Only show employees on probation")]}
            type="checkbox"
            containerStyle="mt-1"
            setWithValue
          />
        </div>

        {/* Actions */}
        <div className="d-flex justify-content-end gap-10">
          <FloatingMenu
            list={
              <Box
                sx={{
                  my: 3,
                  mx: 2,
                  gap: 2,
                  height: 230,
                  minWidth: 320,
                  display: "flex",
                }}
              >
                <div>
                  <CheckboxForm
                    formName={dataTableRef}
                    options={filterSusbendOptions?.map(({ label, value }) => ({
                      label: t(label?.toLowerCase()),
                      value,
                    }))}
                    name="suspendFilter"
                    type="checkbox"
                    containerStyle="containerStyle"
                    optionsContainerStyle="d-flex flex-column"
                    optionItemStyle="optionItemStyle mb-2 w-60px"
                    optionInputStyle=" "
                    optionLabelStyle="optionLabelStyle"
                    optionLabelActiveStyle="optionLabelActiveStyle"
                  />
                  <CheckboxBooleanForm
                    formName={dataTableRef}
                    name="is_blacklisted"
                    options={[t("blacklisted")]}
                    type="checkbox"
                    setWithValue
                    labelStyle="checkbox"
                    containerStyle="mb-2"
                    optionsContainerStyle="d-flex flex-column"
                    optionItemStyle="optionItemStyle w-60px"
                    optionInputStyle="optionInputStyle w-100 h-10 mr-2 containerStyle checkbox"
                    optionLabelStyle="optionLabelStyle pt-1"
                    optionLabelActiveStyle="optionLabelActiveStyle"
                  />
                  <hr />
                  <CheckboxBooleanForm
                    formName={dataTableRef}
                    options={[t("home")]}
                    name="from_home"
                    type="checkbox"
                    labelStyle="checkbox"
                    containerStyle="my-3"
                    optionsContainerStyle="d-flex flex-column"
                    optionItemStyle="optionItemStyle w-60px"
                    optionInputStyle="optionInputStyle w-100 h-10 mr-2 containerStyle checkbox"
                    optionLabelStyle="optionLabelStyle pt-1"
                    optionLabelActiveStyle="optionLabelActiveStyle"
                  />
                  <HasPrivileges
                    reqireMain={[Privilages.VIEW_EMPLOYEES_ON_BREAK]}
                    allowBP
                  >
                    <CheckboxBooleanForm
                      formName={dataTableRef}
                      options={[t("on break")]}
                      name="on_break"
                      type="checkbox"
                      labelStyle="checkbox"
                      containerStyle="my-3"
                      optionsContainerStyle="d-flex flex-column"
                      optionItemStyle="optionItemStyle w-60px"
                      optionInputStyle="optionInputStyle w-100 h-10 mr-2 containerStyle checkbox"
                      optionLabelStyle="optionLabelStyle pt-1"
                      optionLabelActiveStyle="optionLabelActiveStyle"
                    />
                  </HasPrivileges>
                </div>
                <Divider orientation="vertical" />
                <div>
                  <CheckboxForm
                    formName={dataTableRef}
                    options={filterOptions.map(({ label, value }) => ({
                      label: t(label?.toLowerCase()),
                      value,
                    }))}
                    name="statusFilter"
                    type="checkbox"
                    containerStyle="containerStyle"
                    optionsContainerStyle="d-flex flex-column"
                    optionItemStyle="optionItemStyle mb-2 w-60px"
                    optionInputStyle=" "
                    optionLabelStyle="optionLabelStyle"
                    optionLabelActiveStyle="optionLabelActiveStyle"
                  />
                </div>
              </Box>
            }
          />

          <HasPrivileges
            reqireMain={[Privilages.ADD_EDIT_DELETE_EMPLOYEES]}
            allowBP
            avalibleOnExpire={false}
            altExpireChildren={
              <ColorButton
                disabled
                label="add new"
                color="#27b40c"
                icon={<Add fontSize="small" />}
                disabledLabel={t("Your company subscription has expired")}
              />
            }
          >
            <ColorButton
              color="#27b40c"
              href={canAddNewEmployee ? "/employees/new" : undefined}
              label="add new"
              icon={<Add fontSize="small" />}
              disabled={!canAddNewEmployee}
              disabledLabel={
                !canAddNewEmployee
                  ? t("you have reached the maximum number of employees")
                  : ""
              }
            />
          </HasPrivileges>

          <>
            {hasActiveSubscription ? (
              <button
                className="btn p-0 employee_list_export_btn_style"
                type="button"
                onClick={() =>
                  dispatch(
                    showExportEmployeeListModal({
                      ref: "exportEmployeeListModal",
                    })
                  )
                }
              >
                <FontAwesomeIcon icon={faFileDownload} />
              </button>
            ) : (
              <span className="tooltipStyle" style={{ opacity: "0.65" }}>
                <Tooltip title={t("Your company subscription has expired")}>
                  <button
                    className="btn p-0 employee_list_export_btn_style mx-2"
                    type="button"
                  >
                    <FontAwesomeIcon icon={faFileDownload} />
                  </button>
                </Tooltip>
              </span>
            )}
          </>

          {listView ? (
            <>
              <ColorButton
                isIconOnly
                color="#2764ac"
                label="Show grid view"
                onClick={handleGridDataViewChange}
                sx={{ width: 40, height: 32.5 }}
                icon={<Apps fontSize="small" />}
              />

              <BarsIconButton onClick={() => setIsToggleModalVissible(true)} />
            </>
          ) : (
            <ColorButton
              isIconOnly
              color="#2764ac"
              label="Show list view"
              onClick={handleListDataViewChange}
              sx={{ width: 40, height: 32.5 }}
              icon={<FormatListBulleted fontSize="small" />}
            />
          )}
        </div>
      </div>

      {/* Lists */}
      {isListLoading ? (
        <Loader inner />
      ) : listView ? (
        <>
          {/* (Start) Hr Employees List View */}
          <HrEmployeesList
            openSignOnBehalfModal={openSignOnBehalfModal}
            handlePaginate={handlePaginate}
            dataTableRef={dataTableRef}
          />
          {/* (End) Hr Employees List View */}
        </>
      ) : (
        <>
          {/* (Start) Hr Employees Grid View */}
          <HrEmployees
            dataTableRef={dataTableRef}
            handlePaginate={handlePaginate}
            openSignOnBehalfModal={openSignOnBehalfModal}
          />
          {/* (End) Hr Employees Grid View */}
        </>
      )}

      {/* Modals */}
      <EditEmployeeModal />
      <SuspensionModal />
      {activationModalActions?.isVissible ? <ActivationModal /> : null}

      {/* Start of activation and suspension records modal */}
      {activiationSuspensionModalIsOpen ? (
        <ActivaitionSuspensionRecordsModal />
      ) : null}
      {/* End of activation and suspension records modal */}

      <ManagePrivilagesModal />
      <ConfirmDeleteManagerModal />
      {isToggleModalVissible ? (
        <ToggleModal
          toggleModal={() => setIsToggleModalVissible(!isToggleModalVissible)}
        />
      ) : null}
      {signOnBehalfModal?.isOpen ? (
        <SignInOutOnBehalfModal
          data={signOnBehalfModal?.data}
          refetchList={fetchEmployeesData}
          onClose={() => setSignOnBehalfModal({ isOpen: false, data: null })}
        />
      ) : null}
      <ExportModal />
    </div>
  );
};

export default ViewHrEmployees;
