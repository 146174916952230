import React, { useEffect } from "react";
import moment from "moment";
import { CheckboxForm, DateTimePickerForm } from "form-builder";
import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import Pagination from "../../Components/Pagination";
import Constants from "../../Helpers/Constants";
import Loader from "../../Components/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import ShowMoreText from "react-show-more-text";
import {
  showClaimRequestModalAction,
  showAcceptClaimRequestModalAction,
  cancelClaimRequestAction,
  rejectClaimRequestAction,
  onFormResetAction,
} from "../../Store/Actions";
import AcceptClaimRequestModal from "./AcceptClaimRequestModal";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import swal from "sweetalert";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  AcceptIconButton,
  TimesIconButton,
} from "../../Components/IconButtonWithTooltip";
import FloatingMenu from "../../Components/FloatingMenu";

const MyClaims = ({ employeeClaimsData, pagination, isLoading, ...props }) => {
  // for Accept claim request modal
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    return () => {
      dispatch(onFormResetAction(props.dataTableRef));
    };
  }, []);
  const handleAcceptClaimRequest = (data) => {
    let acceptClaimData = {
      amount: data?.amount,
      reason: data?.reason,
      payroll_at: data?.payroll_at,
      id: data?.id,
      name: data?.user?.name,
      currency: data?.currency?.name,
    };
    props.showAcceptClaimRequestModalAction({
      ref: "acceptClaimRequestModalActions",
      formName: "acceptClaimRequestModal",
      acceptClaimData,
    });
  };

  const handleRejectClaimRequest = (row) => {
    swal({
      title: t("default_warning_reject_message"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: [t("Cancel"), t("OK")],
    }).then((willDelete) => {
      if (willDelete) {
        props.rejectClaimRequestAction(row?.id, row?.payroll_at);
      }
    });
  };

  // const handleCancelClaimRequest = (id) => {
  //   swal({
  //     title: t("default_warning_cancel_message"),
  //     icon: "warning",
  //     className: "swal-warning-style",
  //     dangerMode: true,
  //     buttons: true,
  //     buttons: [t("Cancel"), t("OK")],
  //   }).then((willDelete) => {
  //     if (willDelete) {
  //       props.cancelClaimRequestAction(id);
  //     }
  //   });
  // };

  const columns = [
    {
      name: t("amount"),
      wrap: true,
      selector: "amount",
      sortable: false,
      grow: 1,
      cell: (row) => (
        <div className="d-flex align-items-baseline">
          <div className="pr-1">{row?.currency?.name}</div>
          <div>{row?.amount}</div>
        </div>
      ),
    },
    {
      name: t("reason"),
      wrap: true,
      selector: "reason",
      sortable: false,
      grow: 1,
      cell: (row) => (
        <ShowMoreText
          lines={1}
          more="Show more"
          less="Show less"
          expanded={false}
          width={280}
        >
          {row?.reason}
        </ShowMoreText>
      ),
    },
    {
      name: t("Approved By"),
      wrap: true,
      selector: "name",
      sortable: false,
      grow: 1,
      omit: !props?.isEmployee,
      cell: (row) => <>{row?.actionBy?.name}</>,
    },
    {
      name: t("incurred at"),
      wrap: true,
      selector: "incurred_at",
      sortable: false,
      grow: 1.4,
      cell: (row) => (
        <>
          {row?.incurred_at != null
            ? moment(row?.incurred_at).format("DD - MM - yyyy")
            : ""}
        </>
      ),
    },
    {
      name: t("payable at"),
      wrap: true,
      selector: "payroll_at",
      sortable: false,
      grow: 1.3,
      cell: (row) => (
        <>
          {row?.payroll_at != null
            ? moment(row?.payroll_at).format("MMMM YYYY")
            : ""}
        </>
      ),
    },
    {
      name: t("files"),
      wrap: true,
      sortable: false,
      grow: 0.5,
      // omit: props?.isEmployee != 1,
      cell: (row) => (
        <div className="data-table-action claim_request_btn_wrapper_style">
          {row?.files?.map((file, i) => (
            <a
              className="btn"
              title={file?.path}
              key={file?.id}
              href={file?.path}
              download={`Attachment - ${i}`}
              target="_blank" rel="noreferrer"
            >
              <FontAwesomeIcon icon={faFileAlt} />
            </a>
          ))}
        </div>
      ),
    },
    {
      name: t("status"),
      wrap: true,
      selector: "status",
      sortable: true,
      grow: 0.75,
      cell: (row) => {
        switch (row?.status?.toLowerCase()) {
          case "pending":
            return <span className="badge pending_status">{t("pending")}</span>;
          case "unpaid":
            return <span className="badge unpaid_status">{t("unpaid")}</span>;
          case "rejected":
            return (
              <span className="badge rejected_status">{t("rejected")}</span>
            );
          case "accepted":
            return (
              <span className="badge approved_status">{t("accepted")}</span>
            );
          case "paid":
            return <span className="badge paid_status">{t("paid")}</span>;
          default:
            break;
        }
      },
    },
    {
      name: "",
      wrap: true,
      selector: "action",
      grow: 0.5,
      cell: (row) =>
        row?.status?.toLowerCase() === "pending" ? (
          <HasPrivileges
            reqireMain={[Privilages.MANAGE_CLAIM_REQUESTS]}
            allowBP
            avalibleOnExpire={false}
            altExpireChildren={
              <div className="cards_table_actions">
                {props?.isEmployee ? (
                  <>
                    <AcceptIconButton />
                    <TimesIconButton />
                  </>
                ) : (
                  <TimesIconButton />
                )}
              </div>
            }
          >
            <div className="cards_table_actions">
              {props?.isEmployee ? (
                <>
                  <AcceptIconButton
                    onClick={() => handleAcceptClaimRequest(row)}
                  />
                  <TimesIconButton
                    label="reject"
                    onClick={() => handleRejectClaimRequest(row)}
                  />
                </>
              ) : (
                <TimesIconButton
                  label="Cancel"
                  onClick={() => handleRejectClaimRequest(row)}
                />
              )}
            </div>
          </HasPrivileges>
        ) : null,
      ignoreRowClick: true,
    },
  ];

  return (
    <div className="col-12 my-2 my-requests">
      <div className="d-flex flex-wrap gap-10 justify-content-between mb-2 requests-style">
        <div className="d-flex align-items-center">
          <div className="side-title mb-0 pr-2">{t("claims")}</div>
          <FloatingMenu
            list={
              <CheckboxForm
                formName={props.dataTableRef}
                reducer={props.reducer}
                options={Constants.ClaimsStatusFilter?.map(
                  ({ label, value }) => ({ label: t(label), value })
                )}
                name="status"
                type="checkbox"
                containerStyle="px-3"
                labelStyle="label-style"
                optionsContainerStyle="py-2 d-flex flex-column"
                optionItemStyle="optionItemStyle py-1"
                optionInputStyle=" "
                optionLabelStyle="optionLabelStyle"
                optionLabelActiveStyle="optionLabelActiveStyle"
              />
            }
          />
        </div>

        <div className="d-flex pl-1 pl-xl-0">
          <DateTimePickerForm
            name="from"
            placeholder="from"
            reducer={props.reducer}
            formName={props.dataTableRef}
            requestFormat="YYYY-MM-DD"
          />
          <DateTimePickerForm
            name="to"
            placeholder="to"
            reducer={props.reducer}
            formName={props.dataTableRef}
            requestFormat="YYYY-MM-DD"
          />
        </div>
      </div>

      <DataTable
        noDataComponent={<div className="p-4"> {t("no_records")} </div>}
        className="cards_table custom-claims-table-style"
        columns={columns}
        // data={props?.data?.data}
        data={employeeClaimsData}
        noHeader
        persistTableHead
        sortServer
        defaultSortAsc={props.data.sorting.dir == "asc"}
        defaultSortField={props.data.sorting.key}
        paginationComponent={() => (
          <Pagination
            styleWraper=""
            onPaginate={props.onPaginate}
            customPaginator={pagination}
          />
        )}
        pagination={true}
        paginationServer={true}
        progressPending={isLoading}
        progressComponent={<Loader />}
      />

      {/* Start of Accept Claim request modal */}
      <AcceptClaimRequestModal />
      {/* End of Accept Claim request modal */}
    </div>
  );
};

const mapStateToProps = (state, { reducer, dataTableRef }) => {
  return {
    data: state[reducer][dataTableRef],
    user: state.auth.userProfile,
  };
};

export default connect(mapStateToProps, {
  showClaimRequestModalAction,
  showAcceptClaimRequestModalAction,
  cancelClaimRequestAction,
  rejectClaimRequestAction,
})(MyClaims);
