import React from "react";
import { useTranslation } from "react-i18next";

const PayrollWidget = (props) => {
  const { t } = useTranslation();
  const { WidgetIcon, WidgetStatusColor, title, currency, amount = 0 } = props;
  return (
    <div className="d-flex justify-content-between align-items-center payroll_widget_wrapper_style mt-xl-4 mt-2">
      <div className="d-flex justify-content-center">
        <div>
          <img src={`assets/img/${WidgetIcon}`} width="26.8" height="29.8" />
        </div>

        <div>
          <h1>{title}</h1>
        </div>
      </div>

      <div className="amount_wrapper_style">
        <h5 className={`${WidgetStatusColor}_style mb-0`}>
          {+amount?.toFixed(2)}{" "}
          <span className="font-weight-bold">{t(currency)}</span>
        </h5>
      </div>
    </div>
  );
};

export default PayrollWidget;
