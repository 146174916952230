import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import PettyCash from "../../Containers/PettyCash";
import CompanyExpenses from "../../Containers/CompanyExpenses";
import ExpensesTabs from "./ExpensesTabs";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import HelperFns from "../../Helpers/HelperFns";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ViewExpenses = (props) => {
  const history = useHistory();
  let query = useQuery();
  const [activeValue, setActiveValue] = useState(null);

  useEffect(() => {
    if (!activeValue) {
      renderfirstTab();
    }
  }, [activeValue]);

  const renderfirstTab = () => {
    if (
      HelperFns.checkPrivileges({
        privileges: [Privilages.VIEW_THE_EXPENSES_OF_MY_OFFICE],
        allowBP: true,
      })
    ) {
      setActiveValue("company-expenses");
      return;
    }
    if (
      HelperFns.checkPrivileges({
        privileges: [Privilages.VIEW_PETTY_CASH_BALANCE_ADJUSTMENT_LIST],
        allowBP: true,
      })
    ) {
      setActiveValue("petty-cash");
      return;
    }
    return null;
  };

  const renderSelectedTab = () => {
    switch (activeValue?.toLowerCase()) {
      case "company-expenses":
        return (
          <HasPrivileges
            reqireMain={[Privilages.VIEW_THE_EXPENSES_OF_MY_OFFICE]}
            allowBP
          >
            <CompanyExpenses />
          </HasPrivileges>
        );
      case "petty-cash":
        return (
          <HasPrivileges
            reqireMain={[Privilages.VIEW_PETTY_CASH_BALANCE_ADJUSTMENT_LIST]}
            allowBP
          >
            <PettyCash />
          </HasPrivileges>
        );
      default:
        break;
    }
  };
  return (
    <div className="content no-padding-first-child">
      <ExpensesTabs setActiveValue={setActiveValue} activeValue={activeValue} />
      <div className="pt-4">{renderSelectedTab()}</div>
    </div>
  );
};

export default ViewExpenses;
