export const NORMAL_LEAVES = "1";
export const PERMISSIONS = "2";
export const UNPAID_PERMISSIONS = "26";
export const HOME = "3";
export const OVERTIME = "4";
export const EMERGENCY_LEAVES = "5";
export const SICK_LEAVES = "6";
export const UNPAID_LEAVES = "7";
export const NORMAL_HALF_DAY = "8";
export const EMERGENCY_HALF_DAY = "9";
export const EDIT_ATTENDANCE = "10";
export const BEREAVEMENT = "11";
export const MATERNITY_PATERNITY_LEAVE = "12";
export const HAJJ_LEAVE = "13";
export const WORK_INJURY_LEAVE = "28";
export const STUDY_LEAVE = "14";
export const SABBATICAL_LEAVE = "15";
export const MARRIAGE_LEAVE = "16";
export const EXPENSE_CLAIM = "21";
export const In_Lieu_Of_Work_Day_Leave = "23";
export const MILITARY_CALL_LEAVE = "24";
export const IGNORE_ASSIGNMENT = "17";
export const UNPAID_HALF_DAY = "18";
export const CHANGE_SHIFT = "20";
export const DAY_OFF = "19";
export const REQUEST_LOAN = "22";
export const ADDITIONAL_SHIFT = "25";
export const OPEN_REQUEST = "27";

//For Request Rules
export const RequestTypeOptions = [
  { label: "Paid Permissions", value: PERMISSIONS },
  { label: "unpaid permissions", value: UNPAID_PERMISSIONS },
  { label: "normal leaves", value: NORMAL_LEAVES },
  { label: "emergency leaves", value: EMERGENCY_LEAVES },
  { label: "unpaid leaves", value: UNPAID_LEAVES },
  { label: "normal half day", value: NORMAL_HALF_DAY },
  { label: "emergency half day", value: EMERGENCY_HALF_DAY },
  { label: "Home", value: HOME },
  { label: "overtime", value: OVERTIME },
  { label: "Edit Attendance", value: EDIT_ATTENDANCE },
  { label: "sick leaves", value: SICK_LEAVES },
  { label: "Change Shift", value: CHANGE_SHIFT },
  { label: "Additional Shift", value: ADDITIONAL_SHIFT },
  { label: "Day Off", value: DAY_OFF },
  // { label: "Loan Request", value: REQUEST_LOAN },
  { label: "Open Request", value: OPEN_REQUEST },
  { label: "ignore assignment", value: IGNORE_ASSIGNMENT },
];

// For Inbound Requests Filter
export const InboundRequestTypeOptions = [
  { label: "Paid Permissions", value: PERMISSIONS },
  { label: "unpaid permissions", value: UNPAID_PERMISSIONS },
  { label: "normal leaves", value: NORMAL_LEAVES },
  { label: "emergency leaves", value: EMERGENCY_LEAVES },
  { label: "Unpaid Leaves", value: UNPAID_LEAVES },
  { label: "Normal Half Day", value: NORMAL_HALF_DAY },
  { label: "Unpaid Half Day", value: UNPAID_HALF_DAY },
  { label: "Emergency Half Day", value: EMERGENCY_HALF_DAY },
  { label: "ignore assignment", value: IGNORE_ASSIGNMENT },

  { label: "Home", value: HOME },
  { label: "Overtime", value: OVERTIME },
  { label: "Edit Attendance", value: EDIT_ATTENDANCE },
  { label: "Sick Leaves", value: SICK_LEAVES },

  { label: "Bereavement", value: BEREAVEMENT },
  { label: "Maternity/Paternity Leaves", value: MATERNITY_PATERNITY_LEAVE },
  { label: "Hajj Leaves", value: HAJJ_LEAVE },
  { label: "work injury Leaves", value: WORK_INJURY_LEAVE },
  { label: "Study Leaves", value: STUDY_LEAVE },
  { label: "Sabbatical Leaves", value: SABBATICAL_LEAVE },
  { label: "Marriage Leaves", value: MARRIAGE_LEAVE },
  { label: "In Lieu Of Work Day Leave", value: In_Lieu_Of_Work_Day_Leave },
  { label: "Military Call Leave", value: MILITARY_CALL_LEAVE },
  { label: "Change Shift", value: CHANGE_SHIFT },
  { label: "Additional Shift", value: ADDITIONAL_SHIFT },
  { label: "Day Off", value: DAY_OFF },
  { label: "Open Request", value: OPEN_REQUEST },
];

export const noSignInOutTimeStatuses = [
  "bereavement",
  "emergency leaves",
  "holiday",
  "leave",
  "normal leaves",
  "sick leaves",
  "unpaid leaves",
  "Day Off",
  "maternity/paternity leaves",
  "hajj leaves",
  "work injury leaves",
  "study leaves",
  "sabbatical leaves",
];
