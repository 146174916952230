import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { CheckboxBooleanForm } from "form-builder";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { updateValueAction } from "../Store/Actions";
import { countryRelatedFieldsQuery } from "../Graphql/query";
import { Spinner } from "reactstrap";

const CountryFields = ({ formName }) => {
  const { t } = useTranslation();
  const [countryRelatedFieldsState, setCountryRelatedFieldsState] = useState(
    {}
  );
  const dispatch = useDispatch();

  const { loading: countryRelatedFieldsLoading } = useQuery(
    countryRelatedFieldsQuery,
    {
      onCompleted: (res) => {
        let countryFields = {};
        res.profile.company.offices.forEach((office) => {
          if (!countryFields[office.country.name]) {
            countryFields[office.country.name] = office.country.customFields;
            office.country.customFields.forEach((key) => {
              dispatch(updateValueAction(formName, key.field_name, false));
            });
          }
        });

        setCountryRelatedFieldsState(countryFields);
      },
    }
  );

  return (
    <div>
      <div className="py-2 label-style-default">
        {t("country related fields")}
      </div>
      {countryRelatedFieldsLoading ? (
        <Spinner
          style={{
            width: "1rem",
            height: "1rem",
            color: "#03a9f4",
            marginRight: 400,
            marginLeft: 40,
          }}
        />
      ) : (
        Object.keys(countryRelatedFieldsState).map((country) => {
          return (
            <div>
              <div className="px-2 label-style-default">{t(country)}</div>
              {countryRelatedFieldsState[country].map((field) => {
                return (
                  <div className="px-4 font-weight-bolder ">
                    <CheckboxBooleanForm
                      formName={formName}
                      options={[t(field.field_title)]}
                      name={field.field_name}
                      type="checkbox"
                      setWithValue={true}
                    />
                  </div>
                );
              })}
            </div>
          );
        })
      )}
    </div>
  );
};

export default CountryFields;
