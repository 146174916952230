import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  onInputResetWithValueAction,
  showErrorToast,
} from "../../Store/Actions";
import PaymentFactors from "../../Constants/PaymentFactors";
import { getCalculatedTaxesQuery } from "../../Graphql/query";
import { RegulationTypes } from "../../Constants/RegulationTypes";
import { CheckboxBooleanForm, InputForm } from "form-builder";
import moment from "moment";

const TaxSection = (props) => {
  const {
    formName,
    formNameValidation,
    formSubmitting,
    formServerValidation,
    index,
  } = props;

  const FormProps = {
    formName,
    formNameValidation,
    formSubmitting,
    formServerValidation,
  };

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [bracetDetails, setBracetDetails] = useState([]);

  const base_salary = useSelector(
    (state) => state.super?.salaryConfigForm?.base_salary
  );
  const socialDeduction = useSelector(
    (state) => state.super?.salaryConfigForm?.socialDeduction
  );
  const medicalDeduction = useSelector(
    (state) => state.super?.salaryConfigForm?.medicalDeduction
  );
  const salary_deductibles = useSelector(
    (state) => state.super?.salaryConfigForm?.salary_deductibles
  );
  const temporary_employee = useSelector(
    (state) => state.super?.salaryConfigForm?.temporary_employee
  );

  // Tax EGP For Regulation Type
  const netWithNoTaxes = () => {
    let base = +base_salary;
    let gross = +props?.totalGross;

    const result = `${salary_deductibles
      ?.map((deductible) =>
        deductible.payment_factor_id === PaymentFactors.FIXED_AMOUNT ||
        deductible.payment_factor_id ===
          PaymentFactors.PERCENT_OF_INSURED_SALARY
          ? +deductible.value
          : deductible.payment_factor_id === PaymentFactors.BASE_PERCENTAGE
            ? +(+deductible.value * base) / 100
            : deductible.payment_factor_id === PaymentFactors.GROSS_PERCENTAGE
              ? +(+deductible.value * gross) / 100
              : 0
      )
      .reduce(
        (acc, curr) => acc - curr,
        gross - +socialDeduction - +medicalDeduction
      )
      .toFixed(2)}`;

    return result;
  };

  useEffect(() => {
    if (props.taxInfoId) {
      calculatedTaxes();
    }

    return () => {};
  }, [
    props.type,
    props.taxInfoId,
    props.totalGross,
    temporary_employee,
    props.date,
    netWithNoTaxes(),
  ]);

  const calculatedTaxes = () => {
    if (
      props.type == RegulationTypes.REGULATION_EGP &&
      moment(
        props.date,
        ["YYYY-MM-DD", "YYYY/MM/DD", "MM-YYYY"],
        true
      )?.isValid()
    ) {
      // fetch taxes value
      let taxedSalary = netWithNoTaxes();

      getEgpTaxes({
        variables: {
          input: {
            total_value: isNaN(taxedSalary) ? 0 : taxedSalary * 12,
            temporary_employee: Boolean(temporary_employee),
            tax_info_id: props.taxInfoId,
          },
        },
      });
    }
  };

  const [getEgpTaxes, { called, loading, data }] = useLazyQuery(
    getCalculatedTaxesQuery,
    {
      onCompleted: (data) => {
        setBracetDetails(JSON.parse(data?.calculateTaxRealtime?.tax_details));

        let amount = `${(data?.calculateTaxRealtime?.tax_value / 12)?.toFixed(
          2
        )}`;

        dispatch(onInputResetWithValueAction(formName, "taxDeduction", amount));
      },
      onError: (error) => {
        dispatch(showErrorToast(error?.graphQLErrors[0]?.extensions?.reason));
      },
    }
  );

  return (
    <div className="d-flex flex-fill order-three flex-row align-items-end">
      <InputForm
        name="taxDeduction"
        label="taxes"
        type={!loading ? "number" : "text"}
        // label={t("taxes")}
        onWheelCapture={(e) => {
          e.target.blur();
        }}
        {...FormProps}
        labelStyle="mb-2"
        rootStyle="flex-grow-1"
        containerStyle=""
        placeholder={t("amount")}
        inputContainerStyle="w-100"
        validateBy="textRequired"
        disabled={props.type == RegulationTypes.REGULATION_EGP}
        validationName={`input.user_salary_config_input.salary_deductibles.${index}.value`} // should change this
        icon="money"
        hasTooltip={props.type == RegulationTypes.REGULATION_EGP}
        tooltipTitle={
          typeof bracetDetails == "object" ? (
            <>
              <ul>
                {bracetDetails?.map((detail) => (
                  <li>
                    (bracket {detail?.bracket_number}) , ({detail?.from} -{" "}
                    {detail?.to}) , {detail?.percentage} % = {detail?.value}{" "}
                  </li>
                ))}
              </ul>
            </>
          ) : (
            <p>{bracetDetails}</p>
          )
        }
      />
      {props.type == RegulationTypes.REGULATION_EGP ? (
        <>
          <div className="mx-2">
            <CheckboxBooleanForm
              {...FormProps}
              options={[t("temporary employee")]}
              name="temporary_employee"
              type="checkbox"
              containerStyle="mb-2"
              validationName={`input.user_salary_config_input.salary_detail.temporary_employee`}
            />
          </div>
        </>
      ) : null}
    </div>
  );
};

export default TaxSection;
