import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CheckboxForm from "./Form/CheckboxForm";
import { onInputChangeAction, updateRadioScopeAction } from "../Store/Actions";
import Masonry from "react-masonry-component";
import { useTranslation } from "react-i18next";
import Privilages from "../Constants/Privilages";

const formName = "managePrivilagesModal";

const companyOptions = [
  { label: "All Companies", value: "all" },
  { label: "Managed Companies", value: "managed" },
];

const employeesOptions = [
  { label: "all employees", value: "all" },
  { label: "managed employees", value: "managed" },
];

const TreeView = (props) => {
  return (
    <Masonry>
      <OptionsList
        options={props.parents}
        isFirst={true}
        adminPriviages={props.adminPriviages}
      />
    </Masonry>
  );
};

// Recursive component
const OptionsList = ({ options, isFirst, adminPriviages }) => {
  const privileges = useSelector((state) => state.super[formName]);

  return (
    <>
      {options?.map((option, i) => (
        <div key={i} className={`${isFirst ? "col-12 col-xl-6" : ""}`}>
          <div
            className={`${isFirst ? "boxContainer py-2 my-2 pl-3 pr-2" : ""}`}
          >
            <ul className={`${isFirst ? "pl-0 mb-0" : "p-l-22"}`}>
              <div
                className={`d-flex flex-row justify-content-between flex-wrap  ${
                  isFirst ? "first-parnet-privileges" : ""
                }`}
              >
                <Checkbox {...option} />
                {option?.flag && privileges?.main?.includes(option?.id) ? (
                  <div className="d-flex flex-wrap align-items-center justify-content-between py-1">
                    <form className="d-flex justify-content-end">
                      {adminPriviages &&
                      [
                        Privilages.VIEW_COMPANIES,
                        Privilages.VIEW_COMPANY_MEETING,
                        Privilages.VIEW_COMPANY_REMINDERS,
                        Privilages.VIEW_COMPANY_ACTIVITIES,
                      ]?.includes(option.id)
                        ? companyOptions?.map((opt) => (
                            <Radiobox key={option.id} {...opt} {...option} />
                          ))
                        : employeesOptions?.map((opt) => (
                            <Radiobox key={option.id} {...opt} {...option} />
                          ))}
                    </form>
                  </div>
                ) : null}
              </div>

              {/* Base Case */}
              {option?.children?.length > 0 &&
                privileges?.main?.includes(option?.id) && (
                  <OptionsList
                    options={option.children}
                    adminPriviages={adminPriviages}
                  />
                )}
            </ul>
          </div>
        </div>
      ))}
    </>
  );
};

// checkbox component, completly controlled by parent
const Checkbox = ({ name, id, descendants, flag, privilege_type_id }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const privileges = useSelector((state) => state.super[formName]);
  const handleCheckboxChange = (e) => {
    //handle if un Checked and has descendants
    if (!e.target.checked) {
      let event = {
        target: {
          name: "main",
          value: privileges?.main?.filter(
            (item) => !descendants.includes(+item) && item != id
          ),
        },
      };
      // remove all desendant from scope Privilages
      dispatch(
        updateRadioScopeAction(formName, e.target.name, [...descendants, id])
      );
      // remove all desendant from main Privilages
      dispatch(onInputChangeAction(formName, event));
    } else {
      dispatch(onInputChangeAction(formName, e, "checkbox", id));
      if (e.target.checked && flag)
        dispatch(updateRadioScopeAction(formName, "managed", id));
    }
  };
  return (
    <CheckboxForm
      options={[{ value: id, label: t(name) }]}
      name="main"
      formName="managePrivilagesModal"
      type="checkbox"
      containerStyle=" "
      labelStyle="label-style"
      optionsContainerStyle="my-1"
      optionItemStyle=" "
      optionInputStyle=" "
      optionLabelStyle=" "
      optionLabelActiveStyle="optionLabelActiveStyle"
      onChange={handleCheckboxChange}
      disabled={privilege_type_id == 1}
    />
  );
};
// Radiobox component, completly controlled by parent
const Radiobox = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleRadioChange = (e) => {
    dispatch(updateRadioScopeAction(formName, e.target.name, e.target.value));
  };
  const privileges = useSelector((state) => state.super[formName]);

  return (
    <div className="option-item">
      <label className="radio">
        <input
          type="radio"
          name={props.value}
          value={props.id}
          onChange={handleRadioChange}
          checked={privileges[props.value]?.includes(props.id)}
        />
        <span></span>

        <div className="optionLabelStyle">{t(props.label)}</div>
      </label>
    </div>
  );
};

export default TreeView;
