import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import MainModal from "../../Components/MainModal";
import { BSelect, DateTimePickerForm } from "form-builder";
import { useMutation } from "@apollo/client";
import {
  editActivationMutation,
  editActivationFlexMutation,
} from "../../Graphql/mutation";
import {
  onFormResetAction,
  toggleEditActivationModal,
} from "../../Store/Actions";
import { showToast } from "../../Helpers/HelperFns";
import HelperFns from "../../Helpers/HelperFns";
import moment from "moment";
import ClockinsInput from "../ClockinsInput";

const formName = "editActiviationModalForm";
const formNameValidation = "editActiviationModalFormClientValidations";
const formNameServerValidation = "editActiviationModalFormServerValidations";

// employee ateendance options objects
const employeeAttendanceOptions = [
  { label: "Attended", value: "Attended" },
  { label: "Absent", value: "Absent" },
  { label: "Day Off", value: "Day Off" },
];

const initialState = [
  {
    clockInTime: null,
    clockOutTime: null,
  },
];

const EditActiviationModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Local State
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [showEmployeeAttendanceSection, setShowEmployeeAttendanceSection] =
    useState(false);

  const [clockins, setClockins] = useState(initialState);

  // Redux States
  const isOpen = useSelector(
    (state) => state?.super?.editActivationModal?.isOpen
  );
  const formData = useSelector((state) => state?.super?.[formName]);
  const clientValidations = useSelector(
    (state) => state?.super?.[formNameValidation]
  );

  const serverValidations = useSelector(
    (state) => state?.super?.[formNameServerValidation]
  );

  // Server State
  useEffect(() => {
    return () => {
      dispatch(onFormResetAction(formName));
      dispatch(onFormResetAction(formNameValidation));
    };
  }, []);

  // handle to and from condition to show employee attendance select
  useEffect(() => {
    if (
      moment(formData?.from) < moment(formData?.old_from) ||
      moment(formData?.to) > moment(formData?.old_to)
    ) {
      setShowEmployeeAttendanceSection(true);
    } else {
      setShowEmployeeAttendanceSection(false);
    }
  }, [formData?.to, formData?.from]);

  // Start of handle edit activiation mutation
  const [editActivationRecord, { loading, error: submitError }] = useMutation(
    props?.isFlexUser ? editActivationFlexMutation : editActivationMutation,
    {
      onCompleted: (data) => {
        if (
          data?.edit_activation?.status == "error" ||
          data?.flex_edit_activation?.status == "error"
        ) {
          // error toaster
          showToast(
            "error",
            data?.edit_activation?.message ||
              data?.flex_edit_activation?.message
          );
          return;
        }
        // success toaster
        showToast("success");

        // handle dismiss modal
        handleDismissModal();

        // handle refetch data list
        props?.handleRefetch();
      },
      onError(err) {
        // error toaster
        showToast(
          "error",
          err?.graphQLErrors?.[0].extensions?.reason ?? err?.message
        );
      },
    }
  );

  useEffect(() => {
    if (submitError) {
      if (submitError?.graphQLErrors?.[0]?.extensions?.validation) {
        dispatch(
          onFormResetAction(
            formNameServerValidation,
            submitError?.graphQLErrors?.[0]?.extensions?.validation
          )
        );
      }
    } else {
      dispatch(onFormResetAction(formNameServerValidation));
    }
  }, [submitError]);
  // End of handle edit activiation mutation

  /* ↓ Helpers ↓ */

  // handle triggering edit record mutation function
  const handleEditRecordMutationAction = () => {
    editActivationRecord({
      variables: {
        input: {
          user_id: formData?.user_id,
          from: formData?.from,
          old_from: formData?.old_from,
          to: formData?.to,
          old_to: formData?.old_to,
          employee_attendance: showEmployeeAttendanceSection
            ? formData?.employee_attendance
            : null,
          ...(props?.isFlexUser
            ? {
                workDayTemplateId:
                  formData?.employee_attendance === "Day Off"
                    ? ""
                    : formData?.workDayTemplateId,

                ranges:
                  formData?.employee_attendance == "Attended"
                    ? clockins
                        ?.filter((clockin) => !!clockin?.clockInTime)
                        ?.map((clockin) => ({
                          clockInTime: moment(clockin?.clockInTime).format(
                            "HH:mm:ss"
                          ),
                          clockOutTime: moment(clockin?.clockOutTime).format(
                            "HH:mm:ss"
                          ),
                        }))
                    : [],
              }
            : {
                workTimingID:
                  formData?.employee_attendance === "Day Off"
                    ? ""
                    : formData?.workTimingID,
              }),
          workplaceID:
            formData?.employee_attendance === "Day Off"
              ? ""
              : formData?.workplaceID,
        },
      },
    });
  };

  // handle submit form function
  const handleSubmit = () => {
    setFormSubmitting(true);

    if (!clientValidations?.length) {
      // handle check password before submitting
      HelperFns.checkPassword(
        "defaut_warning_messsage",
        "",
        "Confirm, edit the record",
        "",
        () => handleEditRecordMutationAction()
      );
    }
  };

  // handle dismiss modal function
  const handleDismissModal = () => {
    dispatch(toggleEditActivationModal({ isOpen: false }));
  };

  return (
    <MainModal
      isOpen={isOpen}
      toggle={handleDismissModal}
      modalTitle={t("edit")}
      btnLabel={t("save")}
      btnOnClick={handleSubmit}
      btnSubmitLoading={loading}
    >
      <div className="row">
        <div className="col-12 col-md-6">
          <DateTimePickerForm
            name="old_from"
            placeholder="from"
            label="old from"
            disabled
            formName={formName}
            formNameValidation={formNameValidation}
            formSubmitting={formSubmitting}
            requestFormat="YYYY-MM-DD"
          />
        </div>

        <div className="col-12 col-md-6">
          <DateTimePickerForm
            name="old_to"
            placeholder="to"
            label="old to"
            disabled
            formName={formName}
            formNameValidation={formNameValidation}
            formSubmitting={formSubmitting}
            requestFormat="YYYY-MM-DD"
          />
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-md-6">
          <DateTimePickerForm
            name="from"
            label="from"
            placeholder="from"
            disabled={formData?.disbaleFromDatePicker}
            validateBy="textRequired"
            isClearable
            formName={formName}
            formNameValidation={formNameValidation}
            formSubmitting={formSubmitting}
            requestFormat="YYYY-MM-DD"
          />
        </div>

        <div className="col-12 col-md-6">
          <DateTimePickerForm
            name="to"
            label="to"
            placeholder="to"
            disabled={formData?.disableToDatePicker}
            validateBy="textRequired"
            isClearable
            formName={formName}
            formNameValidation={formNameValidation}
            formSubmitting={formSubmitting}
            requestFormat="YYYY-MM-DD"
          />
        </div>
      </div>

      {showEmployeeAttendanceSection ? (
        <>
          <div className="row mt-2">
            <div className="col-12">
              <BSelect
                formName={formName}
                formNameValidation={formNameValidation}
                formSubmitting={formSubmitting}
                name="employee_attendance"
                label="Set Employee Attendance As"
                keepDefaultStyle
                placeholder={t("select option")}
                options={employeeAttendanceOptions}
                optionLabel="label"
                optionValue="value"
                labelStyle="mb-1"
                inputContainerStyle="b-select-style "
                skipLocalization
                isClearable
              />
            </div>
          </div>

          {formData?.employee_attendance === "Day Off" ? null : (
            <div className="row mt-2">
              <div className="col-12 col-md-6">
                <BSelect
                  formName={formName}
                  formNameValidation={formNameValidation}
                  formSubmitting={formSubmitting}
                  name={
                    props?.isFlexUser ? "workDayTemplateId" : "workTimingID"
                  }
                  label={
                    props?.isFlexUser ? "work day template" : "work timing"
                  }
                  keepDefaultStyle
                  placeholder={t("select option")}
                  options={props?.workTimingOptions}
                  optionLabel="name"
                  optionValue="id"
                  labelStyle="mb-1"
                  inputContainerStyle="b-select-style "
                  skipLocalization
                  isClearable
                />
              </div>

              <div className="col-12 col-md-6">
                <BSelect
                  formName={formName}
                  formNameValidation={formNameValidation}
                  formSubmitting={formSubmitting}
                  name="workplaceID"
                  label={t("work place")}
                  keepDefaultStyle
                  placeholder={t("select option")}
                  options={props?.workPlaceOptions}
                  optionLabel="name"
                  optionValue="id"
                  labelStyle="mb-1"
                  inputContainerStyle="b-select-style "
                  skipLocalization
                  isClearable
                />
              </div>

              {formData?.employee_attendance == "Attended" ? (
                <div className="col-12">
                  <ClockinsInput
                    clockins={clockins}
                    setClockins={setClockins}
                  />

                  {!!serverValidations?.["input.ranges"] ? (
                    <div className="invalid-container-style">
                      <p className="validity-msg-style">
                        {serverValidations?.["input.ranges"]?.join(" ")}
                      </p>
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
          )}
        </>
      ) : null}
    </MainModal>
  );
};

export default EditActiviationModal;
