export const WorkDayTypes = {
  FIXED: "fixed",
  FLEXIBLE: "flexible",
};

export const EmployeeTypes = {
  FIXED: "Employee",
  FLEX: "FlexEmployee",
};

export const treatExtraHoursAsOptions = [
  { label: "normal hours", value: "normal" },
  { label: "ignore additoinal hours", value: "ignore" },
];
