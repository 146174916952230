import * as Types from "./types";

/* Toast */

export const showSuccessToast = (msg, duration = 3000, translate = false) => {
  return {
    type: Types.SHOW_TOAST,
    payload: { msg, duration, type: "success", translate },
  };
};

export const showErrorToast = (msg, duration = 3000, translate = false) => {
  return {
    type: Types.SHOW_TOAST,
    payload: { msg, duration, type: "error", translate },
  };
};

export const showToast = (payload) => {
  return {
    type: Types.SHOW_TOAST,
    payload,
  };
};

export const hideToast = () => {
  return {
    type: Types.HIDE_TOAST,
  };
};

/* Container Modal */

export const openModalContainer = (name) => {
  return {
    type: Types.OPEN_MODAL_CONTAINER,
    payload: { name },
  };
};

export const closeModalContainer = () => {
  return {
    type: Types.CLOSE_MODAL_CONTAINER,
  };
};
