import React, { forwardRef, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Pagination from "../../Components/Pagination";
import Loader from "../../Components/Loader";
import { useTranslation } from "react-i18next";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  GET_TIMESHEET_SUMMARY,
  GET_TIMESHEET_SUMMARY_FILTERS_DATA,
} from "../../Graphql/query";
import { TabContext, TabList } from "@mui/lab";
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  IconButton,
  List,
  Tab,
} from "@mui/material";
import { BSelect, CheckboxBooleanForm } from "form-builder";
import { ACCEPT_OR_REJECT_TIMESHEET_EMPLOYEES } from "../../Graphql/mutation";
import { showErrorToast, showSuccessToast } from "../../Store/Actions";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import HelperFns from "../../Helpers/HelperFns";
import { Link } from "react-router-dom";
import CanViewEmployeeProfile from "../../Helpers/HOC/CanViewEmployeeProfile";
import Constants from "../../Constants";
import { Check, Close, ListAlt } from "@mui/icons-material";
import TimeSheetSummaryPerEmployee from "./TimeSheetSummaryPerEmployee";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import Swal from "sweetalert2";

export const statusButtons = [
  { id: 1, label: "all", value: "all" },
  { id: 2, label: "pending", value: "pending" },
  { id: 3, label: "accepted", value: "accepted" },
  { id: 3, label: "rejected", value: "rejected" },
];

const formName = "timesheetSummaryFilters";
const formProps = {
  formName,
  reducer: "flex",
};

const paginationInitState = {
  total: 20,
  perPage: 20,
  lastPage: 1,
  lastItem: 20,
  firstItem: 1,
  currentPage: 1,
  hasMorePages: false,
};

const getStatusObj = (pending, approved, rejected) => {
  if (pending > 0 && approved == 0 && rejected == 0) {
    return {
      status: "Pending",
      number: pending,
    };
  } else {
    if (approved > 0 && rejected == 0 && pending == 0) {
      return {
        status: "Approved",
        number: approved,
      };
    } else if (rejected > 0 && approved == 0 && pending == 0) {
      return {
        status: "Rejected",
        number: rejected,
      };
    } else {
      return {
        status: "Reviewed",
        number: approved + rejected,
      };
    }
  }
};

const TimesheetSummary = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [summaryType, setSummaryType] = useState("monthly");
  const [selectedStatus, setSelectedStatus] = useState("all");
  const [selectedRows, setSelectedRows] = useState([]);
  const [currentlyLoading, setCurrentlyLoading] = useState(null);
  const [currentlyLoadingEmployee, setCurrentlyLoadingEmployee] =
    useState(null);

  const [pagination, setPagination] = useState(paginationInitState);

  const [modal, setModal] = useState({
    isOpen: false,
    data: {},
  });

  const filters = useSelector((state) => state?.flex?.[formName]);

  const handleChangeStatus = (value) => {
    setSelectedStatus(value);
    setSelectedRows([]);
  };

  const handleChange = (_, newValue) => {
    setSummaryType(newValue);
  };

  const [
    fetchTimesheetSummary,
    { loading: timesheetSummaryLoading, data: timesheetQueryData },
  ] = useLazyQuery(GET_TIMESHEET_SUMMARY, {
    variables: {
      page: pagination?.currentPage ?? 1,
      input: {
        type: summaryType,
        monthYear: `${filters?.month}-${filters?.year}`,
        emp_id: filters?.employees?.length ? filters?.employees : undefined,
        office_id: filters?.offices?.length ? filters?.offices : undefined,
        department_id: filters?.departments?.length
          ? filters?.departments
          : undefined,
        position_id: filters?.positions?.length
          ? filters?.positions
          : undefined,

        approval_status:
          selectedStatus == "pending"
            ? 2
            : selectedStatus == "accepted"
              ? 1
              : selectedStatus == "rejected"
                ? 5
                : undefined,
      },
    },
    onCompleted: (data) => {
      setPagination(data?.timeSheetSummary?.paginatorInfo ?? {});
    },
  });

  const [
    attemptAccepOrRejecttMultipleEmployees,
    { loading: acceptOrRejectEmployeesLoading },
  ] = useMutation(ACCEPT_OR_REJECT_TIMESHEET_EMPLOYEES, {
    onCompleted: (data) => {
      if (data?.acceptSummaryByDatesPerOneOrManyEmployees?.status == "fail") {
        dispatch(
          showErrorToast(
            data?.acceptSummaryByDatesPerOneOrManyEmployees?.message
          )
        );
        setCurrentlyLoading(null);
        setCurrentlyLoadingEmployee(null);
        return;
      }

      dispatch(
        showSuccessToast(
          data?.acceptSummaryByDatesPerOneOrManyEmployees?.message
        )
      );
      fetchTimesheetSummary();
      setCurrentlyLoading(null);
      setCurrentlyLoadingEmployee(null);
      setSelectedRows([]);

      if (
        data?.acceptSummaryByDatesPerOneOrManyEmployees?.details?.length > 0
      ) {
        Swal.fire({
          title: t("some employees has clockins that have no clock out time"),
          html: `
          <div>
            ${data?.acceptSummaryByDatesPerOneOrManyEmployees?.details?.map(
              (detail) => {
                return `<div>${detail?.employee_name} - ${detail?.work_day_template_name} - ${detail?.container_date}</div>`;
              }
            )}
          </div>
          `,
          icon: "warning",
          className: "swal-warning-style",
          confirmButtonColor: "#ff6a6a",
          confirmButtonText: t("ok"),
          reverseButtons: true,
        });
      }
    },
    onError: (error) => {
      setCurrentlyLoading(null);
      setCurrentlyLoadingEmployee(null);

      dispatch(
        showErrorToast(
          error?.graphQLErrors?.[0]?.extensions?.reason || error?.message
        )
      );
    },
  });

  const [
    fetchTimesheetSummaryFiltersData,
    {
      loading: timesheetSummaryFiltersDataLoading,
      data: timesheetFiltersQueryData,
    },
  ] = useLazyQuery(GET_TIMESHEET_SUMMARY_FILTERS_DATA);

  const timeSheetSummaryData = timesheetQueryData?.timeSheetSummary?.data ?? [];

  useEffect(() => {
    fetchTimesheetSummaryFiltersData({
      variables: {
        from: moment(`${filters?.month}-${filters?.year}`, "MM-YYYY").startOf(
          "month"
        ),
        to: moment(`${filters?.month}-${filters?.year}`, "MM-YYYY").endOf(
          "month"
        ),
      },
    });
  }, []);

  const selectedWeek = timesheetFiltersQueryData?.weeks?.find(
    (week) => week?.from == filters?.week
  );

  useEffect(() => {
    fetchTimesheetSummary({
      ...(summaryType == "weekly"
        ? {
            variables: {
              date_range: {
                from: selectedWeek?.from,
                to: selectedWeek?.paidAt,
              },
            },
          }
        : {}),
    });
  }, [summaryType]);

  const columns = [
    {
      name: t("employees"),
      wrap: true,
      sortable: false,
      grow: 1.7,
      cell: (row) => {
        return (
          <CanViewEmployeeProfile
            allowBP
            directManger={row?.manager_id}
            copiedManagers={row?.copied_managers_ids?.split(",") ?? []}
            altChildren={
              <h3 className="timesheet-summary-employee-name">
                {row?.employee_name}
              </h3>
            }
          >
            <Link to={`/employees/employee-profile/${row?.id}`}>
              <h3 className="timesheet-summary-employee-name">
                {row?.employee_name}
              </h3>
            </Link>
          </CanViewEmployeeProfile>
        );
      },
    },
    {
      name: t("total clock-ins"),
      wrap: true,
      sortable: false,
      grow: 1.2,
      selector: "total_clockins",
    },
    {
      name: t("day offs"),
      wrap: true,
      sortable: false,
      grow: 1.2,
      selector: "day_off_count",
    },
    {
      name: t("off hours"),
      wrap: true,
      sortable: false,
      grow: 1.2,
      cell: (row) => "----",
    },
    {
      name: t("total work hours"),
      wrap: true,
      sortable: false,
      grow: 1.2,
      selector: "actual_total_work_hours",
    },
    {
      name: t("overtime"),
      wrap: true,
      sortable: false,
      grow: 1.2,
      cell: (row) => "----",
    },
    {
      name: t("leave hours"),
      wrap: true,
      sortable: false,
      grow: 1.2,
      selector: "actual_total_leave_hours",
    },
    {
      name: t("status"),
      wrap: true,
      sortable: false,
      grow: 1.5,
      cell: (row) => {
        const pending = row?.pending;
        const approved = row?.approved;
        const rejected = row?.rejected;

        const statusObj = getStatusObj(pending, approved, rejected);

        const { status, number } = statusObj;

        return (
          <div className="d-flex align-items-center">
            <div
              className={`timesheet-summary-${status?.toLowerCase()}-status`}
            >
              {t(status)}
            </div>

            <div
              className={`timesheet-summary-${status?.toLowerCase()}-status timesheet-summary-status-number`}
            >
              {number}
            </div>
          </div>
        );
      },
    },
    {
      name: t("approved by"),
      wrap: true,
      sortable: false,
      grow: 1.2,
      cell: (row) => {
        const approvedByNames = row?.approved_by_data?.split(",") ?? [];
        return (
          <div className="timesheet-approved-by-container">
            {approvedByNames?.map((name, index) => (
              <div
                className={`timesheet-summary-approved-by ${
                  index !== 0 ? "approved-by-overlap" : ""
                }`}
              >
                {HelperFns.getIntials(name)}
              </div>
            ))}
          </div>
        );
      },
    },
    {
      name: t("actions"),
      wrap: true,
      sortable: false,
      grow: 1.2,
      cell: (row) => {
        const pending = row?.pending;
        const approved = row?.approved;
        const rejected = row?.rejected;

        const statusObj = getStatusObj(pending, approved, rejected);

        const { status } = statusObj;

        return acceptOrRejectEmployeesLoading &&
          currentlyLoadingEmployee == row?.id ? (
          <CircularProgress size={18} />
        ) : (
          <HasPrivileges
            allowBP
            reqireMain={[Privilages.MANAGE_TIMESHEET_RECORDS]}
          >
            <div className="d-flex align-items-center gap-5">
              {status == "Pending" ? (
                <>
                  <IconButton
                    sx={{
                      height: 18,
                      width: 18,
                      borderRadius: 999,
                      backgroundColor: "#27B40C",
                      color: "white",
                      ":hover": {
                        backgroundColor: "#27B40C",
                      },
                    }}
                    onClick={() =>
                      handleAcceptOrRejectOneEmployee(
                        status == "Pending" ? 1 : 2,
                        row?.id
                      )
                    }
                    disabled={
                      acceptOrRejectEmployeesLoading &&
                      currentlyLoadingEmployee == row?.id
                    }
                  >
                    <Check style={{ fontSize: 15 }} />
                  </IconButton>
                  <IconButton
                    sx={{
                      height: 18,
                      width: 18,
                      borderRadius: 999,
                      backgroundColor: "#DC3545",
                      color: "white",
                      ":hover": {
                        backgroundColor: "#DC3545",
                      },
                    }}
                    onClick={() =>
                      handleAcceptOrRejectOneEmployee(
                        status == "Pending" ? 5 : 2,
                        row?.id
                      )
                    }
                    disabled={
                      acceptOrRejectEmployeesLoading &&
                      currentlyLoadingEmployee == row?.id
                    }
                  >
                    <Close style={{ fontSize: 15 }} />
                  </IconButton>
                </>
              ) : (
                <IconButton
                  sx={{
                    height: 18,
                    width: 18,
                    borderRadius: 999,
                    color: "black",
                    ":hover": {},
                  }}
                  onClick={() => handleAcceptOrRejectOneEmployee(2, row?.id)}
                  disabled={
                    acceptOrRejectEmployeesLoading &&
                    currentlyLoadingEmployee == row?.id
                  }
                >
                  <RotateLeftIcon style={{ fontSize: 20 }} />
                </IconButton>
              )}
              <IconButton
                sx={{
                  height: 18,
                  width: 18,
                }}
                disabled={acceptOrRejectEmployeesLoading}
                onClick={() => handleOpenModal(row)}
              >
                <ListAlt style={{ color: "black" }} />
              </IconButton>
            </div>
          </HasPrivileges>
        );
      },
    },
  ];

  const onPaginate = (page = pagination?.currentPage) => {
    setPagination((prev) => ({ ...prev, currentPage: page }));
  };

  const handleAcceptOrRejectEmployees = (acceptOrReject, employeeIds) => {
    attemptAccepOrRejecttMultipleEmployees({
      variables: {
        input: {
          type: summaryType,
          empIds: employeeIds?.map(Number),
          status: acceptOrReject,
          ...(summaryType == "monthly"
            ? {
                monthYear: `${filters?.month}-${filters?.year}`,
              }
            : {
                date_range: {
                  from: selectedWeek?.from,
                  to: selectedWeek?.paidAt,
                },
              }),
        },
      },
    });

    setCurrentlyLoading(acceptOrReject);
  };

  const allRowsSelected =
    timeSheetSummaryData?.length &&
    timeSheetSummaryData?.every((row) => selectedRows?.includes(row?.id));

  const isChecked = (e) => {
    if (e?.name == "select-all-rows") {
      return allRowsSelected;
    } else {
      const id = e?.name?.replace("select-row-", "");

      return selectedRows?.includes(id);
    }
  };

  const handleSelect = (e) => {
    if (e?.name == "select-all-rows") {
      // if all of them selected -> unselect all
      if (allRowsSelected) {
        setSelectedRows([]);
      } else {
        // if some of them selected or none -> select all of them
        setSelectedRows(
          timeSheetSummaryData?.map((employee) => employee?.id) ?? []
        );
      }
    } else {
      const id = e?.name?.replace("select-row-", "");

      if (selectedRows?.includes(id)) {
        setSelectedRows((prev) =>
          prev?.filter((selectedId) => selectedId != id)
        );
      } else {
        setSelectedRows((prev) => [...prev, id]);
      }
    }
  };

  const handleAcceptOrRejectOneEmployee = (acceptOrReject, employeeId) => {
    handleAcceptOrRejectEmployees(acceptOrReject, [employeeId]);
    setCurrentlyLoadingEmployee(employeeId);
  };

  const handleOpenModal = (row) => {
    setModal({
      isOpen: true,
      data: {
        employeeId: row?.id,
        employeeName: row?.employee_name,
        monthYear: `${filters?.month}-${filters?.year}`,
      },
    });
  };

  const handleCloseModal = () => {
    setModal({
      isOpen: false,
      data: {},
    });
  };

  const selectedEmployees = timeSheetSummaryData?.filter((day) =>
    selectedRows?.includes(day?.id)
  );

  const showAccept = () => {
    // if (
    //   selectedEmployees?.every(
    //     (employee) =>
    //       getStatusObj(
    //         employee?.pending,
    //         employee?.approved,
    //         employee?.rejected
    //       )?.status == "Rejected"
    //   )
    // ) {
    //   return true;
    // }
    if (
      selectedEmployees?.every(
        (employee) =>
          getStatusObj(
            employee?.pending,
            employee?.approved,
            employee?.rejected
          )?.status == "Pending"
      )
    ) {
      return true;
    }
    // if (
    //   selectedEmployees?.some(
    //     (employee) =>
    //       getStatusObj(
    //         employee?.pending,
    //         employee?.approved,
    //         employee?.rejected
    //       )?.status == "Reviewed"
    //   )
    // ) {
    //   return true;
    // }
    // if (
    //   selectedEmployees?.some(
    //     (employee) =>
    //       getStatusObj(
    //         employee?.pending,
    //         employee?.approved,
    //         employee?.rejected
    //       )?.status == "Approved"
    //   ) &&
    //   selectedEmployees?.some(
    //     (employee) =>
    //       getStatusObj(
    //         employee?.pending,
    //         employee?.approved,
    //         employee?.rejected
    //       )?.status == "Rejected"
    //   )
    // ) {
    //   return true;
    // }

    return false;
  };

  const showReject = () => {
    // if (
    //   selectedEmployees?.every(
    //     (employee) =>
    //       getStatusObj(
    //         employee?.pending,
    //         employee?.approved,
    //         employee?.rejected
    //       )?.status == "Approved"
    //   )
    // ) {
    //   return true;
    // }
    if (
      selectedEmployees?.every(
        (employee) =>
          getStatusObj(
            employee?.pending,
            employee?.approved,
            employee?.rejected
          )?.status == "Pending"
      )
    ) {
      return true;
    }
    // if (
    //   selectedEmployees?.some(
    //     (employee) =>
    //       getStatusObj(
    //         employee?.pending,
    //         employee?.approved,
    //         employee?.rejected
    //       )?.status == "Reviewed"
    //   )
    // ) {
    //   return true;
    // }
    // if (
    //   selectedEmployees?.some(
    //     (employee) =>
    //       getStatusObj(
    //         employee?.pending,
    //         employee?.approved,
    //         employee?.rejected
    //       )?.status == "Approved"
    //   ) &&
    //   selectedEmployees?.some(
    //     (employee) =>
    //       getStatusObj(
    //         employee?.pending,
    //         employee?.approved,
    //         employee?.rejected
    //       )?.status == "Rejected"
    //   )
    // ) {
    //   return true;
    // }

    return false;
  };

  const showReset = () => {
    if (
      selectedEmployees?.some(
        (employee) =>
          getStatusObj(
            employee?.pending,
            employee?.approved,
            employee?.rejected
          )?.status != "Pending"
      )
    ) {
      return true;
    }

    return false;
  };

  return (
    <div>
      <TabContext value={summaryType}>
        <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 5 }}>
          <TabList onChange={handleChange}>
            <Tab key={"monthly-tab"} label={t("monthly")} value={"monthly"} />
            <Tab key={"weekly-tab"} label={t("weekly")} value={"weekly"} />
          </TabList>
        </Box>
      </TabContext>

      <div className="row gap-2">
        <BSelect
          label="offices"
          name="offices"
          options={timesheetFiltersQueryData?.company_offices?.data ?? []}
          isClearable
          isMulti
          containerStyle="month-picker mr-2"
          isLoading={timesheetSummaryFiltersDataLoading}
          placeholder={t("select offices")}
          icon="office"
          rootStyle="col-12 col-md-3 mb-3"
          {...formProps}
        />

        <BSelect
          label="departments"
          name="departments"
          options={timesheetFiltersQueryData?.company_departments?.data ?? []}
          isClearable
          isMulti
          containerStyle="month-picker mr-2"
          isLoading={timesheetSummaryFiltersDataLoading}
          placeholder={t("select departments")}
          icon="department"
          rootStyle="col-12 col-md-3 mb-3"
          {...formProps}
        />

        <BSelect
          label="positions"
          name="positions"
          options={timesheetFiltersQueryData?.company_positions?.data ?? []}
          isClearable
          isMulti
          containerStyle="month-picker mr-2"
          isLoading={timesheetSummaryFiltersDataLoading}
          placeholder={t("select positions")}
          icon="person"
          rootStyle="col-12 col-md-3 mb-3"
          {...formProps}
        />

        <BSelect
          label="employees"
          name="employees"
          options={
            timesheetFiltersQueryData?.getFlexEmployees?.map(
              (emp) => emp?.user
            ) ?? []
          }
          isClearable
          isMulti
          containerStyle="month-picker mr-2"
          isLoading={timesheetSummaryFiltersDataLoading}
          placeholder={t("select employees")}
          icon="person"
          rootStyle="col-12 col-md-3 mb-3"
          {...formProps}
        />

        <BSelect
          label="year"
          name="year"
          options={Constants.YearData}
          containerStyle="month-picker mr-2"
          isLoading={false}
          placeholder={t("select year")}
          icon="calendar"
          rootStyle="col-12 col-md-3 mb-3"
          optionLabel="value"
          optionValue="value"
          {...formProps}
        />

        <BSelect
          label="month"
          name="month"
          options={Constants.MonthsData}
          containerStyle="month-picker mr-2"
          isLoading={false}
          placeholder={t("select month")}
          icon="calendar"
          rootStyle="col-12 col-md-3 mb-3"
          optionLabel="value"
          optionValue="key"
          {...formProps}
        />

        {summaryType == "weekly" ? (
          <BSelect
            label="week"
            name="week"
            options={timesheetFiltersQueryData?.weeks ?? []}
            isClearable
            containerStyle="month-picker mr-2"
            isLoading={false}
            placeholder={t("select week")}
            icon="calendar"
            rootStyle="col-12 col-md-3 mb-3"
            optionLabel="name"
            optionValue="from"
            {...formProps}
          />
        ) : null}
      </div>

      <div className="d-flex align-items-center justify-content-between mt-2 mb-4">
        <div className="d-flex align-items-center gap-5">
          {statusButtons?.map((button) => (
            <Button
              onClick={() => handleChangeStatus(button?.value)}
              key={button?.value}
              sx={{
                textTransform: "unset",

                ...(selectedStatus == button?.value
                  ? {
                      border: "2px solid #009EFB",
                      color: "#009EFB",
                    }
                  : {
                      border: "2px solid transparent",
                      color: "gray",
                      ":hover": {
                        border: "2px solid #009EFB",
                        color: "#009EFB",
                        opacity: 0.5,
                      },
                    }),
              }}
            >
              {t(button?.label)}
            </Button>
          ))}
        </div>

        <div className="d-flex align-items-center gap-5">
          <HasPrivileges
            allowBP
            reqireMain={[Privilages.MANAGE_TIMESHEET_RECORDS]}
          >
            {showAccept() ? (
              <Button
                onClick={() => handleAcceptOrRejectEmployees(1, selectedRows)}
                sx={{
                  backgroundColor: "#27B40C",
                  color: "white",
                  height: 35,
                  width: 100,
                  textTransform: "unset",
                  ":hover": {
                    backgroundColor: "#188C01",
                  },
                  ":disabled": {
                    color: "white",
                    opacity: 0.7,
                  },
                }}
                disabled={
                  !!!selectedRows?.length || acceptOrRejectEmployeesLoading
                }
              >
                {acceptOrRejectEmployeesLoading &&
                currentlyLoading == 1 &&
                !!!currentlyLoadingEmployee ? (
                  <CircularProgress size={20} style={{ color: "white" }} />
                ) : (
                  t("accept")
                )}
              </Button>
            ) : null}
          </HasPrivileges>

          <HasPrivileges
            allowBP
            reqireMain={[Privilages.MANAGE_TIMESHEET_RECORDS]}
          >
            {showReject() ? (
              <Button
                onClick={() => handleAcceptOrRejectEmployees(5, selectedRows)}
                sx={{
                  backgroundColor: "#DE4243",
                  color: "white",
                  height: 35,
                  width: 100,
                  textTransform: "unset",
                  ":hover": {
                    backgroundColor: "#C63031",
                  },
                  ":disabled": {
                    color: "white",
                    opacity: 0.7,
                  },
                }}
                disabled={
                  !!!selectedRows?.length || acceptOrRejectEmployeesLoading
                }
              >
                {acceptOrRejectEmployeesLoading &&
                currentlyLoading == 0 &&
                !!!currentlyLoadingEmployee ? (
                  <CircularProgress size={20} style={{ color: "white" }} />
                ) : (
                  t("reject")
                )}{" "}
              </Button>
            ) : null}
          </HasPrivileges>

          <HasPrivileges
            allowBP
            reqireMain={[Privilages.MANAGE_TIMESHEET_RECORDS]}
          >
            {showReset() ? (
              <Button
                onClick={() => handleAcceptOrRejectEmployees(2, selectedRows)}
                sx={{
                  backgroundColor: "#555",
                  color: "white",
                  height: 35,
                  width: 100,
                  textTransform: "unset",
                  ":hover": {
                    backgroundColor: "#555",
                  },
                  ":disabled": {
                    color: "white",
                    opacity: 0.7,
                  },
                }}
                disabled={
                  !!!selectedRows?.length || acceptOrRejectEmployeesLoading
                }
              >
                {acceptOrRejectEmployeesLoading &&
                currentlyLoading == 2 &&
                !!!currentlyLoadingEmployee ? (
                  <CircularProgress size={20} style={{ color: "white" }} />
                ) : (
                  t("reset")
                )}{" "}
              </Button>
            ) : null}
          </HasPrivileges>
        </div>
      </div>

      <DataTable
        noDataComponent={<div className="p-4"> {t("no_records")} </div>}
        className="requests-profile-data-list datatable_shadow_style"
        columns={columns}
        data={timeSheetSummaryData}
        noHeader
        persistTableHead
        paginationComponent={() => (
          <Pagination
            customPaginator={pagination}
            styleWraper=""
            onPaginate={onPaginate}
            reducer={"flex"}
          />
        )}
        pagination={true}
        paginationServer={true}
        progressPending={timesheetSummaryLoading}
        progressComponent={<Loader />}
        selectableRows
        // selectableRowDisabled={(row) => row?.pending == 0} // this is commented for testing purposes and should be uncommented when deploying
        clearSelectedRows={false}
        selectableRowsComponentProps={(row) => row}
        selectableRowsComponent={forwardRef((e, d) => {
          if (!e.disabled) {
            return (
              <div className="mr-2">
                <CheckboxBooleanForm
                  formName={formName}
                  options={[""]}
                  name={e.name}
                  type="checkbox"
                  labelStyle="checkbox"
                  containerStyle="containerStyle"
                  optionsContainerStyle="d-flex flex-column"
                  optionItemStyle="optionItemStyle mr-0"
                  optionInputStyle="optionInputStyle containerStyle checkbox fix-checkbox-payroll-datable-responsive"
                  optionLabelStyle="optionLabelStyle ml-4 my-1 w-100"
                  optionLabelActiveStyle="optionLabelActiveStyle"
                  {...e}
                  checked={isChecked(e)}
                  onChange={() => handleSelect(e)}
                />
              </div>
            );
          } else {
            return (
              <div className="fix-checkbox-payroll-datable-responsive"></div>
            );
          }
        })}
      />

      {modal.isOpen ? (
        <TimeSheetSummaryPerEmployee
          modal={modal}
          handleCloseModal={handleCloseModal}
          summaryType={summaryType}
          selectedWeek={selectedWeek}
          refetch={fetchTimesheetSummary}
        />
      ) : null}
    </div>
  );
};

export default TimesheetSummary;
