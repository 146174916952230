import React from "react";

import {
  Slide,
  AppBar,
  Dialog,
  Toolbar,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { CompaniesList } from "../../Containers/AdminPanelLists";

const ViewCancelledCompanies = ({ onClose, data }) => {
  return (
    <Dialog
      open
      fullScreen
      toggle={onClose}
      hasModalFooter={false}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Cancelled Companies
          </Typography>
        </Toolbar>
      </AppBar>

      {data?.companiesIDs ? (
        <div className="px-4 pt-4">
          <CompaniesList relevantCompaniesIds={data.companiesIDs} />
        </div>
      ) : null}
    </Dialog>
  );
};

export default ViewCancelledCompanies;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
