import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { InputForm } from "form-builder";

import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import {
  addProcessStageAction,
  upsertRecruitmentProcessAction,
  setRecruitmentFormSubmitting,
  resetRecruitmentProcessForm,
  onRecruitemtProccessFormChangeAction,
} from "../../Store/Actions";
import { v4 as uuid, validate as uuidValidate } from "uuid";
import { processFormFieldSchema } from "../../Helpers/Validations/processFieldValidation";
import { Spinner } from "reactstrap";
import Loader from "../../Components/Loader";
import ProcessForm from "../../Components/RecruitmentProcessStage/ProcessForm";
import {
  recruitmentProcessFormScheme,
  runValidation,
} from "../../Helpers/Validations/formbuilderInputValidation";

// create a stage object dynamically
const createStage = () => {
  return {
    name: "",
    id: uuid(),
    process_id: null,
    inactivate_application: false,
    initial_flag: false,
    form: {
      id: null,
      fields: [],
    },
  };
};

export const RecruitmentProcessForm = (props) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const FormProps = {
    formName: "recruitmentProcessesForm",
    formServerValidation: "recruitmentProcessFormServerValidation",
    formSubmitting: props.recruitmentProcessFormSubmitting,
  };

  useEffect(() => {
    return () => {
      props.resetRecruitmentProcessForm();
    };
  }, []);

  const recruitmentProcessesForm = useSelector(
    (state) => state.recruitment.recruitmentProcessesForm
  );

  let initial_flags = recruitmentProcessesForm?.stages?.map(
    (stage) => stage.initial_flag
  );
  let inactivate_applications = recruitmentProcessesForm?.stages?.map(
    (stage) => stage.inactivate_application
  );

  useEffect(() => {
    if (!initial_flags?.includes(true) && initial_flags?.length > 1) {
      dispatch(
        onRecruitemtProccessFormChangeAction(
          recruitmentProcessesForm?.stages[0].id,
          { initial_flag: true }
        )
      );
    }
    if (!inactivate_applications?.includes(true) && initial_flags?.length) {
      dispatch(
        onRecruitemtProccessFormChangeAction(
          recruitmentProcessesForm?.stages[
            recruitmentProcessesForm?.stages.length - 1
          ].id,
          { inactivate_application: true }
        )
      );
    }
    return () => {};
  }, [initial_flags, inactivate_applications]);

  const { fieldId, stageId } =
    useSelector((state) => state.recruitment?.openField) || {};

  // let activeField = useSelector(
  //   (state) =>
  //     state.recruitment?.recruitmentProcessestSages?.[
  //       stageId
  //     ]?.form?.fields?.filter((el) => el.id === fieldId)[0]
  // );

  const processFormOpenFields = useSelector(
    (state) => state.recruitment?.processFormOpenFields
  );

  const handleAddStage = () => {
    if (processFormOpenFields?.length) {
      let openFieldsStatus = processFormOpenFields?.map((opField) => {
        let openFieldStage = recruitmentProcessesForm?.stages?.find(
          (stage) => stage.id === opField.stageId
        );
        let openField = openFieldStage?.form?.fields?.find(
          (field) => field.id === opField.fieldId
        );
        if (!!openField?.id) {
          dispatch({
            type: "SUBMITED_FIELD",
            payload: openField?.id,
          });
          return runValidation(openField);
        } else {
          return true;
        }
      });

      if (openFieldsStatus?.includes(false)) {
        return;
      } else {
        dispatch(addProcessStageAction(createStage()));
      }
    } else {
      dispatch(addProcessStageAction(createStage()));
    }
  };

  const handleSubmit = () => {
    recruitmentProcessFormScheme.validate(recruitmentProcessesForm, {
      abortEarly: false,
    });

    // return;

    props.setRecruitmentFormSubmitting();
    let res = {
      ...recruitmentProcessesForm,
      stages: props?.recruitmentProcessesForm?.stages?.map(
        ({ form, ...stage }) => ({
          ...stage,
          id: uuidValidate(stage.id) ? null : stage.id,
          form: {
            id: uuidValidate(form.id) ? null : form.id,
            fields: serializeForm(form.fields),
          },
        })
      ),
    };

    props.upsertRecruitmentProcessAction(res);

    return;

    // if (activeField) {
    //   processFormFieldSchema
    //     .validate(activeField, { abortEarly: true })
    //     .then((valid) => {
    //       props.upsertRecruitmentProcessAction(res);
    //     })
    //     .catch((err) => {

    //     });
    // } else {
    //   props.upsertRecruitmentProcessAction(res);
    // }

    // props.upsertRecruitmentProcessAction(res);
  };

  const serializeForm = (items, id = null) =>
    items
      ?.filter((item) => item.parent_field_id == id)
      ?.map(
        ({
          id,
          parent_field_id,
          mainOptions,
          apply_validation,
          apply_description,
          dependent_option_names,
          validation_type_ids,
          ancestors,
          ...item
        }) => ({
          ...item,
          id: uuidValidate(id) ? null : id,
          dependent_option_names: dependent_option_names.length
            ? dependent_option_names.map((opt) => opt?.label)
            : [],
          children_fields: serializeForm(items, id),
          options: item?.options?.map((option) => ({
            id:
              uuidValidate(option.value) || option.value == option.label
                ? null
                : option.value,
            name: option.label,
          })),
          validation_type_ids:
            !!apply_validation || validation_type_ids?.length
              ? validation_type_ids?.map((validation) => validation.value)
              : [],
        })
      );
  return (
    <>
      <div>
        <div className="my-3">
          <div className="col-12">
            <InputForm
              {...FormProps}
              label={t("name")}
              reducer="recruitment"
              // validateBy="textRequired"
              name="name"
              type="text"
              labelStyle="mr-5"
              placeholder={t("name")}
              containerStyle="align-items-center d-flex flex-row mb-3 mt-0"
              inputContainerStyle="flex-grow-1"
              validationName={`input.name`}
            />
          </div>
          <div className="col-12 align-items-center d-flex flex-row">
            <label className="mr-2">{t("add status")}</label>
            <button
              className="bg-main btn-add border-0 btn mx-4"
              onClick={handleAddStage}
            >
              <FontAwesomeIcon icon={faPlus} color="#fff" />
            </button>
          </div>
        </div>
        {recruitmentProcessesForm?.stages?.map((stage, i) => (
          <>
            {/* <RecruitmentProcessStage key={i} status={i} stage={stage} /> */}
            {stage?.name?.toLowerCase() === "hired" ? null : (
              <ProcessForm key={`${i}-${stage.id}`} status={i} stage={stage} />
            )}
          </>
        ))}
        {recruitmentProcessesForm?.stages
          ?.filter((stage) => stage.name.toLowerCase() === "hired")
          ?.map((stage, i) => (
            <>
              {/* <RecruitmentProcessStage key={i} status={i} stage={stage} /> */}
              <ProcessForm
                key={`${i}-${stage.id}`}
                status={recruitmentProcessesForm?.stages?.length - 1}
                stage={stage}
              />
            </>
          ))}

        <div className="d-flex justify-content-end align-items-center">
          <button
            className="btn btn-primary submit_btn_style"
            onClick={handleSubmit}
          >
            {props.isLoading ? (
              <Spinner
                style={{ width: "1rem", height: "1rem", color: "#fff" }}
              />
            ) : (
              t("save")
            )}
          </button>
        </div>
      </div>
      {props?.initRecruitmentProcessLoading && (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  recruitmentProcessStages: state.recruitment.recruitmentProcessestSages,
  recruitmentProcessesForm: state.recruitment.recruitmentProcessesForm,
  recruitmentProcessFormSubmitting:
    state.recruitment.recruitmentProcessFormSubmitting,
  isLoading: state.recruitment.upsertRecruitmentProcessLoading,
  initRecruitmentProcessLoading:
    state.recruitment.initRecruitmentProcessLoading,
});

export default connect(mapStateToProps, {
  addProcessStageAction,
  upsertRecruitmentProcessAction,
  setRecruitmentFormSubmitting,
  resetRecruitmentProcessForm,
})(RecruitmentProcessForm);
