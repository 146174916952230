import React from "react";
import MainModal from "../../Components/MainModal";
import { useTranslation } from "react-i18next";
import RequestLimitConfig from "../../Components/AttendanceTypeModal/RequestLimitConfig";
import { BSelect, CheckboxBooleanForm, InputForm } from "form-builder";
import { UPSERT_COMPANY_SETTINGS_PRESET } from "../../Graphql/mutation";
import { useMutation } from "@apollo/client";
import {
  normalizeCompanySettingsPresetSubmittedData,
  showToast,
} from "../../Helpers/HelperFns";
import { useDispatch, useSelector } from "react-redux";
import { showSuccessToast } from "../../Store/Actions";

const formName = "miscellanousForm";

const MiscellanousSettingsModal = ({
  modalData = {},
  handleClose = () => {},
  refetch = () => {},
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const formData = useSelector((state) => state?.flex?.[formName]);

  const [submitSettings, { loading }] = useMutation(
    UPSERT_COMPANY_SETTINGS_PRESET,
    {
      onCompleted: (data) => {
        console.log("data", data);
        if (data?.upsertCompanySettingPreset?.status == "success") {
          dispatch(showSuccessToast());
          handleClose();
          refetch();
        } else {
        }
      },
      onError(err) {
        showToast(
          "error",
          err?.graphQLErrors?.[0]?.extensions?.reason ||
            err?.graphQLErrors?.[0]?.message ||
            err?.message
        );
      },
    }
  );

  const handleSubmit = () => {
    submitSettings({
      variables: {
        input: normalizeCompanySettingsPresetSubmittedData(formData ?? {}),
      },
    });
  };

  const renderSection = () => {
    switch (modalData?.section) {
      case "probation":
        return (
          <>
            <InputForm
              formName={formName}
              reducer="flex"
              label={"Probation Period"}
              name="probation_value"
              placeholder="Enter period"
              labelStyle="mb-2"
              type="number"
              containerStyle="mb-2"
            />

            <BSelect
              formName={formName}
              reducer="flex"
              label={"Probation unit"}
              name="probation_unit"
              keepDefaultStyle
              placeholder={t("select option")}
              optionLabel="label"
              optionValue="label"
              options={[{ label: "days" }, { label: "months" }]}
              getOptionLabel={(option) => t(option?.label)}
            />
          </>
        );
      case "requests limits":
        return (
          <>
            <CheckboxBooleanForm
              rootStyle="mt-3"
              formName={formName}
              reducer="flex"
              name="has_request_dayoff_limit"
              options={[t("Day off request limit")]}
              optionLabelStyle={""}
              type="checkbox"
            />
            <div className="align-items-end px-0 row">
              <InputForm
                rootStyle="col-md-6"
                formName={formName}
                reducer="flex"
                label={"Max. Number of requests"}
                hasMinMax
                minValue={0}
                maxValue={Infinity}
                name="dayoff_request_limits"
                placeholder="Max. Number of requests"
                dependOn="has_request_dayoff_limit"
                dependancyType="equal"
                dependancyValue={[1]}
                stepInput
                labelStyle="mb-2"
                inputContainerStyle="input-container-style-default max-days-style"
                type="number"
                containerStyle=" "
              />

              <BSelect
                rootStyle="col-md-6"
                formName={formName}
                reducer="flex"
                label={"reset every"}
                name="dayoff_period_type"
                keepDefaultStyle
                dependOn="has_request_dayoff_limit"
                dependancyType="equal"
                dependancyValue={[1]}
                placeholder={t("select option")}
                optionLabel="label"
                optionValue="label"
                options={[
                  { label: "week" },
                  { label: "month" },
                  { label: "year" },
                ]}
                getOptionLabel={(option) => t(option.label)}
                containerStyle="row justify-content-between align-items-center"
                labelStyle="col-6"
                inputContainerStyle="col-6"
              />
            </div>

            <div className="d-flex align-items-center justify-content-between my-3">
              <CheckboxBooleanForm
                formName={formName}
                reducer="flex"
                name="submit_dayoff_cutoff_time"
                options={[
                  "Employee must submit dayoff request before the start of work day by",
                ]}
                optionLabelStyle={""}
                type="checkbox"
              />

              <InputForm
                formName={formName}
                reducer="flex"
                name="dayoff_cutoff_time"
                inputContainerStyle="input-container-style-default max-days-style"
                type="number"
                containerStyle="d-flex align-items-center justify-content-between"
                hasSuffix
                suffixTitle="hours"
                dependOn="submit_dayoff_cutoff_time"
                dependancyType="equal"
                dependancyValue={[1]}
              />
            </div>

            <hr />

            <CheckboxBooleanForm
              rootStyle="mt-3"
              formName={formName}
              reducer="flex"
              name="has_request_change_shift_limit"
              options={["Change shift request limit"]}
              optionLabelStyle={""}
              type="checkbox"
            />
            <div className="align-items-end px-0 row">
              <InputForm
                rootStyle="col-md-6"
                formName={formName}
                reducer="flex"
                label={"Max. Number of requests"}
                hasMinMax
                minValue={0}
                maxValue={Infinity}
                name="change_shift_request_limits"
                placeholder="Max. Number of requests"
                dependOn="has_request_change_shift_limit"
                dependancyType="equal"
                dependancyValue={[1]}
                stepInput
                labelStyle="mb-2"
                inputContainerStyle="input-container-style-default max-days-style"
                type="number"
                containerStyle=" "
              />

              <BSelect
                rootStyle="col-md-6"
                formName={formName}
                reducer="flex"
                label={"reset every"}
                name="change_shift_period_type"
                keepDefaultStyle
                dependOn="has_request_change_shift_limit"
                dependancyType="equal"
                dependancyValue={[1]}
                placeholder={t("select option")}
                optionLabel="label"
                optionValue="label"
                options={[
                  { label: "week" },
                  { label: "month" },
                  { label: "year" },
                ]}
                getOptionLabel={(option) => t(option.label)}
                containerStyle="row justify-content-between align-items-center"
                labelStyle="col-6"
                inputContainerStyle="col-6"
              />
            </div>

            <div className="d-flex align-items-center justify-content-between my-3">
              <CheckboxBooleanForm
                formName={formName}
                reducer="flex"
                name="submit_change_shift_cutoff_time"
                options={[
                  "Employee must submit change shift request before the start of work day by",
                ]}
                optionLabelStyle={""}
                type="checkbox"
              />

              <InputForm
                formName={formName}
                reducer="flex"
                name="change_shift_cutoff_time"
                inputContainerStyle="input-container-style-default max-days-style"
                type="number"
                containerStyle="d-flex align-items-center justify-content-between"
                hasSuffix
                suffixTitle="hours"
                dependOn="submit_change_shift_cutoff_time"
                dependancyType="equal"
                dependancyValue={[1]}
              />
            </div>

            <hr />
            <CheckboxBooleanForm
              rootStyle="mt-3"
              formName={formName}
              reducer="flex"
              name="has_request_additional_shift_limit"
              options={["Additional shift request limit"]}
              optionLabelStyle={""}
              type="checkbox"
            />
            <div className="align-items-end px-0 row">
              <InputForm
                rootStyle="col-md-6"
                formName={formName}
                reducer="flex"
                label={"Max. Number of requests"}
                hasMinMax
                minValue={0}
                maxValue={Infinity}
                name="additional_shift_request_limits"
                placeholder="Max. Number of requests"
                dependOn="has_request_additional_shift_limit"
                dependancyType="equal"
                dependancyValue={[1]}
                stepInput
                labelStyle="mb-2"
                inputContainerStyle="input-container-style-default max-days-style"
                type="number"
                containerStyle=" "
              />

              <BSelect
                rootStyle="col-md-6"
                formName={formName}
                reducer="flex"
                label={"reset every"}
                name="additional_shift_period_type"
                keepDefaultStyle
                dependOn="has_request_additional_shift_limit"
                dependancyType="equal"
                dependancyValue={[1]}
                placeholder={t("select option")}
                optionLabel="label"
                optionValue="label"
                options={[
                  { label: "week" },
                  { label: "month" },
                  { label: "year" },
                ]}
                getOptionLabel={(option) => t(option.label)}
                containerStyle="row justify-content-between align-items-center"
                labelStyle="col-6"
                inputContainerStyle="col-6"
              />
            </div>

            <div className="d-flex align-items-center justify-content-between my-3">
              <CheckboxBooleanForm
                formName={formName}
                reducer="flex"
                name="submit_additional_shift_cutoff_time"
                options={[
                  "Employee must submit additional shift request before the start of work day by",
                ]}
                optionLabelStyle={""}
                type="checkbox"
              />

              <InputForm
                formName={formName}
                reducer="flex"
                name="additional_shift_cutoff_time"
                inputContainerStyle="input-container-style-default max-days-style"
                type="number"
                containerStyle="d-flex align-items-center justify-content-between"
                hasSuffix
                suffixTitle="hours"
                dependOn="submit_additional_shift_cutoff_time"
                dependancyType="equal"
                dependancyValue={[1]}
              />
            </div>
          </>
        );
      case "flex shifts linking":
        return (
          <>
            <CheckboxBooleanForm
              formName={formName}
              name="allow_linked_shifts"
              options={["link with previous shifts"]}
              containerStyle="m-l-170"
              rootStyle="my-2"
              reducer="flex"
            />

            {formData?.allow_linked_shifts ? (
              <>
                <CheckboxBooleanForm
                  formName={formName}
                  name="set_time_limit"
                  options={["set time limit"]}
                  containerStyle="m-l-170"
                  rootStyle="my-2"
                  reducer="flex"
                />

                {formData?.set_time_limit ? (
                  <InputForm
                    formName={formName}
                    name="time_limit"
                    type="number"
                    hasSuffix
                    suffixTitle={"minutes"}
                    inputContainerStyle="input-container-style-default w-100"
                    reducer="flex"
                  />
                ) : null}
              </>
            ) : null}
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <MainModal
      isOpen={modalData?.isOpen}
      modalTitle={`update ${modalData?.section}`}
      btnOnClick={handleSubmit}
      toggle={handleClose}
      btnSubmitLoading={loading}
      disableSubmitButton={loading}
      size="lg"
    >
      {renderSection()}
    </MainModal>
  );
};

export default MiscellanousSettingsModal;
