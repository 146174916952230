import React, { useEffect, useState } from "react";
import { AddButton } from "../../Components/Buttons";
import Loader from "../../Components/Loader";
import { useTranslation } from "react-i18next";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import {
  BSelect,
  CheckboxBooleanForm,
  DateTimePickerForm,
  InputForm,
} from "../../Builder/Form";
import MainModal from "../../Components/MainModal";
import {
  FETCH_EMPLOYEE_GRACE_MINUTES_PROFILE_SETTINGS,
  FETCH_TIME_RANGES_PRESETS,
} from "../../Graphql/query";
import { useMutation, useQuery } from "@apollo/client";
import {
  DELETE_EMPLOYEE_GRACE_MINUTES_PROFILE,
  UPSERT_EMPLOYEE_GRACE_MINUTES_PROFILE,
} from "../../Graphql/mutation";
import { useSelector, useDispatch } from "react-redux";
import { onFormResetAction, showErrorToast } from "../../Store/Actions";
import { useParams } from "react-router-dom";
import HelperFns, { showToast } from "../../Helpers/HelperFns";
import {
  AcceptIconButton,
  EditIconButton,
  RemoveIconButton,
  TimesIconButton,
} from "../../Components/IconButtonWithTooltip";
import moment from "moment";
import DataTable from "react-data-table-component";
import swal from "sweetalert";

const formName = "employeeGraceMinutesProfileSetting";
const formServerValidation =
  "employeeGraceMinutesProfileSettingServerValidation";

const EmployeeGraceMinutesProfileSetting = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { userId } = useParams();

  const columns = [
    {
      name: t("start"),
      selector: "start",
      cell: (row) => <div>{moment(row?.start).format("DD-MM-YYYY")}</div>,
    },
    {
      name: t("allow grace minutes"),
      selector: "allow_grace_minutes",
      sortable: false,
      align: "center",
      cell: (row) => {
        return (
          <span>
            {row?.allow_grace_minutes ? (
              <AcceptIconButton
                iconProps={{
                  size: "lg",
                }}
              />
            ) : (
              <TimesIconButton
                iconProps={{
                  size: "lg",
                }}
              />
            )}
          </span>
        );
      },
    },
    {
      name: t("sign in grace minutes"),
      selector: "sign_in_grace_minutes",
      wrap: false,
      align: "center",
    },
    {
      name: t("actions"),
      wrap: true,
      selector: "action",
      grow: 0.25,
      cell: (row) => (
        <HasPrivileges
          reqireMain={[Privilages.ADD_EDIT_DELETE_EMPLOYEE_GRACE_MINUTES]}
          allowBP
          avalibleOnExpire={false}
          altExpireChildren={
            <div className="cards_table_actions">
              <EditIconButton />
              {row?.canDelete ? <RemoveIconButton /> : null}
            </div>
          }
        >
          <div className="cards_table_actions">
            <EditIconButton onClick={() => handleEdit(row)} />
            {row?.canDelete ? (
              <RemoveIconButton onClick={() => handleDelete(row)} />
            ) : null}
          </div>
        </HasPrivileges>
      ),
      ignoreRowClick: true,
    },
  ];

  const { loading, error, data, refetch } = useQuery(
    FETCH_EMPLOYEE_GRACE_MINUTES_PROFILE_SETTINGS,
    {
      notifyOnNetworkStatusChange: true,
      skip: !!!userId,
      variables: {
        employee_id: userId,
      },
    }
  );

  const [
    employeeGraceMinutesSettingModal,
    setEmployeeGraceMinutesSettingModal,
  ] = useState({
    isOpen: false,
  });

  const addNewConfig = () => {
    setEmployeeGraceMinutesSettingModal((prevState) => ({
      ...prevState,
      isOpen: true,
    }));
  };

  const toggle = () => {
    setEmployeeGraceMinutesSettingModal((prevState) => ({
      isOpen: false,
    }));
    dispatch(onFormResetAction(formName));
    dispatch(onFormResetAction(formServerValidation));
  };

  const handleEdit = (row) => {
    let { canDelete, ...editRecord } = row;
    dispatch(onFormResetAction(formName, editRecord));
    setEmployeeGraceMinutesSettingModal({
      isOpen: true,
    });
  };

  const handleDelete = (row) => {
    swal({
      width: "auto",
      title: t("are you sure"),
      text: t("defaut_warning_messsage"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: [t("Cancel"), { text: t("OK"), closeModal: true }],
    }).then((willDelete) => {
      if (willDelete) {
        deleteEmployeeOvertimeGraceMinutesProfile({
          variables: { id: row?.id },
        });
      }
    });
  };

  const [
    deleteEmployeeOvertimeGraceMinutesProfile,
    { loading: deleteLoading },
  ] = useMutation(DELETE_EMPLOYEE_GRACE_MINUTES_PROFILE, {
    onCompleted(res) {
      refetch();
      // do someting
    },
    onError(error) {
      // do something
      dispatch(
        showErrorToast(
          (error?.graphQLErrors?.[0]?.extensions?.reason ||
            error?.graphQLErrors?.[0]?.message) ??
            error?.message
        )
      );
    },
  });

  return (
    <>
      <div className="work_schedule_tab">
        <HasPrivileges
          reqireMain={[Privilages.ADD_EDIT_DELETE_EMPLOYEE_GRACE_MINUTES]}
          allowBP
        >
          <div className="tab_header mb-4">
            <AddButton onClick={addNewConfig}>
              {t("Add New Configuration")}
            </AddButton>
          </div>
        </HasPrivileges>

        <DataTable
          noDataComponent={<div className="p-4"> {t("no_records")} </div>}
          className="cards_table"
          columns={columns}
          data={data?.getGraceMinutes ?? []}
          noHeader
          persistTableHead
          progressPending={loading || deleteLoading}
          progressComponent={<Loader />}
        />
      </div>

      {employeeGraceMinutesSettingModal?.isOpen ? (
        <EmployeeGraceMinutesProfileSettingModal
          modalData={employeeGraceMinutesSettingModal}
          toggle={toggle}
          refetch={refetch}
        />
      ) : null}
    </>
  );
};

export default EmployeeGraceMinutesProfileSetting;

const EmployeeGraceMinutesProfileSettingModal = (props) => {
  const FormProps = {
    formName,
    formServerValidation,
  };

  const { t } = useTranslation();
  const { userId } = useParams();
  const dispatch = useDispatch();

  const { loading, error, data, refetch } = useQuery(
    FETCH_TIME_RANGES_PRESETS,
    {
      notifyOnNetworkStatusChange: true,
      skip: !props?.modalData?.isOpen,
    }
  );

  const [
    upsertEmployeeGraceMinutesProfileSetting,
    { loading: submitLoading, reset, error: submitError },
  ] = useMutation(UPSERT_EMPLOYEE_GRACE_MINUTES_PROFILE, {
    onCompleted(res) {
      if (res?.upsertGraceMinute?.id) {
        props.toggle();
        props?.refetch();
      }
    },
  });

  useEffect(() => {
    if (submitError?.graphQLErrors?.[0]?.extensions?.reason) {
      showToast(
        "error",
        submitError?.graphQLErrors?.[0]?.extensions?.reason,
        false
      );
    }
    if (submitError?.graphQLErrors?.[0]?.extensions?.validation) {
      dispatch(
        onFormResetAction(
          formServerValidation,
          submitError?.graphQLErrors?.[0]?.extensions?.validation
        )
      );
    }

    return () => {};
  }, [submitError]);

  const employeeGraceMinutesProfileSetting = useSelector(
    (state) => state?.super?.employeeGraceMinutesProfileSetting
  );

  const handleSave = () => {
    reset();
    let input = {
      ...employeeGraceMinutesProfileSetting,
      emp_id: userId,
    };

    upsertEmployeeGraceMinutesProfileSetting({ variables: { input } });
  };

  return (
    <MainModal
      isOpen={props?.modalData?.isOpen}
      size="lg"
      modalTitle={t("New Configuration")}
      btnSubmitLoading={loading || submitLoading}
      btnOnClick={handleSave}
      toggle={props.toggle}
      className="p-5 "
    >
      <>
        <DateTimePickerForm
          {...FormProps}
          datePickerContainer="w-100"
          label="Starting From"
          containerStyle="flex-column align-items-start"
          labelStyle="custom-label-style mb-2"
          name="start"
          validateBy="textRequired"
          validationName="input.start"
          disabled={loading || submitLoading}
          requestFormat="YYYY-MM-DD"
        />

        <div>
          <h6 className="sub-title">{t("Grace Minutes")}</h6>
          <CheckboxBooleanForm
            {...FormProps}
            options={["allow grace minutes"]}
            name="allow_grace_minutes"
            type="checkbox"
            setWithValue
          />

          <InputForm
            {...FormProps}
            hasSuffix
            suffixTitle="minutes"
            validateBy="textRequired"
            label="grace minutes"
            name="sign_in_grace_minutes"
            validationName={`sign_in_grace_minutes`}
            type="text"
            containerStyle="container-style-default justify-content-between"
            dependOn="allow_grace_minutes"
            dependancyType="equal"
            dependancyValue={[true]}
          />
        </div>
      </>
    </MainModal>
  );
};
