/*
  Offices
*/

/*
  Departments
*/

/*
  Positions
*/

/*
  Work Timings
*/

/*
  Leave & Breaks
*/

/*
  Attendance Profiles
*/

/*
  Holidays
*/

/*
  Documents
*/

/*
  Expense Categories
*/

/*
  Access levels
*/

/*
  Announcements
*/

export const serializeAnnouncementFetching = ({ send_to = "{}", ...data }) => {
  const sendTo = JSON.parse(send_to);
  const is_all = +!Object.values(sendTo)?.length;

  return {
    ...data,
    is_all,
    is_offices: is_all ? 0 : +!!sendTo?.offices?.length,
    is_employees: is_all ? 0 : +!!sendTo?.users?.length,
    is_positions: is_all ? 0 : +!!sendTo?.positions?.length,
    is_departments: is_all ? 0 : +!!sendTo?.departments?.ids?.length,
    justManagers: sendTo?.departments?.justManagers || 0,

    employees: sendTo?.users ? sendTo?.users?.map(String) : [],
    managers: sendTo?.managers ? sendTo?.managers?.map(String) : [],
    offices: sendTo?.offices ? sendTo?.offices?.map(String) : [],
    positions: sendTo?.positions ? sendTo?.positions?.map(String) : [],
    departments: sendTo?.departments?.ids
      ? sendTo?.departments?.ids?.map(String)
      : [],

    // AdminPanel
    sendToState: sendTo?.type || "ALL",
    companies: sendTo?.companies ? sendTo?.companies?.map(String) : [],
  };
};

export const serializeAnnouncementUpsert = ({
  is_all,
  is_offices,
  is_employees,
  is_positions,
  is_departments,
  sendToState,
  isAdminPanel,
  offices,
  managers,
  positions,
  employees,
  companies,
  departments,
  justManagers,
  ...data
}) => {
  const filterEmptyArr = (arr) => (arr.length ? arr : undefined);

  return {
    ...data,
    type: isAdminPanel ? "Admin" : "Company",
    send_to: isAdminPanel
      ? JSON.stringify({
          type: sendToState,
          companies: companies.map(Number),
        })
      : is_all
        ? "{}"
        : JSON.stringify({
            offices: filterEmptyArr(offices.map(Number)),
            users: filterEmptyArr(employees.map(Number)),
            managers: filterEmptyArr(managers.map(Number)),
            positions: filterEmptyArr(positions.map(Number)),
            departments: departments.length
              ? { justManagers, ids: departments.map(Number) }
              : undefined,
          }),
  };
};
