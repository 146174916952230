import React from "react";
import { connect } from "react-redux";
import { CheckboxBooleanForm } from "form-builder";
import MainModal from "../../Components/MainModal";
import {
  toggleYearlyReportCheckbox,
  exportYearlyReportAction,
  hideExportYearlyReportModal,
} from "../../Store/Actions";
import { useTranslation } from "react-i18next";
import CountryFields from "../../Components/CountryFields";

const formName = "yearlyReportExport";

const ExportModal = (props) => {
  const { t } = useTranslation();

  const { isModalVissible, column, order } = props;

  const toggleModal = () => {
    props?.hideExportYearlyReportModal({
      ref: "exportYearlyReportModal",
    });
  };

  const handleSubmitBtn = () => {
    const { remote_work, ...data } = props?.reportColumns;

    props?.exportYearlyReportAction({
      ref: "exportYearlyReportModal",
      exportColumns: data,
      filterOptions: {
        user_id: props?.yearlyReport?.employees ?? null,
        department_id: props?.yearlyReport?.departments ?? null,
        office_id: props?.yearlyReport?.offices ?? null,
        year: props?.yearlyReport?.year?.toString(),
        column: column || "name",
        order: order,
      },
    });
  };

  return (
    <MainModal
      isOpen={isModalVissible}
      toggle={toggleModal}
      modalTitle={t("export yearly report")}
      btnLabel={t("export")}
      btnOnClick={handleSubmitBtn}
      btnSubmitLoading={props?.isLoading}
    >
      <div className="row">
        <div className="col-6">
          {/* (Start) Offices */}
          <CheckboxBooleanForm
            formName={formName}
            options={["offices"]}
            name="office"
            type="checkbox"
          />
          {/* (End) Offices */}

          {/* (Start) Departments */}
          <CheckboxBooleanForm
            formName={formName}
            options={["departments"]}
            name="department"
            type="checkbox"
          />
          {/* (End) Departments */}

          {/* (Start) Positions */}
          <CheckboxBooleanForm
            formName={formName}
            options={["positions"]}
            name="position"
            type="checkbox"
          />

          <CheckboxBooleanForm
            formName={formName}
            options={["employee code"]}
            name="emp_code"
            type="checkbox"
          />
          {/* (End) Positions */}

          {/* (Start) Holidays */}
          <CheckboxBooleanForm
            formName={formName}
            options={["holidays"]}
            name="holidays"
            type="checkbox"
          />
          {/* (End) Holidays */}

          {/* (Start) Weekends */}
          <CheckboxBooleanForm
            formName={formName}
            options={["Days Off"]}
            name="weekends"
            type="checkbox"
          />
          {/* (End) Weekends */}

          {/* (Start) Work Days: Head Checkbox*/}
          <CheckboxBooleanForm
            formName={formName}
            options={["work days"]}
            name="attended_days"
            type="checkbox"
          />
          <div className="col-12 mx-2">
            {/* (Start) Total Days Attended */}

            <CheckboxBooleanForm
              formName={formName}
              options={["total normal days attended"]}
              name="ontime_total"
              type="checkbox"
              dependOn="attended_days"
              dependancyType="equal"
              dependancyValue={[1]}
              resetValue={0}
            />
            <div className="col-12 mx-2">
              <CheckboxBooleanForm
                formName={formName}
                options={["normal days ontime"]}
                name="ontime_normal"
                type="checkbox"
                dependOn="ontime_total"
                dependancyType="equal"
                dependancyValue={[1]}
                resetValue={0}
              />
              <CheckboxBooleanForm
                formName={formName}
                options={["normal days late"]}
                name="late_normal"
                type="checkbox"
                dependOn="ontime_total"
                dependancyType="equal"
                dependancyValue={[1]}
                resetValue={0}
              />
              <div className="col-12 mx-2">
                <CheckboxBooleanForm
                  formName={formName}
                  options={["minor late"]}
                  name="normal_minor_late"
                  type="checkbox"
                  dependOn="late_normal"
                  dependancyType="equal"
                  dependancyValue={[1]}
                  resetValue={0}
                />
                <CheckboxBooleanForm
                  formName={formName}
                  options={[t("major late", { tier: 2 })]}
                  name="normal_major_late"
                  type="checkbox"
                  dependOn="late_normal"
                  dependancyType="equal"
                  dependancyValue={[1]}
                  resetValue={0}
                />
                <CheckboxBooleanForm
                  formName={formName}
                  options={[t("major late", { tier: 3 })]}
                  name="normal_major_late1"
                  type="checkbox"
                  dependOn="late_normal"
                  dependancyType="equal"
                  dependancyValue={[1]}
                  resetValue={0}
                />
                <CheckboxBooleanForm
                  formName={formName}
                  options={[t("major late", { tier: 4 })]}
                  name="normal_major_late2"
                  type="checkbox"
                  dependOn="late_normal"
                  dependancyType="equal"
                  dependancyValue={[1]}
                  resetValue={0}
                />
                <CheckboxBooleanForm
                  formName={formName}
                  options={[t("major late", { tier: 5 })]}
                  name="normal_major_late3"
                  type="checkbox"
                  dependOn="late_normal"
                  dependancyType="equal"
                  dependancyValue={[1]}
                  resetValue={0}
                />
                <CheckboxBooleanForm
                  formName={formName}
                  options={[t("major late", { tier: 6 })]}
                  name="normal_major_late4"
                  type="checkbox"
                  dependOn="late_normal"
                  dependancyType="equal"
                  dependancyValue={[1]}
                  resetValue={0}
                />
                <CheckboxBooleanForm
                  formName={formName}
                  options={[t("major late", { tier: 7 })]}
                  name="normal_major_late5"
                  type="checkbox"
                  dependOn="late_normal"
                  dependancyType="equal"
                  dependancyValue={[1]}
                  resetValue={0}
                />
                <CheckboxBooleanForm
                  formName={formName}
                  options={[t("major late", { tier: 8 })]}
                  name="normal_major_late6"
                  type="checkbox"
                  dependOn="late_normal"
                  dependancyType="equal"
                  dependancyValue={[1]}
                  resetValue={0}
                />
                <CheckboxBooleanForm
                  formName={formName}
                  options={[t("major late", { tier: 9 })]}
                  name="normal_major_late7"
                  type="checkbox"
                  dependOn="late_normal"
                  dependancyType="equal"
                  dependancyValue={[1]}
                  resetValue={0}
                />
                <CheckboxBooleanForm
                  formName={formName}
                  options={[t("major late", { tier: 10 })]}
                  name="normal_major_late8"
                  type="checkbox"
                  dependOn="late_normal"
                  dependancyType="equal"
                  dependancyValue={[1]}
                  resetValue={0}
                />
                <CheckboxBooleanForm
                  formName={formName}
                  options={[t("major late", { tier: 11 })]}
                  name="normal_major_late9"
                  type="checkbox"
                  dependOn="late_normal"
                  dependancyType="equal"
                  dependancyValue={[1]}
                  resetValue={0}
                />
                <CheckboxBooleanForm
                  formName={formName}
                  options={[t("major late", { tier: 12 })]}
                  name="normal_major_late10"
                  type="checkbox"
                  dependOn="late_normal"
                  dependancyType="equal"
                  dependancyValue={[1]}
                  resetValue={0}
                />
              </div>
            </div>
            {/* (End) Total Days Attended */}

            {/* (Start) Total Half-Days Attended */}
            <CheckboxBooleanForm
              formName={formName}
              options={["total half-days attended"]}
              name="half_total"
              type="checkbox"
              dependOn="attended_days"
              dependancyType="equal"
              dependancyValue={[1]}
              resetValue={0}
            />
            <div className="col-12 mx-2">
              <CheckboxBooleanForm
                formName={formName}
                options={["half-days ontime"]}
                name="ontime_half"
                type="checkbox"
                dependOn="half_total"
                dependancyType="equal"
                dependancyValue={[1]}
                resetValue={0}
              />
              <CheckboxBooleanForm
                formName={formName}
                options={["half-days late"]}
                name="late_half"
                type="checkbox"
                dependOn="half_total"
                dependancyType="equal"
                dependancyValue={[1]}
                resetValue={0}
              />
              <div className="col-12 mx-2">
                <CheckboxBooleanForm
                  formName={formName}
                  options={["minor late"]}
                  name="half_minor_late"
                  type="checkbox"
                  dependOn="late_half"
                  dependancyType="equal"
                  dependancyValue={[1]}
                  resetValue={0}
                />
                <CheckboxBooleanForm
                  formName={formName}
                  options={[t("major late", { tier: 2 })]}
                  resetValue={0}
                  name="half_major_late"
                  type="checkbox"
                  dependOn="late_half"
                  dependancyType="equal"
                  dependancyValue={[1]}
                />
                <CheckboxBooleanForm
                  formName={formName}
                  options={[t("major late", { tier: 3 })]}
                  name="half_major_late1"
                  type="checkbox"
                  dependOn="late_half"
                  dependancyType="equal"
                  dependancyValue={[1]}
                  resetValue={0}
                />
                <CheckboxBooleanForm
                  formName={formName}
                  options={[t("major late", { tier: 4 })]}
                  name="half_major_late2"
                  type="checkbox"
                  dependOn="late_half"
                  dependancyType="equal"
                  dependancyValue={[1]}
                  resetValue={0}
                />
                <CheckboxBooleanForm
                  formName={formName}
                  options={[t("major late", { tier: 5 })]}
                  name="half_major_late3"
                  type="checkbox"
                  dependOn="late_half"
                  dependancyType="equal"
                  dependancyValue={[1]}
                  resetValue={0}
                />
                <CheckboxBooleanForm
                  formName={formName}
                  options={[t("major late", { tier: 6 })]}
                  name="half_major_late4"
                  type="checkbox"
                  dependOn="late_half"
                  dependancyType="equal"
                  dependancyValue={[1]}
                  resetValue={0}
                />
                <CheckboxBooleanForm
                  formName={formName}
                  options={[t("major late", { tier: 7 })]}
                  name="half_major_late5"
                  type="checkbox"
                  dependOn="late_half"
                  dependancyType="equal"
                  dependancyValue={[1]}
                  resetValue={0}
                />
                <CheckboxBooleanForm
                  formName={formName}
                  options={[t("major late", { tier: 8 })]}
                  name="half_major_late6"
                  type="checkbox"
                  dependOn="late_half"
                  dependancyType="equal"
                  dependancyValue={[1]}
                  resetValue={0}
                />
                <CheckboxBooleanForm
                  formName={formName}
                  options={[t("major late", { tier: 9 })]}
                  name="half_major_late7"
                  type="checkbox"
                  dependOn="late_half"
                  dependancyType="equal"
                  dependancyValue={[1]}
                  resetValue={0}
                />
                <CheckboxBooleanForm
                  formName={formName}
                  options={[t("major late", { tier: 10 })]}
                  name="half_major_late8"
                  type="checkbox"
                  dependOn="late_half"
                  dependancyType="equal"
                  dependancyValue={[1]}
                  resetValue={0}
                />
                <CheckboxBooleanForm
                  formName={formName}
                  options={[t("major late", { tier: 11 })]}
                  name="half_major_late9"
                  type="checkbox"
                  dependOn="late_half"
                  dependancyType="equal"
                  dependancyValue={[1]}
                  resetValue={0}
                />
                <CheckboxBooleanForm
                  formName={formName}
                  options={[t("major late", { tier: 12 })]}
                  name="half_major_late10"
                  type="checkbox"
                  dependOn="late_half"
                  dependancyType="equal"
                  dependancyValue={[1]}
                  resetValue={0}
                />
              </div>
            </div>
            {/* (End) Total Half-Days Attended */}

            {/* (Start) Days From Home */}
            <CheckboxBooleanForm
              formName={formName}
              options={["days from home"]}
              name="from_home"
              type="checkbox"
              dependOn="attended_days"
              dependancyType="equal"
              dependancyValue={[1]}
              resetValue={0}
            />
            {/* (End) Days From Home */}

            {/* (Start) Late Days */}
            <CheckboxBooleanForm
              formName={formName}
              options={["late days"]}
              name="late_total"
              type="checkbox"
              dependOn="attended_days"
              dependancyType="equal"
              dependancyValue={[1]}
              resetValue={0}
            />
            {/* (End) Late Days */}

            {/* (Start) Late Duration */}
            <CheckboxBooleanForm
              formName={formName}
              options={["late duration"]}
              name="late_duration"
              type="checkbox"
              dependOn="attended_days"
              dependancyType="equal"
              dependancyValue={[1]}
              resetValue={0}
            />
            {/* (End) Late Duration */}

            {/* (Start) Total Absent Days */}
            <CheckboxBooleanForm
              formName={formName}
              options={["total absent days"]}
              name="absent_total"
              type="checkbox"
              dependOn="attended_days"
              dependancyType="equal"
              dependancyValue={[1]}
              resetValue={0}
            />
            {/* (End) Total Absent Days */}

            <div className="col-12 mx-2">
              {/* (Start) Total Normal Days Absent */}
              <CheckboxBooleanForm
                formName={formName}
                options={["total normal days absent"]}
                name="absent_normal"
                type="checkbox"
                dependOn="absent_total"
                dependancyType="equal"
                dependancyValue={[1]}
                resetValue={0}
              />
              <div className="col-12 mx-2">
                <CheckboxBooleanForm
                  formName={formName}
                  options={["no show absences"]}
                  name="absent_normal_no_show"
                  type="checkbox"
                  dependOn="absent_normal"
                  dependancyType="equal"
                  dependancyValue={[1]}
                  resetValue={0}
                />
                <CheckboxBooleanForm
                  formName={formName}
                  options={["early sign-out absence"]}
                  name="absent_normal_early_sign_out"
                  type="checkbox"
                  dependOn="absent_normal"
                  dependancyType="equal"
                  dependancyValue={[1]}
                  resetValue={0}
                />
                <CheckboxBooleanForm
                  formName={formName}
                  options={["late sign-in absence"]}
                  name="absent_normal_late_sign_in"
                  type="checkbox"
                  dependOn="absent_normal"
                  dependancyType="equal"
                  dependancyValue={[1]}
                  resetValue={0}
                />
              </div>
              {/* (End) Total Normal Days Absent */}

              {/* (Start) Total Half-Days Absent */}
              <CheckboxBooleanForm
                formName={formName}
                options={["total half-days absent"]}
                name="absent_half"
                type="checkbox"
                dependOn="absent_total"
                dependancyType="equal"
                dependancyValue={[1]}
                resetValue={0}
              />
              <div className="col-12 mx-2">
                <CheckboxBooleanForm
                  formName={formName}
                  options={["no show absences"]}
                  name="absent_half_no_show"
                  type="checkbox"
                  dependOn="absent_half"
                  dependancyType="equal"
                  dependancyValue={[1]}
                  resetValue={0}
                />
                <CheckboxBooleanForm
                  formName={formName}
                  options={["early sign-out absence"]}
                  name="absent_half_early_sign_out"
                  type="checkbox"
                  dependOn="absent_half"
                  dependancyType="equal"
                  dependancyValue={[1]}
                  resetValue={0}
                />
                <CheckboxBooleanForm
                  formName={formName}
                  options={["late sign-in absence"]}
                  name="absent_half_late_sign_in"
                  type="checkbox"
                  dependOn="absent_half"
                  dependancyType="equal"
                  dependancyValue={[1]}
                  resetValue={0}
                />
              </div>
              {/* (End) Total Half-Days Absent */}
            </div>
          </div>
          {/* (Start) Work Days: Head Checkbox*/}
        </div>

        <div className="col-6">
          {/* (Start) Leaves */}
          <CheckboxBooleanForm
            formName={formName}
            options={["leaves"]}
            name="leavesColumn"
            type="checkbox"
          />
          <div className="col-12 mx-2">
            <CheckboxBooleanForm
              dependOn="leavesColumn"
              dependancyType="equal"
              dependancyValue={[1]}
              resetValue={0}
              formName={formName}
              options={["normal leaves"]}
              name="normal_leaves"
              type="checkbox"
            />
            <CheckboxBooleanForm
              dependOn="leavesColumn"
              dependancyType="equal"
              dependancyValue={[1]}
              resetValue={0}
              formName={formName}
              options={["emergency leaves"]}
              name="emergency_leaves"
              type="checkbox"
            />
            <CheckboxBooleanForm
              dependOn="leavesColumn"
              dependancyType="equal"
              dependancyValue={[1]}
              resetValue={0}
              formName={formName}
              options={["unpaid leaves"]}
              name="unpaid_leaves"
              type="checkbox"
            />
            <CheckboxBooleanForm
              dependOn="leavesColumn"
              dependancyType="equal"
              dependancyValue={[1]}
              resetValue={0}
              formName={formName}
              options={["sick leaves"]}
              name="sick_leaves"
              type="checkbox"
            />
            <CheckboxBooleanForm
              dependOn="leavesColumn"
              dependancyType="equal"
              dependancyValue={[1]}
              resetValue={0}
              formName={formName}
              options={["half-days"]}
              name="half_days"
              type="checkbox"
            />
          </div>
          {/* (End) Leaves */}

          {/* (Start) Remaining Leaves */}
          <CheckboxBooleanForm
            formName={formName}
            options={["remaining leaves"]}
            name="remaningLeaves"
            type="checkbox"
          />
          <div className="col-12 mx-2">
            <CheckboxBooleanForm
              dependOn="remaningLeaves"
              dependancyType="equal"
              dependancyValue={[1]}
              formName={formName}
              options={["normal leaves"]}
              name="remaining_annual_leaves"
              type="checkbox"
              resetValue={0}
            />
            <CheckboxBooleanForm
              dependOn="remaningLeaves"
              dependancyType="equal"
              dependancyValue={[1]}
              formName={formName}
              options={["emergency leaves"]}
              name="remaining_emergency_credit"
              type="checkbox"
              resetValue={0}
            />
          </div>
          {/* (End) Remaining Leaves */}

          {/* (Start) check-ins hours */}
          <CheckboxBooleanForm
            formName={formName}
            options={["Check-in Hours"]}
            name="total_checkIn_hours_secs"
            type="checkbox"
          />
          {/* (End) check-ins hours */}

          {/* (Start) Overtime */}

          <CheckboxBooleanForm
            formName={formName}
            options={["overtime"]}
            name="overtimeColumn"
            type="checkbox"
          />
          <div className="col-12 mx-2">
            <CheckboxBooleanForm
              dependOn="overtimeColumn"
              dependancyType="equal"
              dependancyValue={[1]}
              resetValue={0}
              formName={formName}
              options={["total overtime hours"]}
              name="total_over_time_hours"
              type="checkbox"
            />

            <CheckboxBooleanForm
              dependOn="overtimeColumn"
              dependancyType="equal"
              dependancyValue={[1]}
              resetValue={0}
              formName={formName}
              options={["number of days"]}
              name="overtime_days"
              type="checkbox"
            />
            <CheckboxBooleanForm
              dependOn="overtimeColumn"
              dependancyType="equal"
              dependancyValue={[1]}
              resetValue={0}
              formName={formName}
              options={["overtime duration"]}
              name="accepted_total_daily_overtime_hours"
              type="checkbox"
            />
          </div>
          {/* (End) Overtime */}

          {/* (Start) permissions */}
          <CheckboxBooleanForm
            formName={formName}
            options={["permissions"]}
            name="permissionsColumn"
            type="checkbox"
          />
          <div className="col-12 mx-2">
            <CheckboxBooleanForm
              dependOn="permissionsColumn"
              dependancyType="equal"
              dependancyValue={[1]}
              resetValue={0}
              formName={formName}
              options={["number of permissions"]}
              name="permissions"
              type="checkbox"
            />
            <CheckboxBooleanForm
              dependOn="permissionsColumn"
              dependancyType="equal"
              dependancyValue={[1]}
              resetValue={0}
              formName={formName}
              options={["permission duration"]}
              name="permissions_time"
              type="checkbox"
            />

            <CheckboxBooleanForm
              dependOn="permissionsColumn"
              dependancyType="equal"
              dependancyValue={[1]}
              resetValue={0}
              formName={formName}
              options={["number of unpaid permissions"]}
              name="unpaid_permissions"
              type="checkbox"
            />
            <CheckboxBooleanForm
              dependOn="permissionsColumn"
              dependancyType="equal"
              dependancyValue={[1]}
              resetValue={0}
              formName={formName}
              options={["unpaid permission duration"]}
              name="unpaid_permissions_time"
              type="checkbox"
            />
          </div>
          {/* (End) permissions */}

          {/* (Start) breaks */}
          <CheckboxBooleanForm
            formName={formName}
            options={["breaks"]}
            name="breaksColumn"
            type="checkbox"
          />
          <div className="col-12 mx-2">
            <CheckboxBooleanForm
              dependOn="breaksColumn"
              dependancyType="equal"
              dependancyValue={[1]}
              resetValue={0}
              formName={formName}
              options={["number of breaks"]}
              name="breaks"
              type="checkbox"
            />
            <CheckboxBooleanForm
              dependOn="breaksColumn"
              dependancyType="equal"
              dependancyValue={[1]}
              resetValue={0}
              formName={formName}
              options={["break duration"]}
              name="breaks_time"
              type="checkbox"
            />
          </div>
          {/* (End) breaks */}

          {/* (Start) Total Work Hours */}
          <CheckboxBooleanForm
            formName={formName}
            options={["total hrs worked"]}
            name="total_hours_secs"
            type="checkbox"
          />
          {/* (End) Total Work Hours */}

          {/* (Start) Expected Work Hours */}
          <CheckboxBooleanForm
            formName={formName}
            options={["expected work hrs"]}
            name="expected_hours_secs"
            type="checkbox"
          />

          {/* start remot work */}
          <CheckboxBooleanForm
            formName={formName}
            options={["remote work"]}
            name="remote_work"
            type="checkbox"
          />
          <div className="col-12 mx-2">
            <CheckboxBooleanForm
              formName={formName}
              options={["from_home"]}
              name="from_home"
              type="checkbox"
              dependOn="remote_work"
              dependancyType="equal"
              dependancyValue={[1]}
              resetValue={0}
            />
            <CheckboxBooleanForm
              formName={formName}
              options={["outside home"]}
              name="from_field"
              type="checkbox"
              dependOn="remote_work"
              dependancyType="equal"
              dependancyValue={[1]}
              resetValue={0}
            />
          </div>
          {/* end remot work */}

          {/* (Start) Expected Work Hours */}
          <CheckboxBooleanForm
            formName={formName}
            options={["expected work hrs"]}
            name="expected_hours_secs"
            type="checkbox"
          />
          {/* (End) Expected Work Hours */}
          <CheckboxBooleanForm
            formName={formName}
            options={["total early sign out time"]}
            name="total_early_sign_out_time"
            type="checkbox"
          />
          <CheckboxBooleanForm
            formName={formName}
            options={["total penalties as days"]}
            name="total_penalties_as_days"
            type="checkbox"
          />
          <CheckboxBooleanForm
            formName={formName}
            options={["total penalties as fixed amount"]}
            name="total_penalties_as_fixed_amount"
            type="checkbox"
          />
          <CheckboxBooleanForm
            formName={formName}
            options={["total penalties as hours"]}
            name="total_penalties_as_hours"
            type="checkbox"
          />

          <CountryFields formName={formName} />
        </div>
      </div>
    </MainModal>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.super.exportYearlyReportModal?.isLoading,
    reportColumns: state.super.yearlyReportExport,
    yearlyReport: state.super.yearlyReport,
    isModalVissible: state.super.exportYearlyReportModal.isVissible,
  };
};

export default connect(mapStateToProps, {
  toggleYearlyReportCheckbox,
  exportYearlyReportAction,
  hideExportYearlyReportModal,
})(ExportModal);
