import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Constants from "../../Constants";
import MainModal from "../MainModal";
import {
  BSelect,
  InputForm,
  DateTimePickerForm,
  CheckboxBooleanForm,
} from "form-builder";
import Dropzone from "react-dropzone-uploader";
import {
  onInputResetAction,
  attendanceActionModalFailed,
  normalRequestActionAttempt,
  unpaidRequestActionAttempt,
  emergencyRequestActionAttempt,
  permissionRequestActionAttempt,
  halfdayRequestActionAttempt,
  homeExchangeRequestActionAttempt,
  homeFlexRequestActionAttempt,
  upsertClaimsRequestAction,
  onInputChangeAction,
  upsertSickRequestAction,
  onInputResetWithValueAction,
  upsertCustomRequestAction,
  showErrorToast,
  showSuccessToast,
  updateValueAction,
  toggleAttendanceActionModal,
} from "../../Store/Actions";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import HelperFns, { getReqData, showToast } from "../../Helpers/HelperFns";
import * as REQUESTS from "../../Constants/Requests";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import moment from "moment";
import {
  GET_FLEX_EMPLOYEE_APPLICABLE_WORKDAY_TEMPLATES,
  GET_FLEX_RELEVANT_ASSIGNMENTS,
  GET_FLEX_WORK_DAY_TEMPLATES_MENU,
  applicableWorktimingsQuery,
  employeeBalanceQuery,
  employeeClaimsQuery,
  employeeProfileQuery,
  employeeRequestsQuery,
  getEmployeeProfile,
  myBalanceQuery,
  myClaimsQuery,
  myProfileQuery,
  myRequestsQuery,
  relevantAssignmentsQuery,
  workTimingsQuery,
} from "../../Graphql/query";
import {
  SUBMIT_FLEX_ANNUAL_LEAVE_REQUEST,
  SUBMIT_FLEX_CHANGE_SHIFT_REQUEST,
  SUBMIT_FLEX_CUSTOM_LEAVE_REQUEST,
  SUBMIT_FLEX_DAY_OFF_REQUEST,
  SUBMIT_FLEX_IGNORE_ASSIGNMENT_REQUEST,
  SUBMIT_FLEX_OPEN_REQUEST,
  SUBMIT_FLEX_SICK_LEAVE_REQUEST,
  SUBMIT_FLEX_UNPAID_LEAVE_REQUEST,
  SUBMIT_FLEX_WORK_REMOTELY_REQUEST,
  openRequestMutation,
  requestLoanMutation,
  upsertClaimsRequestMutation,
} from "../../Graphql/mutation";
import swal from "sweetalert";
import { RadioboxForm } from "../../Builder/Form";
import gql from "graphql-tag";
import { toggleFlexAttendanceActionModal } from "../../Store/Actions/flexActions/actions";
import { Button, ModalFooter, Spinner } from "reactstrap";

const reducer = "flex";
const formName = "flexAttendanceActionModal";
const formNameValidation = "flexAttendanceActionModalValidation";
const userFormServerValidation = "requestFormServerValidation";

const GET_EMPLOYEES = gql`
  query getEmployees {
    users_by_role(
      first: 9999
      input: { page_flag: "employees_list", status: ["Active"] }
    ) {
      data {
        id
        name
      }
    }
  }
`;

const GET_AVAILABLE_REQUESTS = gql`
  query getAvailableRequests($employeeID: ID = null, $date: String = null) {
    availableRequests: getAllowedFlexRequests(
      employeeID: $employeeID
      date: $date
    ) {
      id
      name
    }
    employee(id: $employeeID) {
      ... on FlexEmployee {
        __typename
        flexRelevantAttendanceTypeConfiguration(date: $date) {
          workRemotelySettings {
            can_ex_days
            home_days
          }
        }
      }
    }
  }
`;

const FormProps = {
  formName,
  formNameValidation,
  reducer,
};

const handleDateTimePickerForm = (
  isSubmiting,
  name,
  placeholder,
  type,
  requestFormat = "YYYY-MM-DD",
  label
) => (
  <DateTimePickerForm
    {...FormProps}
    name={name}
    placeholder={placeholder}
    validateBy="textRequired"
    formSubmitting={isSubmiting}
    containerStyle="my-1"
    inputContainerStyle=" "
    {...{ [type]: true }}
    reducer="flex"
    validationName={`input.${name}`}
    formServerValidation={userFormServerValidation}
    rootStyle="w-100"
    requestFormat={requestFormat}
    {...(label && { label })}
  />
);

const FlexAttendanceActionModal = ({
  isEmployee,
  clientEmployee,
  userServerVaildation = {},
  ...props
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    action_type,
    from,
    to,
    assignment_id,
    half_day_will_attend,
    requestFrom,
    requestTo,
    leaveHours,
    include_weekends_and_holidays_in_custom_requests,
    payment_flag,
    workday_template_id,
    amount,
    reason,
    from_home_field,
  } = useSelector((state) => state.flex?.flexAttendanceActionModal);

  const selectedProfile = useSelector(
    (state) => state?.super?.selectedEmployeeProfile
  );

  const userProfile = useSelector((state) => state.user.userProfile);

  const employeeProfile = isEmployee ? userProfile : selectedProfile;

  const employeeId = props?.hasEmpInput
    ? clientEmployee?.id
    : employeeProfile?.id;

  const [applicableWorkTimings, setApplicableWorkTimings] = useState([]);
  const [requestable, setRequestable] = useState(null);

  const myRequestsList = useSelector((state) => state.user.myRequestsList);
  const myBalanceList = useSelector((state) => state.user.myBalanceList);
  const myClaimsList = useSelector((state) => state.user.myClaimsList);

  const employeeRequestsList = useSelector(
    (state) => state.super.employeeRequestsList
  );
  const employeeBalanceList = useSelector(
    (state) => state.super.employeeBalanceList
  );
  const employeeClaimsList = useSelector(
    (state) => state.super.employeeProfileClaimsList
  );

  useEffect(() => {
    setModalMessage("");

    if (!requestTo) {
      dispatch(onInputResetWithValueAction(formName, "requestTo", requestFrom));
    }
  }, [requestFrom]);

  useEffect(() => {
    setModalMessage("");

    if (!to) {
      dispatch(onInputResetWithValueAction(formName, "to", from));
    }
  }, [from]);

  const [isSubmiting, setIsSubmiting] = useState(false);

  const [requestFiles, setRequestFiles] = useState([]);

  const serializedAttachemnts = requestFiles?.map(({ file, extension }) => ({
    file,
    extension,
  }));

  const isModalVissible = props.modalActions.isVissible;
  const isLoading = props.modalActions.isLoading;

  const [modalMessage, setModalMessage] = useState("");

  const hasActiveSubscription = useSelector(
    (state) => state?.auth?.userProfile?.company?.hasActiveSubscription
  );

  const employeeRefetchQueries = [
    {
      query: myProfileQuery,
    },
    ...(HelperFns.checkPrivileges({
      privileges: [Privilages.VIEW_REQUESTS],
      allowBP: true,
    })
      ? [
          {
            query: myRequestsQuery,
            variables: {
              rows: 10,
              page: 1,
              field: myRequestsList.sorting.key,
              order: myRequestsList.sorting.dir.toUpperCase(),
              status: myRequestsList.statusFilter,
              // month: myRequestsList.monthFilter
              //   ? myRequestsList.monthFilter
              //   : null,
              // year: myRequestsList.yearFilter
              //   ? myRequestsList.yearFilter
              //   : null,
              from: myRequestsList?.from,
              to: myRequestsList?.to,
            },
          },
        ]
      : []),
    ...(HelperFns.checkPrivileges({
      privileges: [Privilages.VIEW_LEAVE_BALANCE_HISTORY],
      allowBP: true,
    })
      ? [
          {
            query: myBalanceQuery,
            variables: {
              rows: 10,
              page: 1,
              field: myBalanceList.sorting.key,
              order: myBalanceList.sorting.dir.toUpperCase(),
              type: myBalanceList.type,
              year: myBalanceList.yearFilter,
            },
          },
        ]
      : []),
    ...(HelperFns.checkPrivileges({
      privileges: [Privilages.APPLY_FOR_CLAIM_REQUESTS],
      allowBP: true,
    })
      ? [
          {
            query: myClaimsQuery,
            variables: {
              claimRows: 10,
              claimPage: 1,
              claimStatus: myClaimsList.status,
              // claimMonth: myClaimsList.month ? myClaimsList.month : null,
              // claimYear: myClaimsList.year ? myClaimsList.year : null,
              from: myClaimsList?.from,
              to: myClaimsList?.to,
            },
          },
        ]
      : []),
  ];

  const managerRefetchQueries = [
    {
      query: employeeProfileQuery,
      variables: {
        id: employeeId,
        isExpired: !hasActiveSubscription,
      },
    },
    ...(HelperFns.checkPrivileges({
      privileges: [Privilages.VIEW_EMPLOYEE_REQUESTS],
      allowBP: true,
    })
      ? [
          {
            query: employeeRequestsQuery,
            variables: {
              id: employeeId,
              rows: 10,
              page: 1,
              field: employeeRequestsList.sorting.key,
              order: employeeRequestsList.sorting.dir.toUpperCase(),
              status: employeeRequestsList.statusFilter,
              from: employeeRequestsList.from ? employeeRequestsList.from : to,
              to: employeeRequestsList.to,
              route: "employee_profile",
            },
          },
        ]
      : []),
    ...(HelperFns.checkPrivileges({
      privileges: [Privilages.VIEW_EMPLOYEE_BALANCE_ADJUSTMENT],
      allowBP: true,
    })
      ? [
          {
            query: employeeBalanceQuery,
            variables: {
              id: employeeId,
              rows: 10,
              page: 1,
              field: employeeBalanceList?.sorting.key,
              order: employeeBalanceList?.sorting.dir.toUpperCase(),
              type: employeeBalanceList?.type,
              year: employeeBalanceList?.yearFilter,
            },
          },
        ]
      : []),

    ...(HelperFns.checkPrivileges({
      privileges: [Privilages.VIEW_EMPLOYEE_CLAIMS],
      allowBP: true,
    })
      ? [
          {
            query: employeeClaimsQuery,
            variables: {
              claimRows: 10,
              claimPage: 1,
              id: employeeId,
              claimStatus: employeeClaimsList?.status,
              // claimMonth: employeeClaimsList?.month
              //   ? employeeClaimsList?.month
              //   : null,
              // claimYear: employeeClaimsList?.year,
              from: employeeClaimsList?.from,
              to: employeeClaimsList?.to,
            },
          },
        ]
      : []),
  ];

  const refetchQueries = isEmployee
    ? employeeRefetchQueries
    : managerRefetchQueries;

  const [
    attemptSubmitFlexAnnualLeaveRequest,
    { loading: flexAnnualLeaveRequestLoading },
  ] = useMutation(SUBMIT_FLEX_ANNUAL_LEAVE_REQUEST, {
    onCompleted: (data) => {
      if (
        data?.flexAnnualFullDayRequest?.__typename === "GeneralException" ||
        data?.flexAnnualFullDayRequest?.__typename === "PaidPayrollException"
      ) {
        setModalMessage(data?.flexAnnualFullDayRequest?.message);
      } else {
        showToast("success", t("done"));
        dispatch(toggleAttendanceActionModal());
      }
    },
    refetchQueries,
  });

  const [
    attemptSubmitFlexUnpaidLeaveRequest,
    { loading: flexUnpaidLeaveRequestLoading },
  ] = useMutation(SUBMIT_FLEX_UNPAID_LEAVE_REQUEST, {
    onCompleted: (data) => {
      if (
        data?.FlexUnpaidLeaveRequest?.__typename === "GeneralException" ||
        data?.FlexUnpaidLeaveRequest?.__typename === "PaidPayrollException"
      ) {
        setModalMessage(data?.FlexUnpaidLeaveRequest?.message);
      } else {
        showToast("success", t("done"));
        dispatch(toggleAttendanceActionModal());
      }
    },
    refetchQueries,
  });

  const [
    attemptSubmitFlexCustomLeaveRequest,
    { loading: flexCustomLeaveRequestLoading },
  ] = useMutation(SUBMIT_FLEX_CUSTOM_LEAVE_REQUEST, {
    onCompleted: (data) => {
      if (
        data?.FlexCustomRequest?.__typename === "GeneralException" ||
        data?.FlexCustomRequest?.__typename === "PaidPayrollException"
      ) {
        setModalMessage(data?.FlexCustomRequest?.message);
      } else {
        showToast("success", t("done"));
        dispatch(toggleAttendanceActionModal());
      }
    },
    refetchQueries,
  });

  const [
    attemptSubmitFlexSickeaveRequest,
    { loading: flexSickLeaveRequestLoading },
  ] = useMutation(SUBMIT_FLEX_SICK_LEAVE_REQUEST, {
    onCompleted: (data) => {
      if (
        data?.flexSickLeaveRequest?.__typename === "GeneralException" ||
        data?.flexSickLeaveRequest?.__typename === "PaidPayrollException"
      ) {
        setModalMessage(data?.flexSickLeaveRequest?.message);
      } else {
        showToast("success", t("done"));
        dispatch(toggleAttendanceActionModal());
      }
    },
    onError: (error) => {
      showToast(
        "error",
        error?.graphQLErrors?.[0]?.extensions?.reason ||
          error?.graphQLErrors?.[0]?.message ||
          error?.message
      );
    },
    refetchQueries,
  });

  const [
    attemptSubmitFlexWorkRemotelyRequest,
    { loading: flexWorkRemotelyRequestLoading },
  ] = useMutation(SUBMIT_FLEX_WORK_REMOTELY_REQUEST, {
    onCompleted: (data) => {
      if (
        data?.FlexWorkRemotelyRequest?.__typename === "GeneralException" ||
        data?.FlexWorkRemotelyRequest?.__typename === "PaidPayrollException"
      ) {
        setModalMessage(data?.FlexWorkRemotelyRequest?.message);
      } else {
        showToast("success", t("done"));
        dispatch(toggleAttendanceActionModal());
      }
    },
    onError: (error) => {
      showToast(
        "error",
        error?.graphQLErrors?.[0]?.extensions?.reason ||
          error?.graphQLErrors?.[0]?.message ||
          error?.message
      );
    },
    refetchQueries,
  });

  const [
    attemptSubmitFlexChangeShiftRequest,
    { loading: flexChangeShiftRequestLoading },
  ] = useMutation(SUBMIT_FLEX_CHANGE_SHIFT_REQUEST, {
    onCompleted: (res) => {
      if (
        res?.flex_change_shift_request?.__typename === "GeneralException" ||
        res?.flex_change_shift_request?.__typename === "PaidPayrollException"
      ) {
        setModalMessage(res?.flex_change_shift_request?.message ?? "");
      } else {
        showToast("success", t("done"));
        dispatch(toggleAttendanceActionModal());
      }
    },
    onError: (error) => {
      if (error?.message === "swal") {
        swal({
          title: t("are you sure"),
          text: t("You have other requests on this day"),
          icon: "warning",
          className: "swal-warning-style",
          dangerMode: true,
          buttons: [t("Cancel"), t("confirm")],
        }).then((confirm) => {
          if (confirm) {
            attemptSubmitFlexChangeShiftRequest({
              variables: {
                input: {
                  employeeId: employeeId,
                  date: requestFrom,
                  WorkDayTemplateId: workday_template_id,
                  files: serializedAttachemnts,
                  swalValue: true,
                },
              },
            });
          }
        });
      } else {
        dispatch(
          showErrorToast(
            error?.graphQLErrors?.[0]?.extensions?.reason ||
              error?.graphQLErrors?.[0]?.message ||
              error?.message
          )
        );
      }
    },
    refetchQueries,
  });

  const [
    attemptSubmitFlexIgnoreAssignmentRequest,
    { loading: flexIgnoreAssignmentRequestLoading },
  ] = useMutation(SUBMIT_FLEX_IGNORE_ASSIGNMENT_REQUEST, {
    onCompleted: (data) => {
      if (
        data?.ignoreFlexException?.__typename === "GeneralException" ||
        data?.ignoreFlexException?.__typename === "PaidPayrollException"
      ) {
        setModalMessage(data?.ignoreFlexException?.message);
      } else {
        showToast("success", t("done"));
        dispatch(toggleAttendanceActionModal());
      }
    },
    refetchQueries,
  });

  const [
    attemptSubmitClaimRequest,
    { loading: claimRequestLoading, error: expenseErrors, reset: resetExpense },
  ] = useMutation(upsertClaimsRequestMutation, {
    onCompleted: (data) => {
      if (data?.ignoreFlexException?.__typename === "GeneralException") {
        setModalMessage(data?.upsert_claim?.message);
      } else {
        showToast("success", t("done"));
        dispatch(toggleAttendanceActionModal());
      }
    },
    refetchQueries,
  });

  const expenseServerErrors =
    expenseErrors?.graphQLErrors?.[0]?.extensions?.validation ?? {};

  const { data: emps, loading: empsLLoading } = useQuery(GET_EMPLOYEES, {
    skip: !props?.hasEmpInput,
  });

  const [
    attemptGetFlexWorkdayTemplate,
    {
      data: workdayTemplatesQueryData,
      loading: flexWorkdayTemplateMenuLoading,
    },
  ] = useLazyQuery(GET_FLEX_WORK_DAY_TEMPLATES_MENU);

  const [
    fetchApplicableWorkTimings,
    { loading: fetchApplicableWorkTimingsLoading },
  ] = useLazyQuery(GET_FLEX_EMPLOYEE_APPLICABLE_WORKDAY_TEMPLATES);

  const handleRefetchingIncaseOfSuccess = () => {
    dispatch(
      onInputResetWithValueAction(
        "flexAttendanceModalActions",
        "isVissible",
        false
      )
    );
    dispatch(showSuccessToast());
  };

  const handleIgnoreAssignment = () => {
    if (assignmentsOptions?.length === 0) {
      setModalMessage(t("you have no assignments at this date"));
      return;
    }

    attemptSubmitFlexIgnoreAssignmentRequest({
      variables: {
        input: {
          employee_id: isEmployee ? null : employeeId,
          assignment_id:
            assignmentsOptions?.length == 1
              ? assignmentsOptions?.[0]?.id
              : assignment_id,
          date: requestFrom,
          files: serializedAttachemnts ?? [],
        },
      },
    });
  };

  useEffect(() => {
    setRequestable(null);
    props.onInputResetAction(formName, "from");
    props.onInputResetAction(formName, "to");
    props.onInputResetAction(formName, "deducted_from_emergency");
    props.onInputResetAction(formName, "half_day_will_attend");
    props.onInputResetAction(formName, "home_day");
    props.onInputResetAction(formName, "amount");
    props.onInputResetAction(formName, "reason");
    props.onInputResetAction(formName, "assignment_id");
    props.onInputResetAction(formName, "workday_template_id");
    props.onInputResetAction(formName, "notes");
    props.onInputResetAction(formName, "name");
    props.attendanceActionModalFailed("");
    setRequestFiles([]);
    // reset dropzone content
    const btn = document.querySelector(".dzu-submitButton");
    if (btn) {
      btn.click();
    }
    // reset dropzone content
    setIsSubmiting(false);
    setApplicableWorkTimings([]);
    setModalMessage("");
  }, [action_type]);

  useEffect(() => {
    setModalMessage("");
    setRequestable(null);
    setApplicableWorkTimings([]);
    setIsSubmiting(false);
  }, [requestFrom, requestTo]);

  const [
    attemptGetFlexRelevantAssignments,
    {
      isLoading: flexRelevantAssignmentsLoading,
      data: flexRelevantAssignmentsQueryData,
    },
  ] = useLazyQuery(GET_FLEX_RELEVANT_ASSIGNMENTS);

  const assignmentsOptions =
    flexRelevantAssignmentsQueryData?.relevantFlexAssignments?.data ?? [];

  useEffect(() => {
    if (!!requestFrom && action_type == REQUESTS.IGNORE_ASSIGNMENT) {
      attemptGetFlexRelevantAssignments({
        variables: {
          input: {
            employee_id: isEmployee ? null : employeeId,
            from: requestFrom,
            to: requestFrom,
          },
        },
      });
    }
  }, [requestFrom, action_type]);

  const fetchApplicableWorkdayTemplates = (date, requestFor) => {
    setApplicableWorkTimings([]);
    setRequestable(null);
    return new Promise((resolve, reject) => {
      fetchApplicableWorkTimings({
        variables: {
          employee_id: employeeId,
          date: moment(date).format("YYYY-MM-DD"),
          for: requestFor,
        },
        onCompleted: (data) => {
          let options = [
            ...(data?.employee?.applicableWorkTimings?.default ?? []),
            ...(data?.employee?.applicableWorkTimings?.exceptions ?? []),
          ];
          let normalizedOptions = [];

          options?.forEach((applicable) => {
            switch (applicable?.__typename) {
              case "FlexScheduleDetailEmployee":
                normalizedOptions.push({
                  id: applicable?.id,
                  name: applicable?.FlexScheduleDetail?.WorkDayTemplate?.name,
                  typename: applicable?.__typename,
                  leaveHours:
                    applicable?.FlexScheduleDetail?.WorkDayTemplate
                      ?.work_hours_per_leave_day,
                });
                break;
              case "FlexException":
                normalizedOptions.push({
                  id: applicable?.id,
                  name: applicable?.flexWorkDayTemplate?.name,
                  typename: applicable?.__typename,
                  leaveHours:
                    applicable?.flexWorkDayTemplate?.work_hours_per_leave_day,
                });
                break;
              default:
                return;
            }
          });

          resolve(normalizedOptions);
        },
        onError: (error) => {
          reject(error);
        },
      });
    });
  };

  useEffect(() => {
    props.onInputResetAction(formName, "action_type");
    setIsSubmiting(false);
    setRequestFiles([]);

    return () => {
      props.onInputResetAction(formName, "requestFrom");
      props.onInputResetAction(formName, "requestTo");
      props.onInputResetAction(formName, "action_type");
    };
  }, [isModalVissible]);

  // initail vlaue for requestFiles set to empty array

  // function to add object to requestFiles array
  const addRequestFileObject = (file) => {
    setRequestFiles((requestFiles) => [...requestFiles, file]);
  };

  // function to remove an object from an requestFiles array
  const removeRequestFileObject = (id) => {
    setRequestFiles((requestFiles) =>
      requestFiles.filter((file) => file?.id !== id)
    );
  };

  const handleImageChange = ({ file, meta }, status) => {
    // to convert image to base64 string
    let reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      let base64 = reader.result?.split(",");
      let fileType = file?.type?.split("/");

      if (status == "done") {
        let target = {
          file: base64[1],
          extension: fileType[1],
          id: meta?.id,
        };
        addRequestFileObject(target);
      }
    };

    if (status == "removed") {
      removeRequestFileObject(meta?.id);
    }
    return;
  };

  const handleTextArea = (e) => {
    props.onInputChangeAction(formName, e);
  };

  const handleModalTypes = () => {
    switch (props?.data?.action_type) {
      case REQUESTS.NORMAL_LEAVES:
        // case REQUESTS.EMERGENCY_LEAVES:
        return (
          <div className="row align-items-start">
            {reqFlags?.isEmgNormal ? (
              <div className="col-sm-6 mt-2">
                <CheckboxBooleanForm
                  {...FormProps}
                  options={["Treat as an emergency"]}
                  name="deducted_from_emergency"
                  type="checkbox"
                  reducer="flex"
                />
              </div>
            ) : null}
          </div>
        );
      case REQUESTS.UNPAID_LEAVES:
        return null;

      case REQUESTS.HOME:
        return (
          <div className="row align-items-start">
            {employeeRelevantAttendanceTypeConfiguration?.workRemotelySettings
              ?.can_ex_days == 1 ? (
              <>
                <div className="col-sm-6">
                  <BSelect
                    {...FormProps}
                    name="home_day"
                    placeholder="In exchange for"
                    options={employeeRelevantAttendanceTypeConfiguration?.workRemotelySettings?.home_days
                      ?.split(",")
                      ?.map((day) => ({ value: day }))}
                    optionLabel="value"
                    optionValue="value"
                    getOptionLabel={(option) => t(option.value?.toLowerCase())}
                    validateBy="textRequired"
                    keepDefaultStyle
                    dependOn="action_type"
                    reducer="flex"
                    icon="calendar"
                  />
                </div>
              </>
            ) : null}

            <div className="col-sm-6 mt-2">
              <RadioboxForm
                reducer="flex"
                {...FormProps}
                formSubmitting={isSubmiting}
                containerStyle="custom-tardiness-container justify-content-start"
                optionsContainerStyle="penalty-days-style fit-width flex-md-row"
                optionItemStyle="d-flex align-items-center mr-2"
                optionLabelStyle="mb-0 mr-3"
                optionInputStyle=" "
                options={[
                  { label: "from_home", value: "home" },
                  { label: "from field", value: "field" },
                ]}
                validateBy="textRequired"
                name="from_home_field"
                validationName="input.from_field"
              />
            </div>
          </div>
        );

      case REQUESTS.NORMAL_HALF_DAY:
        // case REQUESTS.EMERGENCY_HALF_DAY:
        return (
          <>
            <div className="row align-items-start">
              <div className="col-sm-6">
                <BSelect
                  {...FormProps}
                  name="half_day_will_attend"
                  placeholder={t("Which Half Would You Attend")}
                  options={Constants.HalfDay}
                  optionLabel="label"
                  optionValue="value"
                  validateBy="textRequired"
                  formSubmitting={isSubmiting}
                  keepDefaultStyle
                  reducer="flex"
                  icon="calendar"
                />
              </div>
            </div>
            {reqFlags?.isEmgHalf ? (
              <div className="col-sm-6 mt-2">
                <CheckboxBooleanForm
                  {...FormProps}
                  options={["Treat as an emergency"]}
                  name="deducted_from_emergency"
                  type="checkbox"
                  reducer="flex"
                />
              </div>
            ) : null}
          </>
        );
      case REQUESTS.UNPAID_HALF_DAY:
        return (
          <>
            <div className="row align-items-start">
              <div className="col-sm-6">
                <BSelect
                  {...FormProps}
                  name="half_day_will_attend"
                  placeholder={t("Which Half Would You Attend")}
                  options={Constants.HalfDay}
                  optionLabel="label"
                  optionValue="value"
                  validateBy="textRequired"
                  formSubmitting={isSubmiting}
                  keepDefaultStyle
                  reducer="flex"
                  icon="calendar"
                />
              </div>
            </div>
          </>
        );
      case REQUESTS.PERMISSIONS:
        return (
          <div className="row align-items-start">
            <div className="col-sm-6">
              {handleDateTimePickerForm(
                isSubmiting,
                "from",
                "from",
                "dateTime",
                "YYYY-MM-DD HH:mm",
                "from"
              )}
            </div>
            <div className="col-sm-6">
              {handleDateTimePickerForm(
                isSubmiting,
                "to",
                "to",
                "dateTime",
                "YYYY-MM-DD HH:mm",
                "to"
              )}
            </div>
          </div>
        );

      case REQUESTS.EXPENSE_CLAIM:
        return (
          <HasPrivileges
            reqireMain={[Privilages.APPLY_FOR_CLAIM_REQUESTS]}
            allowBP
          >
            <div>
              <div className="row">
                <div className="col-12">
                  <InputForm
                    name="amount"
                    label={"amount"}
                    labelStyle="mb-2"
                    placeholder={t("amount")}
                    reducer="flex"
                    {...FormProps}
                    validationName={`input.amount`}
                    formServerValidation={userFormServerValidation}
                    formSubmitting={isSubmiting}
                    containerStyle=""
                    inputContainerStyle="w-100"
                    type="number"
                    validateBy="textRequired"
                  />
                </div>
              </div>

              <div className="row my-2">
                <div
                  className={`col-12 ${
                    "input.reason" in expenseServerErrors
                      ? "invalid-container-style"
                      : ""
                  }`}
                >
                  <label className="mb-2 validity-label-style">
                    {t("reason")}
                  </label>
                  <textarea
                    rows="4"
                    name="reason"
                    placeholder={t("state reason")}
                    onChange={handleTextArea}
                    value={props?.flexAttendanceActionModal?.reason}
                  />

                  <span className="validity-msg-style">
                    {"input.reason" in expenseServerErrors
                      ? expenseServerErrors?.["input.reason"]
                      : null}
                  </span>
                </div>
              </div>
            </div>
          </HasPrivileges>
        );
      case REQUESTS.IGNORE_ASSIGNMENT:
        return (
          <>
            {assignmentsOptions?.length > 1 ? (
              <BSelect
                {...FormProps}
                name="assignment_id"
                label={t("assignment")}
                labelStyle="mb-2"
                keepDefaultStyle
                placeholder={t("select option")}
                options={assignmentsOptions}
                validateBy="textRequired"
                formSubmitting={isSubmiting}
                reducer="flex"
                isLoading={flexRelevantAssignmentsLoading}
              />
            ) : null}
          </>
        );
      case REQUESTS.BEREAVEMENT:
      case REQUESTS.MATERNITY_PATERNITY_LEAVE:
      case REQUESTS.HAJJ_LEAVE:
      case REQUESTS.WORK_INJURY_LEAVE:
      case REQUESTS.STUDY_LEAVE:
      case REQUESTS.SABBATICAL_LEAVE:
      case REQUESTS.MARRIAGE_LEAVE:
      case REQUESTS.In_Lieu_Of_Work_Day_Leave:
      case REQUESTS.MILITARY_CALL_LEAVE:
        return (
          <div>
            <div className="row align-items-start mb-2"></div>

            <div>
              <CheckboxBooleanForm
                {...FormProps}
                formSubmitting={isSubmiting}
                options={[
                  "Include Holidays and Weekends When Calculating Leave Durations",
                ]}
                name="include_weekends_and_holidays_in_custom_requests"
                validationName="input.include_weekends_and_holidays_in_custom_requests"
                type="checkbox"
                reducer="flex"
              />

              <RadioboxForm
                reducer="flex"
                {...FormProps}
                formSubmitting={isSubmiting}
                containerStyle="custom-tardiness-container justify-content-start"
                // labelStyle="d-flex mr-3"
                optionsContainerStyle="penalty-days-style fit-width flex-md-row"
                optionItemStyle="d-flex align-items-center mr-2"
                optionLabelStyle="mb-0 mr-3"
                optionInputStyle=" "
                // label="deduct from"
                options={[
                  { label: "Paid", value: "paid" },
                  { label: "Unpaid", value: "unpaid" },
                ]}
                validateBy="textRequired"
                name="payment_flag"
                validationName="input.payment_flag"
              />
            </div>
          </div>
        );
      case REQUESTS.CHANGE_SHIFT:
      case REQUESTS.ADDITIONAL_SHIFT:
        return (
          <BSelect
            {...FormProps}
            name="workday_template_id"
            label={t("work day template")}
            labelStyle="mb-2 mt-3"
            keepDefaultStyle
            placeholder={t("select option")}
            options={
              workdayTemplatesQueryData?.flex_Work_Day_Template_Menu ?? []
            }
            validateBy="textRequired"
            formSubmitting={isSubmiting}
            reducer="flex"
            isLoading={flexWorkdayTemplateMenuLoading}
            isDisabled={flexWorkdayTemplateMenuLoading}
          />
        );
      case REQUESTS.REQUEST_LOAN:
        return (
          <div className="w-100 ">
            <div className="row w-100 ">
              <div className="col-6">
                <InputForm
                  name="name"
                  label={"name"}
                  labelStyle="mb-2"
                  placeholder={t("name")}
                  reducer="flex"
                  {...FormProps}
                  validationName={`input.name`}
                  formServerValidation={userFormServerValidation}
                  formSubmitting={isSubmiting}
                  containerStyle=""
                  inputContainerStyle="w-100"
                  type="text"
                  validateBy="textRequired"
                />
              </div>

              <div className="col-6">
                <InputForm
                  name="amount"
                  label={"amount"}
                  labelStyle="mb-2"
                  placeholder={t("amount")}
                  reducer="flex"
                  {...FormProps}
                  validationName={`input.amount`}
                  formServerValidation={userFormServerValidation}
                  formSubmitting={isSubmiting}
                  containerStyle=""
                  inputContainerStyle="w-100"
                  type="number"
                  validateBy="textRequired"
                />
              </div>
            </div>

            <div className={`container-style-default w-100 row`}>
              <div className=" col-12">
                <InputForm
                  name="notes"
                  label={"notes"}
                  labelStyle="mb-2"
                  placeholder={t("notes")}
                  reducer="flex"
                  {...FormProps}
                  validationName={`input.notes`}
                  formServerValidation={userFormServerValidation}
                  formSubmitting={isSubmiting}
                  containerStyle=""
                  inputContainerStyle="w-100"
                  type="text"
                  isMultiLines
                />
              </div>
            </div>
          </div>
        );

      case REQUESTS.OPEN_REQUEST:
        return (
          <>
            <div className="row mt-4 mb-3">
              <div className="col-12">
                <InputForm
                  name="addInfo"
                  label={"reason"}
                  labelStyle="mb-2"
                  placeholder={t("state reason")}
                  reducer="user"
                  {...FormProps}
                  validateBy="textRequired"
                  validationName={`input.addInfo`}
                  formServerValidation={userFormServerValidation}
                  formSubmitting={isSubmiting}
                  containerStyle=""
                  inputContainerStyle="w-100"
                  type="text"
                  isMultiLines
                />
              </div>
            </div>
          </>
        );

      default:
        return;
    }
  };

  const applicableFor =
    props?.data?.action_type === REQUESTS.NORMAL_LEAVES ||
    props?.data?.action_type === REQUESTS.UNPAID_LEAVES ||
    props?.data?.action_type === REQUESTS.SICK_LEAVES ||
    props?.data?.action_type === REQUESTS.BEREAVEMENT ||
    props?.data?.action_type === REQUESTS.MATERNITY_PATERNITY_LEAVE ||
    props?.data?.action_type === REQUESTS.HAJJ_LEAVE ||
    props?.data?.action_type === REQUESTS.WORK_INJURY_LEAVE ||
    props?.data?.action_type === REQUESTS.STUDY_LEAVE ||
    props?.data?.action_type === REQUESTS.SABBATICAL_LEAVE ||
    props?.data?.action_type === REQUESTS.In_Lieu_Of_Work_Day_Leave ||
    props?.data?.action_type === REQUESTS.MILITARY_CALL_LEAVE ||
    props?.data?.action_type === REQUESTS.MARRIAGE_LEAVE
      ? "Leave"
      : props?.data?.action_type === REQUESTS.PERMISSIONS
        ? "Permission"
        : props?.data?.action_type === REQUESTS.HOME
          ? "Home"
          : props?.data?.action_type === REQUESTS.NORMAL_HALF_DAY ||
              props?.data?.action_type === REQUESTS.UNPAID_HALF_DAY
            ? "HalfDay"
            : null;

  useEffect(() => {
    if (
      (Constants.possibleDateRangeRequests.includes(action_type) &&
        moment(requestFrom).format("YYYY-MM-DD") !=
          moment(requestTo).format("YYYY-MM-DD")) ||
      !!!action_type ||
      action_type == REQUESTS.IGNORE_ASSIGNMENT ||
      !!!applicableFor ||
      !!!requestFrom ||
      !!!requestTo
    ) {
      return;
    }

    handleFetchApplicables();
  }, [requestFrom, requestTo, applicableFor, action_type]);

  useEffect(() => {
    if (!!requestable) {
      dispatch(
        onInputResetWithValueAction(
          formName,
          "leaveHours",
          moment(requestable?.leaveHours, "hh:mm:ss").format("hh:mm")
        )
      );
    }
  }, [requestable]);

  const handleFetchApplicables = async () => {
    try {
      const applicables = await fetchApplicableWorkdayTemplates(
        requestFrom,
        applicableFor
      );

      if (applicables?.length === 0) {
        setModalMessage(
          "There are no shifts that you can submit this request on"
        );
        return;
      }

      if (applicables?.length > 1) {
        setApplicableWorkTimings(applicables);
        return;
      }

      if (applicables?.length === 1) {
        setRequestable(applicables[0]);
        return;
      }
    } catch (error) {
      setRequestable(null);
      setApplicableWorkTimings([]);
      setModalMessage(error?.message ?? t("something went wrong"));
    }
  };

  const handleNormalLeaveRequest = () => {
    attemptSubmitFlexAnnualLeaveRequest({
      variables: {
        input: {
          employeeId: isEmployee ? null : employeeId,
          dateFrom: requestFrom,
          dateTo: requestTo,
          leaveHours: moment(leaveHours, "HH:mm").format("HH:mm:ss"),
          deductedFromEmergency: !!props?.data?.deducted_from_emergency,
          clockable:
            requestFrom && requestTo && requestFrom === requestTo
              ? {
                  clockableId: requestable?.id,
                  clockableType: requestable?.typename,
                }
              : null,
          files: serializedAttachemnts ?? [],
        },
      },
    });
  };

  const handleUnpaidLeaveRequest = () => {
    attemptSubmitFlexUnpaidLeaveRequest({
      variables: {
        input: {
          employeeId: isEmployee ? null : employeeId,
          dateFrom: requestFrom,
          dateTo: requestTo,
          leaveHours: moment(leaveHours, "HH:mm").format("HH:mm:ss"),
          clockable:
            requestFrom && requestTo && requestFrom === requestTo
              ? {
                  clockableId: requestable?.id,
                  clockableType: requestable?.typename,
                }
              : null,
          files: serializedAttachemnts ?? [],
        },
      },
    });
  };

  const handleHomeRequest = () => {
    attemptSubmitFlexWorkRemotelyRequest({
      variables: {
        input: {
          employeeId: isEmployee ? null : employeeId,
          requestDay: requestFrom,
          clockable: {
            clockableId: requestable?.id,
            clockableType: requestable?.typename,
          },
          files: serializedAttachemnts ?? [],
          from_field: from_home_field == "field" ? 1 : 0,
        },
      },
    });
  };

  const handleExpenseClaimRequest = () => {
    resetExpense();
    attemptSubmitClaimRequest({
      variables: {
        input: {
          id: props?.data?.id, // ??
          incurred_at: requestFrom,
          amount: amount,
          reason: reason,
          attachments: serializedAttachemnts,
          employee_id: isEmployee ? null : employeeId,
        },
      },
    });
  };

  const handleSickLeaveRequest = () => {
    attemptSubmitFlexSickeaveRequest({
      variables: {
        input: {
          employeeId: isEmployee ? null : employeeId,
          dateFrom: requestFrom,
          dateTo: requestTo,
          leaveHours: moment(leaveHours, "HH:mm").format("HH:mm:ss"),
          clockable:
            requestFrom && requestTo && requestFrom === requestTo
              ? {
                  clockableId: requestable?.id,
                  clockableType: requestable?.typename,
                }
              : null,
          files: serializedAttachemnts ?? [],
        },
      },
    });
  };

  const handleCustomLeaveRequest = () => {
    attemptSubmitFlexCustomLeaveRequest({
      variables: {
        input: {
          employeeId: isEmployee ? null : employeeId,
          dateFrom: requestFrom,
          dateTo: requestTo,
          requestTypeId: action_type,
          paymentFlag: payment_flag,
          includeDayoffAndHoliday:
            !!include_weekends_and_holidays_in_custom_requests,
          leaveHours: moment(leaveHours, "HH:mm").format("HH:mm:ss"),
          clockable:
            requestFrom && requestTo && requestFrom === requestTo
              ? {
                  clockableId: requestable?.id,
                  clockableType: requestable?.typename,
                }
              : null,
          files: serializedAttachemnts ?? [],
        },
      },
    });
  };

  const handleChangeShift = () => {
    attemptSubmitFlexChangeShiftRequest({
      variables: {
        input: {
          employeeId: employeeId,
          date: requestFrom,
          WorkDayTemplateId: workday_template_id,
          files: serializedAttachemnts,
          swalValue: true,
        },
      },
    });
  };

  const handleAdditionalShift = () => {
    attemptSubmitFlexChangeShiftRequest({
      variables: {
        input: {
          employeeId: employeeId,
          date: requestFrom,
          WorkDayTemplateId: workday_template_id,
          files: serializedAttachemnts,
          is_change_shift: false,
        },
      },
    });
  };

  const [submitLoanRequest, { loading: loanRequestLoading }] = useMutation(
    requestLoanMutation,
    {
      onCompleted: (data) => {
        if (data?.upsert_loan_request?.__typename === "GeneralException") {
          return setModalMessage(data?.upsert_loan_request?.message ?? "");
        }

        if (data?.upsert_loan_request?.id) {
          showToast("success", t("done"));
          dispatch(toggleAttendanceActionModal());
        } else {
          dispatch(showErrorToast());
        }
      },
      onError: (error) => {
        dispatch(
          showErrorToast(
            error?.graphQLErrors?.[0]?.extensions?.reason ||
              error?.graphQLErrors?.[0]?.message ||
              error?.message
          )
        );
      },
      refetchQueries,
    }
  );

  const [submitDayOff, { loading: dayOffLoading }] = useMutation(
    SUBMIT_FLEX_DAY_OFF_REQUEST,
    {
      onCompleted: (data) => {
        if (
          data?.flex_day_off_request?.__typename === "GeneralException" ||
          data?.flex_day_off_request?.__typename === "PaidPayrollException"
        ) {
          setModalMessage(data?.flex_day_off_request?.message);
        } else {
          showToast("success", t("done"));
          dispatch(toggleAttendanceActionModal());
        }
      },
      onError: (error) => {
        if (error?.message === "swal") {
          swal({
            title: t("are you sure"),
            text: t("You have other requests on this day"),
            icon: "warning",
            className: "swal-warning-style",
            dangerMode: true,
            buttons: [t("Cancel"), t("confirm")],
          }).then((confirm) => {
            if (confirm) {
              submitDayOff({
                variables: {
                  input: {
                    timeFrom: requestFrom,
                    timeTo: requestTo,
                    files: serializedAttachemnts,
                    swalValue: true,
                  },
                },
              });
            }
          });
          return;
        }
        dispatch(showErrorToast(error?.graphQLErrors?.[0].extensions?.reason));
      },
      refetchQueries: isEmployee
        ? employeeRefetchQueries
        : managerRefetchQueries,
    }
  );

  const handleLoanRequest = () => {
    submitLoanRequest({
      variables: {
        input: {
          emp_id: employeeId,
          amount: parseFloat(props?.data.amount),
          name: props?.data.name,
          notes: props?.data.notes,
        },
      },
    });
  };

  const handleDayOff = () => {
    submitDayOff({
      variables: {
        input: {
          employeeId: employeeId,
          timeFrom: requestFrom,
          timeTo: requestTo,
          files: serializedAttachemnts,
        },
      },
    });
  };

  const [submitOpenRequest, { loading: openRequestLoading }] = useMutation(
    SUBMIT_FLEX_OPEN_REQUEST,
    {
      onCompleted: (data) => {
        if (data?.flex_open_request?.__typename === "GeneralException") {
          // error message

          return setModalMessage(data?.flex_open_request?.message ?? "");
        }

        if (data?.flex_open_request?.flex_request?.id) {
          // refetch data
          showToast("success", t("done"));
          dispatch(toggleAttendanceActionModal());
        } else {
          // error message
          setModalMessage(data?.flex_open_request?.message ?? "");
        }
      },
      onError: (error) => {
        dispatch(
          showErrorToast(
            error?.graphQLErrors[0]?.extensions?.reason ||
              error?.graphQLErrors[0]?.message ||
              error?.message
          )
        );
      },
      refetchQueries: isEmployee
        ? employeeRefetchQueries
        : managerRefetchQueries,
    }
  );

  const handleOpenRequest = () => {
    submitOpenRequest({
      variables: {
        input: {
          employeeId: employeeId,
          timeFrom: requestFrom,
          timeTo: requestTo,
          addInfo: props?.data?.addInfo,
          files: serializedAttachemnts ?? [],
        },
      },
    });
  };

  const handleSubmitBtn = () => {
    if (
      props?.attendanceValidation?.length &&
      !(
        props?.attendanceValidation[0] === "workday_template_id" &&
        action_type !== REQUESTS.CHANGE_SHIFT
      )
    ) {
      setIsSubmiting(true);
      return;
    }

    setModalMessage("");

    switch (props?.data?.action_type) {
      case REQUESTS.NORMAL_LEAVES:
        handleNormalLeaveRequest();
        return;

      case REQUESTS.UNPAID_LEAVES:
        handleUnpaidLeaveRequest();
        return;

      case REQUESTS.HOME:
        handleHomeRequest();
        return;

      case REQUESTS.EXPENSE_CLAIM:
        // to send file and extenstion only without id key in the array
        handleExpenseClaimRequest();
        return;

      case REQUESTS.SICK_LEAVES:
        handleSickLeaveRequest();
        return;

      case REQUESTS.IGNORE_ASSIGNMENT:
        return handleIgnoreAssignment();

      case REQUESTS.BEREAVEMENT:
      case REQUESTS.MATERNITY_PATERNITY_LEAVE:
      case REQUESTS.HAJJ_LEAVE:
      case REQUESTS.WORK_INJURY_LEAVE:
      case REQUESTS.STUDY_LEAVE:
      case REQUESTS.SABBATICAL_LEAVE:
      case REQUESTS.MARRIAGE_LEAVE:
      case REQUESTS.In_Lieu_Of_Work_Day_Leave:
      case REQUESTS.MILITARY_CALL_LEAVE:
        handleCustomLeaveRequest();
        return;

      case REQUESTS.CHANGE_SHIFT:
        handleChangeShift();
        return;

      case REQUESTS.REQUEST_LOAN:
        handleLoanRequest();
        return;

      case REQUESTS.DAY_OFF:
        handleDayOff();
        return;

      case REQUESTS.OPEN_REQUEST:
        handleOpenRequest();
        return;

      case REQUESTS.ADDITIONAL_SHIFT:
        handleAdditionalShift();
        return;

      default:
        return;
    }
  };

  const [getAvailableRequests, { loading, data }] = useLazyQuery(
    GET_AVAILABLE_REQUESTS
  );

  const employeeRelevantAttendanceTypeConfiguration =
    data?.employee?.flexRelevantAttendanceTypeConfiguration;

  const handleGetAvailableRequests = (date) => {
    (!props?.hasEmpInput || (props?.hasEmpInput && clientEmployee?.id)) &&
      getAvailableRequests({
        variables: {
          employeeID: employeeId,
          date: date.format("YYYY-MM-DD"),
        },
        onCompleted: ({ availableRequests = [] }) => {
          const { availableRequestsOptions, reqFlags } = getReqData(
            availableRequests || []
          );

          setSelectTypesOptions(availableRequestsOptions);
          setReqFlags(reqFlags);
        },
        onError: (err) => {
          setSelectTypesOptions([]);
          setReqFlags({});
          showToast(
            "error",
            err?.graphQLErrors?.[0]?.extensions?.reason ||
              err?.graphQLErrors?.[0]?.message ||
              err?.message
          );
        },
      });
  };
  const [reqFlags, setReqFlags] = useState({});
  const [selectTypesOptions, setSelectTypesOptions] = useState([]);

  const shouldRenderApplicableWorkTimings =
    (action_type === REQUESTS.PERMISSIONS ||
      action_type === REQUESTS.HOME ||
      action_type === REQUESTS.NORMAL_HALF_DAY ||
      action_type === REQUESTS.UNPAID_HALF_DAY ||
      action_type === REQUESTS.UNPAID_LEAVES ||
      action_type === REQUESTS.NORMAL_LEAVES ||
      action_type === REQUESTS.SICK_LEAVES ||
      action_type === REQUESTS.BEREAVEMENT ||
      action_type === REQUESTS.MATERNITY_PATERNITY_LEAVE ||
      action_type === REQUESTS.HAJJ_LEAVE ||
      action_type === REQUESTS.WORK_INJURY_LEAVE ||
      action_type === REQUESTS.STUDY_LEAVE ||
      action_type === REQUESTS.SABBATICAL_LEAVE ||
      action_type === REQUESTS.In_Lieu_Of_Work_Day_Leave ||
      action_type === REQUESTS.MILITARY_CALL_LEAVE ||
      action_type === REQUESTS.MARRIAGE_LEAVE) &&
    applicableWorkTimings?.length > 1;

  const requestFromLabel = () => {
    switch (action_type) {
      case REQUESTS.NORMAL_LEAVES:
      case REQUESTS.SICK_LEAVES:
      case REQUESTS.UNPAID_LEAVES:
      case REQUESTS.BEREAVEMENT:
      case REQUESTS.MATERNITY_PATERNITY_LEAVE:
      case REQUESTS.HAJJ_LEAVE:
      case REQUESTS.WORK_INJURY_LEAVE:
      case REQUESTS.STUDY_LEAVE:
      case REQUESTS.SABBATICAL_LEAVE:
      case REQUESTS.MARRIAGE_LEAVE:
      case REQUESTS.In_Lieu_Of_Work_Day_Leave:
      case REQUESTS.MILITARY_CALL_LEAVE:
      case REQUESTS.OPEN_REQUEST:
        return "from";
      case REQUESTS.EXPENSE_CLAIM:
        return "incurred at";
      default:
        return "date";
    }
  };

  const removeAllSelectedFiles = (_, allFiles) => {
    allFiles?.forEach((f) => f?.remove());
  };

  const showLeaveHoursInput =
    [
      REQUESTS.NORMAL_LEAVES,
      REQUESTS.SICK_LEAVES,
      REQUESTS.UNPAID_LEAVES,
      REQUESTS.BEREAVEMENT,
      REQUESTS.HAJJ_LEAVE,
      REQUESTS.WORK_INJURY_LEAVE,
      REQUESTS.MARRIAGE_LEAVE,
      REQUESTS.MATERNITY_PATERNITY_LEAVE,
      REQUESTS.MILITARY_CALL_LEAVE,
      REQUESTS.SABBATICAL_LEAVE,
      REQUESTS.STUDY_LEAVE,
    ].includes(action_type) && requestFrom == requestTo;

  const isSubmitButtonLoading =
    flexAnnualLeaveRequestLoading ||
    flexUnpaidLeaveRequestLoading ||
    flexSickLeaveRequestLoading ||
    flexCustomLeaveRequestLoading ||
    flexWorkRemotelyRequestLoading ||
    flexChangeShiftRequestLoading ||
    flexIgnoreAssignmentRequestLoading ||
    claimRequestLoading ||
    dayOffLoading ||
    loanRequestLoading ||
    openRequestLoading;

  const isSubmitButtonDisabled =
    fetchApplicableWorkTimingsLoading ||
    isSubmitButtonLoading ||
    (requestFrom == requestTo &&
      action_type != REQUESTS.CHANGE_SHIFT &&
      action_type != REQUESTS.ADDITIONAL_SHIFT &&
      action_type != REQUESTS.IGNORE_ASSIGNMENT &&
      action_type != REQUESTS.EXPENSE_CLAIM &&
      action_type != REQUESTS.DAY_OFF &&
      action_type != REQUESTS.REQUEST_LOAN &&
      action_type != REQUESTS.OPEN_REQUEST &&
      !!!requestable);

  const handleInterceptRequestType = (_, value) => {
    if (
      value?.id == REQUESTS.CHANGE_SHIFT ||
      value?.id == REQUESTS.ADDITIONAL_SHIFT
    ) {
      attemptGetFlexWorkdayTemplate();
    }
  };

  const handleChangeLeaveHours = (e) => {
    if (
      moment.duration(moment(e).format("HH:mm:ss")).asMilliseconds() >
      moment.duration(requestable?.leaveHours).asMilliseconds()
    ) {
      return;
    }
  };

  return (
    // <MainModal
    //   isOpen={isModalVissible}
    //   toggle={handleCloseModal}
    //   modalTitle={t("new flex request")} // change this back to new request (this is just for testing purposes)
    //   btnOnClick={handleSubmitBtn}
    //   btnSubmitLoading={isSubmitButtonLoading}
    //   btnLabel={t("save")}
    //   disableSubmitButton={isSubmitButtonDisabled}
    // >
    <>
      <div className="d-flex align-items-center">
        {props?.data?.action_type !== REQUESTS.REQUEST_LOAN && (
          <>
            <DateTimePickerForm
              {...FormProps}
              name="requestFrom"
              label={requestFromLabel()}
              placeholder="select date"
              // validateBy="textRequired"
              formSubmitting={isSubmiting}
              containerStyle="my-1"
              inputContainerStyle=" "
              reducer="flex"
              rootStyle="w-100 mb-3 mr-3"
              requestFormat="YYYY-MM-DD"
              validationName={
                action_type == REQUESTS.EXPENSE_CLAIM
                  ? "input.incurred_at"
                  : "input.from"
              }
              formServerValidation={userFormServerValidation}
              onIntercept={handleGetAvailableRequests}
              disabled={props?.hasEmpInput && !clientEmployee?.id}
            />
            <div
              className={` ${
                expenseServerErrors?.["input.incurred_at"]
                  ? "invalid-container-style"
                  : ""
              }`}
            >
              <span className="validity-msg-style">
                {expenseServerErrors?.["input.incurred_at"]
                  ? expenseServerErrors?.["input.incurred_at"]
                  : null}
              </span>
            </div>
          </>
        )}

        {Constants.possibleDateRangeRequests.includes(action_type) ? (
          <DateTimePickerForm
            {...FormProps}
            name="requestTo"
            label="to"
            placeholder="select date"
            validateBy="textRequired"
            formSubmitting={isSubmiting}
            containerStyle="my-1"
            inputContainerStyle=" "
            reducer="flex"
            rootStyle="w-100 mb-3 ml-3"
            requestFormat="YYYY-MM-DD"
            // validationName="input.requestFrom"
            // formServerValidation={userFormServerValidation}
          />
        ) : null}
      </div>
      {/* (Start) Type Select */}

      <div className="mb-2">
        <BSelect
          {...FormProps}
          name="action_type"
          label={t("request type")}
          labelStyle="mb-2"
          keepDefaultStyle
          placeholder={t("select option")}
          options={loading ? [] : selectTypesOptions}
          // optionValue="value"
          validateBy="textRequired"
          formSubmitting={isSubmiting}
          reducer="flex"
          // getOptionLabel={(option) => t(option.label?.toLowerCase())}
          icon="type"
          isLoading={loading}
          isDisabled={loading}
          onInterceptInputOnChange={handleInterceptRequestType}
        />
      </div>

      {showLeaveHoursInput ? (
        <div className="mb-2">
          <DateTimePickerForm
            {...FormProps}
            name="leaveHours"
            label={"leave hours"}
            placeholder={t("leave hours")}
            validateBy="textRequired"
            formSubmitting={isSubmiting}
            containerStyle="my-1"
            inputContainerStyle=" "
            reducer="flex"
            validationName={`input.leaveHours`}
            formServerValidation={userFormServerValidation}
            rootStyle="w-100"
            format={"HH:mm"}
            timeOnly
            use12Hours={false}
            onIntercept={(e) => handleChangeLeaveHours(e)}
            disabledTime={() => {
              const requestableMaxLeaveHours = requestable?.leaveHours ?? {}; // ex -> "08:00:00"
              const maxHours = moment(
                requestableMaxLeaveHours,
                "HH:mm:ss"
              ).format("H");

              const maxMinutes = moment(
                requestableMaxLeaveHours,
                "HH:mm:ss"
              ).format("mm");

              return {
                disabledHours: () => {
                  let disabledHours = [];
                  for (let i = +maxHours + 1; i < 24; i++) {
                    disabledHours.push(i);
                  }
                  return disabledHours;
                },
                disabledMinutes: (hour) => {
                  let disabledMinutes = [];
                  if (hour == 0) {
                    disabledMinutes.push(0);
                  }
                  if (hour == +maxHours) {
                    for (let i = +maxMinutes + 1; i < 60; i++) {
                      disabledMinutes.push(i);
                    }
                  }
                  return disabledMinutes;
                },
              };
            }}
          />
        </div>
      ) : null}

      {/* (End) Type Select */}

      {/* (Start) Modal Content */}
      {handleModalTypes()}

      {!!props?.data?.action_type &&
      props?.data?.action_type !== REQUESTS.REQUEST_LOAN ? (
        <div className="row">
          <div
            className={`col-12 mt-1 documents_dropzone_style ${
              "input.attachments" in userServerVaildation
                ? "invalid-container-style"
                : ""
            }`}
          >
            <label className="mb-2 validity-label-style">{t("files")}</label>
            <Dropzone
              onChangeStatus={handleImageChange}
              canRemove
              accept="image/*"
              name="attachments"
              multiple={true}
              inputContent={t("face_placeholder")}
              onSubmit={removeAllSelectedFiles}
            />
            <span className="validity-msg-style">
              {"input.attachments" in userServerVaildation
                ? userServerVaildation?.["input.attachments"]
                : null}
            </span>
          </div>
        </div>
      ) : null}
      {/* (End) Modal Content */}

      {/* applicable work timings for requesting */}
      {shouldRenderApplicableWorkTimings ? (
        <div className="col-12 px-0">
          <BSelect
            {...FormProps}
            name="requestable"
            label={t("requestable")}
            labelStyle="mb-2"
            keepDefaultStyle
            placeholder={t("select option")}
            options={applicableWorkTimings}
            validateBy="textRequired"
            formSubmitting={isSubmiting}
            reducer="flex"
            isLoading={fetchApplicableWorkTimingsLoading}
            onChange={(value) => setRequestable(value)}
            value={requestable}
          />
        </div>
      ) : null}

      {/* (Start) Form server validation message */}
      <span className="warnig-msg-style">
        {userServerVaildation?.[`input.description`] ?? " "}
      </span>
      {/* (End) Form server validation message */}

      {/* (Start) Error Message */}
      {modalMessage ? (
        <div className="warnig-msg-style">{modalMessage}</div>
      ) : null}
      {/* (End) Error Message */}

      {/* modal footer start */}
      <ModalFooter>
        <Button
          disabled={isSubmitButtonLoading || isSubmitButtonDisabled}
          color={"primary"}
          type="submit"
          onClick={handleSubmitBtn}
        >
          {isSubmitButtonLoading ? (
            <Spinner style={{ width: "1rem", height: "1rem", color: "#fff" }} />
          ) : (
            t("save")
          )}
        </Button>
      </ModalFooter>
      {/* modal footer end */}
    </>
  );
};

const mapStateToProps = (state) => ({
  data: state.flex?.flexAttendanceActionModal,
  attendanceValidation: state.flex?.flexAttendanceActionModalValidation,
  modalActions: state.flex?.flexAttendanceModalActions,
  userProfile: state.user.userProfile,
  userServerVaildation: state.flex?.[userFormServerValidation],
});

export default connect(mapStateToProps, {
  onInputResetAction,
  normalRequestActionAttempt,
  unpaidRequestActionAttempt,
  emergencyRequestActionAttempt,
  attendanceActionModalFailed,
  permissionRequestActionAttempt,
  halfdayRequestActionAttempt,
  homeExchangeRequestActionAttempt,
  homeFlexRequestActionAttempt,
  upsertClaimsRequestAction,
  onInputChangeAction,
  upsertSickRequestAction,
})(FlexAttendanceActionModal);
