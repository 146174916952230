import React, { useState, useEffect } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";

import moment from "moment";
import HelperFns, { openSwalConfirm } from "../../Helpers/HelperFns";
import { flexDraftSchedulesQuery } from "../../Graphql/query";

import { Link } from "react-router-dom";
import { BSelect } from "../../Builder/Form";
import Loader from "../../Components/Loader";
import AddIcon from "@mui/icons-material/Add";
import DataTable from "react-data-table-component";
import Pagination from "../../Components/Pagination";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  EditIconButton,
  RemoveIconButton,
} from "../../Components/IconButtonWithTooltip";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import { deleteFlexDraftMutation } from "../../Graphql/mutation";

const FlexDraftSchedulesPage = () => {
  const { t } = useTranslation();
  const [selectedWorkGroup, setSelectedWorkGroup] = useState("");
  const [draftSchedules, setDraftSchedules] = useState([]);
  const [paginatorInfo, setPaginatorInfo] = useState({});
  const [workGroups, setWorkGroups] = useState([]);

  const [fetchDraftSchedules, { loading: fetchDraftSchedulesLoading }] =
    useLazyQuery(flexDraftSchedulesQuery, {
      onCompleted: (data) => {
        setDraftSchedules(data?.flexDraftSchedules?.data);
        setPaginatorInfo(data?.flexDraftSchedules?.paginatorInfo);
        if (data?.workGroups) {
          setWorkGroups(data?.workGroups);
        }
      },
    });

  const [deleteDraft, { loading: deleteDraftLoading }] = useMutation(
    deleteFlexDraftMutation,
    {
      refetchQueries: () =>
        fetchDraftSchedules({ variables: { includeWorkGroups: true } }),
    }
  );

  useEffect(() => {
    fetchDraftSchedules({
      variables: {
        includeWorkGroups: true,
      },
    });
  }, []);

  const skipFirstRender = useDidUpdateEffect(() => {
    fetchDraftSchedules({
      variables: {
        includeWorkGroups: false,
        workGroupId: selectedWorkGroup?.id,
      },
    });
  }, [selectedWorkGroup]);

  const columns = [
    {
      name: t("work group"),
      wrap: true,
      selector: (row) => row?.workGroup?.name,
      sortable: false,
      grow: 1,
    },
    {
      name: t("last updated by"),
      selector: "updated_at",
      wrap: true,
      sortable: false,
      grow: 1,
      cell: (row) => (
        <span dir={HelperFns.isStringRTL(row?.name) ? "rtl" : "ltr"}>
          {row?.updated_by ?? "----------"}
        </span>
      ),
    },
    {
      name: t("last updated at"),
      selector: "updated_at",
      wrap: true,
      sortable: false,
      grow: 1,
      cell: (row) => (
        <span dir={HelperFns.isStringRTL(row?.name) ? "rtl" : "ltr"}>
          {row?.updated_at
            ? moment(row?.updated_at).format("DD-MM-YYYY hh:mm a")
            : "----------"}
        </span>
      ),
    },
    {
      name: t("days in cycle"),
      wrap: true,
      selector: "schedule_days",
      sortable: false,
      grow: 1,
    },
    {
      name: t("start date"),
      wrap: true,
      selector: "start",
      sortable: false,
      grow: 1,
    },
    {
      name: t("recurrence"),
      wrap: true,
      selector: "repeat",
      sortable: false,
      grow: 1,
      cell: (row) => (row?.repeat ? "yes" : "no"),
    },
    {
      sortable: false,
      grow: 1,
      cell: (row) => (
        <HasPrivileges
          allowBP
          reqireMain={[Privilages.EDIT_DELETE_DRAFTED_SCHEDULES]}
        >
          <div className="cards_table_actions">
            <Link
              className="pt-1"
              to={`/flex-schedules/form?schedule_id=${row?.id}`}
            >
              <EditIconButton label="edit" iconProps={{ size: "md" }} />
            </Link>
            <RemoveIconButton onClick={() => handleDelete(row?.id)} />
          </div>
        </HasPrivileges>
      ),
    },
  ];

  useEffect(() => {
    return () => {
      setSelectedWorkGroup("");
    };
  }, []);

  const handleOnChange = (workGroup) => {
    setSelectedWorkGroup(workGroup ?? "");
  };

  const handlePaginate = (page) => {
    fetchDraftSchedules({
      variables: {
        includeWorkGroups: false,
        page,
      },
    });
  };

  const handleDelete = (id) => {
    openSwalConfirm(
      (willDelete) => willDelete && deleteDraft({ variables: { id } })
    );
  };

  return (
    <div className="content positive-relative">
      {deleteDraftLoading ? (
        <div className="loader_wrapper_style positive-absolute">
          <Loader />
        </div>
      ) : null}

      <div className="page-title-container d-flex align-items-center justify-content-start mb-4 border-0 my-0 py-0 ">
        <Link to="/attendance?tab=work-calendar">
          <ArrowBackIosIcon className="back_icon" />
        </Link>
        <h1 className="flex-1 mb-0">{t("Flex Drafted Schedules")}</h1>
      </div>

      {/* Search Header */}
      <div className="d-flex align-items-start justify-content-between">
        <div className="search_container mr-custom-2">
          <BSelect
            icon="search"
            keepDefaultStyle
            placeholder={t("select option")}
            options={workGroups}
            value={selectedWorkGroup}
            onChange={handleOnChange}
            rootStyle="w-100"
            isClearable
          />
        </div>

        <Link to="/flex-schedules/form" className="add-new-draft-schedule-btn">
          <AddIcon className="add_btn_icon" />
          {t("add new")}
        </Link>
      </div>

      <div className="w-100 mt-4" style={{ overflow: "auto" }}>
        <div style={{ minWidth: "1200px" }}>
          {/* Table */}
          <DataTable
            className="cards_table"
            columns={columns}
            data={draftSchedules}
            noHeader
            persistTableHead
            sortServer
            progressPending={fetchDraftSchedulesLoading}
            progressComponent={<Loader />}
            pagination={true}
            paginationServer={true}
            paginationComponent={() => (
              <Pagination
                customPaginator={paginatorInfo}
                styleWraper=""
                onPaginate={handlePaginate}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default FlexDraftSchedulesPage;
