import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Constants from "../../Helpers/Constants";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";
import {
  fetchApplicationsListAction,
  resetApplicationListAction,
  updateApplicationsStatusAction,
  showApplicationModalAction,
} from "../../Store/Actions";
import { InputForm, RadioboxForm, BSelect } from "form-builder";
import Select from "react-select";
import Loader from "../../Components/Loader";
import DataTable from "react-data-table-component";
import Pagination from "../../Components/Pagination";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import moment from "moment";
import { useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { KeyboardBackspace } from "@mui/icons-material";
import ApplicationModal from "./ApplicationModal";

const dataTableRef = "applicationsList";

const ApplicationsList = (props) => {
  const { jobPostId } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    props.fetchApplicationsListAction({
      job_post_id: jobPostId,
      ref: dataTableRef,
      [dataTableRef]: props.data[dataTableRef],
    });
    return () => {
      props.resetApplicationListAction();
    };
  }, []);

  const handleFilter = () => {
    props.fetchApplicationsListAction({
      job_post_id: jobPostId,
      ref: dataTableRef,
      [dataTableRef]: {
        ...props.data[dataTableRef],
        pagination: {
          ...props.data[dataTableRef].pagination,
          currentPage: 1,
        },
      },
    });
  };

  const useDidFilterUpdate = useDidUpdateEffect(handleFilter, [
    props.data[dataTableRef].status,
    props.data[dataTableRef].name,
    props.data[dataTableRef].stage_id,
  ]);

  const handlePaginate = (
    page = props.data[dataTableRef].pagination.currentPage
  ) => {
    
    props.fetchApplicationsListAction({
      job_post_id: jobPostId,
      ref: dataTableRef,
      [dataTableRef]: {
        ...props.data[dataTableRef],
        pagination: {
          ...props.data[dataTableRef].pagination,
          currentPage: page,
        },
      },
    });
  };

  // handle update application process status
  const handleOnChangeApllicationStatus = (row, val) => {
    props.updateApplicationsStatusAction(
      val?.id,
      row?.applicant_job_post_id,
      jobPostId
    );
  };

  // handling add application modal
  const handleAddApplication = () => {
    let data = {
      id: null,
      job_post_id: jobPostId,
    };
    props.showApplicationModalAction({
      data,
      ref: "applicationModalActions",
      formName: "applicationModal",
    });
  };

  const columns = [
    {
      name: t("name"),
      wrap: true,
      selector: "applicant_name",
      sortable: false,
      grow: 1.2,
      cell: (row) => (
        <HasPrivileges
          reqireMain={[Privilages.VIEW_APPLICANT_PROFILES]}
          allowBP
          altChildren={<>{row?.applicant_name}</>}
          avalibleOnExpire={false}
          altExpireChildren={<>{row?.applicant_name}</>}
        >
          <Link
            to={`/recruitment/applicant-profile/${row?.applicant_id}`}
            className="cursor-pointer"
          >
            {row?.applicant_name}
          </Link>
        </HasPrivileges>
      ),
    },
    {
      name: t("email"),
      wrap: true,
      selector: "email",
      sortable: false,
      grow: 1,
      style: {
        textTransform: "none",
      },
    },
    {
      name: t("phone"),
      wrap: true,
      selector: "phone_numbers",
      sortable: false,
      grow: 1,
    },
    {
      name: t("Date Applied"),
      wrap: true,
      selector: "date_applied",
      sortable: false,
      grow: 1,
      cell: (row) => <>{moment(row?.date_applied).format("DD - MM - YYYY")}</>,
    },
    {
      name: t("no. of applications"),
      wrap: true,
      selector: "count_of_applications_per_applicant",
      sortable: false,
      grow: 1.2,
    },
    {
      name: t("status"),
      wrap: true,
      selector: "stage_name",
      sortable: false,
      grow: 2,
      cell: (row) => (
        <HasPrivileges
          reqireMain={[Privilages.CHANGE_APPLIACATION_STATUS]}
          allowBP
          altChildren={
            <Select
              className={"select-def-input-containe b-select-style w-100"}
              classNamePrefix={"b-select-style"}
              getOptionLabel={(option) => t(`${option?.name}`)}
              getOptionValue={(option) => option?.id}
              defaultValue={{
                name: row?.stage_name,
                id: row?.stage_id,
              }}
              key={`${row?.applicant_id}`}
              isRtl={document.dir?.toLowerCase() == "rtl"}
              isSearchable
              placeholder={t("select option")}
              options={props?.data?.stagesOptions}
              isDisabled={true}
            />
          }
          avalibleOnExpire={false}
          altExpireChildren={
            <Select
              className={"select-def-input-containe b-select-style w-100"}
              classNamePrefix={"b-select-style"}
              getOptionLabel={(option) => t(`${option?.name}`)}
              getOptionValue={(option) => option?.id}
              defaultValue={{
                name: row?.stage_name,
                id: row?.stage_id,
              }}
              key={`${row?.applicant_id}`}
              isRtl={document.dir?.toLowerCase() == "rtl"}
              isSearchable
              placeholder={t("select option")}
              options={props?.data?.stagesOptions}
              isDisabled={true}
            />
          }
        >
          <Select
            className={"select-def-input-containe b-select-style w-100"}
            classNamePrefix={"b-select-style"}
            getOptionLabel={(option) => t(`${option?.name}`)}
            getOptionValue={(option) => option?.id}
            defaultValue={{
              name: props?.isApplicationStageStatusLoading?.includes(
                row?.applicant_job_post_id
              )
                ? ""
                : row?.stage_name,
              id: props?.isApplicationStageStatusLoading?.includes(
                row?.applicant_job_post_id
              )
                ? ""
                : row?.stage_id,
            }}
            key={`${row?.applicant_id}`}
            onChange={(val) => handleOnChangeApllicationStatus(row, val)}
            isRtl={document.dir?.toLowerCase() == "rtl"}
            isSearchable
            placeholder={t("select option")}
            options={props?.data?.stagesOptions}
            isLoading={props?.isApplicationStageStatusLoading?.includes(
              row?.applicant_job_post_id
            )}
            isDisabled={props?.isApplicationStageStatusLoading?.includes(
              row?.applicant_job_post_id
            )}
          />
        </HasPrivileges>
      ),
    },
  ];

  return (
    <div className="content pt-2">
      <div className="d-flex mb-3">
        <Link to="/recruitment?tab=job-posts">
          <div className="back-btn d-flex align-items-center position-sticky">
            <KeyboardBackspace className="back-icon" />
            <span>{t("back")}</span>
          </div>
        </Link>
      </div>

      <div className="row">
        <div className="col-12">
          <h3 className="page-title-bold">{t("Applications")}</h3>
        </div>
      </div>

      <div>
        <div className="side-title mt-2 mb-0">
          {props.data[dataTableRef].jobPostName}
        </div>
      </div>

      <div className="row my-3 align-items-baseline">
        <div className="col-12 col-lg-5">
          <RadioboxForm
            reducer="recruitment"
            formName={dataTableRef}
            options={Constants.recruitmentProcessStatusFilter?.map(
              ({ label, value }) => ({
                label: t(label),
                value,
              })
            )}
            name="status"
            type="radio"
            containerStyle="my-2 my-lg-0"
            labelStyle="label-style"
            optionsContainerStyle="optionsContainerStyle"
            optionItemStyle="optionItemStyle"
            optionInputStyle=" "
            optionLabelStyle="optionLabelStyle"
          />
        </div>

        <div className="col-12 col-lg-7">
          <div className="row align-items-baseline">
            <div className="col-12 col-lg-4">
              <BSelect
                name="stage_id"
                optionLabel="name"
                optionValue="id"
                reducer="recruitment"
                options={props?.data?.stagesOptions}
                formName={dataTableRef}
                keepDefaultStyle
                containerStyle=""
                isClearable
                placeholder={t("select status")}
              />
            </div>

            <div className="row align-items-baseline col-12 col-lg-8 px-lg-0 my-2 my-lg-0">
              <div className="col">
                <InputForm
                  reducer="recruitment"
                  name="name"
                  formName={dataTableRef}
                  placeholder={t("search")}
                  inputContainerStyle=" "
                  containerStyle=" "
                />
              </div>

              <div>
                <button
                  className="btn add_new_btn_style"
                  type="button"
                  onClick={handleAddApplication}
                >
                  {t("add new")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <DataTable
        noDataComponent={<div className="p-4"> {t("no_records")} </div>}
        className="recruitment_datatables_style application_list_style datatable_shadow_style"
        columns={columns}
        data={props.data[dataTableRef].data}
        noHeader
        persistTableHead
        paginationComponent={() => (
          <Pagination
            tableRef={dataTableRef}
            styleWraper=""
            onPaginate={handlePaginate}
            reducer="recruitment"
          />
        )}
        pagination={true}
        paginationServer={true}
        progressPending={props.data[dataTableRef].isLoading}
        progressComponent={<Loader />}
      />

      {/* Start of Application Modal */}
      <ApplicationModal />
      {/* End of Application Modal */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state?.recruitment,
    isApplicationStageStatusLoading:
      state?.recruitment?.updateApplicationStageStatusLoading,
  };
};

export default connect(mapStateToProps, {
  fetchApplicationsListAction,
  resetApplicationListAction,
  updateApplicationsStatusAction,
  showApplicationModalAction,
})(ApplicationsList);
