import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  CheckboxBooleanForm,
  DateTimePickerForm,
  InputForm,
  RadioboxForm,
  BSelect,
} from "form-builder";
import { onInputChangeAction, updateValueAction } from "../../Store/Actions";
import Constants from "../../Constants";
import { useTranslation } from "react-i18next";
import AttViolationSection from "./AttViolationSection";

let earlySignOutArray = [];
for (let i = 1; i < 11; ++i) {
  earlySignOutArray[i] = i + 1;
}

const AttSignOutSection = (props) => {
  const { formProps } = props;
  const isMinutes = props.data.tardiness_type_sign_out === "minutes";
  const [lastSignOutTier, setLastSignOutTier] = useState("");
  useEffect(() => {
    setLastSignOutTier(lastSignOutTierCalculator());
  }, []);
  const lastSignOutTierCalculator = () => {
    let last_signout_tier = "";
    for (let i = 10; i > 0; i--) {
      if (props.data?.["allow_early_signout" + i] == 1) {
        last_signout_tier = i;
        return last_signout_tier;
      }
    }
    return last_signout_tier;
  };

  const { t } = useTranslation();

  const earlySignOutRangeToChange = (event) => {
    props?.onInputChangeAction(formProps?.formName, event);
    setLastSignOutTier(lastSignOutTierCalculator());
  };

  return (
    <div className={+props.data?.sign_in_req ? " " : "d-none"}>
      <h5 className="my-2 sub-title-style">{t("sign out")}</h5>

      <CheckboxBooleanForm
        {...formProps}
        validationName="input.allow_early_signout"
        options={["Allow Early Sign Out"]}
        name="allow_early_signout"
        type="checkbox"
        dependOn="sign_in_req"
        dependancyType="equal"
        dependancyValue={[1]}
      />

      <div className="d-flex align-items-center justify-content-between">
        <CheckboxBooleanForm
          {...formProps}
          options={["set minimum work hours"]}
          name="allow_min_work_hours"
          type="checkbox"
          dependOn="allow_early_signout"
          dependancyType="equal"
          dependancyValue={[1]}
        />

        <InputForm
          {...formProps}
          dependOn="allow_min_work_hours"
          dependancyType="equal"
          dependancyValue={[1]}
          validateBy="textRequired"
          name="min_work_hours"
          validationName="input.min_work_hours"
          type="text"
          stepInput
          labelStyle=" "
          inputContainerStyle="m-start-auto"
        />
      </div>

      <RadioboxForm
        {...formProps}
        options={[
          { label: "Time Intervals", value: "time_interval" },
          { label: "Minutes", value: "minutes" },
        ]}
        labelStyle="d-block font-weight-bold gray-color"
        optionsContainerStyle="penalty-days-style fit-width flex-md-row flex-column"
        optionItemStyle=" "
        optionLabelStyle="mb-0 mr-3"
        optionInputStyle=" "
        containerStyle="d-flex flex-wrap align-items-center gap-20 my-2"
        label="tardiness penalty should be deducted according to"
        name="tardiness_type_sign_out"
        validationName="input.tardiness_type_sign_out"
        validateBy="textRequired"
        dependOn="allow_early_signout"
        dependancyType="equal"
        dependancyValue={[1]}
      />

      <div className="d-flex flex-wrap justify-content-between">
        <RadioboxForm
          {...formProps}
          containerStyle="custom-tardiness-container justify-content-start"
          labelStyle="d-flex mr-3"
          optionsContainerStyle="penalty-days-style fit-width flex-md-row flex-column"
          optionItemStyle="d-flex align-items-center"
          optionLabelStyle="mb-0 mr-3"
          optionInputStyle=" "
          label="deduct from"
          validateBy="textRequired"
          options={Constants.penaltiesDeducted}
          name="early_signout_deduction_type"
          validationName="input.early_signout_deduction_type"
          type="checkbox"
          dependOn="allow_early_signout"
          dependancyType="equal"
          dependancyValue={[1]}
        />

        {isMinutes ? (
          <InputForm
            {...formProps}
            type="number"
            label="Annual Leave Hours"
            hasSuffix
            suffixTitle="Hours"
            rootStyle={`w-100 mb-2${
              props?.data?.tardiness_type_sign_in === "minutes" &&
              props?.data?.annual_leave_hours_sign_in
                ? " opacity-7"
                : ""
            }`}
            name="annual_leave_hours_sign_out"
            value={
              props?.data?.tardiness_type_sign_in === "minutes" &&
              Boolean(props?.data?.annual_leave_hours_sign_in)
                ? props?.data?.annual_leave_hours_sign_in
                : props?.data?.annual_leave_hours_sign_out
            }
            disabled={
              props?.data?.tardiness_type_sign_in === "minutes" &&
              Boolean(props?.data?.annual_leave_hours_sign_in)
            }
            containerStyle="d-flex align-items-center justify-content-between"
            dependOn="early_signout_deduction_type"
            dependancyType="equal"
            dependancyValue={["Leaves"]}
            validateBy="textRequired"
          />
        ) : (
          <BSelect
            {...formProps}
            name="early_violations_deduct_unit"
            label="deduct as"
            labelStyle="deduct-as-label-style"
            dependOn="early_signout_deduction_type"
            dependancyType="equal"
            dependancyValue={["Salary"]}
            // validationName="input.quantity_unit"
            keepDefaultStyle
            options={Constants.violdationQuantityUnitsOptions}
            optionLabel="label"
            optionValue="value"
            validateBy="textRequired"
            containerStyle="custom-tardiness-container justify-content-start p-0"
            inputContainerStyle="violation_type_b_select"
            placeholder={t("type")}
          />
        )}
      </div>

      {/* Tiers */}

      <div className="d-flex flex-wrap align-items-center justify-content-between">
        {props.data.allow_early_signout ? (
          <h6 className="content-header-sub-label my-1 col-12 col-lg-5 p-0">
            {t("Apply Tier Penalty if employee signs out early", { tier: 1 })}
          </h6>
        ) : null}

        <div style={{ marginInlineStart: "-12px" }}>
          <InputForm
            {...formProps}
            dependOn="allow_early_signout"
            dependancyType="equal"
            dependancyValue={[1]}
            validateBy="textRequired"
            label="from"
            name="dummy_range"
            type="text"
            value="0"
            disabled
            labelStyle="mr-2"
            inputContainerStyle="m-start-auto"
            hasSuffix
            suffixTitle="Minutes"
          />
        </div>

        <InputForm
          {...formProps}
          dependOn="allow_early_signout"
          dependancyType="equal"
          dependancyValue={[1]}
          validateBy="textRequired"
          label="to"
          name="early_signout_range_to"
          validationName="input.early_signout_range_to"
          type="text"
          hasMinMax
          maxValue={Number(props.data?.work_hours) * 60}
          minValue={0}
          labelStyle="mr-2"
          inputContainerStyle="m-start-auto"
          onChange={earlySignOutRangeToChange}
          hasSuffix
          suffixTitle="Minutes"
        />
      </div>

      <div className={+props.data?.allow_early_signout ? "mt-3" : "d-none"}>
        <AttViolationSection
          formProps={formProps}
          parent="sign_out"
          childName="early_sign_out_violations"
          penalty_type="early_sign_out"
        />
      </div>

      <CheckboxBooleanForm
        {...formProps}
        dependOn="allow_early_signout"
        dependancyType="equal"
        dependancyValue={[1]}
        options={["Skip penalties not applied when calculating violations"]}
        name="skip_not_applied_early_sign_out_penalties"
        validationName="input.skip_not_applied_early_sign_out_penalties"
        type="checkbox"
      />

      {earlySignOutArray.map((_, i) => (
        <>
          <div className="d-flex flex-wrap align-items-center justify-content-between">
            <CheckboxBooleanForm
              {...formProps}
              validationName={"input.allow_early_signout" + i}
              options={[
                t("Apply Tier Penalty if employee signs out early", {
                  tier: i + 1,
                }),
              ]}
              name={"allow_early_signout" + i}
              type="checkbox"
              dependOn={
                i == 1 ? "allow_early_signout" : "allow_early_signout" + (i - 1)
              }
              dependancyType="equal"
              dependancyValue={[1]}
            />
            <InputForm
              {...formProps}
              dependOn={"allow_early_signout" + i}
              dependancyType="equal"
              dependancyValue={[1]}
              validateBy="textRequired"
              label="from"
              name="dummy_range"
              type="text"
              value={
                i == 1
                  ? props.data?.early_signout_range_to
                  : props.data?.["early_signout_range_to" + (i - 1)]
              }
              disabled
              labelStyle="mr-2"
              rootStyle="opacity-7"
              inputContainerStyle="m-start-auto"
              hasSuffix
              suffixTitle="Minutes"
            />
            <InputForm
              {...formProps}
              dependOn={"allow_early_signout" + i}
              dependancyType="equal"
              dependancyValue={[1]}
              validateBy="textRequired"
              label="to"
              name={"early_signout_range_to" + i}
              validationName={"input.early_signout_range_to" + i}
              type="number"
              hasMinMax
              maxValue={Number(props.data?.work_hours) * 60}
              minValue={
                i == 1
                  ? props.data?.early_signout_range_to
                  : props.data?.["early_signout_range_to" + (i - 1)]
              }
              labelStyle="mr-2"
              onChange={earlySignOutRangeToChange}
              inputContainerStyle="m-start-auto"
              hasSuffix
              suffixTitle="Minutes"
            />
          </div>

          <div
            className={
              +props.data?.["allow_early_signout" + i] ? "mt-3" : "d-none"
            }
          >
            <AttViolationSection
              formProps={formProps}
              parent="sign_out"
              childName={"early_sign_out_violations" + i}
              penalty_type={"early_sign_out" + i}
            />
          </div>

          <CheckboxBooleanForm
            {...formProps}
            dependOn={"allow_early_signout" + i}
            dependancyType="equal"
            dependancyValue={[1]}
            options={["Skip penalties not applied when calculating violations"]}
            name={"skip_not_applied_early_sign_out_penalties" + i}
            validationName={
              "input.skip_not_applied_early_sign_out_penalties" + i
            }
            type="checkbox"
          />
        </>
      ))}

      <div className={+props.data?.allow_early_signout ? "mt-3" : "d-none"}>
        <div className="col-12 px-0">
          <BSelect
            {...formProps}
            dependOn="allow_early_signout"
            dependancyType="equal"
            dependancyValue={[1, "1"]}
            name="early_signout_reset_period"
            validationName="input.early_signout_reset_period"
            label={
              "restart calculating penalties from the first violation every"
            }
            keepDefaultStyle
            options={Constants.resetOptions}
            optionLabel="label"
            optionValue="value"
            validateBy="textRequired"
            containerStyle="custom-tardiness-container align-items-baseline"
            inputContainerStyle="w-25 max-w-208"
            icon="calendar"
          />
        </div>

        <div className="d-flex flex-row flex-wrap justify-content-between">
          {props.data?.allow_early_signout ? (
            <h6 style={{ fontSize: "13px" }} className="content-header mb-4">
              {t("Consider Absent if employee works less than")}{" "}
              <span
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  paddingLeft: "20px",
                }}
              >
                {props.data?.work_hours -
                  props.data?.["early_signout_range_to" + lastSignOutTier] /
                    60}{" "}
                {t("Hours")}
              </span>
            </h6>
          ) : null}
        </div>
      </div>

      <div className=" d-flex flex-row flex-wrap justify-content-between">
        <CheckboxBooleanForm
          {...formProps}
          dependOn="sign_in_req"
          dependancyType="equal"
          dependancyValue={[1]}
          options={["Automatically Sign Employees Out at"]}
          name="auto_sign_out"
          validationName="input.auto_sign_out"
          type="checkbox"
        />
        <DateTimePickerForm
          timeOnly
          {...formProps}
          dependOn="auto_sign_out"
          dependancyType="equal"
          dependancyValue={[1]}
          validateBy="textRequired"
          name="auto_sign_out_time"
          validationName="input.auto_sign_out_time"
        />
      </div>

      <div className="my-1">
        <CheckboxBooleanForm
          {...formProps}
          options={["Apply Penalty if employee misses signing out"]}
          name="apply_missing_sign_out"
          validationName="input.apply_missing_sign_out"
          type="checkbox"
          containerStyle=""
          dependOn="auto_sign_out"
          dependancyType="equal"
          dependancyValue={[0]}
        />

        <div className="col-12 px-0 mt-3">
          {+props.data?.apply_missing_sign_out ? (
            <AttViolationSection
              formProps={formProps}
              childName="missing_sign_out_violations"
              penalty_type="missing_sign_out"
            />
          ) : null}

          <CheckboxBooleanForm
            {...formProps}
            dependOn="apply_missing_sign_out"
            dependancyType="equal"
            dependancyValue={[1]}
            options={["Skip penalties not applied when calculating violations"]}
            name="skip_not_applied_missing_sign_out_penalties"
            validationName="input.skip_not_applied_missing_sign_out_penalties"
            type="checkbox"
          />

          <div className="d-flex flex-column align-items-start w-100">
            <RadioboxForm
              {...formProps}
              containerStyle="custom-tardiness-container justify-content-start"
              labelStyle="d-flex mr-3"
              optionsContainerStyle="penalty-days-style fit-width flex-md-row flex-column"
              optionItemStyle="d-flex align-items-center"
              optionLabelStyle="mb-0 mr-3"
              optionInputStyle=" "
              label="deduct from"
              validateBy="textRequired"
              options={Constants.penaltiesDeducted}
              name="missing_sign_out_deduction_type"
              validationName="input.missing_sign_out_deduction_type"
              type="checkbox"
              dependOn="apply_missing_sign_out"
              dependancyType="equal"
              dependancyValue={[1]}
            />

            <BSelect
              {...formProps}
              name="missing_violations_deduct_unit"
              label="deduct as"
              labelStyle="deduct-as-label-style"
              dependOn="missing_sign_out_deduction_type"
              dependancyType="equal"
              dependancyValue={["Salary"]}
              // validationName="input.quantity_unit"
              keepDefaultStyle
              options={Constants.violdationQuantityUnitsOptions}
              optionLabel="label"
              optionValue="value"
              validateBy="textRequired"
              containerStyle="custom-tardiness-container justify-content-start p-0"
              inputContainerStyle="violation_type_b_select"
              placeholder={t("type")}
            />
          </div>

          <BSelect
            {...formProps}
            dependOn="apply_missing_sign_out"
            dependancyType="equal"
            dependancyValue={[1, "1"]}
            name="missing_sign_out_reset_period"
            validationName="input.missing_sign_out_reset_period"
            label={
              "restart calculating penalties from the first violation every"
            }
            keepDefaultStyle
            options={Constants.resetOptions}
            optionLabel="label"
            optionValue="value"
            validateBy="textRequired"
            containerStyle="custom-tardiness-container align-items-baseline"
            inputContainerStyle="w-25 max-w-208"
            icon="calendar"
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, { formProps }) => {
  return {
    data: state.super[formProps.formName],
  };
};

export default connect(mapStateToProps, {
  onInputChangeAction,
  updateValueAction,
})(AttSignOutSection);
