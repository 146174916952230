import React, { useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";
import {
  fetchUserPettyCashListAction,
  onInputResetAction,
  showCompanyExpensesModalAction,
  fetchUserProfileCurrentBalanceAction,
  fetchPettyCashCurrenciesAction,
} from "../../Store/Actions";
import { BSelect } from "form-builder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faFileAlt } from "@fortawesome/free-solid-svg-icons";
import Loader from "../../Components/Loader";
import DataTable from "react-data-table-component";
import Pagination from "../../Components/Pagination";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import { useTranslation } from "react-i18next";
import CompanyExpensesModal from "../../Components/CompanyExpensesModal";
import ShowMoreText from "react-show-more-text";
import {
  DownloadFileIconButton,
  EditIconButton,
} from "../../Components/IconButtonWithTooltip";

const dataTableRef = "userPettyCashList";

const MyPettyCash = (props) => {
  const { t } = useTranslation();

  useEffect(() => {
    props.fetchUserPettyCashListAction({
      id: props?.userProfileData?.id,
      ref: dataTableRef,
      [dataTableRef]: props.data[dataTableRef],
      officeId: props?.userProfileData?.office?.id,
    });
    props.fetchUserProfileCurrentBalanceAction({
      emp_id: props?.userProfileData?.id,
    });
    props.fetchPettyCashCurrenciesAction({
      emp_id: props?.userProfileData?.id,
      type: "",
    });
    return () => {
      props.onInputResetAction(dataTableRef, "currency_id");
    };
  }, []);

  const handleFilter = () => {
    props.fetchUserPettyCashListAction({
      ref: dataTableRef,
      [dataTableRef]: {
        ...props.data[dataTableRef],
        pagination: {
          ...props.data[dataTableRef].pagination,
          currentPage: 1,
        },
      },
      id: props?.userProfileData?.id,
    });
  };

  const useDidFilterUpdate = useDidUpdateEffect(handleFilter, [
    props.data[dataTableRef].currency_id,
  ]);

  const handlePaginate = (
    page = props.data[dataTableRef].pagination.currentPage
  ) => {
    
    props.fetchUserPettyCashListAction({
      ref: dataTableRef,
      [dataTableRef]: {
        ...props.data[dataTableRef],
        pagination: {
          ...props.data[dataTableRef].pagination,
          currentPage: page,
        },
      },
      id: props?.userProfileData?.id,
    });
  };

  // handling add company expenses modal
  const handleAddCompanyExpenses = () => {
    let data = {
      id: null,
      emp_id: props?.userProfileData?.id,
      office_id: props?.userProfileData?.office?.id,
      on_behalf_of: 1,
      isUserProfile: true,
    };
    props.showCompanyExpensesModalAction({
      data,
      ref: "companyExpensesModalActions",
      formName: "companyExpensesModal",
    });
  };

  // handleing edit company expenses modal
  const handleEditCompanyExpenses = (row) => {
    let data = {
      id: row?.id,
      emp_id: props?.userProfileData?.id,
      office_id: row?.office?.id,
      category_id: row?.category?.id,
      sub_category_id: row?.sub_category?.id,
      details: row?.details,
      type: row?.type,
      date: row?.date,
      amount: row?.amount.toString(),
      currency_id: row?.currency?.id,
      on_behalf_of: row?.type === "expense" ? 1 : 0,
      attachments: row?.files,
    };
    props.showCompanyExpensesModalAction({
      data,
      ref: "companyExpensesModalActions",
      formName: "companyExpensesModal",
    });
  };

  // to approximate the numbers
  function roundToTwo(num) {
    return (+num)?.toFixed(2);
  }

  useEffect(() => {
    return () => {};
  }, [props.isLoading]);

  if (props.isLoading) {
    return <Loader />;
  }

  const columns = [
    {
      name: t("date"),
      wrap: true,
      sortable: false,
      cell: (row) => (
        <>
          {row?.created_at != null
            ? moment(row?.created_at).format("DD - MM - YYYY")
            : ""}
        </>
      ),
    },
    {
      name: t("Transaction"),
      wrap: true,
      sortable: false,
      grow: 0.5,
      cell: (row) => (
        <>
          {row?.type == "add"
            ? t("Balance Added")
            : row?.type == "deduct"
              ? t("Balance deducted")
              : t("Expense")}
        </>
      ),
    },
    {
      name: t("description"),
      wrap: true,
      sortable: false,
      cell: (row) => (
        <>
          <div className="d-flex">
            <b>{row?.category?.name}</b>
            {row?.category && row?.sub_category ? (
              <div className="mx-2">-</div>
            ) : (
              ""
            )}
            {row?.sub_category ? row?.sub_category?.name : ""}
          </div>
        </>
      ),
    },
    {
      name: t("detials"),
      wrap: true,
      sortable: false,
      grow: 1.2,
      cell: (row) => (
        <ShowMoreText
          lines={1}
          more="Show more"
          less="Show less"
          expanded={false}
          width={280}
        >
          {row?.details}
        </ShowMoreText>
      ),
    },
    {
      name: t("amount"),
      wrap: true,
      sortable: false,
      cell: (row) => (
        <>
          {row?.type !== "add" ? (
            <div className="d-flex red-color">
              (<div className="pr-1">{roundToTwo(row?.amount)}</div>
              <span>{row?.currency?.name}</span>)
            </div>
          ) : (
            <div className="d-flex green-color">
              (<div className="pr-1">{roundToTwo(row?.amount)}</div>
              <span>{row?.currency?.name}</span>)
            </div>
          )}
        </>
      ),
    },
    {
      name: t("balance"),
      wrap: true,
      sortable: false,
      cell: (row) => (
        <div className="d-flex amount_currency_wrapper_style">
          <div className="pr-1">{roundToTwo(row?.balance)}</div>
          <span>{row?.currency?.symbol}</span>
        </div>
      ),
    },
    {
      name: t("incurred at"),
      wrap: true,
      sortable: false,
      cell: (row) => (
        <>
          {row?.date != null ? moment(row?.date).format("DD - MM - YYYY") : ""}
        </>
      ),
    },
    {
      name: t("Attachments"),
      wrap: true,
      sortable: false,
      grow: 0.3,
      cell: (row) => (
        <div className="cards_table_actions">
          {row?.files?.map((file) => (
            <DownloadFileIconButton key={file?.id} href={file?.path} />
          ))}
        </div>
      ),
    },
    {
      name: t("actions"),
      wrap: true,
      center: true,
      sortable: false,
      grow: 0.3,
      cell: (row) => (
        <HasPrivileges
          reqireMain={[Privilages.ADD_EXPENSE]}
          avalibleOnExpire={false}
          altExpireChildren={
            <div className="cards_table_actions">
              {row?.type !== "add" && row?.type !== "deduct" ? (
                <EditIconButton />
              ) : null}
            </div>
          }
        >
          <div className="cards_table_actions">
            {row?.type !== "add" && row?.type !== "deduct" ? (
              <EditIconButton onClick={() => handleEditCompanyExpenses(row)} />
            ) : null}
          </div>
        </HasPrivileges>
      ),
    },
  ];

  return (
    <div className="user_petty_cash_wrapper_style">
      <div className="row mb-3 align-items-baseline">
        <div className="d-flex col-12 col-xl-6 user_petty_cash_current_balance_style align-items-center">
          <h2 className="mb-0">{t("current balance")}</h2>

          <div className="d-flex mx-5">
            {props?.data?.userProfileCurrentBalanceData?.get_total_employee_balance?.map(
              (currentBalance) => (
                <div className="d-flex mx-2">
                  <p className="mb-0">{currentBalance?.balance}</p>
                  <span className="mx-1">{currentBalance?.currency_name}</span>
                </div>
              )
            )}
          </div>
        </div>

        <div className="d-flex justify-content-end align-items-baseline col-12 col-xl-6">
          {props?.financesData?.pettyCashCurrenciesOptionsData.length == 1 ? (
            ""
          ) : (
            <div className="w-25 mx-3">
              <BSelect
                reducer="user"
                name="currency_id"
                formName={dataTableRef}
                options={props?.financesData?.pettyCashCurrenciesOptionsData}
                keepDefaultStyle
                optionLabel="name"
                optionValue="id"
                placeholder={t("Currency")}
                isClearable
              />
            </div>
          )}

          <HasPrivileges
            reqireMain={[Privilages.ADD_EXPENSE]}
            avalibleOnExpire={false}
            altExpireChildren={
              <div>
                <button
                  className="btn add_new_btn_style expense_btn_style"
                  type="button"
                  disabled
                >
                  {t("Add Expense")}
                </button>
              </div>
            }
          >
            <div>
              <button
                className="btn add_new_btn_style expense_btn_style"
                type="button"
                onClick={handleAddCompanyExpenses}
              >
                {t("Add Expense")}
              </button>
            </div>
          </HasPrivileges>
        </div>
      </div>

      <DataTable
        noDataComponent={<div className="p-4"> {t("no_records")} </div>}
        className="cards_table"
        columns={columns}
        data={props.data[dataTableRef].data}
        noHeader
        persistTableHead
        paginationComponent={() => (
          <Pagination
            tableRef={dataTableRef}
            styleWraper=""
            onPaginate={handlePaginate}
            reducer="user"
          />
        )}
        pagination={true}
        paginationServer={true}
        progressPending={props.data[dataTableRef].isLoading}
        progressComponent={<Loader />}
      />

      {/* Start of company expenses modal */}
      <CompanyExpensesModal />
      {/* End of company expenses modal */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    financesData: state.finances,
    data: state.user,
    userProfileData: state.user.userProfile,
    isLoading: state?.user?.userProfilePettyCashIsLoading,
  };
};

export default connect(mapStateToProps, {
  fetchUserPettyCashListAction,
  onInputResetAction,
  showCompanyExpensesModalAction,
  fetchUserProfileCurrentBalanceAction,
  fetchPettyCashCurrenciesAction,
})(MyPettyCash);
