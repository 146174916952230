import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import { hidePenaltyModal } from "../../Store/Actions";

import MainModal from "../MainModal";
import PenaltyUnit from "./PenaltyUnit";
import { ModalHeader } from "reactstrap";

const formName = "editPenaltyModal";
const formRef = "editPenaltyModalActions";
const penaltiesFormat = (penalties) => {
  /** @return Penalty Group { reason: [penalty] } */
  if (penalties?.length) {
    const uniqueReasons = [
      ...new Set(penalties?.map((p) => p?.penalty_reason)),
    ];
    return uniqueReasons?.map((reason) => ({
      [reason]: penalties?.filter((p) => p?.penalty_reason === reason),
    }));
  }
};

const EditPenaltyModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Local State
  const [isDirty, setIsDirty] = React.useState(false);

  // Reducer State
  const modalData = useSelector((state) => state.super[formName]);
  const { isVissible } = useSelector((state) => state.super[formRef]);

  /* ↓ Helpers ↓ */

  const handleModalClose = () => {
    if (isDirty) {
      props?.refetchList();
      setIsDirty(false);
    }
    dispatch(hidePenaltyModal({ ref: formRef, formName }));
  };

  return (
    <MainModal
      size="lg"
      isOpen={isVissible}
      className="h-auto"
      modalHeader={
        <ModalHeader toggle={handleModalClose}>
          <strong>{modalData?.employeeName}</strong>
          <span className="mx-3">
            {moment(modalData?.date).format("dddd DD - MM - YYYY")}
          </span>
        </ModalHeader>
      }
    >
      {(modalData.isFlex ) ? null : (
        <div className="row">
          <div className="col-6 col-lg-4">
            <strong>{t("Sign In")}</strong>
            <span className="d-block mt-2">
              {modalData?.sign_in_time
                ? moment(modalData?.sign_in_time).format("DD - MM - YYYY  LT")
                : "-----"}
            </span>
          </div>
          <div className="col-6 col-lg-4">
            <strong>{t("Sign Out")}</strong>
            <span className="d-block mt-2">
              {modalData?.sign_out_time
                ? moment(modalData?.sign_out_time).format("DD - MM - YYYY  LT")
                : "-----"}
            </span>
          </div>
          {/* <div className="px-2">
          <strong>{t("current leaves balance")}</strong>
          <span className="d-block mt-2">
            {modalData.balance?.remaining_annual_leaves +
              modalData.balance?.remaining_emergency_leaves}{" "}
            {t("days")}
          </span>
        </div> */}
        </div>
      )}

      <div className="mt-4 d-flex flex-column gap-20 boxContainer m-0">
        {penaltiesFormat(modalData?.penalties)?.map((penalty) => {
          const reason = Object.keys(penalty)[0];

          return (
            <div key={reason} className="d-flex gap-20 align-items-center">
              <strong>{t(reason)}</strong>
              <div className="flex-fill">
                {penalty[reason].map((penaltyUnit) => (
                  <PenaltyUnit
                    key={reason}
                    penalty={penaltyUnit}
                    setIsDirty={setIsDirty}
                    isFlex={(modalData.isFlex )}
                  />
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </MainModal>
  );
};

export default EditPenaltyModal;
