import { applyMiddleware, compose, createStore } from "redux";
import { createMigrate, persistReducer, persistStore } from "redux-persist";
import createSagaMiddleware from "redux-saga";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

import rootReducer from "./Reducers";
import rootSaga from "./Sagas";
import { initState } from "./Reducers/DashboardUiReducer";
import { initState as MonthlyState } from "./Reducers/MonthlyReportReducer";
import { initState as authState } from "./Reducers/AuthReducer";

const sagaMiddleware = createSagaMiddleware(); // Saga middleware

const middleWares = [sagaMiddleware];
// var devtools = window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f;

const migrations = {
  1: (state) => {
    return {
      ...state,
      dashboardUi: {
        ...initState,
        ...state?.dashboardUi,
      },
      auth: {
        ...authState,
        ...state?.auth,
      },
    };
  },
  2: (state) => {
    return {
      ...state,
      monthlyReport: {
        ...MonthlyState,
        customReportColumns: state?.monthlyReport?.monthlyReportColumns,
      },
      auth: {
        ...authState,
        ...state?.auth,
      },
    };
  },
  4: (state) => {
    return {
      ...state,
      dashboardUi: {
        ...initState,
        ...state?.dashboardUi,
        crmColumnsVisibility: {
          ...state?.dashboardUi?.crmColumnsVisibility,
          "view related activities": 1,
        },
      },
      monthlyReport: {
        ...MonthlyState,
        customReportColumns: state?.monthlyReport?.monthlyReportColumns,
      },
      auth: {
        ...authState,
        ...state?.auth,
      },
    };
  },
};

const persistConfig = {
  key: "attendance_root",
  whitelist: ["auth", "monthlyReport", "dashboardUi", "LoginUserList"],
  version: 4,
  storage,
  migrate: createMigrate(migrations),
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers =
  (typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const Store = createStore(
  persistedReducer,
  compose(applyMiddleware(...middleWares))
);
export default Store;

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(Store);
