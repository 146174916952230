import React from "react";
import CloseIcon from "@mui/icons-material/Close";

const FilterBadge = ({
  label,
  selectedItems,
  onCancelFilter = () => {},
  optLabel = "name",
  hideCancel = false,
}) => {
  if (!selectedItems?.length) {
    return null;
  }
  return (
    <div
      className="d-flex px-3 py-2 rounded-4 mr-1"
      style={{ backgroundColor: "#ECF7FE" }}
      title={selectedItems?.map((it) => it?.[optLabel])?.join(",")}
    >
      <span className="font-weight-bold font-nunit-sans mr-2">
        {label + (selectedItems?.[0]?.[optLabel] ? ": " : "")}
      </span>
      <span className="font-nunit-sans">
        {selectedItems.map((opt, i) => (
          <>{i === 0 ? opt?.[optLabel] : ", " + opt?.[optLabel]}</>
        ))}
      </span>
      {hideCancel ? null : (
        <div className="btn p-0 m-0 ml-2" onClick={onCancelFilter}>
          <CloseIcon sx={{ color: "#8E8E8E", fontSize: 15 }} />
        </div>
      )}
    </div>
  );
};

export default FilterBadge;
