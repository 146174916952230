import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import {
  updateValueAction,
  onFormResetAction,
  onInputResetAction,
} from "../../Store/Actions";
import {
  changeTiersModalQuery,
  getCanDecreaseTierQuery,
  getGeneratedInvoicesQuery,
  getPlanAvailableDatesQuery,
} from "../../Graphql/query/PaymentTracking";
import moment from "moment";
import Privileges from "../../Constants/Privilages";
import HelperFns, { safeJsonParse, showToast } from "../../Helpers/HelperFns";
import { changeSubscriptionTiersMutation } from "../../Graphql/mutation/PaymentTracking";

import {
  BSelect,
  InputForm,
  RadioboxForm,
  DateTimePickerForm,
} from "form-builder";
import Loader from "../Loader";
import Box from "@mui/material/Box";
import MainModal from "../MainModal";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import ChangePlanTierGeneratedInvoices from "../PaymentTracking/ChangePlanTierGeneratedInvoices";

const reducer = "paymentTracking";
const formName = "changeTiersModal";
const formNameValidation = "paymentTrackingClientValidation";
const formServerValidation = "paymentTrackingServerValidation";
const formatChangeSubscriptionTiersData = (data) => {
  const json = JSON.stringify({
    type: data?.type,
    tier: data?.tier,
    from: data?.startFrom,
  });

  return {
    json,
    from: data?.from,
    company_id: +data?.company_id,
  };
};

const ChangeTiersModal = ({ onClose, refetchList, ...props }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Local State
  const [errMsg, setErrMsg] = React.useState("");
  const [formSubmitting, setFormSubmitting] = React.useState(false);
  const [data, setData] = React.useState({ ...(props?.data || {}) });
  const [generatedInvoices, setGeneratedInvoices] = React.useState([]);
  const [isSelectMonthYear, setIsSelectMonthYear] = React.useState(true);
  const [options, setOptions] = React.useState({
    tiers: [],
    availableDates: [],
  });

  // Reducer State
  const formData = useSelector((state) => state?.[reducer]?.[formName]);
  const formClientValidation = useSelector(
    (state) => state?.[reducer]?.[formNameValidation]
  );

  // Server State
  const {
    data: { canSelectDateInDecreaseTier = 0 } = {},
    loading: canDecreaseTierLoading,
  } = useQuery(getCanDecreaseTierQuery, {
    variables: {
      from: data?.from,
      companyId: +data?.company_id,
    },
    onError: (err) => {
      showToast(
        "error",
        err?.graphQLErrors?.[0]?.extensions?.reason ||
          err?.graphQLErrors?.[0]?.message ||
          err?.message
      );
    },
  });
  const [changeSubscriptionTiers, { loading: changeLoading }] = useMutation(
    changeSubscriptionTiersMutation
  );
  const [getData, { loading }] = useLazyQuery(changeTiersModalQuery);
  const [getPlanAvailableDates, { loading: getPlanAvailableDatesLoading }] =
    useLazyQuery(getPlanAvailableDatesQuery);
  const [getGeneratedInvoices, { loading: getGeneratedInvoicesLoading }] =
    useLazyQuery(getGeneratedInvoicesQuery);

  // Constants
  const isToday =
    formData.type === "Decrease" &&
    !canSelectDateInDecreaseTier &&
    isSelectMonthYear;
  const FormProps = {
    reducer,
    formName,
    formNameValidation,
    formServerValidation,
    formSubmitting,
  };

  /* ↓ State Effects ↓ */

  React.useEffect(() => {
    if (isToday || (formData.type && formData.startFrom)) {
      dispatch(
        onFormResetAction(formName, {
          ...formData,
          tier: null,
          ratePerPeriod: "",
        })
      );
      handleGetData(isToday ? moment().format("YYYY-MM-DD") : undefined);
    }
  }, [formData.type, formData.startFrom, isToday]);

  /* ↓ Helpers ↓ */

  const handleGetData = (date) => {
    getData({
      variables: {
        from: data?.from,
        type: formData?.type,
        company_id: +data?.company_id,
        date: date || formData.startFrom,
      },
      onCompleted: ({ tiers }) => {
        const data = tiers
          ? safeJsonParse(safeJsonParse(tiers || "{}") || "{}")
          : {};

        setData((prev) => ({
          ...prev,
          ratePerPeriod: data?.rpp,
          currentTier: data?.currentTier,
        }));
        setOptions((prev) => ({ ...prev, tiers: data?.tiers || [] }));
      },
      onError: () => {
        setData((prev) => ({ ...prev, tier: null, ratePerPeriod: "" }));
        setOptions((prev) => ({ ...prev, tiers: [] }));
      },
    });
  };

  const handleGetPlanAvailableDates = () => {
    getPlanAvailableDates({
      variables: {
        from: data?.from,
        companyId: +data?.company_id,
      },
      onCompleted: ({ availableDates }) => {
        setOptions((prev) => ({
          ...prev,
          availableDates: availableDates
            ? availableDates.map((date) => ({
                id: date,
                name: moment(date).format("MMMM, YYYY"),
              }))
            : [],
        }));
      },
      onError: (err) => {
        showToast(
          "error",
          err?.graphQLErrors?.[0]?.extensions?.reason ||
            err?.graphQLErrors?.[0]?.message ||
            err?.message
        );
      },
    });
  };

  const handleGetCanaledInvoices = (date) => {
    if (date) {
      getGeneratedInvoices({
        variables: {
          date,
          from: data?.from,
          companyId: +data?.company_id,
        },
        onCompleted: ({ generatedInvoices }) => {
          setGeneratedInvoices(generatedInvoices);
        },
        onError: (err) => {
          showToast(
            "error",
            err?.graphQLErrors?.[0]?.extensions?.reason ||
              err?.graphQLErrors?.[0]?.message ||
              err?.message
          );
        },
      });
    }
  };

  const onSelectTier = (_, tier) => {
    dispatch(updateValueAction(formName, "ratePerPeriod", tier.rppName));
  };

  const onSelectDecrease = (_, val) => {
    val === "Decrease" && handleGetPlanAvailableDates();
  };

  const onSelectStartFrom = (momentDate, option) => {
    const date = isToday
      ? moment().format("YYYY-MM-DD")
      : option?.id || momentDate.format("YYYY-MM-DD");

    handleGetCanaledInvoices(date);
    isToday && handleGetData(date);
  };

  const handleToggleIsSelectMonthYear = () => {
    setIsSelectMonthYear((prev) => !prev);
    dispatch(onInputResetAction(formName, "startFrom"));
  };

  const handleCloseModal = () => {
    onClose();
    dispatch(onFormResetAction(formName));
    dispatch(onFormResetAction(formNameValidation));
    dispatch(onFormResetAction(formServerValidation));
  };

  const isInvalidClientValidation = () => {
    if (formClientValidation?.length) return true;

    if (generatedInvoices?.length) {
      !HelperFns.checkPrivileges({
        privileges: [
          Privileges.SUPER_PRIVILEGE,
          Privileges.CANCEL_GENERATED_INVOICES,
        ],
      })
        ? setErrMsg(
            "you should cancel all generated invoices first. contact an account manager"
          )
        : setErrMsg("you should cancel all generated invoices first");
      return true;
    }

    return false;
  };

  const handleSubmit = () => {
    setFormSubmitting(true);
    if (isInvalidClientValidation()) return;

    changeSubscriptionTiers({
      variables: formatChangeSubscriptionTiersData({
        ...formData,
        id: data?.id,
        from: data?.from,
        company_id: data?.company_id,
      }),
      onCompleted: () => {
        refetchList();
        handleCloseModal();
        showToast("success");
      },
      onError: (err) => {
        const validation = err?.graphQLErrors?.[0]?.extensions?.validation;
        dispatch(onFormResetAction(formServerValidation, validation || {}));

        const msg =
          validation?.json?.[0] ||
          err?.graphQLErrors?.[0]?.extensions?.reason ||
          err?.graphQLErrors?.[0]?.message ||
          err?.message;
        setErrMsg(msg || "");
      },
    });
  };

  return (
    <MainModal
      isOpen
      modalTitle="Change Tiers"
      toggle={handleCloseModal}
      btnOnClick={handleSubmit}
      btnSubmitLoading={changeLoading}
    >
      {loading ||
      canDecreaseTierLoading ||
      getPlanAvailableDatesLoading ||
      getGeneratedInvoicesLoading ? (
        <Loader fixed />
      ) : null}

      <RadioboxForm
        {...FormProps}
        name="type"
        options={[
          { value: "Increase", label: "Increase" },
          { value: "Decrease", label: "Decrease" },
        ]}
        optionItemStyle=" "
        optionInputStyle=" "
        containerStyle="mb-3"
        optionsContainerStyle="d-flex gap-20"
        interceptChange={onSelectDecrease}
      />

      <div className="d-flex justify-content-between mb-2">
        <div>
          <b className="dark-color d-block mb-2">{t("Current Tier")}</b>
          {data?.currentTier}
        </div>
        <div>
          <b className="dark-color d-block mb-2">{t("Rate Per Period")}</b>
          {data?.ratePerPeriod}
        </div>
      </div>

      <div className="position-relative mt-2">
        {formData.type === "Decrease" ? (
          <HasPrivileges
            reqireMain={[
              Privileges.APPLY_CHANGE_ON_TIERS_ON_CUSTOM_DATES,
              Privileges.SUPER_PRIVILEGE,
            ]}
          >
            <Box sx={{ position: "absolute", top: 0, right: 0 }}>
              <button
                className="link-btn"
                onClick={handleToggleIsSelectMonthYear}
              >
                {t(isSelectMonthYear ? "Select Custom date" : "Select date")}
              </button>
            </Box>
          </HasPrivileges>
        ) : null}

        {formData.type === "Decrease" ? (
          isSelectMonthYear ? (
            <BSelect
              {...FormProps}
              name="startFrom"
              label="Start From"
              placeholder="Select date"
              validateBy="textRequired"
              isDisabled={
                !options?.availableDates?.length || !canSelectDateInDecreaseTier
              }
              options={options?.availableDates || []}
              onInterceptInputOnChange={onSelectStartFrom}
              validationName="from"
            />
          ) : (
            <DateTimePickerForm
              {...FormProps}
              name="startFrom"
              label="Start From"
              format="yyyy-MM-DD"
              requestFormat="yyyy-MM-DD"
              validateBy="textRequired"
              labelStyle="mb-2 pt-1"
              containerStyle="w-100"
              hasIcon
              validationName="from"
              onIntercept={onSelectStartFrom}
            />
          )
        ) : (
          <DateTimePickerForm
            {...FormProps}
            name="startFrom"
            label="Start From"
            format="yyyy-MM-DD"
            requestFormat="yyyy-MM-DD"
            validateBy="textRequired"
            labelStyle="mb-2 pt-1"
            containerStyle="w-100 mb-2"
            hasIcon
            validationName="from"
            onIntercept={onSelectStartFrom}
          />
        )}
      </div>

      <BSelect
        {...FormProps}
        label="New Tier"
        name="tier"
        icon="employees"
        validateBy="textRequired"
        rootStyle="w-50 d-inline-block pr-2"
        options={options?.tiers || []}
        onInterceptInputOnChange={onSelectTier}
        disabled={!formData?.startFrom}
        optionValue="quantity"
        validationName="tier"
      />
      <InputForm
        {...FormProps}
        name="ratePerPeriod"
        label="Rate Per Period"
        labelStyle="mb-2 pt-1"
        containerStyle="w-100"
        rootStyle="w-50 d-inline-block"
        validateBy="textRequired"
        icon="calculator"
        disabled
      />

      <ChangePlanTierGeneratedInvoices
        invoices={generatedInvoices}
        refetch={() => handleGetCanaledInvoices(formData?.startFrom)}
      />

      {errMsg ? (
        <p role="alert" className="red-color mt-2 mb-0">
          {t(errMsg)}
        </p>
      ) : null}
    </MainModal>
  );
};

export default ChangeTiersModal;
