import React from "react";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  subscriptionsListQuery,
  subscriptionsListOptionsQuery,
} from "../../Graphql/query/PaymentTracking";
import { showToast } from "../../Helpers/HelperFns";
import Privileges from "../../Constants/Privilages";
import { onFormResetAction } from "../../Store/Actions";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import { billingPeriodOptions } from "../../Constants/PaymentTracking";
import { serializeFetchSubscription } from "../../Helpers/HelperFns/PaymentTracking";

import {
  PaymentMethodCell,
  SubscriptionStatusChip,
} from "../../Components/PaymentTracking";
import {
  EditIconButton,
  PreviewIconButton,
} from "../../Components/IconButtonWithTooltip";
import {
  UpsertSubscriptionModal,
  EditAgentSubscriptionModal,
} from "../../Components/PaymentTrackingModals";
import { Link } from "react-router-dom";
import Loader from "../../Components/Loader";
import DataTable from "react-data-table-component";
import AntSwitch from "../../Components/AntSwitch";
import Pagination from "../../Components/Pagination";
import { AddButton } from "../../Components/Buttons";
import ReactShowMoreText from "react-show-more-text";
import { BSelect, DateTimePickerForm, RadioboxForm } from "form-builder";

const reducer = "paymentTracking";
const filtersFormName = "subscriptionsListFilters";
const FiltersFormProps = { reducer, formName: filtersFormName };
const modalInitState = { isOpen: false, data: null };
const modals = [
  { name: "upsert", Modal: UpsertSubscriptionModal },
  { name: "editAgent", Modal: EditAgentSubscriptionModal },
];
const paginationInitState = {
  total: 20,
  perPage: 20,
  lastPage: 1,
  lastItem: 20,
  firstItem: 1,
  currentPage: 1,
  hasMorePages: false,
};
const sendFilters = (filters) => {
  return {
    page: 1,
    status: filters.type,
    to: filters?.to || undefined,
    from: filters?.from || undefined,
    isSuspended: filters.isSuspended,
    company_id: +filters?.companyID || +filters.company || undefined,
    package_id: +filters.package || undefined,
    period: filters.billingPeriod || undefined,
  };
};

const SubscriptionsList = ({ companyID }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isCompanyProfile = Boolean(companyID);

  // Local State
  const [data, setData] = React.useState([]);
  const [pagination, setPagination] = React.useState(paginationInitState);
  const [modalsState, setModalsState] = React.useState(
    modals.map(({ name }) => ({ name, ...modalInitState }))
  );

  // Reducer State
  const filters = useSelector((state) => state?.[reducer]?.[filtersFormName]);
  const options = useSelector(
    (state) => state?.[reducer]?.paymentTrackingOptions
  );

  // Server State
  const { loading: isOptionsLoading } = useQuery(
    subscriptionsListOptionsQuery,
    {
      notifyOnNetworkStatusChange: true,
      onCompleted: ({ packages, countries, companies }) => {
        dispatch(
          onFormResetAction("paymentTrackingOptions", {
            ...options,
            companies: companies || [],
            countries: countries || [],
            packages: packages?.data || [],
          })
        );
      },
      onError: (err) => {
        showToast(
          "error",
          err?.graphQLErrors?.[0]?.extensions?.reason ||
            err?.graphQLErrors?.[0]?.message ||
            err?.message
        );
      },
    }
  );
  const { loading, refetch } = useQuery(subscriptionsListQuery, {
    notifyOnNetworkStatusChange: true,
    variables: {
      first: pagination?.perPage,
      page: pagination.currentPage,
      ...sendFilters({ companyID, ...filters }),
    },
    onCompleted: ({ subscriptions }) => {
      setData(
        subscriptions?.data
          ? subscriptions?.data.map(serializeFetchSubscription)
          : []
      );
      setPagination(subscriptions?.pagination || paginationInitState);
    },
    onError: (err) => {
      showToast(
        "error",
        err?.graphQLErrors?.[0]?.extensions?.reason ||
          err?.graphQLErrors?.[0]?.message ||
          err?.message
      );
    },
  });

  // Constants
  const columns = React.useMemo(
    () => [
      {
        name: t("company"),
        omit: isCompanyProfile,
        cell: ({ company }) => (
          <HasPrivileges
            reqireMain={[
              Privileges.SUPER_PRIVILEGE,
              Privileges.VIEW_COMPANY_PROFILE,
            ]}
            altChildren={company?.name}
          >
            <Link to={`/admin-panel/company-profile/${company?.id}`}>
              {company?.name}
            </Link>
          </HasPrivileges>
        ),
      },
      {
        name: t("Primary Company agent"),
        cell: (row) => row?.company?.primarySAAS?.user?.name,
      },
      {
        grow: 1.5,
        name: "agent",
        cell: (row) => (
          <>
            {row?.agent?.name}
            <HasPrivileges
              reqireMain={[
                Privileges.ADD_NEW_PAYMENT_SUBSCRIPTION,
                Privileges.SUPER_PRIVILEGE,
              ]}
            >
              <EditIconButton
                onClick={() =>
                  handleOpenModal("editAgent", {
                    from: row?.startDate,
                    agent: row?.agent?.id,
                    company_id: row?.company?.id,
                  })
                }
              />
            </HasPrivileges>
          </>
        ),
      },
      { name: t("plan"), cell: (row) => <div>{row?.plan}</div> },
      { name: t("Payment Type"), selector: "paymentType" },
      { name: t("start date"), selector: "startDate" },
      { name: t("end date"), selector: "endDate" },
      { name: t("Billing Period"), selector: "billingPeriod" },
      { name: t("current tier"), selector: "currentTier" },
      { name: t("rate per period"), selector: "ratePerPeriod" },
      { name: t("suspend after days"), selector: "suspend_after" },
      {
        name: t("status"),
        cell: ({ status }) => <SubscriptionStatusChip status={status} />,
      },
      {
        cell: ({ id }) => (
          <HasPrivileges
            reqireMain={[
              Privileges.VIEW_PAYMENT_SUBSCRIPTION_PROFILE,
              Privileges.SUPER_PRIVILEGE,
            ]}
          >
            <PreviewIconButton href={`/payment-tracking/subscription/${id}`} />
          </HasPrivileges>
        ),
      },
    ],
    []
  );

  /* ↓ State Effects ↓ */

  React.useEffect(() => {
    return () => {
      dispatch(onFormResetAction(filtersFormName));
    };
  }, []);

  /* ↓ Helpers ↓ */

  const handleOpenModal = (name, data = null) => {
    setModalsState((prev) =>
      prev.map((p) => (p.name === name ? { name, data, isOpen: true } : p))
    );
  };

  const handleCloseModal = (name) => {
    setModalsState((prev) =>
      prev.map((p) => (p.name === name ? { name, ...modalInitState } : p))
    );
  };

  const handleSuspendedToggle = () => {
    dispatch(
      onFormResetAction(filtersFormName, {
        ...filters,
        isSuspended: +!filters.isSuspended,
      })
    );
  };

  const handlePaginate = (page = pagination.currentPage) => {
    refetch({ page });
  };

  return (
    <>
      {/* Filters */}
      <div className="d-flex gap-20 flex-wrap pt-2">
        <BSelect
          {...FiltersFormProps}
          label="Billing period"
          name="billingPeriod"
          icon="calendar"
          rootStyle="flex-1"
          options={billingPeriodOptions}
          isClearable
        />
        {!isCompanyProfile ? (
          <BSelect
            {...FiltersFormProps}
            label="company"
            name="company"
            icon="department"
            rootStyle="flex-1"
            options={options.companies}
            isLoading={isOptionsLoading}
            isClearable
          />
        ) : null}
        <BSelect
          {...FiltersFormProps}
          label="package"
          name="package"
          icon="document"
          rootStyle="flex-1"
          options={options.packages}
          isLoading={isOptionsLoading}
          isClearable
        />
      </div>
      <div className="d-flex justify-content-between align-items-end gap-20 my-3">
        <DateTimePickerForm
          {...FiltersFormProps}
          label="from"
          name="from"
          hasIcon
          isClearable
          rootStyle="flex-1"
          containerStyle="pb-0"
          labelStyle="mb-2 pb-1"
          datePickerContainer="w-100"
          requestFormat="YYYY-MM-DD"
        />
        <DateTimePickerForm
          {...FiltersFormProps}
          label="to"
          name="to"
          hasIcon
          isClearable
          rootStyle="flex-1"
          containerStyle="pb-0"
          labelStyle="mb-2 pb-1"
          datePickerContainer="w-100"
          requestFormat="YYYY-MM-DD"
        />
        <div className=" flex-1 d-flex justify-content-between gap-20">
          <div className="d-flex gap-20 align-items-center">
            <div className="d-flex align-items-center gap-10">
              <span>All</span>
              <AntSwitch
                checked={filters?.isSuspended}
                onChange={handleSuspendedToggle}
              />
              <span>Suspended</span>
            </div>
            <RadioboxForm
              {...FiltersFormProps}
              name="type"
              options={[
                { label: "Active", value: "Active" },
                { label: "Inactive", value: "Inactive" },
                { label: "All", value: "All" },
              ]}
              optionItemStyle=" "
              optionInputStyle=" "
              containerStyle="mb-0"
              optionsContainerStyle="d-flex gap-10"
            />
          </div>
          {!isCompanyProfile ? (
            <HasPrivileges
              reqireMain={[
                Privileges.ADD_NEW_PAYMENT_SUBSCRIPTION,
                Privileges.SUPER_PRIVILEGE,
              ]}
            >
              <AddButton onClick={() => handleOpenModal("upsert")} />
            </HasPrivileges>
          ) : null}
        </div>
      </div>

      {/* Table */}
      <DataTable
        noHeader
        data={data}
        columns={columns}
        className="cards_table expandable"
        progressPending={loading}
        progressComponent={<Loader inner />}
        expandableRows
        expandableRowsComponent={<PaymentsChildList />}
        pagination
        paginationServer
        paginationComponent={() => (
          <Pagination
            styleWraper=""
            onPaginate={handlePaginate}
            customPaginator={pagination}
          />
        )}
      />

      {/* Modals */}
      {modals.map(({ name, Modal }, i) =>
        modalsState[i].isOpen ? (
          <Modal
            key={name}
            refetchList={refetch}
            data={modalsState[i]?.data}
            onClose={() => handleCloseModal(name)}
          />
        ) : null
      )}
    </>
  );
};

export default SubscriptionsList;

const PaymentsChildList = (props) => {
  const { t } = useTranslation();

  const columns = React.useMemo(
    () => [
      {
        grow: 1.5,
        name: t("Details"),
        cell: ({ details = "" }) => (
          <ReactShowMoreText>{details}</ReactShowMoreText>
        ),
      },
      { name: t("expected Amount"), selector: "amount", center: true },
      { name: t("actual Amount"), selector: "receivedAmount", center: true },
      {
        name: t("Method"),
        selector: ({ method }) => <PaymentMethodCell method={method} />,
      },
      { name: t("suspension date"), selector: "dueDate" },
      { name: t("Due Date"), selector: "from" },
      // {
      //   width: "110px",
      //   name: t("Invoice"),
      //   cell: (row) =>
      //     ![Stats.GENERATED, Stats.SENT, Stats.PAID, Stats.CANCELLED].includes(
      //       row?.status
      //     ) ? (
      //       <>
      //         {/* TODO: Fix Privilege */}
      //         <ColorButton
      //           isIconOnly
      //           color="#2764AC"
      //           variant="outlined"
      //           label="Generate Invoice"
      //           icon={<UploadFile fontSize="small" />}
      //           sx={{ width: 30, height: 30 }}
      //           onClick={() => handleGenerate(row?.id)}
      //         />
      //         <ColorButton
      //           isIconOnly
      //           color="#7b8288"
      //           variant="outlined"
      //           label="Preview Invoice"
      //           icon={<Visibility fontSize="small" />}
      //           sx={{ width: 30, height: 30, mx: 1 }}
      //           onClick={() => handlePreviewInvoice({ ...row })}
      //         />
      //       </>
      //     ) : (
      //       <button
      //         className="link-btn"
      //         onClick={() => handlePreviewInvoice({ ...row })}
      //       >
      //         {row?.invoice}
      //       </button>
      //     ),
      // },
      { name: t("Status"), selector: "status" },
    ],
    []
  );

  return (
    <DataTable
      noHeader
      columns={columns}
      className="cards_table pt-3 px-2"
      data={props?.data?.payments || []}
      customStyles={{
        headRow: {
          style: { minHeight: "40px !important" },
        },
        rows: {
          style: { minHeight: "40px !important" },
        },
      }}
    />
  );
};
