import React from "react";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  Edit,
  PlayCircleOutline,
  PauseCircleOutline,
  DeleteForever,
  VpnKey,
  Email,
  LocalPhone,
  Home,
  ArrowDownward,
  ArrowUpward,
  KeyboardArrowDown,
} from "@mui/icons-material";
import {
  toggleActivationModal,
  addEmployeeIdToActivation,
  editEmployeeAction,
  editEmployeePrivilegesAction,
  sendPhoneVerificationAction,
  sendEmailVerificationAction,
  resendCredentailsAction,
  fetchInitDeleteManagerAction,
  fetchInitSuspendManagerAction,
  toggleActivationSuspensionRecordsModal,
} from "../../Store/Actions";
import { connect, useDispatch } from "react-redux";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import Pagination from "../../Components/Pagination";
import { faLockOpen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Table from "rc-table";
import HelperFns from "../../Helpers/HelperFns";
import { Sms, WhatsApp } from "@mui/icons-material";
import RenderMultipleSignIns from "../../Components/RenderMultipleSignIns";
import { Box } from "@mui/material";
import { ColorButton } from "../../Components/Buttons";

const HrEmployeesList = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  /* ↓ Helpers ↓ */

  // handle render component
  const shouldRenderComponent = (reqirePrivileges, employee) => {
    if (
      (HelperFns.checkPrivileges({
        allowBP: false,
        scope: "managed",
        privileges: reqirePrivileges,
      }) &&
        HelperFns.isManagedByAuth(
          employee?.manager?.id,
          employee?.copied_managers?.map((user) => user?.id)
        )) ||
      HelperFns.checkPrivileges({
        allowBP: true,
        scope: "all",
        privileges: reqirePrivileges,
      })
    ) {
      return true;
    }
    return false;
  };

  // to approximate the numbers of remaning balance
  function remaningBalanceCalcualtion(num) {
    let roundNumToSeven = (+num)?.toFixed(7);
    let roundNumToTwo = (+roundNumToSeven)?.toFixed(2);
    return Math.abs(roundNumToTwo) == 0 ? 0 : roundNumToTwo;
  }

  // handle open activation and suspension modal fucntion
  const handleToggleActivationSuspensionModal = (modalData) => {
    dispatch(
      toggleActivationSuspensionRecordsModal({ isOpen: true, modalData })
    );
  };

  const handleSuspensionBtn = (row) => {
    // props.fetchInitSuspendManagerAction(row?.id);

    // handle open activation suspension modal
    handleToggleActivationSuspensionModal(row);
  };

  const handleActivationBtn = (row) => {
    // props.toggleActivationModal(
    //   props?.attendance_profile?.activeWorkTiming?.overtime_permissible,
    //   props?.office?.country?.id
    // );
    // props.addEmployeeIdToActivation(
    //   props.id,
    //   props?.office,
    //   props?.office?.country?.id
    // );

    // handle open activation suspension modal
    handleToggleActivationSuspensionModal(row);
  };

  const handleDeleteUser = (row) => {
    props.fetchInitDeleteManagerAction(row?.id);
  };

  const handleEditBtn = (row) => {
    props.editEmployeeAction(row?.id, row?.starts_at);
  };

  const handleVerifyPhoneBtn = (row, send_via) => {
    if (
      row?.phone_verified ||
      row?.user_type == "Owner" ||
      !props.hasActiveSubscription
    ) {
      return;
    }
    props.sendPhoneVerificationAction(null, row?.id, false, send_via);
  };

  const handleVerifyEmailBtn = (row) => {
    if (
      row?.email_verified ||
      row?.user_type === "Owner" ||
      !props.hasActiveSubscription
    ) {
      return;
    }
    props.sendEmailVerificationAction(null, row?.id, false);
  };

  // for resend credentails action
  const handleResendCredentials = (row) => {
    props.resendCredentailsAction(row?.id);
  };

  // for manage privilages modal
  const handleManagePrivilagesModal = (row) => {
    props.editEmployeePrivilegesAction(row?.id, props?.planId, props?.name);
  };

  const columns = [
    {
      width: 600,
      title: t("name"),
      wrap: true,
      align: "center",
      dataIndex: "name",
      key: "name",
      className: "name-col",
      render: (value, row) => (
        <div className="d-flex flex-column align-items-center">
          <div>
            <HasPrivileges
              reqireMain={[
                Privilages.VIEW_EMPLOYEE_PROFILES,
                Privilages.VIEW_EMPLOYEE_SALARY_CONFIGURATION,
                Privilages.VIEW_EMPLOYEE_ATTENDANCE_TYPE_CONFIGURATIONS,
                Privilages.VIEW_EMPLOYEE_PETTY_CASH_BALANCE_IN_EMPLOYEE_PROFILE,
                Privilages.VIEW_EMPLOYEE_DOCUMENTS,
                Privilages.VIEW_EMPLOYEE_SCHEDULES,
              ]}
              allowBP
              altChildren={<>{row?.name?.toLowerCase()}</>}
            >
              {row?.user_type == "Owner" ? (
                <>{row?.name?.toLowerCase()}</>
              ) : (
                <Link
                  to={`/employees/employee-profile/${row.id}`}
                  className="cursor-pointer"
                >
                  {row?.name?.toLowerCase()}
                </Link>
              )}
            </HasPrivileges>
          </div>

          {row?.emp_code ? (
            <span className="mt-1 employee_code">{row?.emp_code}</span>
          ) : null}

          <RenderMultipleSignInsList
            data={row}
            employeesDetails={props?.employeesDetails}
            openSignOnBehalfModal={props?.openSignOnBehalfModal}
          />
        </div>
      ),
    },
    ...(props?.employeeListColumns?.phone
      ? [
          {
            width: 50,
            title: "",
            wrap: true,
            align: "center",
            dataIndex: "phone",
            key: "phone",
            className: "phone-col",
            render: (value, row) => (
              <>
                {row?.has_credentials ? (
                  <>
                    {row?.phone ? (
                      <div className="employee_card_btns_wrapper_style fit-width">
                        <Tooltip
                          classes={{
                            tooltip: "custom_tooltip_style",
                            arrow: "custom_arrow_tooltip_style",
                          }}
                          interactive
                          arrow={true}
                          title={
                            <>
                              <div className="pb-0 text-center pt-2">
                                {/* <div className="col">
                                    <label>{t("email")}</label>
                                  </div> */}
                                <div>
                                  <p className="other_employee_info_style mb-1 text-lowercase px-2">
                                    {/* <label className="mr-2">
                                        {t("email")}
                                      </label> */}
                                    {row?.phone}
                                  </p>
                                </div>
                              </div>
                              <HasPrivileges
                                allowBP
                                reqireMain={[Privilages.VERIFY_PHONE_EMAIL]}
                              >
                                {!row?.phone_verified &&
                                row?.active &&
                                row?.user_type !== "Owner" &&
                                row?.user_type !== "Office" &&
                                props?.hasActiveSubscription ? (
                                  <>
                                    <div className="click-to-verify-message">
                                      {t("verify via")}
                                    </div>
                                    <div className="veriy-phone-buttons-container">
                                      <Tooltip title={t("sms")}>
                                        <button
                                          onClick={() =>
                                            handleVerifyPhoneBtn(row, "sms")
                                          }
                                          className="verify-phone-button"
                                        >
                                          <Sms className="verify-phone-sms-icon" />
                                        </button>
                                      </Tooltip>
                                      <span className="verify-phone-icons-separator"></span>
                                      <Tooltip title={t("whatsapp")}>
                                        <button
                                          onClick={() =>
                                            handleVerifyPhoneBtn(
                                              row,
                                              "whatsapp"
                                            )
                                          }
                                          className="verify-phone-button"
                                        >
                                          <WhatsApp className="verify-phone-whatsapp-icon" />
                                        </button>
                                      </Tooltip>
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}
                              </HasPrivileges>
                            </>
                          }
                        >
                          <div className="d-flex">
                            <HasPrivileges
                              allowBP
                              reqireMain={[Privilages.VERIFY_PHONE_EMAIL]}
                              altChildren={
                                <div className="mx-2 tooltip_icon_btn_wrapper_style">
                                  <LocalPhone
                                    sx={{
                                      fontSize: 12,
                                      color:
                                        row?.phone_verified &&
                                        row?.user_type !== "Owner"
                                          ? "#27b40c"
                                          : "#313030",
                                    }}
                                  />
                                </div>
                              }
                            >
                              <div className="mx-2 tooltip_icon_btn_wrapper_style">
                                <LocalPhone
                                  sx={{
                                    fontSize: 12,
                                    color:
                                      row?.phone_verified &&
                                      row?.user_type !== "Owner"
                                        ? "#27b40c"
                                        : "#313030",
                                  }}
                                />
                              </div>
                            </HasPrivileges>
                          </div>
                        </Tooltip>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
              </>
            ),
          },
        ]
      : []),
    ...(props?.employeeListColumns?.email
      ? [
          {
            width: 50,
            title: "",
            wrap: true,
            align: "center",
            dataIndex: "email",
            key: "email",
            className: "email-col",
            render: (value, row) => (
              <>
                {row?.has_credentials ? (
                  <>
                    {row?.email ? (
                      <div className="employee_card_btns_wrapper_style fit-width">
                        <Tooltip
                          classes={{
                            tooltip: "custom_tooltip_style",
                            arrow: "custom_arrow_tooltip_style",
                          }}
                          arrow={true}
                          title={
                            <React.Fragment>
                              <div className="row pb-0">
                                <div className="col-4">
                                  <label>{t("email")}</label>
                                </div>
                                <div className="col-8">
                                  <p className="other_employee_info_style mb-2 text-lowercase">
                                    {row?.email}
                                  </p>
                                </div>
                              </div>
                            </React.Fragment>
                          }
                        >
                          <div className="d-flex">
                            <HasPrivileges
                              allowBP
                              reqireMain={[Privilages.VERIFY_PHONE_EMAIL]}
                              altChildren={
                                <div className="tooltip_icon_btn_wrapper_style">
                                  <Email
                                    sx={{
                                      fontSize: 12,
                                      color:
                                        row?.email_verified &&
                                        row?.user_type !== "Owner"
                                          ? "#27b40c"
                                          : "#313030",
                                    }}
                                  />
                                </div>
                              }
                            >
                              <div
                                className="tooltip_icon_btn_wrapper_style"
                                onClick={() => handleVerifyEmailBtn(row)}
                              >
                                <Email
                                  sx={{
                                    fontSize: 12,
                                    color:
                                      row?.email_verified &&
                                      row?.user_type !== "Owner"
                                        ? "#27b40c"
                                        : "#313030",
                                  }}
                                />
                              </div>
                            </HasPrivileges>
                          </div>
                        </Tooltip>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
              </>
            ),
          },
        ]
      : []),
    {
      width: 550,
      title: t("username"),
      className: "office-col",
      wrap: true,
      align: "center",
      dataIndex: "office",
      key: "office",
      render: (value, row) => <div>{row?.user_alias}</div>,
    },
    ...(props?.employeeListColumns?.office
      ? [
          {
            width: 550,
            title: t("office"),
            className: "office-col",
            wrap: true,
            align: "center",
            dataIndex: "office",
            key: "office",
            render: (value, row) => <div>{row?.office?.name}</div>,
          },
        ]
      : []),
    ...(props?.employeeListColumns?.department
      ? [
          {
            width: 600,
            title: t("department"),
            wrap: true,
            align: "center",
            dataIndex: "department",
            key: "department",
            className: "department-col",
            render: (value, row) => <div>{row?.department?.name}</div>,
          },
        ]
      : []),
    ...(props?.employeeListColumns?.position
      ? [
          {
            width: 600,
            title: t("position"),
            wrap: true,
            align: "center",
            dataIndex: "position",
            key: "position",
            className: "position-col",
            render: (value, row) => <div>{row?.position?.name}</div>,
          },
        ]
      : []),
    ...(props?.employeeListColumns?.attendance_profile
      ? [
          {
            width: 600,
            title: t("attendance profile"),
            wrap: true,
            align: "center",
            dataIndex: "attendance_profile",
            key: "attendance_profile",
            className: "profile-col",
            render: (value, row) => (
              <div dir="auto">{row?.attendance_profile?.name}</div>
            ),
          },
        ]
      : []),
    ...(props?.employeeListColumns?.direct_manager ||
    props?.employeeListColumns?.copied_manager
      ? [
          {
            width: 600,
            title: t("Managers"),
            wrap: true,
            align: "center",
            dataIndex: "manager",
            key: "manager",
            className: "managers-col",
            render: (value, row) => (
              <div>
                {props?.employeeListColumns?.direct_manager ? (
                  <div>{row?.manager?.name}</div>
                ) : (
                  ""
                )}

                {props?.employeeListColumns?.copied_manager ? (
                  <>
                    {row?.copied_managers?.map((copiedManagers) => (
                      <div>{copiedManagers?.name}</div>
                    ))}
                  </>
                ) : (
                  ""
                )}
              </div>
            ),
          },
        ]
      : []),
    ...(props?.employeeListColumns?.joining_date
      ? [
          {
            width: 550,
            title: t("joining date"),
            className: "joining-col",
            wrap: true,
            align: "center",
            dataIndex: "joining_date",
            key: "joining_date",
            render: (value, row) => (
              <div>{moment(row?.joining_date).format("DD - MM - YYYY")}</div>
            ),
          },
        ]
      : []),
    ...(props?.employeeListColumns?.remaining_annual_leaves ||
    props?.employeeListColumns?.remaining_emergency_leaves ||
    props?.employeeListColumns?.remaining_sick_leaves
      ? [
          {
            title: t("leaves"),
            wrap: true,
            align: "center",
            className: "leaves-col col-group",
            children: [
              ...(props?.employeeListColumns?.remaining_annual_leaves
                ? [
                    {
                      width: 300,
                      title: t("annual"),
                      key: "remaining_annual_leaves",
                      align: "center",
                      className: "normal-col",
                      render: (value, row) => (
                        <div>
                          {row?.employee?.balance?.remaining_annual_leaves !==
                          undefined
                            ? remaningBalanceCalcualtion(
                                row?.employee?.balance?.remaining_annual_leaves
                              )
                            : "----"}
                        </div>
                      ),
                    },
                  ]
                : []),
              ...(props?.employeeListColumns?.remaining_emergency_leaves
                ? [
                    {
                      width: 300,
                      title: t("emergency"),
                      key: "remaining_emergency_leaves",
                      align: "center",
                      className: "emergency-col",
                      render: (value, row) => (
                        <div>
                          {row?.employee?.balance
                            ?.remaining_emergency_leaves !== undefined
                            ? remaningBalanceCalcualtion(
                                Math.max(
                                  Math.min(
                                    +row?.employee?.balance
                                      ?.remaining_emergency_leaves,
                                    +row?.employee?.balance
                                      ?.remaining_annual_leaves
                                  ),
                                  0
                                )
                              )
                            : "----"}
                        </div>
                      ),
                    },
                  ]
                : []),
              ...(props?.employeeListColumns?.remaining_sick_leaves
                ? [
                    {
                      width: 300,
                      title: t("sick"),
                      key: "remaining_sick_leaves",
                      align: "center",
                      className: "sick-col",
                      render: (value, row) => (
                        <div>
                          {row?.employee?.balance?.remaining_sick_leaves !==
                          undefined
                            ? remaningBalanceCalcualtion(
                                row?.employee?.balance?.remaining_sick_leaves
                              )
                            : "----"}
                        </div>
                      ),
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
    ...(props?.employeeListColumns?.remaining_permissions
      ? [
          {
            width: 300,
            title: t("permissions"),
            className: "permissions-col",
            wrap: true,
            align: "center",
            dataIndex: "balance",
            key: "balance",
            render: (value, row) => (
              <div>
                {row?.balance?.remaining_permissions !== undefined
                  ? remaningBalanceCalcualtion(
                      row?.balance?.remaining_permissions
                    )
                  : "----"}
              </div>
            ),
          },
        ]
      : []),
    {
      width: 650,
      title: "",
      wrap: true,
      align: "center",
      dataIndex: "id",
      key: "action",
      right: true,

      render: (value, row) =>
        shouldRenderComponent([Privilages.VIEW_EMPLOYEES], row) && (
          <div className="dropdown hr_employee_icons_wrapper_style mx-3">
            <HasPrivileges
              reqireMain={[Privilages.ADD_EDIT_DELETE_EMPLOYEES]}
              allowBP
              avalibleOnExpire={false}
              altExpireChildren={<Edit className="edit-icon-style" />}
            >
              {row?.user_type == "Owner" ? null : !row?.checkPassword &&
                row?.has_credentials ? (
                <VpnKey
                  className="resend-icon-style"
                  onClick={() => handleResendCredentials(row)}
                />
              ) : null}

              {row?.user_type === "Owner" ? (
                ""
              ) : (
                <Edit
                  className="edit-icon-style"
                  onClick={() => handleEditBtn(row)}
                />
              )}
            </HasPrivileges>

            <HasPrivileges
              reqireMain={[Privilages.ACTIVATE_SUSPEND_EMPLOYEES]}
              allowBP
              avalibleOnExpire={false}
              altExpireChildren={
                row?.user_type === "Owner" ? (
                  ""
                ) : row.active ? (
                  <PauseCircleOutline className="pause-icon-style" />
                ) : (
                  <PlayCircleOutline className="play-icon-style" />
                )
              }
            >
              {row?.user_type == "Owner" ? (
                ""
              ) : row.active ? (
                <PauseCircleOutline
                  className="pause-icon-style"
                  onClick={() => handleSuspensionBtn(row)}
                />
              ) : (
                <PlayCircleOutline
                  className="play-icon-style"
                  onClick={() => handleActivationBtn(row)}
                />
              )}
            </HasPrivileges>

            <HasPrivileges
              reqireMain={[Privilages.ADD_EDIT_DELETE_EMPLOYEES]}
              allowBP
              avalibleOnExpire={false}
              altExpireChildren={
                <DeleteForever className="delete-icon-style" />
              }
            >
              {row?.user_type === "Owner" ? (
                ""
              ) : (
                <DeleteForever
                  className="delete-icon-style"
                  onClick={() => handleDeleteUser(row)}
                />
              )}
            </HasPrivileges>

            <HasPrivileges
              reqireMain={[Privilages.EDIT_USER_PRIVILEGE]}
              allowBP
              avalibleOnExpire={false}
              altExpireChildren={
                <span>
                  <FontAwesomeIcon
                    className="manage-icon-style"
                    icon={faLockOpen}
                  />
                </span>
              }
            >
              {row?.user_type === "Owner" ? (
                ""
              ) : (
                <FontAwesomeIcon
                  className="manage-icon-style"
                  onClick={() => handleManagePrivilagesModal(row)}
                  icon={faLockOpen}
                />
              )}
            </HasPrivileges>
          </div>
        ),
      ignoreRowClick: true,
    },
  ];

  return (
    <>
      <div className="content requests-profile-data-list">
        <Table
          className="employee_list_datatabel_wrapper_style datatable_shadow_style"
          prefixCls="employees-list"
          columns={columns}
          data={props?.data}
          useFixedHeader
          tableLayout
          emptyText={
            <div className="text-center">{t("no data available")}</div>
          }
          scroll={{ x: 1500 }}
          style={{ width: "auto" }}
          onRow={(record, index) => ({
            className: `row-${index}`,
          })}
          footer={() => (
            <Pagination
              tableRef={props?.dataTableRef}
              styleWraper=""
              onPaginate={props?.handlePaginate}
              reducer="super"
            />
          )}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    planId: state.auth.userProfile?.company?.activeSubscription?.plan?.id,
    hasActiveSubscription:
      state.auth.userProfile?.company?.hasActiveSubscription,
    data: state.super?.allEmployeesList?.data,
    employeeListColumns: state.super.employeeListColumns,
    employeesDetails: state?.super?.allEmployeesList?.employeesDetails,
  };
};
export default connect(mapStateToProps, {
  toggleActivationModal,
  addEmployeeIdToActivation,
  editEmployeeAction,
  editEmployeePrivilegesAction,
  sendPhoneVerificationAction,
  sendEmailVerificationAction,
  resendCredentailsAction,
  fetchInitDeleteManagerAction,
  fetchInitSuspendManagerAction,
})(HrEmployeesList);

const RenderMultipleSignInsList = (props) => {
  const { t } = useTranslation();
  const emp_multiple_sign_ins =
    props?.employeesDetails?.find((emp) => emp?.emp_id === props?.data?.id)
      ?.details || [];

  if (emp_multiple_sign_ins?.length <= 1) {
    const getCurrentSignIn = () => emp_multiple_sign_ins?.[0] || null;
    const canSignOnBehalf = HelperFns.checkPrivileges({
      allowBP: true,
      privileges: [Privilages.SIGN_IN_OUT_ON_BEHALF_OF_EMPLOYEES],
    });
    const handleOpenSignOnBehalfModal = (isIn) => {
      const { name, id } = props?.data;
      const { date, ...data } = getCurrentSignIn();

      props?.openSignOnBehalfModal({
        ...data,
        selectedDate: date,
        name,
        id,
        isIn,
      });
    };

    return (
      <>
        <div
          className={`mt-2 d-flex ${
            canSignOnBehalf &&
            emp_multiple_sign_ins?.length === 1 &&
            !!!getCurrentSignIn()?.status
              ? ""
              : "employee-sign-ins-container"
          } ${
            props?.data?.active
              ? `${getCurrentSignIn()?.status}_status_style`
              : "suspend_status_style"
          }`}
        >
          <Tooltip
            title={getCurrentSignIn()?.work_timing_name}
            className="d-flex align-items-center"
          >
            {getCurrentSignIn() === null ? (
              t("not signed in")
            ) : getCurrentSignIn()?.sign_in_time ? (
              <div className="d-flex align-items-center">
                {+getCurrentSignIn()?.from_home ? (
                  +getCurrentSignIn()?.from_field ? (
                    <img
                      src="assets/img/street-wifi-blue.svg"
                      width="18"
                      height="18"
                      className="img"
                      alt=""
                      aria-hidden="true"
                    />
                  ) : (
                    <Home
                      sx={{
                        fontSize: 12,
                        color: "#313030",
                        marginInlineEnd: 0.25,
                      }}
                    />
                  )
                ) : null}
                <div
                  style={{
                    fontSize: emp_multiple_sign_ins?.length > 1 ? 10 : 11,
                  }}
                >
                  <>
                    <ArrowDownward sx={{ fontSize: 11, color: "#27b40c" }} />
                    {getCurrentSignIn()?.sign_in_time
                      ? moment(getCurrentSignIn()?.sign_in_time).format(
                          "hh:mm A"
                        )
                      : " _ _ : _ _"}
                    <span className="px-1">-</span>
                    {canSignOnBehalf &&
                    emp_multiple_sign_ins?.length === 1 &&
                    !getCurrentSignIn()?.sign_out_time ? null : (
                      <>
                        <ArrowUpward sx={{ fontSize: 11, color: "#ff6a6a" }} />
                        {getCurrentSignIn()?.sign_out_time
                          ? moment(getCurrentSignIn()?.sign_out_time).format(
                              "hh:mm A"
                            )
                          : "_ _ : _ _"}
                      </>
                    )}
                  </>
                </div>
              </div>
            ) : (
              <div>
                {props?.data?.user_type === "Owner" ||
                (props?.active && !getCurrentSignIn()?.sign_in_req)
                  ? t("present")
                  : props?.data?.active !== undefined
                    ? props?.data?.active
                      ? canSignOnBehalf &&
                        emp_multiple_sign_ins?.length === 1 &&
                        !!!getCurrentSignIn()?.status
                        ? null
                        : t(getCurrentSignIn()?.status?.toLowerCase())
                      : t("suspended")
                    : ""}
              </div>
            )}
          </Tooltip>
        </div>

        {/* Sign in/out on behalf */}
        {getCurrentSignIn()?.sign_in_req && canSignOnBehalf ? (
          <div>
            {getCurrentSignIn()?.sign_in_time ? (
              !getCurrentSignIn()?.sign_out_time ? (
                <ColorButton
                  label="sign out"
                  color="#ff6a6a"
                  variant="outlined"
                  sx={{ height: 27, mt: 1 }}
                  onClick={() => handleOpenSignOnBehalfModal(false)}
                />
              ) : null
            ) : !!!getCurrentSignIn()?.status ? (
              <ColorButton
                label="sign in"
                color="#238f27"
                variant="outlined"
                sx={{ height: 27 }}
                onClick={() => handleOpenSignOnBehalfModal(true)}
              />
            ) : null}
          </div>
        ) : null}
      </>
    );
  }

  return (
    <div className="dropdown-icon-container mt-2">
      <Tooltip
        classes={{
          arrow: "custom_arrow_tooltip_style",
          tooltip: "custom-employee-card-tooltip",
          popper: "custom_employee_name_tooltip_style",
        }}
        arrow
        color="#333"
        title={
          <div>
            {emp_multiple_sign_ins?.map((signIn, index) => (
              <RenderMultipleSignIns
                index={index}
                signIn={signIn}
                signInTime={signIn?.sign_in_time}
                emp={{ id: props?.data?.id, name: props?.data?.name }}
                openSignOnBehalfModal={props?.openSignOnBehalfModal}
              />
            ))}
          </div>
        }
      >
        <div className="position-relative">
          <Box
            component="span"
            sx={{
              position: "absolute",
              color: "white",
              width: 16,
              height: 16,
              fontSize: 9,
              backgroundColor: "green",
              zIndex: 999,
              borderRadius: "50%",
              display: "grid",
              alignItems: "center",
              top: -6,
              right: -6,
            }}
          >
            {emp_multiple_sign_ins?.length}
          </Box>
          <KeyboardArrowDown className="dropdown-icon" />
        </div>
      </Tooltip>
    </div>
  );
};
